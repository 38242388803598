import React, { Fragment, useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Badge, Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { useUsers } from 'hooks';
import { MESSAGES, userRole, userType } from '../../constants';
import { UserAttendanceList, EditUserInfoModal } from 'components/Users';
import { UserModal } from 'components/Users/UserPreference';
import CustomSwitch from 'components/ui/CustomSwitch';
import { confirmSwal, errorSwal, successSwal } from 'utilities';

const UserDetail = () => {
  const history = useHistory();
  const { userId } = useParams();

  const { userInfo, editUserStatus, editUserExpired, resetPassword } = useUsers(
    { userId }
  );
  const { data: userInfoData } = userInfo || {};

  const [moreModal, setMoreModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [moreModalContent, setMoreModalContent] = useState({
    post: '',
    boardType: '',
  });

  const [userStatus, setUserStatus] = useState({
    status: '',
    expire: '',
  });

  useEffect(() => {
    setUserStatus({
      ...userStatus,
      status: userInfoData?.status,
      expire: userInfoData?.expireYn,
    });
  }, [userInfoData]);

  const handleBack = () => {
    history.goBack();
  };

  const handleStatusToggle = async (use, userId) => {
    let statusValue = 1;
    if (use) {
      statusValue = 0;
    }
    const { status } = await editUserStatus.mutateAsync({
      userId,
      status: statusValue,
    });
    if (status === 200) {
      await successSwal({ text: '상태가 변경되었습니다.' });
    }
  };

  const handleExpireToggle = async (use, userId) => {
    let yn = 'Y';
    if (use) {
      yn = 'N';
    }
    const { status } = await editUserExpired.mutateAsync({ userId, yn });
    if (status === 200) {
      await successSwal({ text: '상태가 변경되었습니다.' });
    }
  };

  // const openMoreModal = (post, boardType) => {
  //   setMoreModal((prev) => !prev);
  //   setMoreModalContent({ post: post, boardType: boardType });
  // };

  const handleOpenEdit = () => setEditModal(true);

  const handleResetPassword = async () => {
    const confirm = await confirmSwal({
      title: '해당 유저의 비밀번호를 초기화합니다.',
      text: '초기화 되는 비밀번호는 "QsfEtg1R47" 입니다. \n 초기화를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await resetPassword.mutateAsync({ userId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.USER_PASSWORD_RESET_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.USER_PASSWORD_RESET_FAIL });
      }
    }
  };

  return (
    <Fragment>
      <div className="profile card card-body px-3 pt-3 pb-0">
        <div className="profile-head">
          <div className="profile-info">
            <div className="profile-details">
              <div className="profile-name px-3">
                <Badge variant="dark badge-sm mb-2">사용자 ID</Badge>
                <h4 className="text-primary ml-1">{userInfoData?.userId}</h4>
              </div>
              <div className="profile-name px-3">
                <Badge variant="dark badge-sm mb-2">사용자 이름</Badge>
                <h4 className="text-primary ml-1">{userInfoData?.name}</h4>
              </div>
              <div className="profile-name px-3">
                <Badge variant="dark badge-sm mb-2">사용자 유형</Badge>
                <h4 className="text-primary ml-1">
                  {userRole[userInfoData?.type - 1]}
                </h4>
              </div>
              <div className="profile-name px-3">
                <Badge variant="dark badge-sm mb-2">이메일</Badge>
                <h4 className="text-primary ml-1">{userInfoData?.email}</h4>
              </div>
              <div className="profile-name px-3">
                <Badge variant="dark badge-sm mb-2">전화번호</Badge>
                <h4 className="text-primary ml-1">{userInfoData?.phone}</h4>
              </div>
              <div className="profile-name px-3">
                {userInfoData?.cloudAccountDto?.username && (
                  <Badge variant="dark badge-sm mb-2">클라우드 계정</Badge>
                )}
                <h4 className="text-primary ml-1">
                  {userInfoData?.cloudAccountDto?.username}
                </h4>
              </div>
              <div className="ml-auto">
                <div
                  onClick={handleBack}
                  className="btn btn-primary px-3 light"
                >
                  <i className="fa fa-reply"></i>
                </div>
                <div
                  className="btn btn-primary px-3 light ml-2"
                  onClick={() => handleOpenEdit(userInfoData)}
                >
                  <i className="fa fa-edit"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="d-flex mb-5 ml-5">
            <Badge
              variant="dark badge-sm mr-3 d-flex align-items-center"
              style={{ opacity: '0.9' }}
            >
              <span className="mr-3">STATUS</span>
              <CustomSwitch
                isTrue={userInfoData?.status === 1}
                data={userInfoData?.userId}
                handleToggle={handleStatusToggle}
              />
            </Badge>

            <Badge
              variant="dark badge-sm d-flex align-items-center mr-3"
              style={{ opacity: '0.9' }}
            >
              <span className="mr-3">EXPIRE</span>
              <CustomSwitch
                isTrue={userInfoData?.expireYn === 'Y'}
                data={userInfoData?.userId}
                handleToggle={handleExpireToggle}
              />
            </Badge>

            {userInfoData?.type === 1 && (
              <Badge
                variant="dark badge-sm d-flex align-items-center mr-3"
                style={{ opacity: '0.9' }}
              >
                <span className="mr-3">비밀번호 초기화</span>
                <PasswordResetButton onClick={handleResetPassword}>
                  초기화
                </PasswordResetButton>
              </Badge>
            )}
          </div>
        </div>
      </div>

      {moreModal && (
        <Modal
          show={true}
          size="xl"
          onHide={() => setMoreModal(false)}
          className="modal fade"
          id="moreModal"
        >
          <UserModal
            author={userInfoData?.name}
            moreModalContent={moreModalContent}
          ></UserModal>
        </Modal>
      )}

      {/* 유저 출결관리 컴포넌트 */}
      {userType[userInfoData?.type] === 'Student' && (
        <UserAttendanceList userInfo={userInfoData || {}} />
      )}

      {/* 유저 수정 모달 */}
      <EditUserInfoModal editModal={editModal} setEditModal={setEditModal} />
    </Fragment>
  );
};

const PasswordResetButton = styled.div`
  padding: 5px 10px;
  background-color: #ec7422;
  border-radius: 10px;
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    background-color: #f35757;
  }
`;

export default UserDetail;
