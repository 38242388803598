import React, { Fragment, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';
import ReactTooltip from 'react-tooltip';

import { UserSection } from 'components/common';
import { useClassBoard, useDownloadClassFile } from 'hooks';
import { confirmSwal, successSwal } from 'utilities/swal';
import ClassBoardComment from 'components/Board/ClassBoardComment';
import { MESSAGES } from 'constants';
import { CardBody, Wrapper } from 'components/layouts/common';
import { CLASS_CATEGORY_URL } from '../../constants';

const ClassBoardDetail = () => {
  const { id } = useParams();
  let history = useHistory();

  const [textValue, setTextValue] = useState('');

  const { boardDetail, boardAttachments, deleteBoard, boardReply } =
    useClassBoard({
      postId: id,
    });
  const { data } = boardDetail || {};
  const { data: lcFiles } = boardAttachments || {};
  const { data: replyies } = boardReply || {};

  const { isLoading, setFileId } = useDownloadClassFile({ postId: id });

  const handleFileDownload = (fileId) => {
    if (isLoading) return;

    setFileId(fileId);
  };

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteBoard.mutateAsync({ postId: id });
      if (status === 204) {
        history.push(CLASS_CATEGORY_URL[data?.lcPostCategoryId - 1]);
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  useEffect(() => {
    if (!data) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${data?.classId}/${id}`);
    }

    const parsingValue = updateImagePaths(data?.content);

    setTextValue(parsingValue);
  }, [data]);

  const handleBack = () => history.goBack();
  const handleGoToEdit = () => history.push(`/class-board-edit/${id}`);

  return (
    <Fragment>
      <Wrapper>
        <CardBody>
          <div className="col-12">
            <div className="toolbar mb-2" role="toolbar">
              <div className="bootstrap-badge">
                <Badge variant="secondary badge-sm mr-2">
                  BoardType : {data?.lcPostCategoryName}
                </Badge>
              </div>
            </div>
            <div className="read-content">
              <div className="media pt-3 d-sm-flex d-block justify-content-between">
                <UserSection
                  dataA={data?.userName}
                  dataB={data?.userId}
                  dataC={
                    data?.modifiedAt !== data?.createdAt
                      ? `${(data?.modifiedAt || '')
                          .replace('T', ` `)
                          .slice(0, 16)}(수정됨)`
                      : (data?.createdAt || '').replace('T', ` `).slice(0, 16)
                  }
                />
                <div className="clearfix mb-3">
                  <ReactTooltip place="bottom" />
                  <div
                    data-tip="뒤로가기"
                    onClick={handleBack}
                    className="btn btn-primary px-3 light"
                  >
                    <i className="fa fa-reply"></i>
                  </div>

                  <div
                    data-tip="수정하기"
                    onClick={handleGoToEdit}
                    className="btn btn-primary px-3 light ml-2"
                  >
                    <i className="fa-regular fa-pen-to-square" />
                  </div>

                  <div
                    data-tip="삭제하기"
                    onClick={() => handleDelete(id)}
                    className="btn btn-primary px-3 light ml-2"
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                </div>
              </div>
              <hr />
              <div className="media mb-3 mt-4">
                <div className="media-body">
                  <span className="pull-right">{data?.regDate}</span>
                  <h3 className="my-1 text-primary">{data?.title}</h3>
                </div>
              </div>
              <div className="read-content-body">
                {textValue && <Viewer initialValue={textValue} />}
              </div>

              {/* 파일이 없다면 아래 쪽 첨부파일 항목 아예 안보이게 */}
              {!!lcFiles?.length && (
                <>
                  <hr />

                  <div className="read-content-attachment">
                    <h6>
                      <i className="fa fa-download mb-2"></i>
                      Files
                      <Badge variant="primary badge-sm ml-2 mr-2">
                        {lcFiles?.length}
                      </Badge>
                    </h6>
                    <div className="row attachment">
                      {lcFiles?.length > 0 ? (
                        lcFiles?.map((file) => (
                          <div
                            onClick={() => handleFileDownload(file.id)}
                            style={{ cursor: 'pointer' }}
                            className="col-auto"
                            key={file.id}
                          >
                            {file.originalFileName}
                          </div>
                        ))
                      ) : (
                        <div className="col-auto">
                          등록된 자료 파일이 없습니다.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {/* 클래스 게시판 댓글 컴포넌트*/}
      <ClassBoardComment replyList={replyies} />
    </Fragment>
  );
};

export default ClassBoardDetail;
