import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import '@toast-ui/editor/dist/toastui-editor.css';

import { EXAM_CONSULTING_COLUMNS } from 'components/Board/Columns';
import { CardinalDropMenu, Pagination, SearchInput } from 'components/common';

import {
  useConsulting,
  useSetConsulting,
  useDebounce,
  useGetQueryString,
  useSetParameterOnQuery,
} from 'hooks';
import { EditConsultingModal } from 'components/Consulting';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const Consulting = () => {
  const queryValues = useGetQueryString();
  const badgeColor = ['#f1a53f', '#51D14F', '#999'];

  const [showModal, setShowModal] = useState(false);
  const [consultingData, setConsultingData] = useState();
  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');

  const [groupId, setGroupId] = useState();
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setGroupId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { deleteConsulting } = useSetConsulting();
  const { consultingList } = useConsulting({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    groupId,
  });
  const { data } = consultingList || {};

  //Table Setting
  const columns = useMemo(() => EXAM_CONSULTING_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '상담 신청 내역을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteConsulting.mutateAsync({ id });
        if (status === 200) {
          await successSwal({ text: MESSAGES.CONSULTING_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.CONSULTING_DELETE_FAIL });
      }
    }
  };

  const handleOpenDetail = (data, i) => {
    if (i !== 7) {
      setShowModal(true);
      setConsultingData(data);
    }
  };

  return (
    <>
      <Wrapper>
        <CardHeader>
          <CardinalDropMenu
            classTitle={classTitle}
            setClassTitle={setClassTitle}
            setId={setGroupId}
            isGroupId
          />

          <div>
            <SearchInput setValue={keyword} setKeyword={setKeyword} />
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              {...cell.getCellProps()}
                              onClick={() => handleOpenDetail(row.original, i)}
                              className="cursor-pointer"
                            >
                              {i === 7 ? (
                                <>
                                  <ReactTooltip />
                                  <i
                                    className="far fa-trash-alt more-btn-icon-box mr-1"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete(row.original.id)
                                    }
                                  />
                                </>
                              ) : i === 0 ? (
                                <div
                                  className={`badge rounded-pill text-white`}
                                  style={{
                                    backgroundColor:
                                      row.original.resultComment === '상담 완료'
                                        ? badgeColor[2]
                                        : row.original.resultComment ===
                                          '승인대기중'
                                        ? badgeColor[0]
                                        : badgeColor[1],
                                  }}
                                >
                                  {row.original.resultComment}
                                </div>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </CardBody>
      </Wrapper>

      {/* 상담 상세 모달 */}
      <EditConsultingModal
        showModal={showModal}
        setShowModal={setShowModal}
        detailData={consultingData}
      />
    </>
  );
};

export default Consulting;
