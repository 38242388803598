import { useMutation, useQueryClient } from 'react-query';

import { editPBLProblem } from '../../services/pbl';

const useEditPBLProblem = ({ problemId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      return await editPBLProblem({ problemId, formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchPBLProblemDetail'] }),
  });
  return { ...queryReturns };
};

export default useEditPBLProblem;
