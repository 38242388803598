import React from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { ModalBadge } from '../common';

const VideoDetailModal = ({ show, setShow, detailData }) => {
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">강의 상세 정보</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="과정명" />
          </div>
          <div className="col-10">{detailData.className}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="유닛명" />
          </div>
          <div className="col-10">{detailData.unitName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="카테고리명" />
          </div>
          <div className="col-10">{detailData.classCategoryName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="챕터명" />
          </div>
          <div className="col-10">{detailData.chapterName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="내용" />
          </div>
          <div className="col-10">{detailData.chapterContent}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="Video URL" />
          </div>
          <div className="col-10">{detailData.videoName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="날짜" />
          </div>
          <div className="col-10">
            {(detailData.chapterDate || '').slice(0, 10)}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="Zoom 사용 여부" />
          </div>
          <Badge
            style={{ marginLeft: '15px' }}
            variant={
              detailData.zoomYn === 'Y'
                ? 'success badge-sm mr-2'
                : 'danger badge-sm mr-2'
            }
          >
            {detailData.zoomYn === 'Y' ? 'On' : 'Off'}
          </Badge>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="Zoom URL" />
          </div>
          <div className="col-10">
            <a href={detailData.zoomUrl} target="_blank" rel="noreferrer">
              {detailData.zoomUrl}
            </a>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default VideoDetailModal;
