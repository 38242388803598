import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ModalBadge } from '../common';

const GatherLinkDetailModal = ({ show, setShow, detailData }) => {
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">Gather링크</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="url" />
          </div>
          <a
            href={detailData.zoomUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="fa-solid fa-video mr-2"></i> {detailData.zoomUrl}
          </a>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GatherLinkDetailModal;
