import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Badge, ListGroup } from 'react-bootstrap';
import { UserSection } from 'components/common';
import '@toast-ui/editor/dist/toastui-editor.css';
import styled from 'styled-components';

import { useExamProblem, useSetExamProblem } from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import ReactTooltip from 'react-tooltip';

const ExamProlemView = () => {
  const { problemId } = useParams();
  let history = useHistory();
  const examProblemTypes = ['객관식', '다중답안객관식', 'OX', '단답형'];

  const { deleteProblem } = useSetExamProblem({});
  const { problemDetail } = useExamProblem({ problemId });
  const { data } = problemDetail || {};

  const handleDelete = async ({ problemId }) => {
    const confirm = await confirmSwal({
      title: '해당 문제를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteProblem.mutateAsync({ problemId });
        if (status === 200) {
          history.push('/exam-problem');
          await successSwal({ text: MESSAGES.PROBLEM_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.PROBLEM_DELETE_FAIL });
      }
    }
  };

  const handleBack = () => history.push('/exam-problem');
  const handleGoToEdit = () => history.push(`/exam-problem-edit/${problemId}`);

  return (
    <Wrapper>
      <CardHeader>
        <UserSection
          dataA="출제자"
          dataB={data?.makerName}
          dataC={data?.makeDate}
        />
        {data?.reviewDate && (
          <UserSection
            dataA="수정자"
            dataB={data?.reviewerName}
            dataC={`수정일: ${data?.reviewDate}`}
          />
        )}
        <div className="d-sm-flex">
          <ReactTooltip place="bottom" />

          <div
            onClick={handleBack}
            className="btn btn-primary px-3 light"
            data-tip="뒤로가기"
          >
            <i className="fa fa-reply"></i>
          </div>

          <div
            data-tip="수정하기"
            onClick={handleGoToEdit}
            className="btn btn-primary px-3 light ml-2"
          >
            <i className="fa-regular fa-pen-to-square" />
          </div>

          <div
            onClick={() => handleDelete({ problemId: data?.problemId })}
            className="btn btn-primary px-3 light ml-2"
            data-tip="삭제하기"
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <ReadContent className="read-content">
          <div className="media mb-2 mt-3 mr-2">
            <div className="media-body">
              <div
                className={`mr-3 py-2 badge rounded-pill bg-${
                  BADGE_COLOR[data?.type]
                } text-white`}
                style={{ opacity: '0.7' }}
              >
                {examProblemTypes[data?.type]}
              </div>
              <Badge variant="outline-primary badge-rounded badge-md light py-2 mr-3">
                {data?.objective.content}
              </Badge>
            </div>
          </div>

          <div className="media mt-5 ml-2 mr-2 fs-16">
            <div className="media-body">
              <p>
                <span>{data?.question}</span>
              </p>
            </div>
          </div>
        </ReadContent>

        <ReadContent className="read-content-body mx-2">
          {data?.questionContent && (
            <QuestionContent>{data?.questionContent}</QuestionContent>
          )}

          {data?.type === 0 ? (
            <AnswerBox className="basic-list-group mt-4">
              <ListGroup>
                <ListGroup.Item className="d-flex justify-content-start align-items-center">
                  <Badge
                    variant={
                      data?.answer === '1'
                        ? `danger badge-rounded badge-md mr-3`
                        : `dark badge-rounded badge-md mr-3`
                    }
                    style={{ opacity: '0.7' }}
                  >
                    1
                  </Badge>
                  <span className="fs-12">{data?.option1}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-start align-items-center">
                  <Badge
                    variant={
                      data?.answer === '2'
                        ? `danger badge-rounded badge-md mr-3`
                        : `dark badge-rounded badge-md mr-3`
                    }
                    style={{ opacity: '0.7' }}
                  >
                    2
                  </Badge>
                  <span className="fs-12">{data?.option2}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-start align-items-center">
                  <Badge
                    variant={
                      data?.answer === '3'
                        ? `danger badge-rounded badge-md mr-3`
                        : `dark badge-rounded badge-md mr-3`
                    }
                    style={{ opacity: '0.7' }}
                  >
                    3
                  </Badge>
                  <span className="fs-12">{data?.option3}</span>
                </ListGroup.Item>
                <ListGroup.Item className="d-flex justify-content-start align-items-center">
                  <Badge
                    variant={
                      data?.answer === '4'
                        ? `danger badge-rounded badge-md mr-3`
                        : `dark badge-rounded badge-md mr-3`
                    }
                    style={{ opacity: '0.7' }}
                  >
                    4
                  </Badge>
                  <span className="fs-12">{data?.option4}</span>
                </ListGroup.Item>
              </ListGroup>
            </AnswerBox>
          ) : (
            ''
          )}

          {data?.imgPath1 ? (
            <h5 className="mt-5">
              <i className="fa fa-paperclip mr-3"></i> {data?.imgPath1}
            </h5>
          ) : (
            ''
          )}

          {data?.imgPath2 ? (
            <h5 className="my-3">
              <i className="fa fa-paperclip mr-3"></i> {data?.imgPath2}
            </h5>
          ) : (
            ''
          )}
        </ReadContent>
        <div className="media mt-5 ml-2 mr-2">
          <div className="media-body">
            <h3 className="fs-14">정답 : {data?.answer}</h3>
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const ReadContent = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 400 !important;
  box-sizing: border-box;

  p {
    max-width: 756px;
    line-height: 1.8;

    span {
      display: inline-block;
      width: 100%;
      letter-spacing: -0.05px;
      word-spacing: -1px;
      margin: 0;
      white-space: pre-wrap;
    }
  }
`;

const AnswerBox = styled.div`
  max-width: 756px;
  span {
    white-space: pre-wrap;
  }
`;

const QuestionContent = styled.p`
  padding: 10px;
  font-size: 13px;
  background-color: #f6f6b8;
  word-break: break-word;
  white-space: pre-wrap;
`;

export default ExamProlemView;
