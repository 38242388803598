/* eslint-disable*/
import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';

import AuthContext from 'context/Auth.context';
import { logout } from '../../../store/actions/AuthActions';

const LogoutPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useContext(AuthContext);

  const handleLogout = () => {
    auth.onLogout();
    dispatch(logout(history));
  };

  return (
    <>
      <Link className="dropdown-item ai-icon" to="#" onClick={handleLogout}>
        <i className="fa-solid fa-arrow-right-from-bracket" />
        <span className="ml-2">Logout </span>
      </Link>
    </>
  );
};

export default LogoutPage;
