import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import styled from 'styled-components';
import { Link, useHistory, useLocation } from 'react-router-dom';

import {
  useFetchCardinalList,
  useFetchPBLProblems,
  useFetchPBLUnits,
} from 'hooks';
import ProblemCard from 'components/Pbl/ProblemCard';
import { CardHeader, Wrapper, CardBody } from 'components/layouts/common';

const PblProblems = () => {
  const history = useHistory();
  const { pathname } = useLocation();
  const getParams = (pathname) => {
    const splited = pathname.split('/');

    if (splited.length <= 2 || splited.length > 4) return;
    if (splited.length === 3) {
      return [splited.at(-1)];
    }
    if (splited.length === 4) {
      return [splited.at(-2), splited.at(-1)];
    }
  };
  const [groupId, unitId] = getParams(pathname) || [];

  const [curriculum, setCurriculum] = useState({
    classId: null,
    groupId: null,
    classTitle: '과정을 선택해주세요.',
    groupName: '',
  });
  const [units, setUnits] = useState({
    pblVisible: false,
    usePbl: false,
    id: null,
    unitTitle: '과정을 먼저 선택해주세요.',
  });

  const { data: cardinals } = useFetchCardinalList();
  const { data: unitList } = useFetchPBLUnits({
    groupId: groupId ?? curriculum.groupId,
  });
  const { data: problems } = useFetchPBLProblems({
    unitId: unitId ?? units.id,
  });
  const usedUnits = unitList?.filter((e) => !!e.usePbl);

  const handleCardinalSelect = (cardinal) => {
    setCurriculum({ ...cardinal });
    setUnits({
      pblVisible: false,
      usePbl: false,
      id: null,
      unitTitle: '과정을 먼저 선택해주세요.',
    });
    history.replace(`/pbl-problem/${cardinal.groupId}`);
  };

  const handleUnitSelect = (unitData) => {
    setUnits({ ...unitData });
    history.replace(`/pbl-problem/${curriculum.groupId}/${unitData.id}`);
  };

  const iconObj = {
    '인프라 활용을 위한 파이썬': 'python',
    '애플리케이션 보안': 'application',
    '시스템/네트워크 보안 기술': 'system-network',
    '클라우드 보안 기술': 'cloud',
  };

  useEffect(() => {
    if (!groupId || !unitId) return;

    if (groupId && cardinals) {
      const current = cardinals.find((e) => e.groupId === Number(groupId));
      setCurriculum({ ...current });
    }

    if (unitId && unitList) {
      const current = unitList.find((e) => e.id === Number(unitId));
      setUnits({ ...current });
    }
  }, [cardinals, unitList]);

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{ width: '100%' }}
          className="d-sm-flex justify-content-between"
        >
          <DropDownBox>
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="md"
                className="mr-2 height-38px"
              >
                {curriculum.classTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {cardinals?.map((list) => (
                  <Dropdown.Item
                    key={list.classId}
                    eventKey={list.classId + 1}
                    onClick={() => handleCardinalSelect(list)}
                    className="fs-12"
                  >
                    {list.classTitle}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="md"
                className="mr-2 height-38px"
                disabled={!curriculum.groupId}
              >
                {units.unitTitle}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {usedUnits?.map((list) => (
                  <Dropdown.Item
                    key={list.id}
                    eventKey={list.id + 1}
                    onClick={() => handleUnitSelect(list)}
                    className="fs-12"
                  >
                    {list.unitTitle}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </DropDownBox>
        </div>
      </CardHeader>

      <CardBody>
        <ContentsBox>
          {!problems && (
            <div className="notice">과정과 유닛을 먼저 선택해주세요. 🤔</div>
          )}

          {problems?.map((problem, index) => {
            return problem.id === null ? (
              <Link
                key={index}
                to={`/pbl-problem-create/${problem.unitId}/${problem.level}`}
              >
                <ProblemCard
                  level={problem.level}
                  title="문제 출제 전"
                  imageSrc={'/assets/idea.png'}
                />
              </Link>
            ) : (
              <Link
                key={index}
                to={`/pbl-problem-edit/${problem.unitId}/${problem.id}`}
              >
                <ProblemCard
                  level={problem.level}
                  solvedCount={problem.solvedCount}
                  title={problem.title}
                  imageSrc={
                    problem.newThumbnailImgName
                      ? `${process.env.REACT_APP_IP}/pbl-file/${problem.id}/thumbnail/${problem.newThumbnailImgName}`
                      : `/${iconObj[units.unitTitle]}.jpg`
                  }
                />
              </Link>
            );
          })}
        </ContentsBox>
      </CardBody>
    </Wrapper>
  );
};

const DropDownBox = styled.div`
  display: flex;
`;

const ContentsBox = styled.div`
  width: 100%;
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  row-gap: 15%;
  column-gap: 30px;
  padding-bottom: 20%;

  > a {
    width: 100%;
    max-width: 350px;
    margin: 0 auto;
  }

  .notice {
    width: 100%;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
`;

export default PblProblems;
