import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { useParams } from 'react-router-dom';

import { confirmSwal, errorSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';
import { useStudyGroupBoard } from 'hooks';

const StudyGroupCommentItem = ({ replyInfo }) => {
  const { id } = useParams();

  const { deletePostComment } = useStudyGroupBoard({ id, studyGroupId: id });

  const handleDelete = async (studyGroupReplyId) => {
    const confirm = await confirmSwal({
      title: '해당 댓글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deletePostComment.mutateAsync({
          studyGroupReplyId,
        });
        if (status === 200) {
          await successSwal({ text: MESSAGES.STUDYPOST_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.STUDYPOST_DELETE_FAIL });
      }
    }
  };

  return (
    <Comment>
      <div className="comment-info">
        <div>
          <span className="name">{replyInfo.userName}</span>
          <span className="date">
            {(replyInfo.regDate || '').slice(0, 10)}{' '}
            {(replyInfo.regDate || '').slice(11, 16)}
          </span>
        </div>

        <div className="comment-info-content">{replyInfo.content}</div>
      </div>

      <div className="comment-buttons">
        <button
          type="button"
          className="delete"
          onClick={() => handleDelete(replyInfo.id)}
        >
          {deletePostComment.isLoading ? (
            <CircularProgress size={20} />
          ) : (
            <>
              <i />
              삭제
            </>
          )}
        </button>
      </div>
    </Comment>
  );
};

const Comment = styled.div`
  padding: 26px 22px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  .comment-info {
    flex: 1 1 0;

    > div:first-child {
      display: flex;
      gap: 5px;
      align-items: flex-end;
      margin-bottom: 15px;

      > span.name {
        font-size: 18px;
        font-weight: bold;
        color: rgb(86, 86, 86);
      }

      > span.date {
        font-size: 14px;
        color: rgb(181, 181, 181);
        margin-left: 20px;
      }
    }

    .comment-info-content {
      color: rgb(48, 48, 48);
      font-size: 15px;
      white-space: pre-wrap;
    }
  }

  .comment-buttons {
    display: flex;
    gap: 10px;
    align-items: center;

    &.none {
      display: none;
    }

    button {
      border: 1px solid #eee;
      background: rgb(255, 255, 255);
      width: 85px;
      height: 40px;
      color: rgb(98, 98, 98);
      font-size: 16px;
      font-weight: bold;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      transition: 0.2s;

      &:focus {
        outline: none;
      }

      i {
        width: 17px;
        height: 18px;
      }
      &.update i {
        background-image: url('/icons/correct_icon.png');
      }
      &.delete i {
        background-image: url('/icons/delete_icon.png');
      }

      &:hover {
        filter: brightness(90%);
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    textarea {
      width: 100%;
      height: 80px;
      outline: none;
      resize: none;
      border: 1px solid #eee;
      font-size: 14px;
      padding: 10px;
    }

    span,
    button {
      display: grid;
      place-items: center;
      border: 1px solid #eee;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 10px;
      margin-top: 5px;
      cursor: pointer;
      transition: 0.2s;
    }

    button {
      min-width: 100px;
      color: #fff;
      font-weight: 500;
      background-color: #ec7422;

      &:hover {
        filter: brightness(90%);
      }

      svg {
        color: #fff;
      }
    }

    .edit-buttons {
      display: flex;
      column-gap: 10px;

      > span {
        height: 30px;
        background-color: #eee;

        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
`;

export default StudyGroupCommentItem;
