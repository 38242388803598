import React, { Fragment, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import { useGetQueryString, useKnowledge, useSetParameterOnQuery } from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { PRE_LEARNING_COLUMNS } from 'components/Board/Columns';
import { BoardWriteButton, GlobalFilter, Pagination } from 'components/common';
import { CardBody, Wrapper } from '../../components/layouts';

const KnowledgeContent = () => {
  const queryValues = useGetQueryString();
  const history = useHistory();

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);

  const query = useSetParameterOnQuery({
    pageNum,
  });

  const { knowledgeList, deleteKnowledge } = useKnowledge({
    pageNum: query.pageNum || 1,
    pageSize,
  });
  const { data } = knowledgeList || {};

  //Table Setting
  const columns = useMemo(() => PRE_LEARNING_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter } = state;

  //PreLearningDelete
  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '해당 지식 컨텐츠를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteKnowledge.mutateAsync({ id });
        if (status === 200) {
          await successSwal({ text: MESSAGES.KNOWLEDGE_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.KNOWLEDGE_DELETE_FAIL });
      }
    }
  };

  const handleGoToDetail = (data, i) => {
    if (i !== 5) {
      history.push({
        pathname: '/knowledge-detail',
        state: {
          data: data,
        },
      });
    }
  };

  return (
    <Wrapper>
      <div style={{ width: '100%' }} className="card-header">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleGoToDetail(cell.row.original, i)
                            }
                          >
                            {i === 1 ? (
                              <div data-tip={cell.row.original.className}>
                                {cell.row.original.className}
                              </div>
                            ) : i === 5 ? (
                              <>
                                <ReactTooltip />
                                <i
                                  className="far fa-trash-alt more-btn-icon-box mr-1"
                                  data-tip="Delete"
                                  onClick={() => handleDelete(row.original.id)}
                                />
                              </>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <BoardWriteButton to="/knowledge-create" />

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default KnowledgeContent;
