import { BASE_URL } from './config';

import axios from 'axios';

/* 클래스 과정 목록 조회 */
export const fetchClassInfoList = async ({ pageNum, pageSize, keyword }) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/classes/api/v1`, { params });
};

/* 과정/그룹 통합 생성 */
export const createClassGroup = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/classes/api/v1`, { ...body });
};

/* 과정/그룹 상세 정보 */
export const fetchClassGroupDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/classes/api/v1/${id}`);
};

/* 과정 수정 */
export const changeClassGroup = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/classes/api/v1/${id}`, {
    ...body,
  });
};

/* 과정/그룹 통합 삭제 */
export const removeClassGroup = async ({ classId }) => {
  return await axios.delete(`${BASE_URL}/admin/classes/api/v1/${classId}`);
};

/* 그룹 수정 */
export const changeGroupInfo = async ({ body }) => {
  return await axios.put(`${BASE_URL}/admin/group/edit`, { ...body });
};

/* 클래스 카테고리 목록 조회 */
export const fetchClassCategoryInfo = async ({
  pageNum,
  pageSize,
  keyword,
  classId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    classId,
  };

  return await axios.get(`${BASE_URL}/admin/class-categories`, { params });
};

/* 카테고리 등록 */
export const createClassCategory = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/class-categories`, {
    ...body,
  });
};

/* 카테고리 수정 */
export const changeClassCategory = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/class-categories/${id}`, {
    ...body,
  });
};

/* 카테고리 삭제 */
export const removeClassCategory = async ({ classCategoryId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/class-categories/${classCategoryId}`
  );
};

/* 클래스 유닛 목록 조회 */
export const fetchClassUnitList = async ({
  pageNum,
  pageSize,
  keyword,
  classId,
  classCategoryId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    classId,
    classCategoryId,
  };

  return await axios.get(`${BASE_URL}/admin/units`, { params });
};

/* 클래스 유닛 등록 */
export const createClassUnit = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/units`, { ...body });
};

/* 클래스 유닛 수정 */
export const changeClassUnit = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/units/${id}`, { ...body });
};

/* 클래스 유닛 삭제 */
export const removeClassUnit = async ({ unitId }) => {
  return await axios.delete(`${BASE_URL}/admin/units/${unitId}`);
};

/* 챕터 조회 */
export const fetchClassChapterList = async ({
  pageNum,
  pageSize,
  keyword,
  classId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    classId,
  };

  return await axios.get(`${BASE_URL}/admin/chapters`, { params });
};

/* 챕터 등록 */
export const createClassChapter = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/chapters`, {
    ...body,
  });
};

/* 챕터 수정 */
export const changeClassChapter = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/chapters/${id}`, {
    ...body,
  });
};

/* 챕터 삭제 */
export const removeClassChapter = async ({ chapterId }) => {
  return await axios.delete(`${BASE_URL}/admin/chapters/${chapterId}`);
};

/* 모든 강의 목록 조회 */
export const fetchAllClassVideoList = async ({
  pageNum,
  pageSize,
  classId,
  classCategoryId,
  unitId,
  chapterId,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    classId,
    classCategoryId,
    unitId,
    chapterId,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/class-info`, { params });
};

/* 강의 등록 */
export const createClassVideo = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/class-info`, {
    ...body,
  });
};

/* 강의 수정 */
export const changeClassVideo = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/class-info/${id}`, {
    ...body,
  });
};

/* 강의 삭제 */
export const removeClassVideo = async ({ videoId }) => {
  return await axios.delete(`${BASE_URL}/admin/class-info/${videoId}`);
};

/* 클래스ID를 통해 카테고리 목록 조회 */
export const fetchClassCategoryByClassId = async ({ classId }) => {
  return await axios.get(`${BASE_URL}/admin/class-categories/group/${classId}`);
};

/* 카테고리ID를 통해 유닛 목록 조회 */
export const fetchClassUnitListByCategoryId = async ({ categoryId }) => {
  return await axios.get(`${BASE_URL}/admin/units/in-categories/${categoryId}`);
};

/* 유닛ID를 통해 챕터 목록 조회 */
export const fetchClassChapterByUnitId = async ({ unitId }) => {
  return await axios.get(`${BASE_URL}/admin/chapters/unit/${unitId}`);
};
