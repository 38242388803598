import React from 'react';
import { Badge } from 'react-bootstrap';
import { Tr } from './style';

const TableList = ({ rowNum, value, sortedKeys, onClick }) => {
  const timeKeys = [
    'attendTime',
    'leavingTime',
    'outingStartTime',
    'outingEndTime',
  ];

  const isToday = (someDate) => {
    const today = new Date();
    return (
      someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
    );
  };
  return (
    <Tr onClick={() => onClick(value)}>
      {sortedKeys.map((item, i) => {
        let result = value[item];

        if (item === 'chapterDate') {
          result = result.substring(0, 10);
        }

        if (timeKeys.includes(item)) {
          result = result ? new Date(value[item]).toLocaleTimeString() : '-';
        }

        if (item === 'status') {
          if (!result) {
            result = isToday(new Date(value['chapterDate'])) ? '-' : '결석';
          }
          const color = {
            결석: 'danger',
            출석: 'success',
            지각: 'warning',
            조퇴: 'warning',
            외출: 'warning',
            '-': '',
          };
          result =
            result === '-' ? (
              result
            ) : (
              <Badge
                variant={`${color[result]} badge-md mb-2`}
                style={{ color: 'white' }}
              >
                {result}
              </Badge>
            );
        }

        return <td key={i}>{result}</td>;
      })}
    </Tr>
  );
};

export default TableList;
