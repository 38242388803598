import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { CardBody, Wrapper } from 'components/layouts/common';
import { useSetClass } from 'hooks';
import { CardinalDropMenu, ValidationErrorMsg } from 'components/common';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const ClassCategoryUpload = () => {
  const history = useHistory();
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [groupId, setGroupId] = useState('');

  const { addClassCategory } = useSetClass();

  const submit = async (form) => {
    const { categoryName, level } = form;

    if (!groupId) {
      setError('groupId', { type: 'custom' });
    } else if (!!groupId) {
      clearErrors();
    }

    const body = {
      groupId,
      categoryName,
      level,
    };

    try {
      const { status } = await addClassCategory.mutateAsync({ body });
      if (status === 201) {
        history.push('/category-list');
        await successSwal({ text: MESSAGES.CATEGORY_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CATEGORY_CREATE_FAIL });
    }
  };

  return (
    <>
      <Wrapper>
        <CardBody>
          <div className="basic-form">
            <form onSubmit={handleSubmit(submit)}>
              <CardinalDropMenu
                classTitle={classTitle}
                setClassTitle={setClassTitle}
                setId={setGroupId}
                isGroupId
              />
              {errors.groupId && (
                <ValidationErrorMsg text="과정을 선택해 주세요." />
              )}
              <div className="form-row">
                <div className="form-group col-md-10 mt-3">
                  <input
                    type="text"
                    placeholder="카테고리명을 입력하세요."
                    className="form-control"
                    name="categoryName"
                    {...register('categoryName', {
                      required: true,
                    })}
                  />
                  {errors.categoryName && (
                    <ValidationErrorMsg text="카테고리명을 입력해 주세요." />
                  )}
                </div>

                <div className="form-group col-md-2 mt-3">
                  <input
                    type="number"
                    placeholder="레벨을 입력하세요."
                    min="0"
                    className="form-control"
                    name="level"
                    {...register('level', {
                      required: true,
                    })}
                  />
                  {errors.level && (
                    <ValidationErrorMsg text="레벨을 입력해 주세요." />
                  )}
                </div>
              </div>

              <hr />

              <div className="d-flex justify-content-start">
                <button type="submit" className="btn btn-primary mt-3 mr-3">
                  등록
                </button>
              </div>
            </form>
          </div>
        </CardBody>
      </Wrapper>
    </>
  );
};

export default ClassCategoryUpload;
