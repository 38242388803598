import React from 'react';
import profileImage from '../../assets/avatar/2.png';

const UserSectionByUser = ({ name, id }) => {
  return (
    <div className="clearfix mb-3 d-flex">
      <img className="mr-3 rounded" width="50" alt="" src={profileImage} />
      <div className="media-body mr-2">
        {name && <h5 className="text-primary mb-0 mt-1">{name}</h5>}
        {id && <p className="mb-0">{id}</p>}{' '}
      </div>
    </div>
  );
};

export default UserSectionByUser;
