import swal from 'sweetalert';

export const confirmSwal = async ({ title, text }) => {
  return await swal({
    title,
    text,
    icon: 'warning',
    buttons: true,
    dangerMode: true,
    className: 'mySwal',
  });
};

export const successSwal = async ({ text }) => {
  return await swal(text, {
    icon: 'success',
  });
};

export const errorSwal = async ({ text }) => {
  return await swal(text, {
    icon: 'error',
  });
};
