import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
  useDebounce,
  useSetConnect,
  useConnect,
  useGetQueryString,
  useSetParameterOnQuery,
} from 'hooks';
import { CardBody, Wrapper } from 'components/layouts';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CLOUD_ACCOUNT_COLUMNS } from '../../components/Board/Columns';
import { SearchInput, Pagination } from 'components/common';
import {
  CloudAccountModal,
  CloudAccountEditModal,
  RegisterCloudAccount,
} from 'components/Connect';

const CloudAccountUpload = () => {
  const queryValues = useGetQueryString();

  const [detailModal, setDetailModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
    }
  }, [queryValues]);

  const { deleteCloudAccount } = useSetConnect();
  const { cloudAccountList } = useConnect({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
  });
  const { data } = cloudAccountList || {};

  //Table Setting
  const columns = useMemo(() => CLOUD_ACCOUNT_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  //CloudAccountDelete
  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '해당 클라우드 계정을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteCloudAccount.mutateAsync({ id });
        if (status === 200) {
          await successSwal({ text: MESSAGES.CLOUD_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.CLOUD_DELETE_FAIL });
      }
    }
  };

  const handleOpenEdit = (data) => {
    setEditModal(true);
    setDetailData(data);
  };

  const handleClickTableRow = (data, i) => {
    if (i !== 6) {
      setDetailModal(true);
      setDetailData(data);
    }
  };

  return (
    <>
      {/* 클라우드 계정 등록 */}
      <RegisterCloudAccount />

      <Wrapper>
        <CardBody>
          <div
            style={{ width: '100%', justifyContent: 'flex-end' }}
            className="d-sm-flex"
          >
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>

          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              {...cell.getCellProps()}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleClickTableRow(row.original, i)
                              }
                            >
                              {i === 6 ? (
                                <>
                                  <ReactTooltip place="bottom" />
                                  <Link
                                    to="#"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete(row.original.id)
                                    }
                                    className="text-black"
                                  >
                                    <i className="far fa-trash-alt more-btn-icon-box mr-1"></i>
                                  </Link>

                                  <i
                                    className="fa-solid fa-pen more-btn-icon-box-info mr-1 cursor-pointer"
                                    onClick={() => handleOpenEdit(row.original)}
                                    data-tip="수정하기"
                                  ></i>
                                </>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            <Pagination
              page={page}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            />
          </div>
        </CardBody>
      </Wrapper>

      {/* 클라우드 계정 상세 모달 */}
      <CloudAccountModal
        show={detailModal}
        setShow={setDetailModal}
        detailData={detailData}
      />

      {/* 클라우드 계정 수정 모달 */}
      <CloudAccountEditModal
        show={editModal}
        setShow={setEditModal}
        detailData={detailData}
        setDetailData={setDetailData}
      />
    </>
  );
};
export default CloudAccountUpload;
