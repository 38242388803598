import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Badge } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import {
  useClass,
  useDebounce,
  useGetQueryString,
  useSetClass,
  useSetParameterOnQuery,
} from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from '../../constants';
import { CLASS_COLUMNS } from 'components/Board/Columns';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { Pagination, SearchInput, BoardWriteButton } from 'components/common';
import { ClassDetailModal, ClassEditModal } from 'components/ProcessManage';

const ClassList = () => {
  const queryValues = useGetQueryString();

  const badgeColor = {
    READY: 'dark',
    ON: 'info',
    OFF: 'danger',
  };

  const [editModal, setEditModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
    }
  }, [queryValues]);

  const { deleteClassGroup } = useSetClass();
  const { classInfoList } = useClass({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    type: 'class',
  });
  const { data } = classInfoList || {};

  //Table Setting
  const columns = useMemo(() => CLASS_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async ({ classId }) => {
    const confirm = await confirmSwal({
      title: '해당 과정을 삭제합니다.',
      text: `과정을 삭제할 시, 해당 과정과 연관된 \n 모든 역량 평가 정보, \n 게시글, \n PBL 정보 \n 등이 함께 삭제됩니다. \n 삭제를 진행하시겠습니까?`,
    });

    if (confirm) {
      try {
        const { status } = await deleteClassGroup.mutateAsync({ classId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.CLASS_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.CLASS_DELETE_FAIL });
      }
    }
  };

  const handleOpenEdit = (data) => {
    setEditModal(true);
    setDetailData({ ...data });
  };

  const handleShowDetail = (data, i) => {
    if (i !== 7) {
      setDetailModal(true);
      setDetailData({ ...data });
    }
  };

  return (
    <>
      <Wrapper>
        <CardHeader justifyContent="flex-end">
          <SearchInput value={keyword} setValue={setKeyword} />
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              {...cell.getCellProps()}
                              onClick={() =>
                                handleShowDetail(cell.row.original, i)
                              }
                              className="cursor-pointer"
                            >
                              {i === 0 ? (
                                <div data-tip={cell.row.original.title}>
                                  {cell.row.original.title}
                                </div>
                              ) : i === 7 ? (
                                <>
                                  <ReactTooltip place="bottom" />

                                  <Link
                                    to="#"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete({
                                        classId: cell.row.original.id,
                                      })
                                    }
                                    className="text-black"
                                  >
                                    <i className="far fa-trash-alt more-btn-icon-box mr-1"></i>
                                  </Link>

                                  <i
                                    className="fa-solid fa-pen more-btn-icon-box-info mr-1 cursor-pointer"
                                    onClick={() => handleOpenEdit(row.original)}
                                    data-tip="수정하기"
                                  />
                                </>
                              ) : i === 6 ? (
                                <Badge
                                  variant={`${
                                    badgeColor[cell.row.original.status]
                                  } badge-rounded badge-sm`}
                                  style={{ opacity: '0.7' }}
                                >
                                  {cell.row.original.status}
                                </Badge>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            <BoardWriteButton to="/class-upload" text="클래스 생성" />

            <div className="text-center">
              {page.length !== 0 && (
                <Pagination
                  page={page}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  data={data || {}}
                  maxPostPage={data?.pages}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {/* 클래스 상세 정보 모달 */}
      <ClassDetailModal
        show={detailModal}
        setShow={setDetailModal}
        detailData={detailData}
      />

      {/* 클래스 수정 모달 */}
      <ClassEditModal
        show={editModal}
        setShow={setEditModal}
        detailData={detailData}
      />
    </>
  );
};

export default ClassList;
