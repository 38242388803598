import React from 'react';
import styled from 'styled-components';

const ProblemCard = ({ level, title, imageSrc, solvedCount }) => {
  const parsedLevel = level < 4 ? `기초 ${level}` : `응용 ${Number(level) - 3}`;

  return (
    <ContentBox>
      <span className={level < 4 ? 'basic' : 'advanced'}>{parsedLevel}</span>
      <ImageBox>
        <img src={imageSrc} alt="" />
      </ImageBox>
      <TextBox>
        {title !== '문제 출제 전' && <span>{solvedCount}명이 풀었어요</span>}
        <h2 className={title === '문제 출제 전' ? 'notYet' : ''}>{title}</h2>
      </TextBox>
    </ContentBox>
  );
};

const ContentBox = styled.div`
  width: 100%;
  position: relative;
  transition: 0.3s;
  cursor: pointer;
  min-height: 300px;
  max-height: 247.2px;
  margin-top: 90px;

  > span {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background-color: #fc9918;
    border-radius: 15px 15px 0 0;
    text-align: center;
    line-height: 50px;
    font-size: 18px;
    font-weight: bold;
    bottom: 100%;
    color: #fff;
    z-index: 1;

    &.basic {
      background-color: #fdba51;
    }
  }

  &:hover {
    transform: scale(103%);
  }

  > img {
    position: absolute;
    width: 40%;
    bottom: 20%;
    right: 0;
    transform: rotate(17deg);
    border-radius: 50%;
  }

  div.lock-box {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    backdrop-filter: blur(3px);
  }

  img.lock-icon {
    position: absolute;
    width: 60%;
    height: 60%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(12deg);
    border-radius: unset;
  }
`;

const ImageBox = styled.div`
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;
  height: 100%;
  position: relative;
  display: flex;
  min-height: 240px;
  max-height: 247.2px;
  align-items: center;

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin: auto;
  }
`;

const TextBox = styled.div`
  margin-top: 5px;

  span {
    color: #888;
  }

  h2 {
    font-size: 1.4rem;
    margin-top: 5px;
  }

  h2.notYet {
    margin-top: 15px;
    text-align: center;
    color: #a8a8a8;
  }
`;

export default ProblemCard;
