import { useQuery } from 'react-query';

const useVersionCheck = () => {
  return useQuery(
    ['latest-hash'],
    async () => {
      const response = await fetch('/latest-hash.txt');

      return response.text();
    },
    {
      retry: 3, // 재시도 횟수
      retryDelay: (attemptIndex) => Math.min(1000 * 2 ** attemptIndex, 3000),
      refetchInterval: 60 * 1000 * 30,
      refetchOnWindowFocus: false,
      refetchIntervalInBackground: true,
    }
  );
};

export default useVersionCheck;
