export const MESSAGES = {
  /* 회원관리 */
  USER_ACCOUNT_CREATE_SUCCESS: '단일 유저 계정이 생성되었습니다.',
  USER_ACCOUNT_CREATE_FAIL: '단일 유저 계정에 실패하였습니다.',
  USER_ACCOUNT_EDIT_SUCCESS: '유저 정보 수정에 성공하였습니다.',
  USER_ACCOUNT_EDIT_FAIL: '유저 정보 수정에 실패하였습니다.',
  USER_ACCOUNT_DELETE_SUCCESS: '계정이 삭제되었습니다.',
  USER_ACCOUNT_DELETE_FAIL: '계정 삭제에 실패하였습니다.',
  EMAIL_CONFIRM_SUCCESS: '이메일 확인에 성공하였습니다.',
  EMAIL_CONFIRM_FAIL: '중복된 이메일이 존재합니다.',
  USER_ATTENDANCE_EDIT_SUCCESS: '출결 정보 수정에 성공하였습니다.',
  USER_ATTENDANCE_EDIT_FAIL: '출결 정보 수정에 성공하였습니다.',
  GROUP_ID_FAIL: '그룹 정보를 가져오는데 실패하였습니다.',
  GROUP_EXAM_EDIT_SUCCESS: '그룹 시험 유무 변경에 성공하였습니다.',

  USER_PASSWORD_RESET_SUCCESS: '해당 사용자의 비밀번호가 초기화되었습니다.',
  USER_PASSWORD_RESET_FAIL: '비밀번호 초기화에 실패하였습니다.',

  /* 과정관리 */
  CLASS_CREATE_SUCCESS: '과정 및 그룹이 등록되었습니다.',
  CLASS_CREATE_FAIL: '과정 및 그룹 등록에 실패하였습니다.',
  CLASS_EDIT_SUCCESS: '과정이 수정되었습니다.',
  CLASS_EDIT_FAIL: '과정 수정에 실패하였습니다.',
  CLASS_DELETE_SUCCESS: '과정이 삭제되었습니다.',
  CLASS_DELETE_FAIL: '과정 삭제에 실패하였습니다.',

  GROUP_EDIT_SUCCESS: '그룹이 수정되었습니다.',
  GROUP_EDIT_FAIL: '그룹 수정에 실패하였습니다.',

  CATEGORY_CREATE_SUCCESS: '카테고리가 등록되었습니다.',
  CATEGORY_CREATE_FAIL: '카테고리 등록에 실패하였습니다.',
  CATEGORY_EDIT_SUCCESS: '카테고리가 수정되었습니다.',
  CATEGORY_EDIT_FAIL: '카테고리 수정에 실패하였습니다.',
  CATEGORY_DELETE_SUCCESS: '카테고리가 삭제되었습니다.',
  CATEGORY_DELETE_FAIL: '카테고리 삭제에 실패하였습니다.',

  UNIT_CREATE_SUCCESS: '유닛이 등록되었습니다.',
  UNIT_CREATE_FAIL: '유닛 등록에 실패하였습니다.',
  UNIT_EDIT_SUCCESS: '유닛이 수정되었습니다.',
  UNIT_EDIT_FAIL: '유닛 수정에 실패하였습니다.',
  UNIT_DELETE_SUCCESS: '유닛이 삭제되었습니다.',
  UNIT_DELETE_FAIL: '유닛 삭제에 실패하였습니다.',

  CHAPTER_CREATE_SUCCESS: '챕터가 등록되었습니다.',
  CHAPTER_CREATE_FAIL: '챕터 등록에 실패하였습니다.',
  CHAPTER_EDIT_SUCCESS: '챕터가 수정되었습니다.',
  CHAPTER_EDIT_FAIL: '챕터 수정에 실패하였습니다.',
  CHAPTER_DELETE_SUCCESS: '챕터가 삭제되었습니다.',
  CHAPTER_DELETE_FAIL: '챕터 삭제에 실패하였습니다.',

  VIDEO_CREATE_SUCCESS: '강의가 등록되었습니다.',
  VIDEO_CREATE_FAIL: '강의 등록에 실패하였습니다.',
  VIDEO_EDIT_SUCCESS: '강의가 수정되었습니다.',
  VIDEO_EDIT_FAIL: '강의 수정에 실패하였습니다.',
  VIDEO_DELETE_SUCCESS: '강의가 삭제되었습니다.',
  VIDEO_DELETE_FAIL: '강의 삭제에 실패하였습니다.',

  LINK_CREATE_SUCCESS: '링크가 등록되었습니다.',
  LINK_CREATE_FAIL:
    '원격 수업 링크 등록에 실패하였습니다. 원격 수업 링크는 https:// 혹은 http:// 로 시작해야합니다.',
  LINK_EDIT_SUCCESS: '링크가 수정되었습니다.',
  LINK_EDIT_FAIL: '링크 수정에 실패하였습니다.',

  CLOUD_CREATE_SUCCESS: '클라우드 계정이 등록되었습니다.',
  CLOUD_CREATE_FAIL: '클라우드 계정 등록에 실패하였습니다.',
  CLOUD_EDIT_SUCCESS: '클라우드 계정이 수정되었습니다.',
  CLOUD_EDIT_FAIL: '클라우드 계정 수정에 실패하였습니다.',
  CLOUD_DELETE_SUCCESS: '클라우드 계정이 삭제되었습니다.',
  CLOUD_DELETE_FAIL: '클라우드 계정 삭제에 실패하였습니다.',

  /* 내클래스 */
  LINK_DELETE_SUCCESS: '링크가 삭제되었습니다.',
  LINK_DELETE_FAIL: '링크 삭제에 실패하였습니다.',
  LINK_USE_EDIT_SUCCESS: '링크 사용 유무가 변경되었습니다.',
  LINK_USE_EDIT_FAIL: '링크 사용 유무 변경에 실패하였습니다.',

  /* 파일 */
  FILE_DOWNLOAD_FAIL:
    '파일 다운로드에 실패하였습니다. 관리자에게 문의해주세요.',
  FILE_MAXIMUM_COUNT_5: '파일은 최대 5개까지 업로드 가능합니다.',
  FILE_INVALID: '파일이 존재하지 않습니다.',

  /* 상담관리 */
  CONSULTING_DELETE_SUCCESS: '상담 내역이 삭제되었습니다.',
  CONSULTING_DELETE_FAIL:
    '수락 상태이거나, 완료 상태인 상담은 삭제가 불가능합니다.',
  CONSULTING_STATUS_SUCCESS: '상담 상태가 변경되었습니다.',
  CONSULTING_STATUS_FAIL: '상담이 완료된 상담은 상태 변경이 불가능합니다.',

  /* 댓글 */
  REPLY_SUBMIT_SUCCESS: '댓글이 등록되었습니다.',
  REPLY_DELETE_SUCCESS: '댓글이 삭제되었습니다.',

  /* 게시글 */
  POST_SUBMIT_SUCCESS: '게시글이 등록되었습니다.',
  POST_SUBMIT_FAIL: '게시글이 등록에 실패하였습니다.',
  POST_DELETE_SUCCESS: '게시글이 삭제되었습니다.',
  POST_EDIT_SUCCESS: '게시글이 수정되었습니다.',

  /* 문제 */
  PROBLEM_SUBMIT_SUCCESS: '문제가 등록되었습니다.',
  PROBLEM_EDIT_SUCCESS: '문제가 수정되었습니다.',
  PROBLEM_DELETE_SUCCESS: '문제가 삭제되었습니다.',
  PROBLEM_DELETE_FAIL: '문제 삭제에 실패하였습니다.',

  /* 시험 */
  EXAM_CREATE_SUCCESS: '시험이 생성되었습니다.',
  EXAM_CREATE_FAIL: '시험 생성에 실패하였습니다.',
  EXAM_DELETE_SUCCESS: '시험이 삭제되었습니다.',
  EXAM_DELETE_FAIL: '시험 삭제에 실패하였습니다.',
  EXAM_EDIT_SUCCESS: '시험이 수정되었습니다.',
  EXAM_RESULT_DELETE_SUCCESS: '시험 결과가 삭제되었습니다.',
  EXAM_RESULT_DELETE_FAIL: '시험 결과 삭제에 실패하였습니다.',
  EXAM_PROBLEM_EDIT_SUCCESS: '시험 문제가 수정되었습니다.',
  EXAM_PROBLEM_EDIT_FAIL: '문제 수정에 실패하였습니다.',

  /* 스터디그룹 */
  STUDYROOM_DELETE_SUCCESS: '스터디룸이 삭제되었습니다.',
  STUDYGROUP_DELETE_SUCCESS: '스터디그룹이 삭제되었습니다.',
  STUDYGROUP_STATUS_SUCCESS: '스터디그룹 상태가 수정되었습니다.',
  STUDYGROUP_EDIT_SUCCESS: '스터디그룹 정보가 변경되었습니다.',
  STUDYGROUP_EDIT_FAIL: '스터디그룹 정보가 변경에 실패하였습니다.',
  STUDYPOST_DELETE_SUCCESS: '댓글이 삭제되었습니다.',
  STUDYPOST_DELETE_FAIL: '댓글 삭제에 실패하였습니다.',

  /* 지식컨텐츠 */
  KNOWLEDGE_CREATE_SUCCESS: '지식 컨텐츠가 등록되었습니다.',
  KNOWLEDGE_CREATE_FAIL: '지식 컨텐츠 등록에 실패하였습니다.',
  KNOWLEDGE_EDIT_SUCCESS: '지식 컨텐츠가 수정되었습니다.',
  KNOWLEDGE_EDIT_FAIL: '지식 컨텐츠 수정에 실패하였습니다.',
  KNOWLEDGE_DELETE_SUCCESS: '지식 컨텐츠가 삭제되었습니다.',
  KNOWLEDGE_DELETE_FAIL: '지식 컨텐츠 삭제에 실패하였습니다.',

  /* 인증 */
  SERVICE_INVALID_TOKEN:
    '로그인 토큰이 유효하지 않습니다. 다시 로그인해주세요.',
  SERVICE_EXPIRED_TOKEN: '로그인이 만료되었습니다. \n 다시 로그인을 해주세요.',
  SERVICE_ADMIN_ONLY: '관리자 전용 서비스입니다.',
  STATUE_NOT_CONFIRMED: '사용 승인이 필요합니다.',
  ERROR_FORBIDDEN: '권한이 없는 사용자입니다. 관리자에게 문의하세요.',

  /* 공통 */
  ERROR_500: '알 수 없는 에러입니다. 관리자에게 문의하세요.',
};
