import React, { useState } from 'react';

/// React router dom
import { Link } from 'react-router-dom';

/// assets
import logo from '../../../assets/logo/sk.png';
import logoText from '../../../assets/logo/sk_shieldus_text_rgb.png';

const NavHader = () => {
  const [toggle, setToggle] = useState(false);

  return (
    <div className="nav-header">
      <Link to="/" className="brand-logo">
        <img className="logo-abbr" src={logo} alt="" />
        <img className="logo-compact" src={logoText} alt="" />
        <img className="brand-title" src={logoText} alt="" />
      </Link>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? 'is-active' : ''}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
    </div>
  );
};

export default NavHader;
