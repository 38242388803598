import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useEffect } from 'react';

const useSetParameterOnQuery = ({ ...queryKeys }) => {
  const history = useHistory();
  const location = useLocation();
  const queryStrings = qs.parse(location.search);

  const [key1, key2, key3, key4, key5, key6, key7, key8] =
    Object.values(queryKeys);

  useEffect(() => {
    const queryStrings = qs.parse(location.search);

    const updatedQueryStrings = { ...queryStrings };
    Object.entries(queryKeys).forEach(([key, value]) => {
      if (value !== queryStrings[key]) {
        updatedQueryStrings[key] = value;
      }
    });

    const newSearch = qs.stringify(updatedQueryStrings);

    history.replace({ ...location, search: newSearch });
  }, [location.search, key1, key2, key3, key4, key5, key6, key7, key8]);

  return queryStrings;
};

export default useSetParameterOnQuery;
