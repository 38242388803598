/* eslint-disable*/
import React, { Fragment, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import { useDebounce, useStudyGroup } from 'hooks';
import { STUDY_GROUP_USER_COLUMNS } from '../Board/Columns';
import { SearchInput, Pagination } from '../common';
import { CardBody, CardHeader, Wrapper } from '../layouts/common';
import { BADGE_COLOR } from '../../constants';

const UserListByStGroup = ({ studyGroupId }) => {
  const history = useHistory();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 300);

  const { studyGroupMembers } = useStudyGroup({
    studyGroupId,
    type: 'studyGroupDetail',
    pageNum,
    pageSize,
    keyword: debouncedKeyword,
  });
  const { data } = studyGroupMembers || {};

  const stateColors = ['success', 'blue', 'danger', 'warning'];
  const userTypes = ['Student', 'Teacher', 'Admin', 'Manager', 'Guest'];

  //Table Setting
  const columns = useMemo(() => STUDY_GROUP_USER_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const handleGoDetail = (userId, i) => {
    if (![8].includes(i)) {
      history.push(`/user-view/${userId}`);
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <div>
          <h5 className="mt-2 text-dark">스터디그룹 유저리스트</h5>
        </div>
        <div>
          <SearchInput value={keyword} setValue={setKeyword} />
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              handleGoDetail(cell.row.original.userId, i)
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {i === 8 ? (
                              <>
                                <ReactTooltip />
                                <Link
                                  to={{
                                    pathname: `/user-view/${cell.row.original.userId}`,
                                    state: {
                                      role: userTypes[
                                        cell.row.original.type - 1
                                      ],
                                      user: cell.row.original,
                                    },
                                  }}
                                  className="text-black"
                                  data-tip="View Detail"
                                >
                                  <i className="far fa-file-alt more-btn-icon-box-dark mr-1"></i>
                                </Link>
                              </>
                            ) : i === 7 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  stateColors[
                                    cell.row.original.expireYn === 'Y'
                                      ? 2
                                      : cell.row.original.sGroupMemberStatus
                                          .length - 2
                                  ]
                                } 
                                 text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {cell.row.original.expireYn === 'Y'
                                  ? '만료'
                                  : cell.row.original.sGroupMemberStatus}
                              </div>
                            ) : i === 2 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.type - 1]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {userTypes[cell.row.original.type - 1]}
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};
export default UserListByStGroup;
