import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { RangeDatePicker } from '@y0c/react-datepicker';
import moment from 'moment-timezone';

import { useClass, useSetClass } from 'hooks';
import { ValidationErrorMsg, ModalBadge } from '../common';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES, TITLE_MAX_LENGTH } from 'constants';

const ClassEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const today = new Date();
  const sevenMonthAfter = new Date(today);
  sevenMonthAfter.setMonth(sevenMonthAfter.getMonth() + 6);
  const [changedStartDate, setStartDate] = useState('');
  const [changedEndDate, setEndDate] = useState('');

  const { editClassGroup } = useSetClass();
  const { classGroupDetail } = useClass({ id: detailData.id || '' });
  const { data: fetchedData, isLoading } = classGroupDetail || {};

  const groupStatusName = ['대기', '진행', '종료'];

  const submit = async (form) => {
    const {
      title,
      groupName,
      groupCompletePercentage,
      noteUrl,
      keyword,
      totalTime,
      count,
      replayAvailableDays,
      status,
    } = form;

    const body = {
      count: +count,
      title,
      replayAvailableDays,
      totalTime,
      groupName,
      keyword,
      startDate: changedStartDate || detailData.startDate,
      endDate: changedEndDate || detailData.endDate,
      groupCompletePercentage: +groupCompletePercentage,
      noteUrl,
      status,
    };

    try {
      const { status } = await editClassGroup.mutateAsync({
        id: detailData.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.CLASS_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CLASS_EDIT_FAIL });
    }
  };

  const onChange = (start, end) => {
    start = moment(start).tz('Asia/Seoul').format().split('+')[0];
    end = moment(end).tz('Asia/Seoul').format().split('+')[0];

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    reset({
      ...fetchedData,
    });
  }, [fetchedData, detailData, isLoading]);

  if (!fetchedData || isLoading) return <></>;
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">클래스 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputWrapper>
            <div>
              <ModalBadge text="과정 이름" required />
              <div>
                <input
                  type="text"
                  placeholder="과정 이름을 입력하세요."
                  className="form-control"
                  name="title"
                  {...register('title', {
                    required: true,
                    maxLength: TITLE_MAX_LENGTH,
                  })}
                />
                {errors.title?.type === 'required' && (
                  <ValidationErrorMsg text="과정 이름을 입력해 주세요." />
                )}
                {errors.title?.type === 'maxLength' && (
                  <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="그룹 이름" />
              <div>
                <input
                  type="text"
                  placeholder="그룹 이름을 입력하세요."
                  className="form-control"
                  name="groupName"
                  {...register('groupName', {
                    required: true,
                  })}
                />
                {errors.groupName?.type === 'required' && (
                  <ValidationErrorMsg text="그룹 이름을 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="강의 시간" />
              <div>
                <input
                  type="number"
                  placeholder="강의 시간을 입력하세요."
                  min="0"
                  className="form-control"
                  name="totalTime"
                  {...register('totalTime', {
                    required: true,
                  })}
                />
                {errors.totalTime?.type === 'required' && (
                  <ValidationErrorMsg text="강의 시간을 입력해 주세요." />
                )}
              </div>
            </div>
            <div>
              <ModalBadge text="다시 보기 가능일" />
              <div>
                <input
                  type="number"
                  placeholder="다시 보기 가능일을 입력하세요."
                  min="0"
                  className="form-control"
                  name="replayAvailableDays"
                  {...register('replayAvailableDays', {
                    required: true,
                  })}
                />
                {errors.replayAvailableDays?.type === 'required' && (
                  <ValidationErrorMsg text="다시 보기 가능일을 입력해 주세요." />
                )}
              </div>
            </div>
            <div>
              <ModalBadge text="기수" />
              <div>
                <input
                  type="number"
                  placeholder="차수"
                  min="0"
                  className="form-control"
                  name="count"
                  {...register('count', {
                    required: true,
                  })}
                />
                {errors.count?.type === 'required' && (
                  <ValidationErrorMsg text="필수 입력값입니다." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="태그" />
              <div>
                <input
                  type="text"
                  placeholder="태그를 입력하세요."
                  className="form-control"
                  name="keyword"
                  {...register('keyword', {
                    required: true,
                  })}
                />
                {errors.keyword?.type === 'required' && (
                  <ValidationErrorMsg text="태그를 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="수료 목표율" />
              <div>
                <input
                  type="number"
                  min={0}
                  placeholder="수료 목표율을 입력하세요."
                  className="form-control"
                  name="groupCompletePercentage"
                  {...register('groupCompletePercentage', {
                    required: true,
                  })}
                />
                {errors.groupCompletePercentage?.type === 'required' && (
                  <ValidationErrorMsg text="수료 목표율을 입력해 주세요." />
                )}
              </div>
            </div>
            <div style={{ flex: 2 }}>
              <ModalBadge text="강의 노트 주소" required={false} />
              <div>
                <input
                  type="text"
                  placeholder="강의 노트 주소를 입력하세요.(ex. https://www.sslc.kr)"
                  className="form-control"
                  name="noteUrl"
                  {...register('noteUrl')}
                />
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="교육 기간" />
              <div>
                <RangeDatePicker
                  startPlaceholder="강의시작일"
                  endPlaceholder="강의종료일"
                  initialStartDate={fetchedData?.startDate}
                  initialEndDate={fetchedData?.endDate}
                  onChange={onChange}
                />
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="그룹 상태" />
              <div className="not-column">
                <span>{groupStatusName[0]}</span>
                <input
                  type="radio"
                  className="myform-radio mr-2"
                  name="status"
                  value="READY"
                  {...register('status')}
                  defaultChecked={detailData.status === 'READY'}
                />
                <span>{groupStatusName[1]}</span>
                <input
                  type="radio"
                  className="myform-radio mr-2"
                  name="status"
                  value="ON"
                  {...register('status')}
                  defaultChecked={detailData.status === 'ON'}
                />
                <span>{groupStatusName[2]}</span>
                <input
                  type="radio"
                  className="myform-radio mr-2"
                  name="status"
                  value="OFF"
                  {...register('status')}
                  defaultChecked={detailData.status === 'OFF'}
                />
              </div>
            </div>
          </StyledInputWrapper>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default ClassEditModal;
