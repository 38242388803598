import { useQuery } from 'react-query';

import {
  fetchClassWithExam,
  fetchExamClassAverage,
  fetchExamDetail,
  fetchExamDetailProblems,
  fetchExamDetailResult,
  fetchExams,
  fetchGroupExamInfo,
  fetchStudentExamResultDetail,
} from 'services';

const useExam = ({
  examId,
  pageNum,
  pageSize,
  examType,
  examLevel,
  className,
  type,
  groupId,
  selectedId,
  subClassId,
  keyword,
}) => {
  const examList = useQuery({
    queryKey: ['fetchExams', { pageNum, pageSize, keyword, groupId }],
    queryFn: async () => {
      const response = await fetchExams({
        pageNum,
        pageSize,
        keyword,
        groupId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!pageNum,
    keepPreviousData: true,
  });

  const examDetail = useQuery({
    queryKey: ['fetchExamDetail', { examId }],
    queryFn: async () => {
      const response = await fetchExamDetail({
        examId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!examId,
    keepPreviousData: true,
  });

  const examDetailResult = useQuery({
    queryKey: ['fetchExamDetailResult', { examId, pageNum, pageSize, keyword }],
    queryFn: async () => {
      const response = await fetchExamDetailResult({
        examId,
        pageNum,
        pageSize,
        keyword,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!examId && !!pageNum,
    keepPreviousData: true,
  });

  const examDetailProblems = useQuery({
    queryKey: [
      'fetchExamDetailProblems',
      { examId, pageNum, pageSize, examType, examLevel, keyword },
    ],
    queryFn: async () => {
      const response = await fetchExamDetailProblems({
        examId,
        pageNum,
        pageSize,
        examType,
        examLevel,
        keyword,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!examId && !!pageNum,
    keepPreviousData: true,
  });

  const examClassAverage = useQuery({
    queryKey: ['fetchExamClassAverage', { className }],
    queryFn: async () => {
      const response = await fetchExamClassAverage({
        className,
      });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!className,
  });

  const classWithExam = useQuery({
    queryKey: ['fetchClassWithExam'],
    queryFn: async () => {
      const response = await fetchClassWithExam();
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: type === 'graph',
  });

  const groupExamInfo = useQuery({
    queryKey: ['fetchGroupExamInfo', { groupId }],
    queryFn: async () => {
      const response = await fetchGroupExamInfo({ groupId });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!groupId,
  });

  const studentExamResultDetail = useQuery({
    queryKey: [
      'fetchStudentExamResultDetail',
      { groupId, selectedId, subClassId },
    ],
    queryFn: async () => {
      const response = await fetchStudentExamResultDetail({
        groupId,
        selectedId,
        subClassId,
      });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!groupId && !!selectedId,
  });

  return {
    examList,
    examDetail,
    examDetailResult,
    examDetailProblems,
    examClassAverage,

    classWithExam,
    groupExamInfo,
    studentExamResultDetail,
  };
};

export default useExam;
