import React, { Fragment, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { MenuItem, Select } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert2';
import FileSaver from 'file-saver';
import 'react-quill/dist/quill.snow.css';

import {
  useFetchPBLProblemDetail,
  useRemovePBLProblem,
  useEditPBLProblem,
} from 'hooks';
import { Badge } from 'components/common';
import TextEditor from 'components/ui/TextEditor';
import { downloadAttachmentFiles } from 'services';
import PblResultView from 'components/Pbl/PBLResultView';
import { CardHeader, Wrapper } from '../../components/layouts/common';

const PblProblemEdit = () => {
  const MAXIMUM_FILES = 5;
  const MAXIMUM_FILE_SIZE = 1024 * 1024 * 10;

  const history = useHistory();
  const { unitId, problemId } = useParams();
  const { register, setValue, getValues } = useForm();

  const { data: detailData } = useFetchPBLProblemDetail({ unitId, problemId });
  const { mutateAsync: removeProblem } = useRemovePBLProblem({ problemId });
  const { mutateAsync: editProblem } = useEditPBLProblem({ problemId });

  const [editMode, setEditMode] = useState(false);
  const [problemState, setProblemState] = useState('OPEN');
  const [isImageLoading, setIsImageLoading] = useState(false);

  const [textValue, setTextValue] = useState('');
  const [thumbnail, setThumbnail] = useState(null);
  const [attachments, setAttachments] = useState([]);
  const [deleteImgs, setDeleteImgs] = useState([]);
  const [deleteAttachments, setDeleteAttachments] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  const [deleteThumbnail, setDeleteThumbnail] = useState(false);

  const [imageSrc, setImageSrc] = useState(null);

  const handleChangeState = (e) => {
    setProblemState(e.target.value);
  };

  const handleEditMode = () => {
    setEditMode((prev) => !prev);
  };

  const handleUploadThumnail = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result || null);
          setThumbnail(file);
          resolve();
        };
      });
    });
  };
  const handleRemoveUploadedThumbnailImage = () => {
    setImageSrc(null);
    setThumbnail(null);
  };
  const handleBasicThumbnail = () => {
    setDeleteThumbnail((prev) => !prev);
  };

  const handleUploadAttachmentFiles = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.zip');
    input.setAttribute('multiple', 'true');
    input.click();

    input.addEventListener('change', async () => {
      const files = input.files;

      if ([...files].some((file) => file.size > MAXIMUM_FILE_SIZE)) {
        swal.fire({
          title: `알림`,
          text: `참고 자료는 용량은 10MB를 넘길 수 없습니다.`,
          icon: 'error',
        });
        return;
      }

      if (files.length > MAXIMUM_FILES) {
        swal.fire({
          title: `알림`,
          text: `참고 자료는 ${MAXIMUM_FILES}개까지만 업로드 가능합니다.`,
          icon: 'error',
        });
        return;
      }

      setAttachments((prev) => [...prev, ...files]);
    });
  };
  const handleRemoveAttachmentFiles = () => {
    setAttachments([]);
  };
  const handleRemoveOneAttachmentFiles = (e) => {
    setDeleteAttachments((prev) => [...prev, e.newFileName]);

    setAttachments((prev) => {
      return [...prev.filter((attach) => attach.name !== e.name)];
    });
  };

  const handleDownloadAttachmentFiles = async ({ problemId, fileName }) => {
    try {
      const response = await downloadAttachmentFiles({ problemId, fileName });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmitEdit = async () => {
    if (isImageLoading) return;

    const title = getValues('title');

    if (textValue.length > 15000) {
      swal.fire({
        title: `본문 컨텐츠가 너무 깁니다.`,
        icon: 'error',
      });
      return;
    }

    if (!title || !textValue) {
      swal.fire({
        title: `제목과 내용을 입력해주세요.`,
        icon: 'error',
      });
      return;
    }

    const form = {
      level: detailData?.level,
      title,
      content: textValue,
      state: problemState,
      deleteImgs,
      deleteAttachments,
      imgs: imageNames,
      deleteThumbnail,
    };
    const formData = new FormData();

    formData.append(
      'form',
      new Blob([JSON.stringify(form)], {
        type: 'application/json',
      })
    );

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }
    if (attachments.length !== 0) {
      attachments.forEach((zipFile) => {
        formData.append('attachments', zipFile);
      });
    }

    try {
      const response = await editProblem({ formData });
      if (response.status === 204) {
        swal.fire({
          title: `성공적으로 수정되었습니다.`,
          icon: 'success',
        });
        history.goBack();
      }
    } catch (error) {
      swal.fire({
        title: `오류가 발생하였습니다.`,
        icon: 'error',
      });
    }
  };

  const handleRemoveProblem = async () => {
    await swal
      .fire({
        title: '이 문제를 정말로 지우시겠습니까?',
        text: '문제 삭제 시, 해당 문제와 관련된 모든 데이터가 삭제됩니다. 정말 삭제하시겠습니까?',
        icon: 'warning',
        showCancelButton: true,
        showConfirmButton: false,
        showDenyButton: true,
        denyButtonText: '삭제',
      })
      .then(async (result) => {
        if (result.isDenied) {
          try {
            const response = await removeProblem();
            if (response.status === 204) {
              swal.fire({
                title: `성공적으로 삭제되었습니다.`,
                icon: 'success',
              });
              history.goBack();
            }
          } catch (error) {
            swal.fire({
              title: `오류가 발생하였습니다.`,
              icon: 'error',
            });
          }
        }
      });
  };

  useEffect(() => {
    if (!detailData || editMode) return;

    function updateImagePaths(htmlString) {
      return htmlString.replace(/temp/g, `${problemId}/image`);
    }

    const parsingValue = updateImagePaths(detailData?.content);

    setProblemState(detailData?.state);
    setValue('title', detailData?.title);
    setTextValue(parsingValue);
  }, [detailData, editMode]);

  const iconObj = {
    '인프라 활용을 위한 파이썬': 'python',
    '애플리케이션 보안': 'application',
    '시스템/네트워크 보안 기술': 'system-network',
    '클라우드 보안 기술': 'cloud',
  };

  const stateToChange = {
    OPEN: '표출',
    HIDE: '숨김',
    CLOSE: '마감',
  };

  const getThumbnail = () => {
    if (deleteThumbnail) {
      return `/${iconObj[detailData?.groupUnitInfo?.unitTitle]}.jpg`;
    }

    if (detailData?.newThumbnailImgName) {
      return `${process.env.REACT_APP_IP}/pbl-file/${detailData?.id}/thumbnail/${detailData?.newThumbnailImgName}`;
    } else {
      return `/${iconObj[detailData?.groupUnitInfo?.unitTitle]}.jpg`;
    }
  };
  const pickThumbnail = getThumbnail();

  const filtered = detailData?.attachmentFiles.filter(
    (e) => !deleteAttachments.includes(e.newFileName)
  );

  const getViewFiles = () => {
    if (!editMode) return detailData?.attachmentFiles;
    if (editMode) {
      if (deleteAttachments.length > 0) {
        if (filtered.length > 0) {
          return filtered;
        }
        return attachments;
      } else if (detailData?.attachmentFiles.length > 0) {
        return detailData?.attachmentFiles;
      }
      return attachments;
    }
  };
  const uploadedAttachmentsFiles = getViewFiles();

  const parsedLevel =
    detailData?.level < 4
      ? `기초 ${detailData?.level}`
      : `응용 ${Number(detailData?.level) - 3}`;

  return (
    <>
      <Wrapper className="card">
        <CardHeader className="card-header" style={{ width: '100%' }}>
          <div
            style={{ width: '100%' }}
            className="d-sm-flex justify-content-between"
          >
            <BadgeBox>
              <Badge
                text={detailData?.groupUnitInfo.groupName}
                color="#ec7807"
              />
              <Badge
                text={detailData?.groupUnitInfo.unitTitle}
                color="#ec7807"
              />
            </BadgeBox>
          </div>
        </CardHeader>

        <Container>
          <EditButtonBox>
            <span onClick={handleEditMode}>
              {editMode ? (
                <>
                  취소
                  <i className="fa-solid fa-pen-to-square" />
                </>
              ) : (
                <>
                  수정
                  <i className="fa-regular fa-pen-to-square" />
                </>
              )}
            </span>
          </EditButtonBox>
          <InputContainer>
            <InputBox>
              <div className="title">레벨</div>
              <div className="input">
                <span className="level">{parsedLevel}</span>
              </div>
            </InputBox>

            <InputBox>
              <div className="title">문제 상태</div>
              <div className="input">
                {editMode ? (
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={problemState}
                    onChange={handleChangeState}
                    renderValue={(e) => `${stateToChange[e]}`}
                  >
                    <MenuItem value="OPEN">표출</MenuItem>
                    <MenuItem value="HIDE">숨김</MenuItem>
                    <MenuItem value="CLOSE">마감</MenuItem>
                  </Select>
                ) : (
                  <Badge
                    text={stateToChange[detailData?.state]}
                    color="#009ECD"
                  />
                )}
              </div>
            </InputBox>

            <InputBox>
              <div className="title">제목</div>
              <div className="input">
                {editMode ? (
                  <input type="text" {...register('title')} />
                ) : (
                  <h4>{detailData?.title}</h4>
                )}
              </div>
            </InputBox>

            <InputBox>
              <div className="title">내용</div>
              <div className="input">
                {editMode ? (
                  <TextEditor
                    htmlStr={textValue}
                    setHtmlStr={setTextValue}
                    setDeleteImgs={setDeleteImgs}
                    imgList={detailData?.imgList}
                    isEdit={true}
                    setImageNames={setImageNames}
                    imageNames={imageNames}
                    setIsImageLoading={setIsImageLoading}
                  />
                ) : (
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{ __html: textValue }}
                  ></div>
                )}
              </div>
            </InputBox>

            <InputBox>
              <div className="title">
                <p>
                  썸네일 이미지
                  <span>(정사각형 이미지 권장)</span>
                </p>
              </div>
              <div className="image-box">
                <img
                  className="thumbnail"
                  src={imageSrc ?? pickThumbnail}
                  alt=""
                />
                {imageSrc && (
                  <b onClick={handleRemoveUploadedThumbnailImage}>&times;</b>
                )}
                <div>
                  {editMode && (
                    <span onClick={handleUploadThumnail}>업로드</span>
                  )}
                  {editMode && (
                    <span className="basic" onClick={handleBasicThumbnail}>
                      {!deleteThumbnail ? '썸네일 제거' : '되돌리기'}
                    </span>
                  )}
                </div>
              </div>
            </InputBox>

            {(detailData?.attachmentFiles.length !== 0 || editMode) && (
              <InputBox>
                <div className="title">참고자료</div>
                <div className="input">
                  <div className="file">
                    {uploadedAttachmentsFiles?.map((e, index) => (
                      <p key={index}>
                        <b
                          onClick={() =>
                            handleDownloadAttachmentFiles({
                              problemId,
                              fileName: e.newFileName,
                            })
                          }
                        >
                          {e.name ?? e.originalFileName}
                        </b>
                        {editMode && (
                          <span
                            onClick={() => handleRemoveOneAttachmentFiles(e)}
                          >
                            &times;
                          </span>
                        )}
                      </p>
                    ))}
                    {filtered?.length > 0 &&
                      attachments?.map((e, index) => (
                        <p key={index}>
                          <b
                            onClick={() =>
                              handleDownloadAttachmentFiles({
                                problemId,
                                fileName: e.newFileName,
                              })
                            }
                          >
                            {e.name ?? e.originalFileName}
                          </b>
                          {editMode && (
                            <span
                              onClick={() => handleRemoveOneAttachmentFiles(e)}
                            >
                              &times;
                            </span>
                          )}
                        </p>
                      ))}
                    {editMode && (
                      <span
                        className="upload-button"
                        onClick={handleUploadAttachmentFiles}
                      >
                        참고자료 수정
                      </span>
                    )}
                    {attachments.length !== 0 && (
                      <span
                        className="upload-button"
                        onClick={handleRemoveAttachmentFiles}
                      >
                        업로드 취소
                      </span>
                    )}
                  </div>
                </div>
              </InputBox>
            )}
          </InputContainer>

          {editMode && (
            <SubmitButtonBox>
              <span
                onClick={handleSubmitEdit}
                className={isImageLoading ? 'imageLoading' : ''}
              >
                문제 수정
              </span>
              <span onClick={handleRemoveProblem}>문제 삭제</span>
            </SubmitButtonBox>
          )}
        </Container>
      </Wrapper>

      {/*결과물 컴포넌트*/}
      <PblResultView />
    </>
  );
};

const BadgeBox = styled.div`
  display: flex;
  column-gap: 10px;
`;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;
  padding: 30px 20px 50px;
`;

const EditButtonBox = styled.div`
  margin-left: auto;

  span {
    padding: 5px 10px;
    border: 1px solid #eee;
    border-radius: 5px;
    font-size: 18px;
    transition: 0.3s;
    cursor: pointer;

    i {
      margin-left: 5px;
    }

    &:hover {
      background-color: #eee;
    }
  }
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputBox = styled.div`
    display: flex;
    overflow: hidden;
    column-gap: 20px;
    border-bottom: 2px solid #b7b7b7;

    div.title {
        min-width: 130px;
        max-width: 130px;
        padding: 20px;
        display: grid;
        place-items: center;
        font-size: 16px;
        font-weight: bold;
        background-color: #eee;
        
        span {
            font-size: 9px;
            color: #888;
            word-break: break-word;
        }
    }

    div.image-box {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        padding: 10px 0;

        img {
            width: 100%;
            max-width: 500px;
            
            &.thumbnail {
                max-width: 250px;
            }
        }
        
        b {
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
            width: 20px;
            height: 20px;
            top: 30px;
            right: 10px;
            padding: 10px;
            font-size: 20px;
            cursor: pointer;
            border: 2px solid #7e7e7e;
            border-radius: 50%;
            background-color: #fff;
        }

        span {
            display: inline-block;
            padding: 5px 20px;
            border: 1px solid #eee;
            margin-top: 10px;
            border-radius: 5px;
            color: #fff;
            font-weight: 500;
            background-color: #f59b4a;
            cursor: pointer;

            &:hover {
                filter: brightness(95%);
            }
            &:nth-child(2),
            &:nth-child(3){
                background-color: #c4c1c0;
                margin-left: 5px;
            }
            
            
        }
    }

    .content {
        width: 100%;
        overflow: hidden;

        > p {
            white-space: pre-wrap;
            margin-bottom: 0;
            line-height: unset;

            img {
                max-width: fit-content;
                width: 100%;
            }
        }

    }

    div.input {
        width: 100%;
        display: flex;
        align-items: center;
        height: auto;
        padding: 10px 0;
        overflow: hidden;

        input {
            width: 100%;
            height: 35px;
        }

        > span.level {
            display: grid;
            place-items: center;
            width: auto;
            height: 35px;
            padding: 0 15px;
            background-color: #fc9918;
            border-radius: 15px;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            z-index: 1;
        }

        .MuiInputBase-input {
            padding: 10px 30px 10px 20px !important;
        }

        .file {
            
            > p {
            width: 100%;
            cursor: pointer;
            transition: 0.2s;margin-bottom: 5px;

            &:hover {
                color: #969696;
                transform: scale(103%);
            }
                > span {
                    font-size: 20px;
                    padding: 5px;
                }
            }
        }
    }
}

.upload-button {
    display: inline-block;
    padding: 5px 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    background-color: #f59b4a;
    cursor: pointer;

    &:hover {
        filter: brightness(95%);
    }
`;

const SubmitButtonBox = styled.div`
  display: flex;
  column-gap: 10px;

  span {
    padding: 10px 20px;
    border-radius: 10px;
    border: 1px solid #eee;
    transition: 0.2s;
    cursor: pointer;
    color: #fff;
    background-color: #f59b4a;

    &:hover {
      filter: brightness(95%);
    }

    &:nth-child(2) {
      background-color: #f57f7f;
    }

    &.imageLoading {
      background-color: #eee;
      cursor: not-allowed;
    }
  }
`;

export default PblProblemEdit;
