import { useMutation, useQueryClient } from 'react-query';

import { changeConsultStatus, removeConsult } from 'services';

const useSetConsulting = () => {
  const queryClient = useQueryClient();

  const deleteConsulting = useMutation({
    mutationFn: async ({ id }) => {
      return await removeConsult({ id });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchConsultingList'],
      }),
  });

  const editConsultStatus = useMutation({
    mutationFn: async ({ id }) => {
      return await changeConsultStatus({ id });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchConsultingList'],
      }),
  });

  return {
    deleteConsulting,
    editConsultStatus,
  };
};

export default useSetConsulting;
