import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import AuthContext from 'context/Auth.context';
import { useSupportBoard } from 'hooks';
import { confirmSwal, successSwal } from 'utilities/swal';
import { MESSAGES } from '../../constants';

const SupportBoardCommentItem = ({ replyInfo }) => {
  const { id } = useParams();
  const { register, setValue, handleSubmit } = useForm();
  const { type, userId } = useContext(AuthContext);

  const { deleteReply, editReply } = useSupportBoard({ id });

  const [commentEdit, setCommentEdit] = useState(false);

  const handleDeleteComment = async () => {
    const confirm = await confirmSwal({
      title: '해당 댓글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteReply.mutateAsync({
        postId: id,
        commentId: replyInfo.id,
      });
      if (status === 204) {
        await successSwal({ text: MESSAGES.REPLY_DELETE_SUCCESS });
      }
    }
  };

  const handleSubmitEdit = async (form) => {
    const body = {
      content: form.comment,
      postId: id,
    };

    const { status } = await editReply.mutateAsync({
      commentId: replyInfo.id,
      body,
    });
    if (status === 204) {
      await successSwal({ text: '댓글이 수정되었습니다.' });
      setCommentEdit(false);
    }
  };

  const handleClickEditButton = () => {
    setCommentEdit((prev) => !prev);
  };
  const handleCancleEdit = () => {
    setCommentEdit(false);
  };

  useEffect(() => {
    if (!commentEdit) return;
    if (commentEdit) {
      setValue('comment', replyInfo.content);
    }
  }, [commentEdit, replyInfo, setValue]);

  return (
    <Comment>
      <div className="comment-info">
        <div>
          {replyInfo.isAdmin && <AdminBadge>관리자</AdminBadge>}
          <span className="name">{replyInfo.author}</span>
          <span className="date">
            {(replyInfo.createdAt || '').slice(0, 10)}{' '}
            {(replyInfo.createdAt || '').slice(11, 16)}
          </span>
        </div>

        {commentEdit ? (
          <form onSubmit={handleSubmit(handleSubmitEdit)}>
            <textarea {...register('comment')} />
            <div className="edit-buttons">
              <span onClick={handleCancleEdit}>취소</span>
              <button type="submit">
                {editReply.isLoading ? (
                  <CircularProgress size={20} />
                ) : (
                  '댓글 수정하기'
                )}
              </button>
            </div>
          </form>
        ) : (
          <div className="comment-info-content">{replyInfo.content}</div>
        )}
      </div>

      <div className={`comment-buttons ${commentEdit ? 'none' : ''}`}>
        <button
          type="button"
          className="update"
          onClick={handleClickEditButton}
        >
          <i />
          수정
        </button>

        {type !== '1' && (
          <button
            type="button"
            className="delete"
            onClick={() =>
              handleDeleteComment({ postId: id, replyId: replyInfo.id })
            }
          >
            {deleteReply.isLoading ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <i />
                삭제
              </>
            )}
          </button>
        )}
      </div>
    </Comment>
  );
};

const Comment = styled.div`
  padding: 26px 22px;
  border-bottom: 1px solid #eee;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;

  .comment-info {
    flex: 1 1 0;

    > div:first-child {
      display: flex;
      gap: 5px;
      align-items: flex-end;
      margin-bottom: 15px;

      > span.name {
        font-size: 18px;
        font-weight: bold;
        color: rgb(86, 86, 86);
      }

      > span.date {
        font-size: 14px;
        color: rgb(181, 181, 181);
        margin-left: 20px;
      }
    }

    .comment-info-content {
      color: rgb(48, 48, 48);
      font-size: 15px;
      white-space: pre-wrap;
    }
  }

  .comment-buttons {
    display: flex;
    gap: 10px;
    align-items: center;

    &.none {
      display: none;
    }

    button {
      border: 1px solid #eee;
      background: rgb(255, 255, 255);
      width: 85px;
      height: 40px;
      color: rgb(98, 98, 98);
      font-size: 16px;
      font-weight: bold;
      border-radius: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      cursor: pointer;
      transition: 0.2s;

      &:focus {
        outline: none;
      }

      i {
        width: 17px;
        height: 18px;
      }
      &.update i {
        background-image: url('/icons/correct_icon.png');
      }
      &.delete i {
        background-image: url('/icons/delete_icon.png');
      }

      &:hover {
        filter: brightness(90%);
      }
    }
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    textarea {
      width: 100%;
      height: 80px;
      outline: none;
      resize: none;
      border: 1px solid #eee;
      font-size: 14px;
      padding: 10px;
    }

    span,
    button {
      display: grid;
      place-items: center;
      border: 1px solid #eee;
      font-size: 12px;
      padding: 5px 10px;
      border-radius: 10px;
      margin-top: 5px;
      cursor: pointer;
      transition: 0.2s;
    }

    button {
      min-width: 100px;
      color: #fff;
      font-weight: 500;
      background-color: #ec7422;

      &:hover {
        filter: brightness(90%);
      }

      svg {
        color: #fff;
      }
    }

    .edit-buttons {
      display: flex;
      column-gap: 10px;

      > span {
        height: 30px;
        background-color: #eee;

        &:hover {
          filter: brightness(90%);
        }
      }
    }
  }
`;

const AdminBadge = styled.span`
  padding: 3px 5px;
  border: 1px solid #ec7422;
  border-radius: 15px;
  color: #ec7422;
  font-weight: bold;
  font-size: 12px;
`;

export default SupportBoardCommentItem;
