import axios from 'axios';

const BASE_URL = process.env.REACT_APP_IP;

/* 지식컨텐츠 목록 조회 */
export const fetchKnowledgeList = async ({ pageNum, pageSize }) => {
  const params = {
    pageNum,
    pageSize,
  };

  return await axios.get(`${BASE_URL}/admin/pre-learning`, { params });
};

/* 지식 컨텐츠 등록 */
export const createKnowledge = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/admin/pre-learning`, formData);
};

/* 지식 컨텐츠 수정 */
export const changeKnowledge = async ({ id, formData }) => {
  return await axios.put(`${BASE_URL}/admin/pre-learning/${id}`, formData);
};

/* 지식 컨텐츠 삭제 */
export const removeKnowledge = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/pre-learning/${id}`);
};

/* 지식 컨텐츠 상세 첨부파일 다운로드 */
export const downloadKnowledgeFile = async ({ fileId }) => {
  return await axios.delete(`${BASE_URL}/admin/pre-learning/file/${fileId}`);
};
