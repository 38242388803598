import { useMutation, useQueryClient } from 'react-query';

import { removeStudentResult } from '../../services/pbl';

const useRemoveStudentResult = ({ problemId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ submitId }) => {
      return await removeStudentResult({ problemId, submitId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchPBLResultsList'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveStudentResult;
