import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';

import { useSupportBoard } from 'hooks';
import { successSwal } from 'utilities/swal';
import SupportBoardCommentItem from './SupportBoardCommentItem';
import { MESSAGES } from 'constants';
import { ValidationErrorMsg } from '../common';
import { CardBody, Wrapper } from '../layouts/common';

const SupportBoardComment = ({ replyList = [], boardType = '' }) => {
  const { id } = useParams();

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { addReply } = useSupportBoard({ id });

  const handleSubmitComment = async (form) => {
    const body = {
      content: form.commentText,
      postId: id,
    };

    const { status } = await addReply.mutateAsync({ body });
    if (status === 201) {
      await successSwal({ text: MESSAGES.REPLY_SUBMIT_SUCCESS });
      setValue('commentText', '');
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <WriteCommentBox>
          <form onSubmit={handleSubmit(handleSubmitComment)}>
            <div>
              <textarea
                name="comment"
                maxLength={1000}
                placeholder="댓글을 입력해주세요."
                {...register('commentText', { required: true })}
              ></textarea>

              <button type="submit">
                {addReply.isLoading ? (
                  <CircularProgress size={30} />
                ) : (
                  '등록하기'
                )}
              </button>
            </div>
            {errors.commentText?.type && (
              <ValidationErrorMsg text="댓글을 입력해주세요." />
            )}
          </form>
        </WriteCommentBox>

        <CommentList>
          <div className="count">
            댓글<span>{replyList.length || 0}</span>개
          </div>

          <div className="comment-list">
            {!!replyList.length ? (
              replyList.map((e, index) => (
                <SupportBoardCommentItem
                  key={index}
                  replyInfo={e}
                  boardType={boardType}
                />
              ))
            ) : (
              <div className="no-comment">댓글이 존재하지 않습니다.</div>
            )}
          </div>
        </CommentList>
      </CardBody>
    </Wrapper>
  );
};

const WriteCommentBox = styled.div`
  padding: 18px;
  background: rgb(246, 246, 246);

  form {
    width: 100%;

    > div {
      display: flex;

      textarea {
        flex: 1 1 0;
        border: 1px solid #e4e4e4;
        outline: none;
        padding: 10px;
        resize: none;
        font-size: 14px;
      }

      button {
        width: 200px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: rgb(255, 255, 255);
        background: rgb(42, 34, 23);
        font-size: 16px;
        appearance: none;
        border: none;
        cursor: pointer;

        svg {
          color: #fff;
        }
      }
    }
  }
`;

const CommentList = styled.div`
  margin-top: 50px;

  div.count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

    span {
      margin-left: 5px;
      color: #ec7422;
    }
  }

  div.comment-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;

    div.no-comment {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      color: rgb(34, 34, 34);
    }

    div.comment {
      padding: 26px 22px;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

export default SupportBoardComment;
