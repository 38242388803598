import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import SwitchWithHookForm from '../ui/SwitchWithHookForm';

const UnitList = ({ unitList, onChange, groupTitle, categoryTitle }) => {
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [selectedIndexes, setSelectedIndexes] = useState([]);

  const handleChange = (id, title, count) => {
    setSelectedUnits((prev) => {
      const newUnits = [...prev];
      const prevIndex = newUnits.findIndex((unit) => unit.id === id);
      if (prevIndex === -1) {
        newUnits.push({ id, count: +count > 0 ? +count : 0 });
      } else {
        newUnits[prevIndex].count = +count;
      }
      return newUnits;
    });
  };

  const handleCheck = (isChecked, index, unitId) => {
    if (isChecked) {
      setSelectedIndexes((prev) => [...prev, index]);
    } else {
      setSelectedIndexes((prev) => prev.filter((i) => i !== index));
      setSelectedUnits((prev) => prev.filter((unit) => unit.id !== unitId));
    }
  };

  useEffect(() => {
    onChange(selectedUnits);
  }, [selectedUnits, onChange]);

  return (
    <div style={{ margin: '20px 0' }}>
      {unitList ? (
        unitList.map((unit, i) => (
          <Row>
            <div style={{ width: '30px' }}>{i + 1}</div>
            <GroupTitle>{groupTitle}</GroupTitle>
            <CategoryTitle style={{ flexGrow: '1' }}>
              {categoryTitle}
            </CategoryTitle>
            <div
              style={{
                width: '400px',
                flexGrow: '2',
                fontWeight: 'bold',
                fontSize: '15px',
              }}
            >
              {unit.title}
            </div>
            <SwitchWithHookForm
              onChange={(isChecked) => handleCheck(isChecked, i, unit.id)}
            />
            <input
              value={
                selectedUnits.find((selected) => selected.id === unit.id)
                  ?.count || ''
              }
              disabled={!selectedIndexes.includes(i)}
              type="number"
              placeholder="문제수"
              onChange={(e) =>
                handleChange(unit.id, unit.title, e.target.value)
              }
              style={{
                width: '100px',
                marginLeft: '50px',
                padding: '5px 5px',
                borderRadius: '5px',
                outline: 'none',
                border: '1px solid #ccc',
              }}
            />
          </Row>
        ))
      ) : (
        <NoContent>
          <p>그룹과 카테고리를 선택해주세요.</p>
        </NoContent>
      )}
    </div>
  );
};

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

const GroupTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  margin: 0 5px;
`;

const CategoryTitle = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  padding: 0 15px;
`;

const NoContent = styled.div`
  min-height: 200px;
  display: grid;
  place-items: center;

  p {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;

export default UnitList;
