import React from 'react';
import styled from 'styled-components';

const FileIcon = () => {
  return (
    <Wrapper>
      <img src="/icons/appendix.png" alt="" />
    </Wrapper>
  );
};

const Wrapper = styled.span`
  display: inline-block;
  width: 18px;
  height: 18px;
  vertical-align: top;
  margin-left: 10px;

  > img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

export default FileIcon;
