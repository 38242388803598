import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RangeDatePicker } from '@y0c/react-datepicker';
import moment from 'moment-timezone';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useSetClass } from 'hooks';
import { CardBody, Wrapper } from 'components/layouts/common';
import { MESSAGES, TITLE_MAX_LENGTH } from 'constants';
import { ModalBadge, ValidationErrorMsg } from 'components/common';
import { errorSwal, successSwal } from 'utilities';

const ClassUpload = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const today = new Date();
  const sevenMonthAfter = new Date(today);
  sevenMonthAfter.setMonth(sevenMonthAfter.getMonth() + 6);

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(sevenMonthAfter);

  const { addClassGroup } = useSetClass();

  const addClass = async (form) => {
    const {
      title,
      groupName,
      groupCompletePercentage,
      noteUrl,
      keyword,
      totalTime,
      count,
      replayAvailableDays,
    } = form;

    const body = {
      count,
      endDate,
      groupCompletePercentage,
      groupName,
      keyword,
      noteUrl,
      replayAvailableDays,
      startDate,
      title,
      totalTime,
    };

    try {
      const { status } = await addClassGroup.mutateAsync({ body });
      if (status === 201) {
        history.push('/class-list');
        await successSwal({ text: MESSAGES.CLASS_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CLASS_CREATE_FAIL });
    }
  };

  const onChange = (start, end) => {
    // datepicker 라이브러리는 로컬 타임존으로 변환한 시간을 보내줌. Axios가 이를 그대로 stringify하면 UTC로 바뀌면서 날짜가 변하게 됨.
    // datepicker가 보내주는 데이터에서 날짜만 따로 떼서 스트링으로 변환
    start = moment(start).tz('Asia/Seoul').format().split('+')[0];
    end = moment(end).tz('Asia/Seoul').format().split('+')[0];
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="basic-form">
          <form onSubmit={handleSubmit(addClass)}>
            <StyledInputWrapper>
              <div>
                <ModalBadge text="과정 이름" />
                <div>
                  <input
                    type="text"
                    placeholder="과정 이름을 입력하세요."
                    className="form-control"
                    name="title"
                    {...register('title', {
                      required: true,
                      maxLength: TITLE_MAX_LENGTH,
                    })}
                  />
                  {errors.title?.type === 'required' && (
                    <ValidationErrorMsg text="과정 이름을 입력해 주세요." />
                  )}
                  {errors.title?.type === 'maxLength' && (
                    <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="그룹 이름" />
                <div>
                  <input
                    type="text"
                    placeholder="그룹 이름을 입력하세요."
                    className="form-control"
                    name="groupName"
                    {...register('groupName', {
                      required: true,
                    })}
                  />
                  {errors.groupName?.type === 'required' && (
                    <ValidationErrorMsg text="그룹 이름을 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="강의 시간" />
                <div>
                  <input
                    type="number"
                    placeholder="강의 시간을 입력하세요."
                    min="0"
                    className="form-control"
                    name="totalTime"
                    {...register('totalTime', {
                      required: true,
                    })}
                  />
                  {errors.totalTime?.type === 'required' && (
                    <ValidationErrorMsg text="강의 시간을 입력해 주세요." />
                  )}
                </div>
              </div>
              <div>
                <ModalBadge text="다시 보기 가능일" />
                <div>
                  <input
                    type="number"
                    placeholder="다시 보기 가능일을 입력하세요."
                    min="0"
                    className="form-control"
                    name="replayAvailableDays"
                    {...register('replayAvailableDays', {
                      required: true,
                    })}
                  />
                  {errors.replayAvailableDays?.type === 'required' && (
                    <ValidationErrorMsg text="다시 보기 가능일을 입력해 주세요." />
                  )}
                </div>
              </div>
              <div>
                <ModalBadge text="기수" />
                <div>
                  <input
                    type="number"
                    placeholder="차수"
                    min="0"
                    className="form-control"
                    name="count"
                    {...register('count', {
                      required: true,
                    })}
                  />
                  {errors.count?.type === 'required' && (
                    <ValidationErrorMsg text="기수 정보를 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="키워드" />
                <div>
                  <input
                    type="text"
                    placeholder="키워드를 입력하세요."
                    className="form-control"
                    name="keyword"
                    {...register('keyword', {
                      required: true,
                    })}
                  />
                  {errors.keyword?.type === 'required' && (
                    <ValidationErrorMsg text="키워드를 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="수료 목표율" />
                <div>
                  <input
                    type="number"
                    min={30}
                    placeholder="수료 목표율을 입력하세요."
                    className="form-control"
                    name="groupCompletePercentage"
                    {...register('groupCompletePercentage', {
                      required: true,
                    })}
                  />
                  {errors.groupCompletePercentage?.type === 'required' && (
                    <ValidationErrorMsg text="수료 목표율을 입력해 주세요." />
                  )}
                </div>
              </div>
              <div style={{ flex: 2 }}>
                <ModalBadge text="강의 노트 주소" />
                <div>
                  <input
                    type="text"
                    placeholder="강의 노트 주소를 입력하세요.(ex. https://www.sslc.kr)"
                    className="form-control"
                    name="noteUrl"
                    {...register('noteUrl', {
                      required: true,
                    })}
                  />
                  {errors.noteUrl?.type === 'required' && (
                    <ValidationErrorMsg text="강의 노트 주소를 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="교육 기간" />
                <div>
                  <RangeDatePicker
                    startPlaceholder="강의시작일"
                    endPlaceholder="강의종료일"
                    initialStartDate={startDate}
                    initialEndDate={endDate}
                    onChange={onChange}
                  />
                </div>
              </div>
            </StyledInputWrapper>

            <hr />
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary mt-3 mr-3">
                클래스 생성
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default ClassUpload;
