import React, { Fragment, useEffect, useMemo, useState } from 'react';
import Pagination from 'components/common/Pagination';
import { PBL_RESULT_PAGE_COLUMNS } from 'components/Board/Columns';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import FileSaver from 'file-saver';
import styled from 'styled-components';
import swal from 'sweetalert';

import { CardinalDropMenu, SearchInput } from 'components/common';
import {
  useDebounce,
  useFetchTotalSubmitter,
  useGetQueryString,
  useRemoveStudentResult,
  useSetParameterOnQuery,
} from 'hooks';
import { downloadPersonalResult } from 'services/pbl';
import PblCommentModal from 'components/Pbl/PblCommentModal';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';

const PblResults = () => {
  const queryValues = useGetQueryString();

  const [show, setShow] = useState(false);
  const [studentId, setStudentId] = useState('');
  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [searchValue, setSearchValue] = useState(queryValues.keyword || '');
  const [problemId, setProblemId] = useState(0);
  const [groupId, setGroupId] = useState('');
  const debouncedSearchValue = useDebounce(searchValue, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedSearchValue,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setSearchValue('');
      setGroupId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { data: totalResult } = useFetchTotalSubmitter({
    pageNum: query.pageNum || 1,
    searchValue: query.keyword || '',
    groupId: groupId,
  });
  const { mutateAsync: removeStudentResult } = useRemoveStudentResult({
    problemId,
  });

  const [data, setData] = useState([]);

  const columns = useMemo(() => PBL_RESULT_PAGE_COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  const handleRemoveStudentResult = async ({ problemId, submitId }) => {
    const result = await swal({
      title: '이 결과물을 정말로 반려하시겠습니까?',
      icon: 'warning',
      buttons: ['취소', '반려'],
      dangerMode: true,
    });

    if (result) {
      await setProblemId(problemId);
      try {
        const response = await removeStudentResult({ submitId });
        if (response.status === 204) {
          swal({
            title: `성공적으로 반려되었습니다.`,
            icon: 'success',
            buttons: '확인',
          });
          setProblemId(0);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handlePersonalResult = async ({ problemId, userId }) => {
    try {
      const response = await downloadPersonalResult({ problemId, userId });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenCommentModal = ({ studentId, problemId }) => {
    const current = data?.find(
      (e) => e.problemId === problemId && e.modifiedBy === studentId
    );

    if (!current.commented) return;

    setShow((prev) => !prev);
    setStudentId(studentId);
    setProblemId(problemId);
  };

  useEffect(() => {
    if (!totalResult?.list) return;

    setData([...totalResult?.list]);
  }, [totalResult?.list]);

  useEffect(() => {
    if (show) return;

    setProblemId(0);
  }, [show]);

  return (
    <Container>
      <Wrapper>
        <CardHeader>
          <div
            style={{ width: '100%' }}
            className="d-sm-flex justify-content-between"
          >
            <CardinalDropMenu
              classTitle={classTitle}
              setClassTitle={setClassTitle}
              setId={setGroupId}
              isGroupId
            />

            <div>
              <SearchInput value={searchValue} setValue={setSearchValue} />
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td {...cell.getCellProps()}>
                              {i === 0 ? (
                                <div data-tip={cell.row.original.groupName}>
                                  {cell.row.original.groupName}
                                </div>
                              ) : i === 2 ? (
                                <LevelBadge>
                                  {cell.row.original.problemLevel < 4
                                    ? `기초 ${cell.row.original.problemLevel}`
                                    : `응용 ${
                                        Number(cell.row.original.problemLevel) -
                                        3
                                      }`}
                                </LevelBadge>
                              ) : i === 6 ? (
                                <ResultBox>
                                  <div>
                                    <span
                                      onClick={() =>
                                        handleRemoveStudentResult({
                                          problemId:
                                            cell.row.original.problemId,
                                          submitId: cell.row.original.submitId,
                                        })
                                      }
                                    >
                                      반려
                                    </span>
                                    <span
                                      onClick={() =>
                                        handlePersonalResult({
                                          problemId:
                                            cell.row.original.problemId,
                                          userId: cell.row.original.modifiedBy,
                                        })
                                      }
                                    >
                                      다운로드
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleOpenCommentModal({
                                          studentId:
                                            cell.row.original.modifiedBy,
                                          problemId:
                                            cell.row.original.problemId,
                                        })
                                      }
                                      className={
                                        cell.row.original.commented
                                          ? 'commented'
                                          : ''
                                      }
                                    >
                                      의견
                                    </span>
                                  </div>
                                </ResultBox>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                제출한 학생 목록이 없습니다.
              </div>
            )}

            <div className="text-center">
              {page.length !== 0 && (
                <Pagination
                  page={page}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  maxPostPage={totalResult?.pages}
                  data={totalResult}
                />
              )}
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {show && (
        <PblCommentModal
          show={show}
          setShow={setShow}
          studentId={studentId}
          problemId={problemId}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  td:nth-child(1) {
    width: 8%;
    max-width: 8%;
  }

  td:nth-child(2) {
    width: 15%;
    max-width: 15%;
  }

  td:nth-child(3) {
    padding: 0 10px 0 0;
  }

  td:nth-child(4) {
    width: 10%;
    max-width: 10%;
  }
`;

const DropDownBox = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  column-gap: 10px;

  button {
    min-width: 235px;
    font-size: 0.813rem !important;
  }

  > div {
    display: flex;
    gap: 5px;
  }

  > div > span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 38px;
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 0 10px;
    color: #fff;
    background-color: #ec7807;
    cursor: pointer;

    &:last-child {
      background-color: #eee;
      color: #333;
    }
    &:hover {
      filter: brightness(90%);
    }

    i {
      margin-left: 3px;
    }
  }
`;

const ResultBox = styled.div`
  display: flex;
  justify-content: flex-start;

  p {
    font-size: 14px;
  }

  div {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;

    span {
      padding: 5px 10px;
      border: 1px solid #eee;
      border-radius: 10px;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      background-color: #63c04c;

      &:first-child {
        background-color: #ff7247;
      }

      &:last-child.commented {
        background-color: #6fc1fb;
        cursor: pointer;
      }

      &:last-child {
        background-color: #eee;
        cursor: default;
      }

      &:hover {
        filter: brightness(95%);
      }
    }
  }
`;

const LevelBadge = styled.span`
  display: grid;
  place-items: center;
  width: auto;
  height: 35px;
  background-color: #fc9918;
  border-radius: 15px;
  font-size: 10px;
  font-weight: bold;
  color: #fff;
  z-index: 1;
`;

export default PblResults;
