import { useQuery } from 'react-query';

import {
  fetchTaskList,
  fetchTaskResultListByTaskId,
  fetchTaskDetailFile,
  fetchTaskDetail,
} from 'services';

const useFetchTaskData = ({
  classId,
  pageNum,
  pageSize,
  keyword,
  taskId,
  id,
}) => {
  const taskList = useQuery({
    queryKey: ['fetchTaskList', { classId, pageNum, pageSize, keyword }],
    queryFn: async () =>
      await fetchTaskList({ classId, pageNum, pageSize, keyword }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!pageNum,
    keepPreviousData: true,
  });

  const taskResultByTaskId = useQuery({
    queryKey: [
      'fetchTaskResultListByTaskId',
      { taskId, pageNum, pageSize, keyword },
    ],
    queryFn: async () =>
      await fetchTaskResultListByTaskId({ taskId, pageNum, pageSize, keyword }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    staleTime: 50000,
    cacheTime: 50000,
    enabled: !!taskId,
    keepPreviousData: true,
  });

  const taskDetailFile = useQuery({
    queryKey: ['fetchTaskDetailFile', { id }],
    queryFn: async () => await fetchTaskDetailFile({ id }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
    keepPreviousData: true,
  });

  const taskDetail = useQuery({
    queryKey: ['fetchTaskDetail', { id }],
    queryFn: async () => await fetchTaskDetail({ id }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
    keepPreviousData: true,
  });

  return {
    taskList,
    taskDetail,

    taskResultByTaskId,
    taskDetailFile,
  };
};

export default useFetchTaskData;
