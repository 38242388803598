import { useQuery } from 'react-query';

import {
  fetchAllClassVideoList,
  fetchClassCategoryByClassId,
  fetchClassChapterByUnitId,
  fetchClassUnitListByCategoryId,
} from 'services';

const useClassVideo = ({
  pageNum,
  pageSize,
  classId,
  categoryId,
  unitId,
  chapterId,
  keyword,
}) => {
  const classAllVideo = useQuery({
    queryKey: [
      'fetchAllClassVideoList',
      {
        pageNum,
        pageSize,
        keyword,
        classId,
        categoryId,
        unitId,
        chapterId,
      },
    ],
    queryFn: async () => {
      const response = await fetchAllClassVideoList({
        pageNum,
        pageSize,
        keyword,
        classId,
        categoryId,
        unitId,
        chapterId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
  });

  const classCategory = useQuery({
    queryKey: ['fetchClassCategoryByClassId', { classId }],
    queryFn: async () => {
      const response = await fetchClassCategoryByClassId({ classId });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!classId,
  });

  const classUnit = useQuery({
    queryKey: ['fetchClassUnitListByCategoryId', { categoryId }],
    queryFn: async () => {
      const response = await fetchClassUnitListByCategoryId({
        categoryId,
      });

      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!categoryId,
  });

  const classChapter = useQuery({
    queryKey: ['fetchClassChapterByUnitId', { unitId }],
    queryFn: async () => {
      const response = await fetchClassChapterByUnitId({
        unitId,
      });

      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!unitId,
  });

  return {
    classAllVideo,
    classCategory,
    classUnit,
    classChapter,
  };
};

export default useClassVideo;
