import React from 'react';
import 'react-dropzone-uploader/dist/styles.css';
import Drop from 'react-dropzone-uploader';

const DropFile = ({ handleGetDropZoneFile, accept }) => {
  const handleChangeStatus = ({ meta, file, remove }, status) => {
    handleGetDropZoneFile(file);
  };

  return (
    <Drop
      onChangeStatus={handleChangeStatus}
      // onSubmit={handleSubmit}
      inputContent="파일을 드래그하거나, 클릭해서 업로드 하세요."
      accept={accept}
      maxFiles={1}
      multiple={false}
      styles={{
        dropzone: {
          minHeight: 200,
          maxHeight: 250,
          width: '100%',
          backgroundColor: '#f2f4fa',
          border: '1px dashed #DDDFE1',
          borderRadius: '10px',
          overflow: 'auto',
        },
        inputLabel: {
          color: '#7e7e7e',
          fontSize: '18px',
          fontWeight: 'normal',
          backgroundColor: '#f2f4fa',
        },
        previewImage: {
          maxWidth: '400px',
          maxHeight: '200px',
        },
      }}
    />
  );
};

export default DropFile;
