/* eslint-disable */
import React, { useContext, useEffect } from 'react';
import AuthContext, { SetAuthContext } from './context/Auth.context';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { parseJWT } from './AuthProvider';
import { errorSwal } from './utilities';
import { MESSAGES } from './constants';

// For Refreshing Token
let refreshSubscribers = [];

export default function AxiosConfig() {
  const dispatch = useDispatch();
  const history = useHistory();

  let isTokenRefreshing = false;
  const auth = useContext(AuthContext);
  const setAuth = useContext(SetAuthContext);
  // For Refreshing Token

  const onTokenRefreshed = async (accessToken) => {
    refreshSubscribers.map((callback) => {
      callback(accessToken);
    });
    refreshSubscribers = [];
  };
  const addRefreshSubscriber = (callback) => {
    refreshSubscribers.push(callback);
  };

  useEffect(() => {
    if (auth.auth) {
      if (refreshSubscribers.length !== 0) {
        onTokenRefreshed(localStorage.getItem('token'));
      }
    }
  }, [auth]);

  axios.interceptors.request.use(
    (config) => {
      const accessToken = localStorage.getItem('token');

      if (accessToken) {
        config.headers.Authorization = accessToken;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  // response interceptor
  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      let accessToken = '';

      const { config, response } = error;
      const originalRequest = config;

      if (
        response.status === 401 &&
        error.config.url !== `${process.env.REACT_APP_IP}/users/login`
      ) {
        if (!isTokenRefreshing) {
          delete axios.defaults.headers.common['Authorization'];
          isTokenRefreshing = true;
          const refreshToken = localStorage.getItem('refreshToken');

          axios
            .post(
              `${process.env.REACT_APP_IP}/users/refreshAccessToken`,
              {}, // token refresh api
              {
                transformRequest: (data, headers) => {
                  delete headers.Authorization;
                  return data;
                },
                headers: {
                  RefreshToken: `${refreshToken}`,
                },
              }
            )
            .then((res) => {
              isTokenRefreshing = false;
              accessToken = res.headers.authorization;
              localStorage.setItem('token', accessToken);
              const parsedData = parseJWT(res.headers.authorization);
              setAuth({
                ...auth,
                auth: true,
                userId: String(parsedData.userId),
                name: String(parsedData.userName),
                type: String(parsedData.userType),
                email: String(parsedData.userEmail),
                status: String(parsedData.status),
                refreshToken: refreshToken,
                token: res.headers.authorization,
                authToken: { Authorization: `${res.headers.authorization}` },
                freeYn: String(parsedData.freeYn),
                allowLanguages: String(parsedData.allowLanguages),
                classId: String(parsedData.classId),
                subClassId: 0,
                groupId: String(parsedData.groupId),
                initialPw: String(parsedData.initialPw),
              });
              onTokenRefreshed(accessToken);
            })
            .catch((error) => {
              Sentry.captureException(
                'error line 117 status:' +
                  response.status +
                  `\n isTokenRefreshing? :${isTokenRefreshing} \n error :${error}`
              );
              isTokenRefreshing = false;
              localStorage.clear();
              auth.onLogout();
              (async () =>
                await errorSwal({ text: MESSAGES.SERVICE_EXPIRED_TOKEN }))();
              history.push('/login');
            });
        } else {
          isTokenRefreshing = false;
          if (
            error.config.url ===
            `${process.env.REACT_APP_IP}/users/refreshAccessToken`
          ) {
            Sentry.captureException('error line 129 status:' + response.status);
            localStorage.clear();
            auth.onLogout();
            history.push('/login');
          }
        }

        const retryOriginalRequest = new Promise((resolve, reject) => {
          addRefreshSubscriber(function (accessToken) {
            originalRequest.headers.Authorization = accessToken;
            axios(originalRequest)
              .then((res) => resolve(res))
              .catch((error) => reject(error));
          });
        });
        return retryOriginalRequest;
      }
      // 400, 404, 500
      else if (response.status === 500) {
        Sentry.captureException('error line 148 status:' + response.status);
      } else if (response.status === 404) {
        Sentry.captureException('error line 150 status:' + response.status);
      } else if (response.status === 400) {
        Sentry.captureException('error line 152 status:' + response.status);
      }

      return Promise.reject(error);
    }
  );
  return <></>;
}
