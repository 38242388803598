import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { useConnect, useSetClassVideo } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { ModalBadge, ValidationErrorMsg } from '../common';
import CustomSwitchNoConfirm from '../ui/CustomSwitchNoConfirm';
import { DatePicker } from '@y0c/react-datepicker';
import { format } from 'date-fns';

const VideoEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { remoteClassList } = useConnect({ type: 'link' });
  const { data: zoomUrlList } = remoteClassList || {};

  const [zoomName, setZoomName] = useState('Gather 링크를 선택하세요.');
  const [zoomId, setZoomId] = useState('');
  const [zoomYn, setZoomYn] = useState(detailData.zoomYn === 'Y');
  const [chapterDate, setChapterDate] = useState('');

  const { editVideo } = useSetClassVideo();

  const submit = async (form) => {
    const body = {
      chapterContent: form.chapterContent,
      chapterDate,
      classId: detailData.classId,
      classCategoryId: detailData.classCategoryId,
      unitId: detailData.unitId,
      chapterId: detailData.chapterId,
      videoName: form.videoName,
      zoomId,
      zoomYn,
    };

    try {
      const { status } = await editVideo.mutateAsync({
        id: detailData.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.VIDEO_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.VIDEO_EDIT_FAIL });
    }
  };

  const onChange = (date) => {
    const chaDate = date.$d;

    const chaDateStr = format(new Date(chaDate), 'yyyy-MM-dd');
    setChapterDate(chaDateStr);
  };

  const handleZoomLink = (data, id) => {
    setZoomName(data);
    setZoomId(id);
  };

  useEffect(() => {
    reset({
      ...detailData,
    });
    setChapterDate(detailData.chapterDate);
  }, [detailData]);

  const handleLectureConfirm = () => {
    setZoomYn(!zoomYn);
  };

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">강의 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputWrapper>
            <div>
              <ModalBadge text="클래스명" required={false} />
              <div>
                <span>{detailData.className}</span>
              </div>
            </div>

            <div>
              <ModalBadge text="카테고리명" required={false} />
              <div>
                <span>{detailData.classCategoryName}</span>
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="유닛명" required={false} />
              <div>
                <span>{detailData.unitName}</span>
              </div>
            </div>

            <div>
              <ModalBadge text="챕터명" required={false} />
              <div>
                <span>{detailData.chapterName}</span>
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="강의 날짜" required={false} />
              <div>
                <DatePicker
                  initialDate={detailData.chapterDate || ''}
                  placeholder="챕터일자"
                  onChange={onChange}
                />
              </div>
            </div>

            <div></div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="강의 내용" />
              <div>
                <input
                  type="text"
                  placeholder="강의 내용을 입력하세요."
                  className="form-control"
                  name="chapterContent"
                  {...register('chapterContent', {
                    required: true,
                  })}
                />
                {errors.chapterContent && (
                  <ValidationErrorMsg text="강의 내용을 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="강의명" required={false} />
              <div>
                <input
                  type="text"
                  placeholder="강의 이름을 입력하세요. 쉼표(,)로 구분해주세요. ex) trx121g66a, sd467df"
                  className="form-control"
                  name="videoName"
                  {...register('videoName')}
                />
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="Zoom 사용여부" required={false} />
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <CustomSwitchNoConfirm
                  isTrue={zoomYn}
                  handleToggle={handleLectureConfirm}
                />
                <span>{zoomYn ? 'ON' : 'OFF'}</span>
              </div>
            </div>
          </StyledInputWrapper>

          {zoomYn && (
            <StyledInputWrapper>
              <div>
                <ModalBadge text="Zoom URL" required={false} />
                <div>
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary"
                      size="md"
                      className="zoom-drop-menu mr-3"
                    >
                      {zoomName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="zoom-drop-menu">
                      {zoomUrlList?.map((zoom) => (
                        <Dropdown.Item
                          key={zoom.id}
                          eventKey={zoom.id + 1}
                          onClick={() => handleZoomLink(zoom.zoomUrl, zoom.id)}
                          className="fs-12"
                        >
                          {zoom.zoomUrl}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </StyledInputWrapper>
          )}
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default VideoEditModal;
