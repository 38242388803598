import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import { DropFile } from '../../components/common';
import { CardBody, CardHeader, Wrapper } from '../../components/layouts';
import { useFetchCardinalList, useKnowledge } from '../../hooks';
import { errorSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';

const KnowledgeEdit = ({ location }) => {
  useEffect(() => {}, [location]);

  let history = useHistory();

  const [classListName, setClassListName] = useState(
    location.state.data.className
  );
  const [fileReUpload, setFileReUploader] = useState(false);
  const [file, setFile] = useState();
  const [updeteChecker, setUpdateChecker] = useState(true);
  const [inputList, setInputList] = useState({
    classId: location.state.data.classId,
    title: location.state.data.title,
    totalTime: location.state.data.totalTime,
    content: location.state.data.content,
    url: location.state.data.url,
  });

  const [errors, setErrors] = useState({
    id: '',
    classId: '',
    title: '',
    totalTime: '',
    content: '',
    imageName: '',
    url: '',
    regDate: '',
  });

  const { data: classList } = useFetchCardinalList();
  const { editKnowledge } = useKnowledge({});

  const editPreLearning = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errors };
    if (inputList.url === '') {
      errorObj.url = '링크를 입력해주세요.';
      error = true;
    }
    if (inputList.classId === '') {
      errorObj.classId = '클래스를 선택해주세요.';
      error = true;
    }
    if (inputList.title === '') {
      errorObj.title = '제목을 입력해주세요.';
      error = true;
    }
    if (inputList.totalTime === '') {
      errorObj.totalTime = '시간을 입력해주세요.';
      error = true;
    }
    if (inputList.content === '') {
      errorObj.content = '내용을 입력해주세요.';
      error = true;
    }
    if (inputList.imageName === '') {
      errorObj.imageName = '대표이미지를 등록해주세요.';
      error = true;
    }

    setErrors(errorObj);

    if (error) {
    } else {
      const frm = new FormData();

      frm.append(
        'data',
        new Blob([JSON.stringify(inputList)], {
          type: 'application/json',
        })
      );
      if (file) {
        frm.append('file', file);
      }

      try {
        const { status } = await editKnowledge.mutateAsync({
          id: location.state.data.id,
          formData: frm,
        });
        if (status === 200) {
          history.push('/knowledge-content');
          await successSwal({ text: MESSAGES.KNOWLEDGE_EDIT_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.KNOWLEDGE_EDIT_FAIL });
      }
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    setInputList({
      ...inputList,
      [name]: value,
    });
    if (updeteChecker) {
      setUpdateChecker(false);
    }
    const errorObj = { ...errors };

    if (name === 'url' && value !== '') {
      errorObj.url = '';
    } else if (name === 'url' && value === '') {
      errorObj.url = '링크를 입력해주세요.';
    }
    if (name === 'title' && value !== '') {
      errorObj.title = '';
    } else if (name === 'title' && value === '') {
      errorObj.title = '제목을 입력해주세요.';
    }
    if (name === 'totalTime' && value !== '') {
      errorObj.totalTime = '';
    } else if (name === 'totalTime' && value === '') {
      errorObj.totalTime = '시간을 입력해주세요.';
    }
    if (name === 'content' && value !== '') {
      errorObj.content = '';
    } else if (name === 'content' && value === '') {
      errorObj.content = '내용을 입력해주세요.';
    }

    setErrors(errorObj);
  };

  const handleGetDropZoneFile = (data) => {
    setInputList({
      ...inputList,
      imageName: data.name,
    });
    setFile(data);
    const errorObj = { ...errors };
    errorObj.imageName = '';
    setErrors(errorObj);
  };

  const handleBack = () => {
    history.goBack();
  };

  const handleClassname = (name, id) => {
    setClassListName(name);
    setInputList({
      ...inputList,
      ['classId']: id,
    });
    const errorObj = { ...errors };
    errorObj.classId = '';
    setErrors(errorObj);
  };

  const handleImageDelete = () => {
    setFileReUploader(true);
  };

  return (
    <Wrapper>
      <CardHeader>
        <div />
        <div className="d-sm-flex">
          <div onClick={handleBack} className="btn btn-primary px-3 light">
            <i className="fa fa-reply"></i>
          </div>
          <div
            onClick={() => {
              setInputList((prev) => {
                return {
                  ...prev,
                  classId: location.state.data.classId,
                  title: location.state.data.title,
                  totalTime: location.state.data.totalTime,
                  content: location.state.data.content,
                  imageName: location.state.data.imageName,
                  url: location.state.data.url,
                  regDate: location.state.data.regDate,
                };
              });

              setErrors({
                id: '',
                classId: '',
                title: '',
                totalTime: '',
                content: '',
                imageName: '',
                url: '',
                regDate: '',
              });

              setUpdateChecker(true);
              setFileReUploader(false);
            }}
            className="btn btn-primary px-3 light ml-2"
          >
            <i className="fas fa-sync-alt"></i>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="basic-form">
          <form onSubmit={editPreLearning}>
            <div className="form-row">
              <div className="form-group">
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="md"
                    className="mr-3 ml-2"
                    disabled
                  >
                    {classListName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {classList?.map((list) => (
                      <Dropdown.Item
                        key={list.classId}
                        eventKey={list.classId + 1}
                        onClick={() =>
                          handleClassname(list.classTitle, list.classId)
                        }
                      >
                        {list.classTitle}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {errors.classId && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.classId}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-10">
                <input
                  type="text"
                  placeholder="제목을 입력하세요."
                  value={inputList.title}
                  className="form-control"
                  name="title"
                  onChange={handleOnChange}
                />
                {errors.title && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.title}
                  </div>
                )}
              </div>

              <div className="form-group col-md-2">
                <input
                  type="number"
                  placeholder="시간을 입력하세요."
                  value={inputList.totalTime}
                  className="form-control"
                  name="totalTime"
                  onChange={handleOnChange}
                />
                {errors.totalTime && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.totalTime}
                  </div>
                )}
              </div>
            </div>
            <div className="form-row">
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  rows="20"
                  id="comment"
                  placeholder="여기에 내용을 작성하세요."
                  value={inputList.content}
                  name="content"
                  onChange={handleOnChange}
                ></textarea>
              </div>
              {errors.content && (
                <div className="text-danger fs-12 ml-3 mb-3">
                  {errors.content}
                </div>
              )}
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  placeholder="링크를 입력하세요."
                  value={inputList.url}
                  className="form-control"
                  name="url"
                  onChange={handleOnChange}
                />
              </div>
              {errors.url && (
                <div className="text-danger fs-12 ml-3 mb-3">{errors.url}</div>
              )}
            </div>

            <div className="form-row mt-3">
              <div className="form-group col-md-12">
                {fileReUpload ? (
                  <>
                    <h5 className="mb-2">
                      <i className="fa fa-paperclip"></i> 대표이미지를
                      등록하세요.
                    </h5>
                    <DropFile
                      accept="image/*"
                      handleGetDropZoneFile={handleGetDropZoneFile}
                    />
                  </>
                ) : (
                  <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-2">
                      <i className="fa fa-paperclip mr-3"></i>{' '}
                      {location.state.data.imageName}
                    </h5>
                    <div
                      onClick={handleImageDelete}
                      className="btn btn-primary light ml-2"
                    >
                      <i className="fa fa-trash"></i>
                    </div>
                  </div>
                )}
                {errors.imageName && (
                  <div className="text-danger fs-12 ml-3 mb-3 mt-2">
                    {errors.imageName}
                  </div>
                )}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-start mt-5">
              <button type="submit" className="btn btn-primary mr-3">
                수정
              </button>
              <button
                type="button"
                onClick={handleBack}
                className="btn btn-dark mr-3"
              >
                취소
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default KnowledgeEdit;
