import axios from 'axios';
import { useQuery } from 'react-query';

const useCategoryList = (groupId) => {
  const { data, isLoading } = useQuery(
    ['useCategoryList', groupId],
    async () => {
      return await axios
        .get(`${process.env.REACT_APP_IP}/admin/exams/creation/${groupId}`)
        .then((res) => res.data);
    },
    { enabled: !!groupId, retry: false }
  );

  return { data, isLoading };
};

export default useCategoryList;
