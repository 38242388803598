import { BASE_URL } from './config';

import axios from 'axios';

/* 게시판 카테고리 조회 */
export const fetchBoardCategories = async () => {
  return await axios.get(`${BASE_URL}/admin/lc-posts/categories`);
};

/* ==================================== 학습지원 게시판 ==================================== */
/* 게시판 카테고리 조회 */
export const fetchSupportCategories = async () => {
  return await axios.get(`${BASE_URL}/admin/posts/learning-board/category`);
};

/* 학습지원 게시판 목록 조회 */
export const fetchSupportBoardList = async ({
  pageNum,
  pageSize,
  keyword,
  board,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    board,
  };

  return await axios.get(`${BASE_URL}/admin/posts`, { params });
};

/* 학습지원 게시글 작성 */
export const createSupportPost = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/admin/posts`, formData);
};

/* 학습지원 게시글 수정 */
export const editSupportPost = async ({ id, formData }) => {
  return await axios.put(`${BASE_URL}/admin/posts/${id}`, formData);
};

/* 학습지원 게시글 작성 시 boardId, categoryId 조회 (select) */
export const fetchBoardSelect = async () => {
  return await axios.get(`${BASE_URL}/admin/posts/board-category`);
};

/* 학습지원 게시글 상세 조회 */
export const fetchSupportBoardDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/posts/${id}`);
};

/* 학습지원 게시글 첨부파일 조회 */
export const fetchSupportBoardFiles = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/posts/${id}/attachments`);
};

/* 학습지원 게시글 삭제 */
export const deleteSupportBoard = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/posts/${id}`);
};

/* 학습지원 게시글 댓글 조회 */
export const fetchSupportBoardReply = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/posts/comments/${id}`);
};

/* 학습지원 게시글 댓글 등록 */
export const addSupportBoardReply = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/posts/comments`, { ...body });
};

/* 학습지원 게시글 댓글 수정 */
export const editSupportBoardReply = async ({ commentId, body }) => {
  return await axios.put(`${BASE_URL}/admin/posts/comments/${commentId}`, {
    ...body,
  });
};

/* 학습지원 게시글 댓글 삭제 */
export const deleteSupportReply = async ({ postId, commentId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/posts/comments/${postId}/${commentId}`
  );
};

/* 문의하기 게시판 게시글 조회 */
export const fetchContactList = async ({
  pageNum,
  pageSize,
  keyword,
  groupId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    groupId,
  };

  return await axios.get(`${BASE_URL}/admin/posts/inquiry`, { params });
};

/* 문의하기 게시판 댓글 읽음 처리 */
export const checkContactReplyReadStatus = async ({ postId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/posts/comments/notifications/${postId}`
  );
};

/* ==================================== 클래스 게시판 ==================================== */
/* 클래스 게시판 카테고리 리스트 조회 */
export const fetchClassCategories = async () => {
  return await axios.get(`${BASE_URL}/admin/lc-posts/categories`);
};

/* 클래스 게시판 리스트 조회 */
export const fetchClassBoardList = async ({
  pageNum,
  pageSize,
  keyword,
  classId,
  category,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    classId,
    category,
  };

  return await axios.get(`${BASE_URL}/admin/lc-posts`, { params });
};

/* 클래스 게시판 게시글 상세 조회 */
export const fetchClassDetail = async ({ postId }) => {
  return await axios.get(`${BASE_URL}/admin/lc-posts/${postId}`);
};

/* 클래스 게시판 게시글 첨부파일 조회 */
export const fetchClassAttachments = async ({ postId }) => {
  return await axios.get(`${BASE_URL}/admin/lc-posts/${postId}/attachments`);
};

/* 클래스 게시판 게시글 댓글 조회 */
export const fetchClassReply = async ({ postId }) => {
  return await axios.get(`${BASE_URL}/admin/lc-posts/comments/${postId}`);
};

/* 클래스 게시글 작성 */
export const createClassBoard = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/admin/lc-posts`, formData);
};

/* 클래스 게시글 수정 */
export const editClassBoard = async ({ postId, formData }) => {
  return await axios.put(`${BASE_URL}/admin/lc-posts/${postId}`, formData);
};

/* 클래스 게시글 삭제 */
export const deleteClassBoard = async ({ postId }) => {
  return await axios.delete(`${BASE_URL}/admin/lc-posts/${postId}`);
};

/* 텍스트 에디터 본문에 이미지 업로드 */
export const uploadBoardContentImage = async (formData) => {
  return await axios.post(`${BASE_URL}/admin/lc-posts/images`, formData);
};

/* 클래스 게시판 첨부파일 다운로드 */
export const downloadClassAttachment = async ({ postId, fileId }) => {
  return await axios.get(
    `${BASE_URL}/admin/lc-posts/${postId}/attachments/${fileId}`,
    {
      responseType: 'blob',
    }
  );
};

/* 클래스 게시판 댓글 작성 */
export const createClassReply = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/lc-posts/comments`, {
    ...body,
  });
};

/* 클래스 게시판 댓글 삭제 */
export const deleteClassReply = async ({ commentId }) => {
  return await axios.delete(`${BASE_URL}/admin/lc-posts/comments/${commentId}`);
};

/* 클래스 게시판 댓글 수정 */
export const editClassReply = async ({ commentId, body }) => {
  return await axios.put(`${BASE_URL}/admin/lc-posts/comments/${commentId}`, {
    ...body,
  });
};
