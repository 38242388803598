import React, { useState } from "react";
import Switch from "react-switch";

export const SwitchWithHookForm = ({ isTrue, value, onChange }) => {
  const [isCheck, setIsCheck] = useState(isTrue || false);

  const handleChange = () => {
    setIsCheck((prev) => !prev);
    onChange(!isCheck);
  };

  return (
    <Switch
      onChange={handleChange}
      checked={isCheck}
      onColor="#ec7807"
      height={20}
      width={40}
      uncheckedIcon={false}
    />
  );
};

export default SwitchWithHookForm;
