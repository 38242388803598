import React, { Fragment, useMemo, useState } from 'react';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import { Link, useHistory, useParams } from 'react-router-dom';

import { useDebounce, useExam, useSetExamResult } from 'hooks';
import { EXAM_RESULT_COLUMNS } from '../Board/Columns';
import { confirmSwal, errorSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';
import { CardBody, CardHeader, Wrapper } from '../layouts';
import { Pagination, SearchInput } from '../common';
import styled from 'styled-components';

const ExamDetailResult = () => {
  const { examId } = useParams();
  const history = useHistory();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 300);

  const { deleteExamResult } = useSetExamResult();
  const { examDetailResult } = useExam({
    examId,
    pageNum,
    pageSize,
    keyword: debouncedKeyword,
  });
  const { data } = examDetailResult || {};

  //Table Setting
  const columns = useMemo(() => EXAM_RESULT_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async ({ resultId }) => {
    const confirm = await confirmSwal({
      title: '해당 시험 결과를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteExamResult.mutateAsync({ resultId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.EXAM_RESULT_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.EXAM_RESULT_DELETE_FAIL });
      }
    }
  };

  const handleGoToResult = (data, i) => {
    if (i !== 6) {
      history.push({
        pathname: '/exam-result-detail',
        state: {
          data,
        },
      });
    }
  };

  return (
    <Wrapper>
      <CardHeader justifyContent="space-between">
        <Title>시험 결과 목록</Title>
        <SearchInput value={keyword} setValue={setKeyword} />
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              handleGoToResult(cell.row.original, i)
                            }
                            className="cursor-pointer"
                          >
                            {i === 6 ? (
                              <>
                                <ReactTooltip />
                                <Link
                                  to="#"
                                  data-tip="Delete"
                                  onClick={() =>
                                    handleDelete({
                                      resultId: cell.row.original.id,
                                    })
                                  }
                                  className="text-black"
                                >
                                  <i className="far fa-trash-alt more-btn-icon-box mr-1"></i>
                                </Link>
                              </>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Title = styled.h4`
  margin-bottom: 0;
  line-height: 2;
`;

export default ExamDetailResult;
