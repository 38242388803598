import { useQuery } from 'react-query';

import { fetchCardinalList } from 'services';

const useFetchCardinalList = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchCardinalList'],
    queryFn: async () => await fetchCardinalList(),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    cacheTime: Infinity,
    staleTime: Infinity,
  });
  return { ...queryReturns };
};

export default useFetchCardinalList;
