import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  changeUserExpired,
  changeUserInfo,
  changeUserStatus,
  createUserAccount,
  fetchUserInfoById,
  fetchUserList,
  registerUsersByExcel,
  removeUserAccount,
  resetUserPassword,
} from 'services';

const useUsers = ({
  pageNum,
  pageSize,
  keyword,
  type,
  role,
  userId,
  classId,
}) => {
  const queryClient = useQueryClient();

  /* 유저 목록 조회 */
  const usersList = useQuery({
    queryKey: [
      'fetchUserList',
      { pageNum, pageSize, keyword, role, classId, type },
    ],
    queryFn: async () =>
      await fetchUserList({
        pageNum,
        pageSize,
        keyword,
        type,
        role,
        classId,
      }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!pageNum,
    keepPreviousData: true,
    staleTime: 30000,
    cacheTime: 30000,
  });

  /* 유저 만료 설정 On/Off */
  const editUserExpired = useMutation({
    mutationFn: async ({ userId, yn }) => {
      return await changeUserExpired({ userId, yn });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      });
    },
  });

  /* 유저 상태 On/Off */
  const editUserStatus = useMutation({
    mutationFn: async ({ userId, status }) => {
      return await changeUserStatus({ userId, status });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      });
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      });
    },
  });

  /* 유저 계정 정보 삭제 */
  const deleteUserAccount = useMutation({
    mutationFn: async ({ userId }) => {
      return await removeUserAccount({ userId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum, pageSize }],
      }),
  });

  /* 엑셀 파일로 유저 계정 대량 생성 */
  const registerUsers = useMutation({
    mutationFn: async ({ formData }) => {
      return await registerUsersByExcel({ formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  /* 단일 유저 계정 생성 */
  const createSingleUserAccount = useMutation({
    mutationFn: async ({ body }) => {
      return await createUserAccount({ body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  /* 유저 상세 조회 */
  const userInfo = useQuery({
    queryKey: ['fetchUserInfoById', { userId }],
    queryFn: async () => await fetchUserInfoById({ userId }),
    select: (res) => res.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!userId,
    staleTime: 50000,
    cacheTime: 50000,
  });

  /* 유저 정보 수정 */
  const editUserInfo = useMutation({
    mutationFn: async ({ body }) => {
      return await changeUserInfo({ body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchUserInfoById', { userId }],
      }),
  });

  /* 유저 비밀번호 초기화 */
  const resetPassword = useMutation({
    mutationFn: async ({ userId }) => {
      return await resetUserPassword({ userId });
    },
  });

  return {
    usersList,
    editUserExpired,
    editUserStatus,
    deleteUserAccount,
    resetPassword,

    createSingleUserAccount,
    registerUsers,
    editUserInfo,

    userInfo,
  };
};

export default useUsers;
