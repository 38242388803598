import React, { Fragment, useContext } from 'react';
import { Badge } from 'react-bootstrap';

//** Import Image */
import { useAdminLoginInfo } from 'hooks';
import profile from '../../assets/avatar/astro.jpg';
import AuthContext from '../../context/Auth.context';
import { userRole } from '../../constants';
import { Sitemap } from './index';

const DashContents = () => {
  const { name, type } = useContext(AuthContext);

  const { data: loginInfo } = useAdminLoginInfo();

  return (
    <Fragment>
      <div className="row">
        <div className="col-lg-12" style={{ paddingTop: '20px' }}>
          <div className="profile card card-body px-3 pt-3 pb-0">
            <div className="profile-head">
              <div className="profile-info">
                <div className="profile-photo">
                  <img
                    src={profile}
                    className="img-fluid rounded-circle"
                    alt="profile"
                  />
                </div>
                <div className="profile-details">
                  <div className="profile-name px-3">
                    <Badge variant="dark badge-sm mb-2">이름</Badge>
                    <h4 className="text-primary ml-1">{name}</h4>
                  </div>
                  <div className="profile-name px-3">
                    <Badge variant="dark badge-sm mb-2">권한</Badge>
                    <h4 className="text-primary ml-1">{userRole[type - 1]}</h4>
                  </div>
                  <div className="profile-name px-3">
                    <Badge variant="dark badge-sm mb-2">최근 로그인 시간</Badge>
                    <h4 className="text-primary ml-1">
                      {loginInfo?.lastLoginAt}
                    </h4>
                  </div>
                  <div className="profile-name px-3">
                    <Badge variant="dark badge-sm mb-2">최근 접속 IP</Badge>
                    <h4 className="text-primary ml-1">
                      {loginInfo?.remoteAddr}
                    </h4>
                  </div>
                  <div className="ml-auto"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* 사이트맵 */}
      <Sitemap />
    </Fragment>
  );
};

export default DashContents;
