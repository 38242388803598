import React, { Fragment, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import { useConnect, useSetConnect } from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { ZOOM_LINK_COLUMNS } from 'components/Board/Columns';
import { GlobalFilter } from 'components/common';
import CustomSwitch from 'components/ui/CustomSwitch';
import {
  GatherLinkDetailModal,
  GatherLinkEditModal,
  UploadGatherLink,
} from 'components/Connect';
import { CardBody, Wrapper } from 'components/layouts/common';

const ZoomLinkUpload = () => {
  const [detailModal, setDetailModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  const { deleteRemoteClass, editRemoteClassUse } = useSetConnect();
  const { remoteClassList } = useConnect({ type: 'link' });
  const { data } = remoteClassList || {};

  //Table Setting
  const columns = useMemo(() => ZOOM_LINK_COLUMNS, []);
  const tableData = useMemo(() => data || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
    gotoPage,
    pageCount,
    pageOptions,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex } = state;

  //ZoomLinkDelete
  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '해당 링크를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteRemoteClass.mutateAsync({ id });
        if (status === 200) {
          await successSwal({ text: MESSAGES.LINK_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.LINK_DELETE_FAIL });
      }
    }
  };

  const handleOpenEdit = (data) => {
    setEditModal(true);
    setDetailData(data);
  };

  const handleClickTableRow = (data, i) => {
    if (i !== 5) {
      setDetailModal(true);
      setDetailData(data);
    }
  };

  //useYn
  const handleUseToggle = async (confirm, data) => {
    const body = {
      isUse: data.row.original.useYn !== 'Y',
    };

    try {
      const { status } = await editRemoteClassUse.mutateAsync({
        id: data.row.original.id,
        body,
      });
      if (status === 200) {
        await successSwal({ text: MESSAGES.LINK_USE_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.LINK_USE_EDIT_FAIL });
    }
  };

  return (
    <>
      {/* 게더링크 업로드 */}
      <UploadGatherLink />

      <Wrapper>
        <CardBody>
          <div className="table-responsive">
            <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              className="zoomTd"
                              {...cell.getCellProps()}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                handleClickTableRow(row.original, i)
                              }
                            >
                              {i === 5 ? (
                                <>
                                  <ReactTooltip place="bottom" />
                                  <Link
                                    to="#"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete(row.original.id)
                                    }
                                    className="text-black"
                                  >
                                    <i className="far fa-trash-alt more-btn-icon-box mr-1"></i>
                                  </Link>

                                  <i
                                    className="fa-solid fa-pen more-btn-icon-box-info mr-1 cursor-pointer"
                                    onClick={() => handleOpenEdit(row.original)}
                                    data-tip="수정하기"
                                  ></i>
                                </>
                              ) : i === 3 ? (
                                <CustomSwitch
                                  isTrue={cell.row.original.useYn === 'Y'}
                                  data={cell}
                                  handleToggle={handleUseToggle}
                                />
                              ) : i === 4 ? (
                                <div
                                  onClick={() =>
                                    window.open(cell.row.original.zoomUrl)
                                  }
                                  className="badge rounded-pill bg-dark text-white cursor-pointer"
                                >
                                  {' '}
                                  <i className="fa-solid fa-video"></i> GoGather
                                </div>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            <div className="d-flex justify-content-between">
              <span>
                Page{' '}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>
                {''}
              </span>
              <span className="table-index">
                Go to page :{' '}
                <input
                  type="number"
                  className="ml-2"
                  defaultValue={pageIndex + 1}
                  min="1"
                  max={pageCount}
                  onChange={(e) => {
                    const pageNumber = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(pageNumber);
                  }}
                />
              </span>
            </div>
            <div className="text-center">
              <div className="filter-pagination  mt-3">
                <button
                  className=" previous-button"
                  onClick={() => gotoPage(0)}
                  disabled={!canPreviousPage}
                >
                  {'<<'}
                </button>

                <button
                  className="previous-button"
                  onClick={() => previousPage()}
                  disabled={!canPreviousPage}
                >
                  Previous
                </button>
                <button
                  className="next-button"
                  onClick={() => nextPage()}
                  disabled={!canNextPage}
                >
                  Next
                </button>
                <button
                  className=" next-button"
                  onClick={() => gotoPage(pageCount - 1)}
                  disabled={!canNextPage}
                >
                  {'>>'}
                </button>
              </div>
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {/* 게더 링크 상세 모달 */}
      <GatherLinkDetailModal
        show={detailModal}
        setShow={setDetailModal}
        detailData={detailData}
      />

      {/* 게더 링크 수정 모달 */}
      <GatherLinkEditModal
        show={editModal}
        setShow={setEditModal}
        detailData={detailData}
      />
    </>
  );
};

export default ZoomLinkUpload;
