import { useState } from 'react';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query';

import { downloadClassAttachment } from '../../services';
import { errorSwal } from '../../utilities';
import { MESSAGES } from '../../constants';

const useDownLoadClassFile = ({ postId }) => {
  const [fileId, setFileId] = useState('');

  const queryReturns = useQuery(
    ['download'],
    async () => {
      try {
        const response = await downloadClassAttachment({ postId, fileId });

        if (response) {
          FileSaver.saveAs(
            response.data,
            decodeURIComponent(
              response.headers['content-disposition']
                .split('filename=')[1]
                .replaceAll('"', '')
            )
          );
          setFileId('');
        }
      } catch (error) {
        setFileId('');
        await errorSwal({
          text: MESSAGES.FILE_DOWNLOAD_FAIL,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!fileId,
    }
  );
  return { ...queryReturns, setFileId };
};

export default useDownLoadClassFile;
