import React from 'react';
import { Button, Modal } from 'react-bootstrap';
import { Viewer } from '@toast-ui/react-editor';
import styled from 'styled-components';

import { useSetConsulting } from 'hooks';
import { ModalBadge } from '../common';
import { errorSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';
import { format } from 'date-fns';
import StatusBadge from '../common/StatusBadge';

const EditConsultingModal = ({ showModal, setShowModal, detailData = {} }) => {
  const { editConsultStatus } = useSetConsulting();

  const defaultData = new Date();

  const consultStatus = {
    승인대기중: '#f1a53f',
    '상담 수락': '#51D14F',
    '상담 완료': '#999',
  };

  const changeStatus = {
    승인대기중: '상담 수락하기',
    '상담 수락': '상담 완료로 변경',
    '상담 완료': '상담 완료',
  };
  const changeBgc = {
    승인대기중: 'primary',
    '상담 수락': 'primary',
    '상담 완료': 'dark',
  };

  const handleStats = async () => {
    try {
      const { status } = await editConsultStatus.mutateAsync({
        id: detailData.id,
      });
      if (status === 200) {
        setShowModal(false);
        await successSwal({ text: MESSAGES.CONSULTING_STATUS_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CONSULTING_STATUS_FAIL });
    }
  };

  if (!detailData) return <></>;
  return (
    <Modal className="fade bd-example-modal-lg" show={showModal} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">
          {detailData?.userName}님의 상담 신청 상세 정보
        </Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => setShowModal(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <StyledInputWrapper>
          <div>
            <ModalBadge text="상담 신청자" required={false} />
            <div>
              <span>
                {detailData.userName}({detailData.userId})
              </span>
            </div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge text="상담 날짜" required={false} />
            <div>
              <span>
                {format(
                  new Date(detailData?.consultingDate || defaultData),
                  'yyyy.MM.dd - HH:mm'
                )}
              </span>
            </div>
          </div>

          <div>
            <ModalBadge text="신청 날짜" required={false} />
            <div>
              <span>
                {format(
                  new Date(detailData?.regDate || defaultData),
                  'yyyy.MM.dd - HH:mm:ss'
                )}
              </span>
            </div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge text="상담 수락일" required={false} />
            <div>
              <span>
                {format(
                  new Date(detailData?.approvalDate || defaultData),
                  'yyyy.MM.dd - HH:mm:ss'
                )}
              </span>
            </div>
          </div>

          <div>
            <ModalBadge text="상담 수락자" required={false} />
            <div>
              <span>{detailData?.approvalUserId}</span>
            </div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge text="상담 내용" required={false} />
            <div>
              <Viewer initialValue={detailData?.content} />
            </div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge text="상태" required={false} />
            <StatusBadge
              text={detailData?.resultComment}
              bgColor={consultStatus[detailData?.resultComment]}
            />
          </div>
        </StyledInputWrapper>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        {detailData?.resultComment !== '상담 완료' && (
          <BadgeButton
            onClick={handleStats}
            variant={changeBgc[detailData?.resultComment]}
          >
            {changeStatus[detailData?.resultComment]}
          </BadgeButton>
        )}
        <Button onClick={() => setShowModal(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const BadgeButton = styled(Button)`
  color: #fff;
  width: auto;
  padding: 2px 30px;
  transition: 0.2s;
  cursor: pointer;
  border: none;

  &:hover {
    filter: contrast(130%);
  }
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  p {
    margin: 0;
  }

  .info {
    font-size: 10px;
    vertical-align: text-bottom;
  }

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default EditConsultingModal;
