import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ModalBadge } from '../common';

const CloudAccountModal = ({ show, setShow, detailData }) => {
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">클라우드 상세</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="클라우드 계정" />
          </div>
          <div className="col-10">{detailData.username}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="비밀번호" />
          </div>
          <div className="col-10">{detailData.password}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="URL" />
          </div>
          <div className="col-10">{detailData.url}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="AccessKey" />
          </div>
          <div className="col-10">{detailData.accessKeyId}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="ClientSecret" />
          </div>
          <div className="col-10">{detailData.clientSecret}</div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="생성일" />
          </div>
          <div className="col-10">
            {(detailData.regDate || '').replace('T', ' ').slice(0, 16)}
          </div>
        </div>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="연결된 사용자" />
          </div>
          <div className="col-10">
            {detailData.userId ? (
              <>
                {detailData.userId}
                {' / '}
                {detailData.name}
                {' / '}
                {
                  detailData.groupCourse?.split(' ')[
                    detailData.groupCourse?.split(' ').length - 1
                  ]
                }
              </>
            ) : (
              '-'
            )}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CloudAccountModal;
