import React from 'react';

import { DashContents } from '../components/Dashboard';

const Home = () => {
  return (
    <>
      <DashContents />
    </>
  );
};

export default Home;
