import {ColumnFilter} from "./ColumnFilter";
import {format} from "date-fns";

export const MORE_ARTICLES_COLUMNS = [
  {
    Header: "제목",
    Footer: "제목",
    accessor: "title",
  },
  {
    Header: "내용",
    Footer: "내용",
    accessor: "content",
    Filter: ColumnFilter,
  },
  {
    Header: "작성일",
    Footer: "작성일",
    accessor: "regDate",
    Cell: ({value}) => {
      return format(new Date(value), "yyyy.MM.dd");
    },
    Filter: "",
  },
];
