import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
  useDebounce,
  useExam,
  useGetQueryString,
  useSetExam,
  useSetParameterOnQuery,
} from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import { EXAM_COLUMNS } from 'components/Board/Columns';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import {
  SearchInput,
  Pagination,
  BoardWriteButton,
  CardinalDropMenu,
} from 'components/common';

const ExamList = ({ history }) => {
  const queryValues = useGetQueryString();
  const examStatus = ['출제중', '대기', '진행중', '종료'];

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [groupId, setGroupId] = useState('');
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setGroupId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { deleteExam } = useSetExam({});
  const { examList } = useExam({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    groupId,
  });
  const { data } = examList || {};

  //Table Setting
  const columns = useMemo(() => EXAM_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async ({ examId }) => {
    const confirm = await confirmSwal({
      title: '시험을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteExam.mutateAsync({ examId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.EXAM_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.EXAM_DELETE_FAIL });
      }
    }
  };

  const handleGoToDetail = (examId, i) => {
    if (i !== 8) {
      history.push(`/exam-detail/${examId}`);
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{
            width: '100%',
            justifyContent: 'space-between',
            gap: '10px',
            marginBottom: '10px',
          }}
          className="d-sm-flex"
        >
          <CardinalDropMenu
            classTitle={classTitle}
            setClassTitle={setClassTitle}
            setId={setGroupId}
            isGroupId
          />
          <SearchInput value={keyword} setValue={setKeyword} />
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    key={index}
                    {...row.getRowProps()}
                    className="cursor-pointer"
                  >
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              handleGoToDetail(cell.row.original.id, i)
                            }
                            className="cursor-pointer"
                          >
                            {i === 8 ? (
                              <>
                                <ReactTooltip />
                                <i
                                  className="far fa-trash-alt more-btn-icon-box mr-1"
                                  data-tip="Delete"
                                  onClick={() =>
                                    handleDelete({
                                      examId: cell.row.original.id,
                                    })
                                  }
                                />
                              </>
                            ) : i === 7 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.status]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {examStatus[cell.row.original.status]}
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <BoardWriteButton to="/exam-create" text="시험 생성" />

          {page.length !== 0 && (
            <Pagination
              page={page}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            ></Pagination>
          )}
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default ExamList;
