import { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';

import { useFetchCardinalList } from '../../hooks';
import {
  fetchClassCategoryByClassId,
  fetchClassUnitListByCategoryId,
} from 'services';

const DropClassCategoryUnit = ({ property }) => {
  const [isPaginationExist, setIsPaginationExist] = useState(false);

  const { data: classList } = useFetchCardinalList();

  useEffect(() => {
    // 과정명 가져오기
    if (classList?.length > 0) {
      property.setClassList(classList);
    }

    if (property.setPageNum) {
      setIsPaginationExist(true);
    } else {
      setIsPaginationExist(false);
    }
  }, [classList]);

  const handleClassTitle = async (data, id) => {
    // 과정명 선택 후
    property.setClassTitle(data);
    property.setClassId(id);
    if (property.setPageNum) {
      // 검색창 리셋
      property.setContent('');
      // 페이지네이션 리셋
      property.setPageNum(1);
    } // 카테고리 가져오기
    try {
      const response = await fetchClassCategoryByClassId({ classId: id });
      if (response.status === 200) {
        property.setCategoryList(response.data);
        if (response.data !== []) {
          property.setCategoryTitle('카테고리를 선택하세요.');
          property.setCategoryId('');
          property.setUnitTitle('유닛을 선택하세요.');
          property.setUnitId('');
        }
      }
    } catch (error) {
      property.setCategoryTitle('해당 카테고리가 없습니다.');
      property.setCategoryList([]);
      property.setUnitId('');
      property.setUnitTitle('해당 유닛이 없습니다.');
      property.setUnitList([]);
      property.setCategoryId('');
    }
  };

  const handleCategory = async (data, id) => {
    // 카테고리 선택 후
    property.setCategoryTitle(data);
    property.setCategoryId(id);
    if (isPaginationExist) {
      // 검색창 리셋
      property.setContent('');
      // 페이지네이션 리셋
      property.setPageNum(1);
    }
    // 단원 가져오기
    const response = await fetchClassUnitListByCategoryId({ categoryId: id });
    if (response.status === 200) {
      if (response.data.data.length > 0) {
        property.setUnitTitle('유닛을 선택해주세요.');
        property.setUnitList(response.data.data);
      } else {
        property.setUnitTitle('생성된 유닛이 없습니다.');
        property.setUnitList([]);
        property.setUnitId('');
      }
    }
  };

  return (
    <div className="d-flex">
      <Dropdown>
        <Dropdown.Toggle
          variant="outline-primary"
          size="md"
          className="height-38px"
        >
          {property.classTitle}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            <>
              {isPaginationExist && (
                <Dropdown.Item
                  key="전체"
                  onClick={() => handleClassTitle('전체', '')}
                  className="fs-12"
                >
                  전체
                </Dropdown.Item>
              )}{' '}
              {property.classList?.map((list, index) => (
                <Dropdown.Item
                  key={index}
                  onClick={() =>
                    handleClassTitle(list.classTitle, list.classId)
                  }
                  className="fs-12"
                >
                  {list.classTitle}
                </Dropdown.Item>
              ))}
            </>
          }
        </Dropdown.Menu>
      </Dropdown>
      {property.categoryList?.length !== 0 && (
        <Dropdown>
          <Dropdown.Toggle
            variant="outline-primary"
            size="md"
            className="mr-2 ml-2 height-38px"
          >
            {property.categoryTitle}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {
              <>
                {isPaginationExist && (
                  <Dropdown.Item
                    key="전체"
                    onClick={() => handleCategory('전체', '')}
                    className="fs-12"
                  >
                    전체
                  </Dropdown.Item>
                )}{' '}
                {property.categoryList?.map((list) => (
                  <Dropdown.Item
                    key={list.id}
                    eventKey={list.id + 1}
                    onClick={() =>
                      handleCategory(list.classCategoryName, list.id)
                    }
                    className="fs-12"
                  >
                    {list.classCategoryName}
                  </Dropdown.Item>
                ))}
              </>
            }
          </Dropdown.Menu>
        </Dropdown>
      )}

      <Dropdown>
        <Dropdown.Toggle
          variant="outline-primary"
          size="md"
          className="mr-2 ml-2 height-38px"
          disabled={!property.categoryId || !property.unitList.length}
        >
          {property.unitTitle}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Item
            key="전체"
            onClick={() => property.handleUnit('전체', '')}
            className="fs-12"
          >
            전체
          </Dropdown.Item>
          {property.unitList.map((list) => (
            <Dropdown.Item
              key={list.id}
              eventKey={list.id + 1}
              onClick={() => property.handleUnit(list.unitName, list.id)}
              className="fs-12"
            >
              {list.unitName}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};
export default DropClassCategoryUnit;
