import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Button, Modal, Badge, Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import swal from 'sweetalert';

import {
  useDebounce,
  useGetQueryString,
  useSetParameterOnQuery,
  useStudyGroup,
} from 'hooks';
import { STG_ROOM_COLUMNS } from 'components/Board/Columns';
import CustomSwitch from 'components/ui/CustomSwitch';
import { SearchInput, Pagination } from 'components/common';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { confirmSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';

export const StgRoomConfirmModal = ({ basicModal, setBasicModal, stgData }) => {
  const [zoomName, setZoomName] = useState('Zoom 링크를 선택하세요.');
  const [zoomId, setZoomId] = useState();

  const { zoomLinkList, updateStudyRoomZoomLink } = useStudyGroup({
    type: 'studyRoom',
  });
  const { data: zoomList } = zoomLinkList || {};

  const handleZoomLink = (data, id) => {
    setZoomName(data);
    setZoomId(id);
  };

  const updateZoomAndConfirm = async () => {
    const body = {
      isApprove: true,
      zoomId,
    };

    const { status } = await updateStudyRoomZoomLink.mutateAsync({
      studyRoomId: stgData.id,
      body,
    });
    if (status === 200) {
      setBasicModal(false);
      await successSwal({ text: '줌링크가 등록되었습니다.' });
    }
  };

  return (
    basicModal && (
      <Modal className="fade bd-example-modal-lg" show={basicModal} size="md">
        <Modal.Header>
          <Modal.Title className="mt-5">
            {stgData.content} ZOOM링크 설정
          </Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>
        <Modal.Body>
          <div className="form-row">
            <div className="form-group d-flex align-items-center mr-5 col-md-12">
              <Badge variant="primary badge-sm light mr-2">ZOOM LINK</Badge>
            </div>
            <div className="form-group col-md-12">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  className="zoom-drop-menu"
                >
                  {zoomName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="zoom-drop-menu">
                  {zoomList?.map((zoom) => (
                    <Dropdown.Item
                      key={zoom.id}
                      eventKey={zoom.id + 1}
                      onClick={() => handleZoomLink(zoom.zoomUrl, zoom.id)}
                      className="fs-12"
                    >
                      {zoom.zoomUrl}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="mb-3">
          <button
            type="submit"
            onClick={updateZoomAndConfirm}
            className="btn btn-primary mr-3"
          >
            업데이트
          </button>
          <button
            type="button"
            onClick={() => setBasicModal(false)}
            className="btn btn-secondary"
          >
            취소
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

const StudyRoomList = () => {
  const queryValues = useGetQueryString();

  const [basicModal, setBasicModal] = useState(false);
  const [stgData, setStgData] = useState({
    id: '',
    content: '',
    approvalYn: '',
    zoomUrl: '',
  });
  const [isChanged, setIsChanged] = useState(false);
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
    }
  }, [queryValues]);

  const {
    studyRoomReserveList,
    deleteStudyRoomReserve,
    updateStudyRoomApprove,
  } = useStudyGroup({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    type: 'studyRoom',
  });
  const { data } = studyRoomReserveList || {};

  //Table Setting
  const columns = useMemo(() => STG_ROOM_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleUseToggle = async (use, cell) => {
    if (!cell.row.original.zoomUrl) {
      swal({
        title: 'ZOOM링크가 설정되어 있지 않습니다.',
        text: 'ZOOM 링크를 먼저 설정 하시겠습니까?',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        className: 'mySwal',
      }).then((zoomSet) => {
        if (zoomSet) {
          handleOpenZoomSet(cell);
        } else {
          swal('확인후 다시 진행을 부탁드립니다.');
        }
      });
      return '';
    }
    let editZoomYn = 'Y';
    if (use) {
      editZoomYn = 'N';
    }

    const body = {
      zoomId: cell.row.original.zoomId,
      isApprove: cell.row.original.approvalYn !== 'Y',
    };

    const { status } = await updateStudyRoomApprove.mutateAsync({
      studyRoomId: cell.row.original.id,
      body,
    });
    if (status === 200) {
      await successSwal({ text: '변경되었습니다.' });
    }
  };

  const handleOpenZoomSet = (data) => {
    setBasicModal(true);
    setStgData({
      id: data.row.original.id,
      content: data.row.original.content,
      approvalYn: data.row.original.approvalYn,
      zoomUrl: data.row.original.zoomUrl,
    });
  };

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '스터디룸을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteStudyRoomReserve.mutateAsync({ id });
      if (status === 200) {
        await successSwal({ text: MESSAGES.STUDYROOM_DELETE_SUCCESS });
      }
    }
  };

  return (
    <>
      <StgRoomConfirmModal
        setBasicModal={setBasicModal}
        basicModal={basicModal}
        stgData={stgData}
        setIsChanged={setIsChanged}
        isChanged={isChanged}
      />
      <Wrapper>
        <CardHeader justifyContent="flex-end">
          <div>
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td {...cell.getCellProps()}>
                              {i === 7 ? (
                                <>
                                  <ReactTooltip />
                                  <i
                                    className="far fa-trash-alt more-btn-icon-box mr-1"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete(cell.row.original.id)
                                    }
                                  />
                                </>
                              ) : i === 5 ? (
                                <CustomSwitch
                                  isTrue={cell.row.original.approvalYn === 'Y'}
                                  data={cell}
                                  handleToggle={handleUseToggle}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 ? (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            ) : (
              ''
            )}

            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </CardBody>
      </Wrapper>
    </>
  );
};

export default StudyRoomList;
