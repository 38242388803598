import React, { Fragment, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import styled from 'styled-components';

import Pagination from '../../common/Pagination';
import { MORE_ARTICLES_COLUMNS } from '../../Board/MoreArticlesColumns';

const Tr = styled.div`
  white-space: pre-wrap;
  padding: 10px 20px 0 20px;
  width: 100%;
  transition: all 0.3s ease-in-out;
`;
const UserModal = ({ author, moreModalContent }) => {
  const [userId, setUserId] = useState(moreModalContent.post.userId);
  const [pageNum, setPageNum] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchContent, setSearchContent] = useState('');
  const [data, setData] = useState([]);
  const [maxPostPage, setMaxPostPage] = useState();
  const [paginationData, setPaginationData] = useState();
  const [activeIndex, setActiveIndex] = useState(false);
  const boardType = [
    'lcPost',
    'post',
    'stgPost',
    'lcPostReply',
    'postReply',
    'stgReply',
  ];
  const boardName = [
    '클래스',
    '학습지원',
    '스터디그룹',
    '클래스 댓글',
    '학습지원 댓글',
    '스터디그룹 댓글',
  ];

  const url = `${process.env.REACT_APP_IP}/admin/${
    boardType[moreModalContent.boardType - 1]
  }/${userId}?pageNum=${pageNum}&pageSize=${pageSize}&searchContent=${searchContent}`;
  const columns = useMemo(() => MORE_ARTICLES_COLUMNS, []);

  const handleClick = (id) => {
    if (activeIndex === id) {
      setActiveIndex(null);
    } else {
      setActiveIndex(id);
    }
  };

  useEffect(() => {
    axios.get(url).then((Response) => {
      setData(Response.data.list);
      setPaginationData(Response.data);
    }); // 클래스 게시글 댓글 조회
  }, []);

  useEffect(() => {
    setMaxPostPage(paginationData?.pages);
  }, [maxPostPage, paginationData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;
  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="row">
          <div style={{ padding: '40px' }} className="col-lg-12">
            <h5 className="text-primary">{`${author}의 모든 ${
              boardName[moreModalContent.boardType - 1]
            } 게시글`}</h5>
            <div className="card">
              <div className="card-header border-0 pb-0">
                <div className="card-body">
                  <div className="table-responsive">
                    <table
                      {...getTableProps()}
                      className="table filtering-table table-responsive-lg"
                    >
                      <thead>
                        {headerGroups.map((headerGroup) => (
                          <tr
                            className="first-child-long-xl"
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, i) => (
                              <th {...column.getHeaderProps()}>
                                {i === 1 ? null : column.render('Header')}
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="" {...getTableBodyProps()}>
                        {page.map((row) => {
                          prepareRow(row);
                          return (
                            <>
                              <tr
                                className="second-child-long cursor-pointer"
                                style={{ position: 'relative' }}
                                {...row.getRowProps()}
                              >
                                {row.cells.map((cell, i) => {
                                  return (
                                    <Fragment key={i}>
                                      <td
                                        style={
                                          activeIndex === row
                                            ? { background: '#eee' }
                                            : { background: 'white' }
                                        }
                                        onClick={() => handleClick(row)}
                                      >
                                        {i !== 1 ? cell.value : null}
                                      </td>
                                    </Fragment>
                                  );
                                })}
                              </tr>
                              {activeIndex === row ? (
                                <tr {...row.getRowProps()}>
                                  {row.cells.map((cell, i) => {
                                    return (
                                      <Tr>{i === 1 ? cell.value : null}</Tr>
                                    );
                                  })}
                                </tr>
                              ) : null}
                            </>
                          );
                        })}
                      </tbody>
                    </table>
                    {page.length === 0 ? (
                      <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                        검색 결과가 없습니다.
                      </div>
                    ) : (
                      ''
                    )}
                    <div className="text-center"></div>
                  </div>
                </div>
              </div>
              {
                <Pagination
                  page={page}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  data={paginationData}
                  maxPostPage={maxPostPage}
                ></Pagination>
              }
              <div className="card-body pt-3">
                <div className="profile-news"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserModal;
