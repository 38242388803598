/* eslint-disable */
import React, { useState } from 'react';
import { Dropdown, DropdownButton, ButtonGroup } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';

import {
  TITLE_MAX_LENGTH,
  MESSAGES,
  SUPPORT_CATEGORY_URL,
} from '../../constants';
import { useSupportBoard } from 'hooks';
import { isNoContent, successSwal } from 'utilities';
import TextEditor from 'components/ui/TextEditorNoImage';
import { BoardAppendixFile, ValidationErrorMsg } from 'components/common';

const SupportBoardWrite = () => {
  const history = useHistory();

  const {
    register,
    setError,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [boardName, setBoardName] = useState('카테고리를 선택해주세요.');
  const [categoryId, setCategoryId] = useState(0);
  const [textValue, setTextValue] = useState('');
  const [files, setFiles] = useState([]);
  const [, setDeleteAttachments] = useState([]);

  const { submitPost, boardSelect } = useSupportBoard({});

  const handleCategorySelect = (categoryInfo) => {
    setCategoryId(categoryInfo.categoryId);
    setBoardName(categoryInfo.categoryName);
  };

  const submit = async (form) => {
    if (isNoContent(textValue)) {
      setError('content', {
        type: 'required',
      });
    }
    if (!categoryId) {
      swal('카테고리를 선택해주세요.', {
        icon: 'error',
      });
    }

    const body = {
      boardId: categoryId,
      categoryId,
      title: form.title,
      content: textValue,
    };

    const formData = new FormData();

    formData.append(
      'data',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );
    if (files) {
      formData.append('file', files[0]);
    }

    const response = await submitPost.mutateAsync({ formData });
    if (response) {
      history.push(SUPPORT_CATEGORY_URL[categoryId - 1]);
      await successSwal({ text: MESSAGES.POST_SUBMIT_SUCCESS });
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 5) {
      swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
      return;
    }

    setFiles((prev) => {
      if ([...prev, ...fileList].length > 5) {
        swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
        return [...prev];
      }

      return [...prev, ...fileList];
    });
  };

  const handleDeleteFile = (index, fileName) => {
    setFiles((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
    setDeleteAttachments((prev) => {
      if (attachments?.find((e) => e.newFileName === fileName)) {
        return [...prev, fileName];
      } else {
        return [...prev];
      }
    });
  };

  return (
    <div className="h-80">
      <div className="row">
        <div className="col-xl-12 col-xxl-12">
          <div className="card">
            <div className="card-header">
              <div className="d-sm-flex">
                <ButtonGroup>
                  <DropdownButton
                    variant="dark"
                    title={boardName}
                    id="bg-vertical-dropdown-3"
                  >
                    <Dropdown.Item
                      eventKey="1"
                      onClick={() =>
                        handleCategorySelect({
                          categoryId: 0,
                          categoryName: '카테고리를 선택해주세요.',
                        })
                      }
                      className="fs-12"
                    >
                      전체 카테고리
                    </Dropdown.Item>
                    {boardSelect.data &&
                      boardSelect.data.map((sub, idx) => (
                        <Dropdown.Item
                          key={idx}
                          eventKey={idx + 1}
                          className="fs-12"
                          onClick={() => handleCategorySelect(sub)}
                        >
                          {sub.categoryName}
                        </Dropdown.Item>
                      ))}
                  </DropdownButton>
                </ButtonGroup>
              </div>
            </div>

            <div className="card-body">
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  <div className="basic-form">
                    <form onSubmit={handleSubmit(submit)}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <input
                            type="text"
                            placeholder="제목을 입력하세요."
                            className="form-control"
                            name="title"
                            {...register('title', {
                              required: true,
                              maxLength: TITLE_MAX_LENGTH,
                            })}
                          />
                          {errors.title?.type === 'required' && (
                            <ValidationErrorMsg text="제목을 입력해 주세요." />
                          )}
                          {errors.title?.type === 'maxLength' && (
                            <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                          )}
                        </div>

                        <div className="form-group col-md-12">
                          <div className="default-tab">
                            <TextEditor
                              htmlStr={textValue}
                              setHtmlStr={setTextValue}
                              style={{ borderRadius: '15px' }}
                            />
                            {errors.content?.type === 'required' && (
                              <ValidationErrorMsg text="내용을 입력해 주세요." />
                            )}
                          </div>
                        </div>
                      </div>

                      {boardName === '자료실' ? (
                        <div className="form-row mt-3">
                          <div className="form-group col-md-12">
                            <h5 className="mb-2">
                              <i className="fa fa-paperclip" />
                              첨부파일
                            </h5>
                            <BoardAppendixFile
                              onChange={handleChangeFile}
                              onDelete={handleDeleteFile}
                              originalFileNames={files}
                            />
                          </div>
                        </div>
                      ) : null}

                      <div className="d-flex justify-content-end">
                        <button type="submit" className="btn btn-primary">
                          게시글 등록
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportBoardWrite;
