import { BASE_URL } from './config';

import axios from 'axios';

/* 시험 목록 조회 */
export const fetchExams = async ({ pageNum, pageSize, keyword, groupId }) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    groupId,
  };

  return await axios.get(`${BASE_URL}/admin/exams/api/v1`, {
    params,
  });
};

/* 시험 상세 조회 */
export const fetchExamDetail = async ({ examId }) => {
  return await axios.get(`${BASE_URL}/admin/exams/api/v1/${examId}`);
};

/* 시험 상세 페이지 결과 조회 */
export const fetchExamDetailResult = async ({
  examId,
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/exams/results/api/v1/${examId}`, {
    params,
  });
};

/* 시험 상세 문제 목록 조회 */
export const fetchExamDetailProblems = async ({
  examId,
  pageNum,
  pageSize,
  examType,
  examLevel,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    examType,
    examLevel,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/exams/api/v1/${examId}/problems`, {
    params,
  });
};

/* 시험 상세 문제 삭제 */
export const removeExamDetailProblem = async ({ problemId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/exams/problem/delete/${problemId}`
  );
};

/* 시험 생성 */
export const createNewExam = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/exams/api/v1/new`, { ...body });
};

/* 시험 삭제 */
export const removeExam = async ({ examId }) => {
  return await axios.delete(`${BASE_URL}/admin/exams/delete/${examId}`);
};

/* 시험 문제 목록 조회 */
export const fetchExamProblemList = async ({
  pageNum,
  pageSize,
  unitId,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    unitId,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/exams/problems`, {
    params,
  });
};

/* 시험 문제 상세 조회 */
export const fetchExamProblemDetail = async ({ problemId }) => {
  return await axios.get(
    `${BASE_URL}/admin/exams/api/v1/problems/${problemId}`
  );
};

/* 시험 문제 상세 수정 */
export const changeExamProblemDetail = async ({ problemId, body }) => {
  return await axios.patch(`${BASE_URL}/admin/exams/problems/${problemId}`, {
    ...body,
  });
};

/* 시험 문제 단원 목록 조회 */
export const fetchExamProblemUnit = async () => {
  return await axios.get(`${BASE_URL}/admin/units/exams`);
};

/* 시험 문제 삭제 */
export const removeExamProblem = async ({ problemId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/exams/problem/delete/${problemId}`
  );
};

/* 시험 클래스 평균 조회 */
export const fetchExamClassAverage = async ({ className }) => {
  return await axios.get(`${BASE_URL}/admin/exams/result/average/${className}`);
};

/* 시험이 있는 클래스, 그룹 정보 목록 조회 */
export const fetchClassWithExam = async () => {
  return await axios.get(`${BASE_URL}/admin/exams/analysis`);
};

/* 그룹 별 시험 정보 리스트 조회 */
export const fetchGroupExamInfo = async ({ groupId }) => {
  return await axios.get(`${BASE_URL}/admin/exams/subjects/${groupId}`);
};

/* 차수 별 시험 친 학생 결과 리스트 조회 */
export const fetchStudentExamResultDetail = async ({
  groupId,
  selectedId,
  subClassId,
}) => {
  return await axios.get(
    `${BASE_URL}/admin/exams/analysis/${groupId}/${selectedId}/${subClassId}`
  );
};

/* 학생 시험 결과 삭제 */
export const removeExamResult = async ({ resultId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/exams/results/api/v1/${resultId}`
  );
};
