import React from 'react';
import styled from 'styled-components';

const Badge = ({ text, color = '#6e6e6e', ...rest }) => {
  return (
    <Container color={color} {...rest}>
      {text}
    </Container>
  );
};

const Container = styled.div`
  width: auto;
  padding: 5px 15px;
  background-color: ${(p) => p.color};
  border-radius: 20px;
  color: #fff;
  box-shadow: 1px 1px 3px #888;
`;

export default Badge;
