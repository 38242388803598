import { useMutation, useQueryClient } from 'react-query';

import { changeClassVideo, createClassVideo, removeClassVideo } from 'services';

const useSetClassVideo = () => {
  const queryClient = useQueryClient();

  // 강의 등록
  const addVideo = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassVideo({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchAllClassVideoList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  // 강의 수정
  const editVideo = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeClassVideo({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchAllClassVideoList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  // 강의 삭제
  const deleteClassVideo = useMutation({
    mutationFn: async ({ videoId }) => {
      return await removeClassVideo({ videoId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchAllClassVideoList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    addVideo,
    editVideo,
    deleteClassVideo,
  };
};

export default useSetClassVideo;
