import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useStudyGroup } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { ModalBadge, ValidationErrorMsg } from 'components/common';

const StudyGroupEditModal = ({ editModal, setEditModal, data }) => {
  const { studyGroupId } = useParams();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { studyGroupDetail, editStudyGroupDetail } = useStudyGroup({
    studyGroupId,
    type: 'studyGroupDetail',
  });
  const { data: detailData } = studyGroupDetail || {};

  const submitEdit = async (form) => {
    if (JSON.stringify(detailData) === JSON.stringify(form)) {
      await errorSwal({ text: '변경된 내용이 없습니다.' });
      return;
    }

    const body = {
      content: form.content,
      foundingMembers: form.foundingMembers,
      limitCount: form.limitCount,
      meetingTime: form.meetingTime,
      studyGroupName: form.studyGroupName,
    };

    try {
      const { status } = await editStudyGroupDetail.mutateAsync({
        studyGroupId,
        body,
      });
      if (status === 200) {
        setEditModal(false);
        await successSwal({ text: MESSAGES.STUDYGROUP_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.STUDYGROUP_EDIT_FAIL });
    }
  };

  useEffect(() => {
    if (detailData) {
      reset({ ...detailData });
    }
  }, [studyGroupId, detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={editModal} size="lg">
      <form onSubmit={handleSubmit(submitEdit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">그룹정보 수정</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setEditModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputWrapper>
            <div>
              <ModalBadge text="그룹 이름" />
              <div>
                <input
                  type="text"
                  placeholder="그룹 이름을 입력하세요."
                  className="form-control"
                  name="studyGroupName"
                  {...register('studyGroupName', { required: true })}
                />
                {errors.studyGroupName?.type === 'required' && (
                  <ValidationErrorMsg text="과정 이름을 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="그룹 설명" />
              <div>
                <textarea
                  style={{
                    minHeight: '150px',
                    resize: 'none',
                  }}
                  placeholder="그룹 설명을 입력하세요."
                  className="form-control"
                  name="content"
                  {...register('content', {
                    required: true,
                  })}
                />
                {errors.content && (
                  <ValidationErrorMsg text="그룹 설명을 입력해주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="그룹 인원" />
              <div>
                <input
                  type="number"
                  min={data?.memberCount}
                  max="30"
                  placeholder="그룹 인원을 입력하세요."
                  className="form-control"
                  name="limitCount"
                  {...register('limitCount', {
                    required: true,
                  })}
                />
                {errors.limitCount && (
                  <ValidationErrorMsg text="그룹 인원을 입력해주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="모임 일정" />
              <div>
                <input
                  type="text"
                  placeholder="일정을 입력하세요."
                  className="form-control"
                  name="meetingTime"
                  {...register('meetingTime', {
                    required: true,
                  })}
                />
                {errors.meetingTime && (
                  <ValidationErrorMsg text="모임 일정을 입력해주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="초기 멤버" />
              <div>
                <textarea
                  style={{
                    minHeight: '120px',
                    resize: 'none',
                  }}
                  placeholder="초기멤버를 입력하세요."
                  className="form-control"
                  name="foundingMembers"
                  {...register('foundingMembers', {
                    required: true,
                  })}
                />
                {errors.foundingMembers && (
                  <ValidationErrorMsg text="초기 멤버를 입력해주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-start">
            <button type="submit" className="btn btn-primary mr-3">
              업데이트
            </button>
            <button
              type="button"
              onClick={() => setEditModal(false)}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default StudyGroupEditModal;
