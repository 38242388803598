import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import {
  useDebounce,
  useExam,
  useGetQueryString,
  useSetExam,
  useSetParameterOnQuery,
} from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import { EXAM_PROBLEM_COLUMNS } from '../Board/Columns';
import { Pagination, SearchInput } from '../common';
import { CardBody, CardHeader, Wrapper } from '../layouts';
import styled from 'styled-components';

const ExamDetailProblems = () => {
  const queryValues = useGetQueryString();

  const { examId } = useParams();
  const history = useHistory();

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(5);
  const [data, setData] = useState([]);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const [examType, setExamType] = useState('');
  const [examName, setExamTypeName] = useState('');
  const [examLevel, setExamLevel] = useState('');
  const [examLevelName, setExamLevelName] = useState('');

  const examProblemTypes = ['객관식', '다중답안객관식', 'OX', '단답형'];
  const examProblemLevels = ['하', '중', '상'];

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setExamType('');
      setExamTypeName('');
      setExamLevel('');
      setExamLevelName('');
    }
  }, [queryValues]);

  const { deleteExamProblem } = useSetExam({ examId });
  const { examDetailProblems } = useExam({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    examId,
    pageSize,
    examLevel,
    examType,
  });
  const { data: detailData } = examDetailProblems || {};

  useEffect(() => {
    if (!detailData) return;

    setData(detailData?.list || []);
  }, [detailData]);

  //Table Setting
  const columns = useMemo(() => EXAM_PROBLEM_COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  //PreLearningDelete
  const handleDelete = async ({ problemId }) => {
    const confirm = await confirmSwal({
      title: '문제를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteExamProblem.mutateAsync({ problemId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.PROBLEM_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.PROBLEM_DELETE_FAIL });
      }
    }
  };

  const handleExamType = (type, index) => {
    setExamTypeName(type);
    setExamType(index);
  };
  const handleExamLevel = (level, index) => {
    setExamLevelName(level);
    setExamLevel(index);
  };

  const handleGoToDetail = (data, i) => {
    if (i !== 6) {
      history.push({
        pathname: `/exam-problem-detail/${data.examProblemId}`,
        state: {
          data: data,
        },
      });
    }
  };

  return (
    <Wrapper>
      <CardHeader style={{ flexDirection: 'column' }}>
        <Title>시험 문제 목록</Title>
        <div
          style={{ width: '100%' }}
          className="d-sm-flex justify-content-between"
        >
          <div className="d-sm-flex">
            {/* 문제 유형 선택 */}
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="md"
                className="mr-2  height-38px"
              >
                {examName || '유형을 선택하세요.'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  key="전체"
                  onClick={() => handleExamType('전체', '')}
                  className="fs-12"
                >
                  전체
                </Dropdown.Item>
                {examProblemTypes.map((list, index) => (
                  <Dropdown.Item
                    key={index}
                    eventKey={index}
                    onClick={() => handleExamType(list, index)}
                    className="fs-12"
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>

            {/* 난이도 선택 */}
            <Dropdown>
              <Dropdown.Toggle
                variant="outline-primary"
                size="md"
                className="mr-2 height-38px"
              >
                {examLevelName || '난이도를 선택하세요.'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item
                  key="전체"
                  onClick={() => handleExamLevel('', '')}
                  className="fs-12"
                >
                  전체
                </Dropdown.Item>
                {examProblemLevels.map((list, index) => (
                  <Dropdown.Item
                    key={index}
                    eventKey={index}
                    onClick={() => handleExamLevel(list, index)}
                    className="fs-12"
                  >
                    {list}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div>
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg exam-list-column"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              handleGoToDetail(cell.row.original, i)
                            }
                            style={{
                              cursor: 'pointer',
                              whiteSpace: i === 3 ? 'pre' : 'normal',
                            }}
                          >
                            {i === 6 ? (
                              <>
                                <div style={{ display: 'flex' }}>
                                  <ReactTooltip />
                                  <div
                                    // to="#"
                                    style={{ cursor: 'pointer' }}
                                    data-tip="Delete"
                                    onClick={() =>
                                      handleDelete({
                                        problemId:
                                          cell.row.original.examProblemId,
                                      })
                                    }
                                    className="text-black"
                                  >
                                    <i className="far fa-trash-alt more-btn-icon-box mr-1"></i>
                                  </div>
                                </div>
                              </>
                            ) : i === 1 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.examLevel + 3]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {examProblemLevels[cell.row.original.examLevel]}
                              </div>
                            ) : i === 0 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.examType]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {examProblemTypes[cell.row.original.examType]}
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={detailData || {}}
                maxPostPage={detailData?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Title = styled.h4`
  margin-bottom: 10px;
  line-height: 2;
`;

export default ExamDetailProblems;
