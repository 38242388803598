/* eslint-disable */
import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import AuthContext, { SetAuthContext } from './context/Auth.context';

/**
 * https://ourcodeworld.com/articles/read/257/how-to-get-the-client-ip-address-with-javascript-only
 *
 * Get the user IP throught the webkitRTCPeerConnection
 * @param onNewIP {Function} listener function to expose the IP locally
 * @return undefined
 */
function getUserIP(onNewIP) {
  //  onNewIp - your listener function for new IPs
  //compatibility for firefox and chrome
  var myPeerConnection =
    window.RTCPeerConnection ||
    window.mozRTCPeerConnection ||
    window.webkitRTCPeerConnection;
  var pc = new myPeerConnection({
      iceServers: [],
    }),
    noop = function () {},
    localIPs = {},
    ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g,
    key;

  function iterateIP(ip) {
    if (!localIPs[ip]) onNewIP(ip);
    localIPs[ip] = true;
  }

  //create a bogus data channel
  pc.createDataChannel('');

  // create offer and set local description
  pc.createOffer()
    .then(function (sdp) {
      sdp.sdp.split('\n').forEach(function (line) {
        if (line.indexOf('candidate') < 0) return;
        line.match(ipRegex).forEach(iterateIP);
      });

      pc.setLocalDescription(sdp, noop, noop);
    })
    .catch(function (reason) {
      // An error occurred, so handle the failure to connect
    });

  //listen for candidate events
  pc.onicecandidate = function (ice) {
    if (
      !ice ||
      !ice.candidate ||
      !ice.candidate.candidate ||
      !ice.candidate.candidate.match(ipRegex)
    )
      return;
    ice.candidate.candidate.match(ipRegex).forEach(iterateIP);
  };
}

let clientIP;
getUserIP(function (ip) {
  clientIP = ip;
});

export function parseJWT(token) {
  const removedBearer = token.replace('Bearer ', '');
  const base64Url = removedBearer.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');

  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join('')
  );
  return JSON.parse(jsonPayload);
}

//사용하고자 하는 컴포넌트 최상위에 지정할 Provider컴포넌트
const AuthProvider = ({ children, history }) => {
  const onLogin = (token, refreshToken, data) => {
    //  TODO: clientIP를 서버로 전달해서 토큰 생성 및 검증에 활용
    if (token && refreshToken) {
      localStorage.setItem('token', token);
      localStorage.setItem('refreshToken', refreshToken);
      const parsedData = parseJWT(token);

      setAuth({
        ...auth,
        auth: true,
        token: String(token),
        refreshToken: String(refreshToken),
        authToken: { Authorization: String(token) },
        userPhone: parsedData.userPhone,
        userId: String(parsedData.userId),
        name: String(parsedData.userName),
        type: parsedData.userType,
        email: String(parsedData.userEmail),
        status: String(parsedData.status),
        freeYn: String(parsedData.freeYn),
        allowLanguage: String(parsedData.allowLanguages),
        classId: String(parsedData.classId),
        subClassId: String('0'),
        groupId: String(parsedData.groupId),
        initialPw: parsedData.initialPw,
        expireDate: parsedData.exp,
      });

      // 유저 정보 헤더 추가
    } else {
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      setAuth({
        ...auth,
        auth: false,
        userId: '',
        userPhone: '',
        name: '',
        type: -1,
        email: '',
        status: '',
        token: '',
        refreshToken: '',
        authToken: '',
        freeYn: '',
        allowLanguages: '',
        classId: '',
        groupId: '',
        subClassId: '',
        initialPw: '',
        expireDate: '',
      });
    }
  };

  const onLogout = () => {
    const token = localStorage.getItem('token');
    axios.post(
      `${process.env.REACT_APP_IP}/users/logout`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    );

    setAuth((prevState) => {
      return {
        ...prevState,
        auth: false,
        userId: '',
        userPhone: '',
        name: '',
        type: -1,
        email: '',
        status: '',
        refreshToken: '',
        token: '',
        authToken: '',
        freeYn: '',
        allowLanguages: '',
        classId: '',
        groupId: '',
        subClassId: '',
        initialPw: '',
        expireDate: '',
      };
    });

    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
  };

  let initialState = {
    auth: false,
    userId: '',
    userPhone: '',
    name: '',
    type: -1,
    email: '',
    status: '',
    token: '',
    refreshToken: '',
    authToken: '',
    freeYn: '',
    allowLanguages: '',
    classId: '',
    subClassId: '',
    groupId: '',
    initialPw: '',
    expireDate: '',
    onLogin,
    onLogout,
  };
  if (localStorage.getItem('token') !== null) {
    const parsedData = parseJWT(localStorage.getItem('token'));

    initialState = {
      ...initialState,
      auth: true,
      userId: String(parsedData.userId),
      userPhone: parsedData.userPhone,
      name: String(parsedData.userName),
      type: parsedData.userType,
      email: String(parsedData.userEmail),
      status: String(parsedData.status),
      token: localStorage.getItem('token'),
      refreshToken: localStorage.getItem('refreshToken'),
      authToken: { Authorization: localStorage.getItem('token') },
      freeYn: String(parsedData.freeYn),
      allowLanguages: String(parsedData.allowLanguages),
      classId: String(parsedData.classId),
      subClassId: 0,
      groupId: String(parsedData.groupId),
      initialPw: parsedData.initialPw,
      expireDate: String(parsedData.exp),
    };
  }

  const [auth, setAuth] = useState(initialState);

  if (auth.auth) {
    const userPath = document.location.href.split('/')[3];
    const userType = auth.type; // 1: 학습자, 2: 출제자
    const status = auth.status; // 0: 대기, 1: 승인, 2: 거절, 3: 탈퇴
    if (userPath === 'student') {
      if (userType !== '1') {
        history.push('/');
      } else if (
        status !== '1' &&
        document.location.href.split('/')[4] !== 'capabilitytest'
      ) {
        toast.error(':: ' + Message.STATUE_NOT_CONFIRMED);
        history.push('/');
      }
    } else if (userPath === 'submitter' && userType !== '2') {
      history.push('/');
    }
  }

  return (
    <SetAuthContext.Provider value={setAuth}>
      <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
    </SetAuthContext.Provider>
  );
};

export default withRouter(AuthProvider);
