import React, { useState } from 'react';
import { Badge, Button, Dropdown, Modal } from 'react-bootstrap';

import { useStudyGroup } from '../../hooks';
import { successSwal } from '../../utilities';

const StudyRoomConfirmModal = ({ basicModal, setBasicModal, stgData }) => {
  const [zoomName, setZoomName] = useState('Zoom 링크를 선택하세요.');
  const [zoomId, setZoomId] = useState();

  const { zoomLinkList, updateStudyRoomZoomLink } = useStudyGroup({
    type: 'studyRoom',
  });
  const { data: zoomList } = zoomLinkList || {};

  const handleZoomLink = (data, id) => {
    setZoomName(data);
    setZoomId(id);
  };

  const updateZoomAndConfirm = async () => {
    const { status } = await updateStudyRoomZoomLink.mutateAsync({
      studyRoomId: stgData.id,
      zoomId,
    });
    if (status === 200) {
      setBasicModal(false);
      await successSwal({ text: '줌링크가 등록되었습니다.' });
    }
  };

  return (
    basicModal && (
      <Modal
        style={{ width: '100%' }}
        className="fade bd-example-modal-lg"
        show={basicModal}
        size="lg"
      >
        <Modal.Header>
          <Modal.Title className="mt-5">
            {stgData.studyGroupName} ZOOM링크 설정
          </Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setBasicModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="form-row">
            <div className="form-group d-flex align-items-center mr-5 col-md-12">
              <Badge variant="primary badge-sm light mr-2">ZOOM LINK</Badge>
            </div>
            <div className="form-group col-md-12">
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  className="zoom-drop-menu"
                >
                  {zoomName}
                </Dropdown.Toggle>
                <Dropdown.Menu className="zoom-drop-menu">
                  {zoomList?.map((zoom) => (
                    <Dropdown.Item
                      key={zoom.id}
                      eventKey={zoom.id + 1}
                      onClick={() => handleZoomLink(zoom.zoomUrl, zoom.id)}
                      className="fs-12"
                    >
                      {zoom.zoomUrl}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button
            type="submit"
            onClick={updateZoomAndConfirm}
            className="btn btn-primary mr-3"
          >
            업데이트
          </button>
          <button
            type="button"
            onClick={() => setBasicModal(false)}
            className="btn btn-secondary"
          >
            취소
          </button>
        </Modal.Footer>
      </Modal>
    )
  );
};

export default StudyRoomConfirmModal;
