import React, { Fragment, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';

import CustomSwitch from 'components/ui/CustomSwitch';
import { PBL_CATEGORY_COLUMNS } from 'components/Board/Columns';
import {
  useChangePBLBasicScore,
  useFetchPBLUnits,
  useChangePBLUnitVisible,
  useChangePBLUsedUnit,
  useFetchPBLBasicScore,
} from 'hooks';
import { CardBody, CardHeader, Wrapper } from '../../components/layouts/common';
import { CardinalDropMenu } from '../../components/common';

const PblCategory = () => {
  const { register, setValue, getValues, watch } = useForm();
  const watchedScore = watch('newScore');
  const disab = Number(watchedScore) < 0 || Number(watchedScore) > 100;

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [groupId, setGroupId] = useState('');

  const { data: unitList } = useFetchPBLUnits({ groupId });
  const { data: basicScore, refetch } = useFetchPBLBasicScore({
    groupId,
  });
  const { mutateAsync: changeVisible } = useChangePBLUnitVisible();
  const { mutateAsync: changeUsedUnit } = useChangePBLUsedUnit();
  const { mutateAsync: changeBasicScore } = useChangePBLBasicScore({
    groupId,
  });

  const [data, setData] = useState([]);

  const columns = useMemo(() => PBL_CATEGORY_COLUMNS, []);
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleUsedUnit = async ({ unitId, usePbl }) => {
    try {
      const response = await changeUsedUnit({ unitId, usePbl });
      if (response.status === 204) {
        swal({
          title: `성공적으로 변경되었습니다.`,
          icon: 'success',
          buttons: '확인',
        });
      }
    } catch (error) {
      const { status } = error.response;
      if (status === 400) {
        swal({
          title: `출제된 문제가 있을 경우 변경이 불가능합니다.`,
          icon: 'error',
          buttons: '확인',
        });
      }
    }
  };

  const handleMenuActiveToggle = async ({ unitId, pblVisible }) => {
    try {
      const response = await changeVisible({ unitId, pblVisible });
      if (response.status === 204) {
        swal({
          title: `성공적으로 변경되었습니다.`,
          icon: 'success',
          buttons: '확인',
        });
      }
    } catch (error) {}
  };

  const handleChangeBasicScore = async () => {
    if (!watchedScore) return;
    if (Number(watchedScore) < 0 || Number(watchedScore) > 100) return;

    const skippableScore = getValues('newScore');
    const result = await swal({
      title: `기준 점수를 ${skippableScore}점으로 변경하시겠습니까?`,
      icon: 'warning',
      buttons: ['취소', '수정'],
      dangerMode: true,
    });

    if (result) {
      const response = await changeBasicScore({ skippableScore });
      if (response.status === 204) {
        swal({
          title: `성공적으로 변경되었습니다.`,
          icon: 'success',
          buttons: '확인',
        });
        setValue('newScore', '');
        refetch();
      }
      try {
      } catch (error) {}
    }
  };

  useEffect(() => {
    if (unitList?.length === 0 || !unitList) return;

    setData([...unitList]);
  }, [unitList]);

  useEffect(() => {
    if (!basicScore) return;

    setValue('basicScore', basicScore.skippableScore);
  }, [basicScore]);

  return (
    <>
      <Wrapper>
        <CardHeader>
          <div
            style={{ width: '100%' }}
            className="d-sm-flex justify-content-between"
          >
            <CardinalDropMenu
              classTitle={classTitle}
              setClassTitle={setClassTitle}
              setId={setGroupId}
              isGroupId
            />
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup, index) => (
                  <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()} key={index}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td {...cell.getCellProps()}>
                              {i === 1 ? (
                                <CustomSwitch
                                  isTrue={cell.row.original.usePbl}
                                  data={cell}
                                  handleToggle={() =>
                                    handleUsedUnit({
                                      unitId: cell.row.original.id,
                                      usePbl: !cell.row.original.usePbl,
                                    })
                                  }
                                />
                              ) : i === 2 ? (
                                <CustomSwitch
                                  disabled={!cell.row.original.usePbl}
                                  isTrue={cell.row.original.pblVisible}
                                  data={cell}
                                  handleToggle={() =>
                                    handleMenuActiveToggle({
                                      unitId: cell.row.original.id,
                                      pblVisible: !cell.row.original.pblVisible,
                                    })
                                  }
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {page.length === 0 ? (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            ) : (
              ''
            )}
          </div>
        </CardBody>
      </Wrapper>

      {groupId && (
        <div className="card">
          <div className="card-body">
            <Title>기준 점수 설정</Title>

            <SettingContainer>
              <div className="title">
                중급 난이도부터 문제를 풀 수 있는 기준 점수
                <span> (1차 사전평가 점수 기준)</span>
              </div>

              <div className="input">
                <input
                  type="text"
                  className="prevScore"
                  readOnly
                  {...register('basicScore')}
                />
                <div>
                  <input
                    type="number"
                    className="newScore"
                    placeholder="수정할 점수를 입력해주세요."
                    max={100}
                    min={0}
                    {...register('newScore')}
                  />
                  {(Number(watchedScore) < 0 || Number(watchedScore) > 100) && (
                    <ErrorMessage>
                      0과 100 사이 글자만 입력이 가능합니다.
                    </ErrorMessage>
                  )}
                </div>
                <span
                  onClick={handleChangeBasicScore}
                  className={!watchedScore || disab ? 'disabled' : ''}
                >
                  수정
                </span>
              </div>
            </SettingContainer>
          </div>
        </div>
      )}
    </>
  );
};

const Title = styled.div`
  font-size: 22px;
  font-weight: bold;
  padding: 0 0 20px;
`;

const SettingContainer = styled.div`
  padding-bottom: 20px;

  .title {
    font-size: 16px;
    font-weight: 500;

    span {
      font-size: 12px;
      color: #939393;
    }
  }

  .input {
    display: flex;
    column-gap: 5px;

    input {
      height: 32px;
    }
    .newScore {
      min-width: 200px;
    }

    .prevScore {
      max-width: 80px;
      min-width: 50px;
      cursor: default;
    }

    > div {
      height: 32px;
      position: relative;

      p {
        position: absolute;
      }
    }

    span {
      padding: 5px 10px;
      background-color: #ec7807;
      color: #fff;
      border-radius: 10px;
      cursor: pointer;
      margin-left: 10px;

      &:hover {
        filter: brightness(90%);
      }

      &.disabled {
        background-color: #a8a8a8;
        cursor: not-allowed;
        color: #fff;
        filter: brightness(100%);
      }
    }
  }
`;

const ErrorMessage = styled.p`
  font-size: 12px;
  color: #f17575;
`;

export default PblCategory;
