import React, { useEffect, useState } from 'react';

import { TableHead, TableList, Tr, Wrapper } from './UserPreference';
import { Pagination } from '../common';
import { colTitles, sortedKeys } from 'constants';
import EditUserAttendanceModal from './EditUserAttendanceModal';
import { useAttendances } from 'hooks';

const UserAttendanceList = ({ userInfo }) => {
  const [modal, setModal] = useState(null);

  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [attendanceData, setAttendanceData] = useState();

  const [keyword, _setKeyword] = useState('');

  const { data } = useAttendances({
    userId: userInfo?.userId,
    classId: userInfo?.classId,
    pageNum,
    pageSize,
    keyword,
  });

  useEffect(() => {
    if (!data) return;

    setAttendanceData(data.list);
  }, [data, pageNum]);

  const openModal = (value) => {
    setModal({ ...value });
  };

  return (
    <>
      <div className="card">
        <Wrapper>
          <TableHead>출결관리</TableHead>
          {/*<div*/}
          {/*  style={{*/}
          {/*    padding: '20px 10px',*/}
          {/*    marginLeft: 'auto',*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <SearchInput value={keyword} setValue={setKeyword} />*/}
          {/*</div>*/}

          <div style={{ width: '100%', padding: '0 20px' }}>
            {attendanceData && (
              <table
                className="table"
                style={{ borderTop: 'none', marginTop: '20px' }}
              >
                <thead>
                  <Tr className="head">
                    {colTitles.map((v, i) => (
                      <th key={i} scope="col">
                        {v}
                      </th>
                    ))}
                  </Tr>
                </thead>
                <tbody>
                  {attendanceData.length !== 0 ? (
                    attendanceData.map((v, i) => (
                      <TableList
                        key={i}
                        value={v}
                        sortedKeys={sortedKeys}
                        onClick={openModal}
                      />
                    ))
                  ) : (
                    <Tr>
                      <td
                        style={{ textAlign: 'center', cursor: 'default' }}
                        colSpan={7}
                      >
                        데이터가 없습니다.
                      </td>
                    </Tr>
                  )}
                </tbody>
              </table>
            )}
          </div>

          <div style={{ width: '100%' }} className="text-center">
            <Pagination
              page={attendanceData || []}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            />
          </div>
        </Wrapper>
      </div>

      {/* 출결 정보 수정 모달 */}
      {modal && <EditUserAttendanceModal modal={modal} setModal={setModal} />}
    </>
  );
};

export default UserAttendanceList;
