import React from 'react';
import styled from 'styled-components';

const CardBody = ({ children }) => {
  return <Wrapper>{children}</Wrapper>;
};

const Wrapper = styled.div`
  padding: 1.875rem;
  flex: 1 1 auto;
  min-height: 1px;
`;

export default CardBody;
