import React from 'react';
import styled from 'styled-components';
import { Badge, Button, Modal } from 'react-bootstrap';

import { ModalBadge } from '../common';

const ClassDetailModal = ({ show, setShow, detailData }) => {
  const badgeColor = {
    READY: 'dark',
    ON: 'info',
    OFF: 'danger',
  };

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">클래스 상세 정보</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="과정명" />
          </div>
          <div className="col-10">{detailData.title}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="교육기간" />
          </div>
          <div className="col-10">
            {detailData.startDate} ~ {detailData.endDate}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="태그" />
          </div>
          <div className="col-10">
            <Tags>
              {(detailData.tag || '').split(',').map((e) => (
                <span>{e}</span>
              ))}
            </Tags>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="상태" />
          </div>
          <div className="col-10">
            <Badge
              variant={`${
                badgeColor[detailData.status]
              } badge-rounded badge-sm`}
              style={{ opacity: '0.7' }}
            >
              {detailData.status}
            </Badge>
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="총 교육 시간" />
          </div>
          <div className="col-10">{detailData.totalTime}H</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="리플레이 가능일" />
          </div>
          <div className="col-10">{detailData.replayAvailableDays}일</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="생성일" />
          </div>
          <div className="col-10">
            {detailData.createdDateTime || ''.slice(0, 10)}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const Tags = styled.div`
  width: 100%;
  display: flex;
  gap: 3px;
  flex-wrap: wrap;

  > span {
    padding: 3px 5px;
    background-color: #ec7422;
    color: #fdf4f8;
    border-radius: 5px;
  }
`;

export default ClassDetailModal;
