import { useMutation, useQueryClient } from 'react-query';

import { changePBLBasicScore } from '../../services';

const useChangePBLBasicScore = ({ groupId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ skippableScore }) => {
      return await changePBLBasicScore({ groupId, skippableScore });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchPBLBasicScore'] }),
  });
  return { ...queryReturns };
};

export default useChangePBLBasicScore;
