export const colTitles = [
  '날짜',
  '입실시간',
  '퇴실시간',
  '외출시간',
  '복귀시간',
  '상태',
];

export const userRole = [
  '교육생',
  '교강사',
  '슈퍼 매니저',
  '운영 매니저',
  'FT',
  '고객사 매니저',
];

export const sortedKeys = [
  'chapterDate',
  'attendTime',
  'leavingTime',
  'outingStartTime',
  'outingEndTime',
  'status',
];

export const userType = {
  1: 'Student',
};

export const EMAIL_REGEX =
  /^([\w\.\_\-])*[a-zA-Z0-9]+([\w\.\_\-])*([a-zA-Z0-9])+([\w\.\_\-])+@([a-zA-Z0-9]+\.)+[a-zA-Z0-9]{2,8}$/i;

export const PHONE_REGEX = /^\d{3}-\d{3,4}-\d{4}$/;
