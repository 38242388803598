import React, { Fragment, useMemo, useState } from 'react';
import { CardBody, CardHeader, Wrapper } from '../layouts';
import ReactTooltip from 'react-tooltip';
import { useParams } from 'react-router-dom';
import CustomSwitch from '../ui/CustomSwitch';
import { useStudyGroup } from '../../hooks';
import { STG_ROOM_COLUMNS } from '../Board/Columns';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import swal from 'sweetalert';
import { confirmSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';
import StudyRoomConfirmModal from './StudyRoomConfirmModal';

const StudyGroupStudyRoomReserve = () => {
  const { studyGroupId } = useParams();

  const [basicModal, setBasicModal] = useState(false);
  const [stgData, setStgData] = useState({
    id: '',
    content: '',
    approvalYn: '',
    zoomUrl: '',
  });
  const [isChanged, setIsChanged] = useState(false);

  const {
    studyGroupDetailRoom,
    deleteStudyRoomReserve,
    updateStudyRoomApprove,
  } = useStudyGroup({
    studyGroupId,
    type: 'studyRoom',
    pageNum: 1,
  });
  const { data } = studyGroupDetailRoom || {};

  //Table Setting
  const columns = useMemo(() => STG_ROOM_COLUMNS, []);
  const tableData = useMemo(() => data || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const handleUseToggle = async (use, cell) => {
    if (!cell.row.original.zoomUrl) {
      swal({
        title: 'ZOOM링크가 설정되어 있지 않습니다.',
        text: 'ZOOM 링크를 먼저 설정 하시겠습니까?',
        icon: 'warning',
        buttons: true,
        dangerMode: true,
        className: 'mySwal',
      }).then((zoomSet) => {
        if (zoomSet) {
          handleOpenZoomSet(cell);
        } else {
          swal('확인후 다시 진행을 부탁드립니다.');
        }
      });
      return '';
    }
    let editZoomYn = 'Y';
    if (use) {
      editZoomYn = 'N';
    }

    const body = {
      zoomId: cell.row.original.zoomId,
      isApprove: cell.row.original.approvalYn !== 'Y',
    };

    const { status } = await updateStudyRoomApprove.mutateAsync({
      studyRoomId: cell.row.original.id,
      body,
    });
    if (status === 200) {
      await successSwal({ text: '변경되었습니다.' });
    }
  };

  const handleOpenZoomSet = (data) => {
    setBasicModal(true);
    setStgData({
      id: data.row.original.id,
      content: data.row.original.content,
      approvalYn: data.row.original.approvalYn,
      zoomUrl: data.row.original.zoomUrl,
    });
  };

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '스터디룸을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteStudyRoomReserve.mutateAsync({ id });
      if (status === 200) {
        await successSwal({ text: MESSAGES.STUDYROOM_DELETE_SUCCESS });
      }
    }
  };

  return (
    <>
      <StudyRoomConfirmModal
        setBasicModal={setBasicModal}
        basicModal={basicModal}
        stgData={stgData}
        setIsChanged={setIsChanged}
        isChanged={isChanged}
      />

      <Wrapper>
        <CardHeader>
          <div>
            <h5 className="mt-2 text-dark">스터디그룹 스터디룸 예약 내역</h5>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td {...cell.getCellProps()}>
                              {i === 7 ? (
                                <>
                                  <ReactTooltip />
                                  <i
                                    className="far fa-trash-alt more-btn-icon-box mr-1"
                                    data-tip="Delete"
                                    onClick={() =>
                                      handleDelete(cell.row.original.id)
                                    }
                                  />
                                </>
                              ) : i === 5 ? (
                                <CustomSwitch
                                  isTrue={cell.row.original.approvalYn === 'Y'}
                                  data={cell}
                                  handleToggle={handleUseToggle}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}
          </div>
        </CardBody>
      </Wrapper>
    </>
  );
};

export default StudyGroupStudyRoomReserve;
