import { useMutation, useQueryClient } from 'react-query';

import { changePBLUsedUnit } from '../../services/pbl';

const useChangePBLUsedUnit = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ unitId, usePbl }) => {
      return await changePBLUsedUnit({ unitId, usePbl });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchPBLUnits'] }),
  });
  return { ...queryReturns };
};

export default useChangePBLUsedUnit;
