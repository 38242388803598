import React, { useEffect } from 'react';
import { Badge, ListGroup } from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { CardBody, CardHeader, Wrapper } from 'components/layouts';
import { ModalBadge, UserSection, ValidationErrorMsg } from 'components/common';
import { useExamProblem, useSetExamProblem } from 'hooks';
import { errorSwal, successSwal } from '../../utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';

const ExamProblemEdit = () => {
  const { problemId } = useParams();
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
  } = useForm({
    mode: 'onChange',
  });

  const examProblemTypes = ['객관식', '다중답안객관식', 'OX', '단답형'];

  const { editProblem } = useSetExamProblem({});
  const { problemDetail } = useExamProblem({ problemId });
  const { data } = problemDetail || {};

  const submit = async (form) => {
    const body = {
      question: form.question,
      questionContent: form.questionContent,
      correctAnswer: form.correctAnswer.trim(),
    };
    if (form.option1) body.option1 = form.option1;
    if (form.option2) body.option2 = form.option2;
    if (form.option3) body.option3 = form.option3;
    if (form.option4) body.option4 = form.option4;

    try {
      const { status } = await editProblem.mutateAsync({
        problemId,
        body,
      });
      if (status === 200) {
        history.push(`/exam-problem-detail/${problemId}`);
        await successSwal({ text: MESSAGES.EXAM_PROBLEM_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.EXAM_PROBLEM_EDIT_FAIL });
    }
  };

  useEffect(() => {
    const {
      answer,
      option1,
      option2,
      option3,
      option4,
      question,
      questionContent,
    } = data || {};

    reset({
      correctAnswer: answer,
      option1,
      option2,
      option3,
      option4,
      question,
      questionContent,
    });
  }, [data]);

  return (
    <Wrapper>
      <CardHeader justifyContent="flex-start">
        <UserSection
          dataA="출제자"
          dataB={data?.makerName}
          dataC={data?.makeDate}
        />
        {data?.reviewDate && (
          <div style={{ marginLeft: '50px' }}>
            <UserSection
              dataA="수정자"
              dataB={data?.reviewerName}
              dataC={`수정일: ${data?.reviewDate}`}
            />
          </div>
        )}
      </CardHeader>

      <CardBody>
        <form onSubmit={handleSubmit(submit)}>
          <div className="read-content">
            <div className="media mb-2 mt-3 mr-2">
              <div className="media-body">
                <div
                  className={`mr-3 py-2 badge rounded-pill bg-${
                    BADGE_COLOR[data?.type]
                  } text-white`}
                  style={{ opacity: '0.7' }}
                >
                  {examProblemTypes[data?.type]}
                </div>
                <Badge variant="outline-primary badge-rounded badge-md light py-2 mr-3">
                  {data?.objective.content}
                </Badge>
              </div>
            </div>
          </div>

          <div
            className="read-content-body mx-2"
            style={{ maxWidth: '920px', fontSize: '13px' }}
          >
            <StyledInputWrapper>
              <div>
                <ModalBadge text="문제 제목" />
                <div>
                  <textarea
                    maxLength={1500}
                    rows={5}
                    className="form-control"
                    placeholder="문제 제목을 입력하세요."
                    name="question"
                    {...register('question', {
                      required: true,
                    })}
                  ></textarea>
                  {errors.question && (
                    <ValidationErrorMsg text="문제 제목을 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="문제 지문" required={false} />
                <div>
                  <textarea
                    name="questionContent"
                    maxLength={1500}
                    rows={5}
                    className="form-control"
                    placeholder="문제 지문을 입력해주세요."
                    {...register('questionContent')}
                  ></textarea>
                </div>
              </div>
            </StyledInputWrapper>

            {data?.type === 0 ? (
              <div className="basic-list-group mt-4">
                <ListGroup>
                  <ListGroup.Item className="d-flex justify-content-start align-items-center">
                    <Badge
                      variant={
                        watch('correctAnswer') === '1'
                          ? `danger badge-rounded badge-md mr-3`
                          : `dark badge-rounded badge-md mr-3`
                      }
                      style={{ opacity: '0.7' }}
                    >
                      1
                    </Badge>
                    <div style={{ width: '100%' }}>
                      <textarea
                        maxLength={2000}
                        rows={2}
                        placeholder="1번 선택지를 입력하세요."
                        className="form-control"
                        name="option1"
                        {...register('option1', {
                          required: data?.type === 0,
                        })}
                      ></textarea>
                      {errors.option1 && (
                        <ValidationErrorMsg text="1번 선택지를 입력해 주세요." />
                      )}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-start align-items-center">
                    <Badge
                      variant={
                        watch('correctAnswer') === '2'
                          ? `danger badge-rounded badge-md mr-3`
                          : `dark badge-rounded badge-md mr-3`
                      }
                      style={{ opacity: '0.7' }}
                    >
                      2
                    </Badge>
                    <div style={{ width: '100%' }}>
                      <textarea
                        maxLength={2000}
                        rows={2}
                        placeholder="2번 선택지를 입력하세요."
                        className="form-control"
                        name="option2"
                        {...register('option2', {
                          required: data?.type === 0,
                        })}
                      ></textarea>
                      {errors.option2 && (
                        <ValidationErrorMsg text="2번 선택지를 입력해 주세요." />
                      )}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-start align-items-center">
                    <Badge
                      variant={
                        watch('correctAnswer') === '3'
                          ? `danger badge-rounded badge-md mr-3`
                          : `dark badge-rounded badge-md mr-3`
                      }
                      style={{ opacity: '0.7' }}
                    >
                      3
                    </Badge>
                    <div style={{ width: '100%' }}>
                      <textarea
                        maxLength={2000}
                        rows={2}
                        placeholder="3번 선택지를 입력하세요."
                        className="form-control"
                        name="option3"
                        {...register('option3', {
                          required: data?.type === 0,
                        })}
                      ></textarea>
                      {errors.option3 && (
                        <ValidationErrorMsg text="3번 선택지를 입력해 주세요." />
                      )}
                    </div>
                  </ListGroup.Item>
                  <ListGroup.Item className="d-flex justify-content-start align-items-center">
                    <Badge
                      variant={
                        watch('correctAnswer') === '4'
                          ? `danger badge-rounded badge-md mr-3`
                          : `dark badge-rounded badge-md mr-3`
                      }
                      style={{ opacity: '0.7' }}
                    >
                      4
                    </Badge>
                    <div style={{ width: '100%' }}>
                      <textarea
                        maxLength={2000}
                        rows={2}
                        placeholder="4번 선택지를 입력하세요."
                        className="form-control"
                        name="option4"
                        {...register('option4', {
                          required: data?.type === 0,
                        })}
                      ></textarea>
                      {errors.option4 && (
                        <ValidationErrorMsg text="4번 선택지를 입력해 주세요." />
                      )}
                    </div>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            ) : (
              ''
            )}

            {data?.imgPath1 ? (
              <h5 className="mt-5">
                <i className="fa fa-paperclip mr-3"></i> {data?.imgPath1}
              </h5>
            ) : (
              ''
            )}

            {data?.imgPath2 ? (
              <h5 className="my-3">
                <i className="fa fa-paperclip mr-3"></i> {data?.imgPath2}
              </h5>
            ) : (
              ''
            )}
          </div>

          <div className="mt-5 ml-2 mr-2">
            <StyledInputWrapper>
              <div>
                <ModalBadge text="정답" />
                <div>
                  {/* 주관형, 단답형 */}
                  {data?.type === 3 && (
                    <>
                      <input
                        type="text"
                        placeholder="정답을 입력하세요."
                        className="form-control"
                        name="correctAnswer"
                        {...register('correctAnswer', {
                          required: true,
                          pattern: /^(?!.* {2,}).*$/,
                        })}
                      />
                      <span className="noti">
                        ※단답형 문제의 경우 정답에 띄어쓰기를 입력할 경우 띄어진
                        단어의 개수만큼 입력 칸이 생성됩니다. 또한, 정답의 순서
                        또한 판별합니다.
                        <br /> ex) 납골당의 어린왕자 → _____, _____ 입력란에
                        '납골당의', '어린왕자' 두 개의 단어를 순서대로 입력해야
                        정답 처리
                      </span>
                      {errors.correctAnswer?.type === 'required' && (
                        <ValidationErrorMsg text="정답을 입력해 주세요." />
                      )}
                      {errors.correctAnswer?.type === 'pattern' && (
                        <ValidationErrorMsg text="2개 이상의 연속된 공백은 기입할 수 없습니다." />
                      )}
                    </>
                  )}

                  {data?.type === 0 && (
                    <RadioBox>
                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="1"
                          name="correctAnswer"
                        />
                        <span>1</span>
                      </div>

                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="2"
                          name="correctAnswer"
                        />
                        <span>2</span>
                      </div>

                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="3"
                          name="correctAnswer"
                        />
                        <span>3</span>
                      </div>

                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="4"
                          name="correctAnswer"
                        />
                        <span>4</span>
                      </div>
                    </RadioBox>
                  )}

                  {data?.type === 2 && (
                    <RadioBox>
                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="O"
                          name="correctAnswer"
                        />
                        <span>O</span>
                      </div>

                      <div>
                        <input
                          {...register('correctAnswer', {
                            required: true,
                          })}
                          type="radio"
                          value="X"
                          name="correctAnswer"
                        />
                        <span>X</span>
                      </div>
                    </RadioBox>
                  )}
                </div>
              </div>
            </StyledInputWrapper>
          </div>

          <div className="d-flex justify-content-end">
            <button type="submit" className="btn btn-primary mr-3">
              문제 수정
            </button>
          </div>
        </form>
      </CardBody>
    </Wrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 25px 0;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
    }
  }

  textarea {
    resize: none;
  }

  span.noti {
    font-size: 12px;
    font-weight: bold;
  }
`;

const RadioBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;

  > div {
    display: flex;
    align-items: center;
  }
`;

export default ExamProblemEdit;
