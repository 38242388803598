import React from 'react';

const Pagination = ({ pageNum, setPageNum, data, maxPostPage, page, id }) => {
  return (
    <div className="text-center">
      {page?.length !== 0 && (
        <div className="filter-pagination mt-3">
          <button
            className="previous-button"
            disabled={pageNum <= 1}
            onClick={() => {
              setPageNum(1);
            }}
          >
            <i className="fa fa-angle-double-left"></i>
          </button>
          {data?.navigatepageNums.map((v, i) => (
            <button
              key={i}
              className={
                v !== data.pageNum ? 'next-button' : 'nowPage next-button'
              }
              onClick={() => {
                setPageNum(v);
              }}
            >
              {v}
            </button>
          ))}
          {!data?.navigatepageNums.includes(maxPostPage) && (
            <>
              <div style={{ display: 'inherit' }}>...</div>
              <button
                className="next-button"
                onClick={() => setPageNum(maxPostPage)}
              >
                {maxPostPage}
              </button>
            </>
          )}
          <button
            className="next-button"
            disabled={pageNum >= maxPostPage}
            onClick={() => {
              setPageNum(maxPostPage);
            }}
          >
            <i className="fa fa-angle-double-right"></i>
          </button>
        </div>
      )}
    </div>
  );
};

export default Pagination;
