import React, { useEffect, useState } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import { useFetchAllUserType, useUsers } from 'hooks';
import { CardinalDropMenu, ModalBadge, ValidationErrorMsg } from '../common';
import { errorSwal, successSwal } from 'utilities';
import { checkEmailValid } from 'services';
import { EMAIL_REGEX, PHONE_REGEX, MESSAGES } from '../../constants';

const SingleUserRegistryModal = ({ isModalOpen, setIsModalOpen }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });
  const emailValue = watch('email');

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');
  const [typeName, setTypeName] = useState('유형선택');
  const [typeId, setTypeId] = useState('');
  const [groupId, setGroupId] = useState('');

  const [isCheckedMail, setIsCheckedMail] = useState(false);
  const [isEmailCheckLoading, setIsEmailCheckLoading] = useState(false);

  const { data: userTypes } = useFetchAllUserType();

  const { createSingleUserAccount } = useUsers({});

  const handleEmailCheck = async () => {
    setIsEmailCheckLoading(true);
    try {
      const { data } = await checkEmailValid({ email: emailValue });
      if (data.message === 'SUCCESS') {
        setIsCheckedMail(true);
        await successSwal({ text: MESSAGES.EMAIL_CONFIRM_SUCCESS });
      } else {
        setIsCheckedMail(false);
        await errorSwal({ text: MESSAGES.EMAIL_CONFIRM_FAIL });
      }
    } catch (error) {
      setIsCheckedMail(false);
      await errorSwal({ text: MESSAGES.ERROR_500 });
    }
    setIsEmailCheckLoading(false);
  };

  const addAccount = async (form) => {
    const body = {
      userId: form.userId,
      name: form.name,
      email: form.email,
      password: form.password,
      phone: form.phone,
      type: typeId,
      groupId: groupId,
    };

    try {
      const response = await createSingleUserAccount.mutateAsync({ body });
      if (response.status === 201) {
        setIsModalOpen(false);
        await successSwal({ text: MESSAGES.USER_ACCOUNT_CREATE_SUCCESS });
      }
    } catch (error) {
      reset();
      await errorSwal({ text: MESSAGES.USER_ACCOUNT_CREATE_FAIL });
    }
  };

  const handleTypeName = (data, id) => {
    setTypeName(data);
    setTypeId(id);
  };

  const handleModalClose = () => setIsModalOpen(false);

  useEffect(() => {
    if (!isModalOpen) return;

    setIsEmailCheckLoading(false);
    setIsCheckedMail(false);
    reset();
  }, [isModalOpen]);

  useEffect(() => {
    setIsCheckedMail(false);
  }, [emailValue]);

  return (
    <Modal className="fade bd-example-modal-lg" show={isModalOpen} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">단일 유저 등록</Modal.Title>
        <Button
          variant=""
          className="close"
          onClick={() => setIsModalOpen(false)}
        >
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <form onSubmit={handleSubmit(addAccount)}>
        <Modal.Body>
          <StyledInputWrapper>
            <div>
              <ModalBadge text="클래스/그룹" />
              <div>
                <CardinalDropMenu
                  classTitle={classTitle}
                  setClassTitle={setClassTitle}
                  setId={setGroupId}
                  isGroupId
                  noAll
                />
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="ID" />
              <div>
                <input
                  type="text"
                  placeholder="ID를 입력하세요."
                  className="form-control"
                  name="userId"
                  {...register('userId', {
                    required: true,
                  })}
                />
                {errors.userId?.type === 'required' && (
                  <ValidationErrorMsg text="ID를 입력해 주세요." />
                )}
              </div>
            </div>

            <div>
              <ModalBadge text="이름" />
              <div>
                <input
                  type="text"
                  placeholder="이름을 입력하세요."
                  className="form-control"
                  name="name"
                  {...register('name', {
                    required: true,
                  })}
                />
                {errors.name?.type === 'required' && (
                  <ValidationErrorMsg text="이름를 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="EMAIL" />
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                }}
              >
                <div style={{ width: '100%' }}>
                  <input
                    type="text"
                    placeholder="이메일을 입력하세요."
                    className="form-control"
                    name="email"
                    {...register('email', {
                      required: true,
                      pattern: EMAIL_REGEX,
                    })}
                  />
                  {errors.email?.type === 'required' && (
                    <ValidationErrorMsg text="이메일을 입력해주세요." />
                  )}
                  {errors.email?.type === 'pattern' && (
                    <ValidationErrorMsg text="이메일 형식이 올바르지 않습니다." />
                  )}
                </div>
                {isCheckedMail ? (
                  <button
                    disabled
                    type="button"
                    className="btn btn-primary col-2"
                    style={{
                      backgroundColor: 'gray',
                      border: '1px solid gray',
                    }}
                  >
                    확인완료
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-primary  col-2"
                    onClick={handleEmailCheck}
                    disabled={errors.email}
                  >
                    {isEmailCheckLoading ? (
                      <CircularProgress size={20} />
                    ) : (
                      '중복확인'
                    )}
                  </button>
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="PASSWORD" />
              <div>
                <input
                  type="text"
                  placeholder="패스워드를 입력하세요."
                  className="form-control"
                  name="password"
                  {...register('password', {
                    required: true,
                  })}
                />
                {errors.password?.type === 'required' && (
                  <ValidationErrorMsg text="패스워드를 입력해 주세요." />
                )}
              </div>
            </div>

            <div>
              <ModalBadge text="TYPE" />
              <div>
                <Dropdown>
                  <Dropdown.Toggle variant="outline-primary" size="md">
                    {typeName}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {userTypes?.map((list) => (
                      <Dropdown.Item
                        key={list.id}
                        eventKey={list.id + 1}
                        onClick={() => handleTypeName(list.name, list.id)}
                        className="fs-12"
                      >
                        {list.name}
                        {` (${list.description})`}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="PHONE" />
              <div>
                <input
                  type="text"
                  placeholder="연락처를 입력하세요. ex) 010-1234-1234"
                  className="form-control"
                  name="phone"
                  {...register('phone', {
                    required: true,
                    pattern: PHONE_REGEX,
                  })}
                />
                {errors.phone?.type === 'required' && (
                  <ValidationErrorMsg text="연락처를 입력해주세요." />
                )}
                {errors.phone?.type === 'pattern' && (
                  <ValidationErrorMsg text="전화번호 형식이 올바르지 않습니다." />
                )}
              </div>
            </div>
          </StyledInputWrapper>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <div className="d-flex justify-content-start">
            <button
              type="submit"
              className="btn btn-primary mr-3"
              disabled={!isCheckedMail}
            >
              등록
            </button>
            <button
              type="button"
              onClick={handleModalClose}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default SingleUserRegistryModal;
