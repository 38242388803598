import React from 'react';
import { Badge } from 'react-bootstrap';
import styled from 'styled-components';

import profileImage from '../../assets/avatar/2.png';
import profileImageExam from '../../assets/avatar/4.png';

export const UserSection = ({
  dataA,
  dataB,
  dataC,
  dataD,
  dataE,
  dataF,
  dataG,
  dataH,
}) => {
  return (
    <div
      className="clearfix mb-3 d-flex"
      style={{ maxWidth: '100%', justifyContent: 'space-between' }}
    >
      <img
        className="mr-3 rounded"
        width="50px"
        height="50px"
        alt=""
        src={dataA === '출제자' ? profileImageExam : profileImage}
      />
      <div className="media-body mr-2">
        <h5 className="text-primary mb-0 mt-1">{dataA}</h5>
        <p className="mb-0">{dataB}</p>
        {dataC && <p className="mb-0">{dataC}</p>}
      </div>

      {dataH && (
        <div style={{ width: '40vw', marginTop: '30px' }}>
          <div style={{ textAlign: 'center' }}>
            <h1>{dataH}</h1>
          </div>
        </div>
      )}
      {dataD && (
        <div style={{ width: '15vw' }}>
          <Score>점수: {dataD}</Score>
          <br />
          <div className="mb-2" style={{ display: 'flex' }}>
            <Badge
              variant="danger badge-rounded badge-md mr-3"
              style={{ opacity: '0.7' }}
            >
              상: {dataE}
            </Badge>
            <Badge
              variant="success badge-rounded badge-md mr-3"
              style={{ opacity: '0.7' }}
            >
              중: {dataF}
            </Badge>
            <Badge
              variant="warning badge-rounded badge-md mr-3"
              style={{ opacity: '0.7' }}
            >
              하: {dataG}
            </Badge>
          </div>
        </div>
      )}
    </div>
  );
};

const Score = styled.h1`
  color: red !important;
  font-size: 30px;
`;

export default UserSection;
