import { useQuery } from 'react-query';

import { fetchPBLComments } from 'services';

const useFetchPBLComments = ({ problemId, studentId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPBLComments', problemId, studentId],
    queryFn: async () => await fetchPBLComments({ problemId, studentId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!problemId || !!studentId,
  });
  return { ...queryReturns };
};

export default useFetchPBLComments;
