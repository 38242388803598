import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  fetchStudyBoardDetail,
  removeStudyPost,
  fetchStudyBoardFiles,
  fetchStudyBoardReply,
  removeStudyPostComment,
} from 'services/studyGroup';

const useStudyGroupBoard = ({ id }) => {
  const queryClient = useQueryClient();

  /* 스터디그룹 게시글 상세 내용 조회 */
  const studyBoardDetail = useQuery({
    queryKey: ['fetchStudyBoardDetail', { id }],
    queryFn: async () => {
      const response = await fetchStudyBoardDetail({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  /* 스터디그룹 게시글 상세 첨부파일 조회 */
  const studyBoardFiles = useQuery({
    queryKey: ['fetchStudyBoardFiles', { id }],
    queryFn: async () => {
      const response = await fetchStudyBoardFiles({ studyGroupId: id });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  /* 스터디그룹 게시글 상세 댓글 조회 */
  const studyBoardReplies = useQuery({
    queryKey: ['fetchStudyBoardReply', { id }],
    queryFn: async () => {
      const response = await fetchStudyBoardReply({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  /* 스터디그룹 게시글 상세 댓글 삭제 */
  const deletePostComment = useMutation({
    mutationFn: async ({ studyGroupReplyId }) => {
      return await removeStudyPostComment({ studyGroupReplyId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyBoardReply', { id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyBoardReply', { id }],
      }),
  });

  /* 스터디그룹 게시글 삭제 */
  const deletePost = useMutation({
    mutationFn: async ({ id }) => {
      return await removeStudyPost({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyPosts', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyPosts', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    studyBoardDetail,
    studyBoardFiles,
    studyBoardReplies,
    deletePostComment,
    deletePost,
  };
};

export default useStudyGroupBoard;
