import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createSupportPost,
  deleteSupportBoard,
  fetchSupportBoardDetail,
  fetchSupportBoardFiles,
  fetchSupportBoardList,
  fetchSupportBoardReply,
  fetchSupportCategories,
  fetchBoardSelect,
  editSupportPost,
  addSupportBoardReply,
  deleteSupportReply,
  editSupportBoardReply,
  fetchContactList,
  checkContactReplyReadStatus,
} from 'services';

const useSupportBoard = ({
  pageNum,
  pageSize,
  keyword,
  board,
  id,
  groupId,
}) => {
  const queryClient = useQueryClient();

  const boardCategories = useQuery({
    queryKey: ['fetchSupportCategories'],
    queryFn: async () => {
      const response = await fetchSupportCategories();
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
  });

  const boardSelect = useQuery({
    queryKey: ['fetchBoardSelect'],
    queryFn: async () => {
      const response = await fetchBoardSelect();
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    /* 리스트 페이지에서 호출안되게 */
    enabled: !pageNum,
  });

  const boardList = useQuery({
    queryKey: ['fetchSupportBoardList', { pageNum, pageSize, board, keyword }],
    queryFn: async () => {
      const response = await fetchSupportBoardList({
        pageNum,
        pageSize,
        keyword,
        board,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
  });

  const contactList = useQuery({
    queryKey: ['fetchContactList', { pageNum, pageSize, groupId, keyword }],
    queryFn: async () => {
      const response = await fetchContactList({
        pageNum,
        pageSize,
        keyword,
        groupId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
  });

  const boardDetail = useQuery({
    queryKey: ['fetchSupportBoardDetail', { id }],
    queryFn: async () => {
      const response = await fetchSupportBoardDetail({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  const boardReply = useQuery({
    queryKey: ['fetchSupportBoardReply', { id }],
    queryFn: async () => {
      const response = await fetchSupportBoardReply({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  const boardFiles = useQuery({
    queryKey: ['fetchSupportBoardFiles', { id }],
    queryFn: async () => {
      const response = await fetchSupportBoardFiles({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  const submitPost = useMutation({
    mutationFn: async ({ formData }) => {
      return await createSupportPost({ formData });
    },
  });

  const editPost = useMutation({
    mutationFn: async ({ id, formData }) => {
      return await editSupportPost({ id, formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardDetail', { id }],
      }),
  });

  const deleteSupportPost = useMutation({
    mutationFn: async ({ id }) => {
      return await deleteSupportBoard({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardList', { pageNum, pageSize, board }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardList', { pageNum, pageSize, board }],
      }),
  });

  const addReply = useMutation({
    mutationFn: async ({ body }) => {
      return await addSupportBoardReply({ body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
  });

  const editReply = useMutation({
    mutationFn: async ({ commentId, body }) => {
      return await editSupportBoardReply({ commentId, body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
  });

  const deleteReply = useMutation({
    mutationFn: async ({ postId, commentId }) => {
      return await deleteSupportReply({ postId, commentId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
  });

  const changeReplyReadStatus = useMutation({
    mutationFn: async ({ postId }) => {
      return await checkContactReplyReadStatus({ postId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchSupportBoardReply', { id }],
      }),
  });

  return {
    boardCategories,
    boardSelect,

    boardList,
    contactList,
    boardDetail,
    deleteSupportPost,
    boardFiles,
    submitPost,
    editPost,

    boardReply,
    addReply,
    editReply,
    deleteReply,

    changeReplyReadStatus,
  };
};

export default useSupportBoard;
