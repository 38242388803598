import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
  useDebounce,
  useFetchTaskData,
  useGetQueryString,
  useSetParameterOnQuery,
} from 'hooks';
import { TASK_MANAGER_COLUMNS } from 'components/Board/Columns';
import { CardinalDropMenu, Pagination, SearchInput } from 'components/common';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';

const Task = ({ history }) => {
  const queryValues = useGetQueryString();
  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');
  const [classId, setClassId] = useState('');

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setClassId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { taskList } = useFetchTaskData({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    classId,
    pageSize,
  });
  const { data } = taskList || {};

  //Table Setting
  const columns = useMemo(() => TASK_MANAGER_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleGoDetail = (data, i) => {
    if (i !== 5) {
      history.push(`/task-view/${data.id}`);
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <CardinalDropMenu
          classTitle={classTitle}
          setClassTitle={setClassTitle}
          setId={setClassId}
        />

        <SearchInput value={keyword} setValue={setKeyword} />
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg task-filtering-table"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() => handleGoDetail(cell.row.original, i)}
                            className="cursor-pointer"
                          >
                            {i === 5 ? (
                              <>
                                <ReactTooltip />

                                <Link
                                  to={{
                                    pathname: `/task-view/${cell.row.original.id}`,
                                    state: {
                                      data: cell.row.original,
                                    },
                                  }}
                                  className="text-black"
                                  data-tip="View Detail"
                                >
                                  <i className="far fa-file-alt more-btn-icon-box-dark mr-1"></i>
                                </Link>
                              </>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default Task;
