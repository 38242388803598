import React from 'react';
import styled from 'styled-components';

const ValidationErrorMsg = ({ text }) => {
  return <StyledError>{text}</StyledError>;
};

const StyledError = styled.div`
  color: #ff1c1c;
  font-size: 12px;
  margin-top: 0;
  width: 100%;
`;

export default ValidationErrorMsg;
