import { useMutation } from 'react-query';

import { createPBLNewProblem } from '../../services/pbl';

const useCreatePBLNewProblem = ({ unitId }) => {
  const queryReturns = useMutation({
    mutationFn: async ({ formData }) => {
      return await createPBLNewProblem({ unitId, formData });
    },
  });
  return { ...queryReturns };
};

export default useCreatePBLNewProblem;
