import { useMutation, useQueryClient } from 'react-query';

import { changeExamProblemDetail, removeExamProblem } from 'services';

const useSetExamProblem = ({ problemId }) => {
  const queryClient = useQueryClient();

  const editProblem = useMutation({
    mutationFn: async ({ problemId, body }) => {
      return await changeExamProblemDetail({ problemId, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExamProblemDetail', { problemId }],
      }),
  });

  const deleteProblem = useMutation({
    mutationFn: async ({ problemId }) => {
      return await removeExamProblem({ problemId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExamProblemList', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExamProblemList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    editProblem,
    deleteProblem,
  };
};

export default useSetExamProblem;
