/* eslint-disable*/
import React, { Fragment, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Badge } from 'react-bootstrap';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Viewer } from '@toast-ui/react-editor';

import { useDownload, useStudyGroupBoard } from 'hooks';
import { UserSection } from 'components/common';
import { CardBody, Wrapper } from 'components/layouts/common';
import StudyBoardComents from '../../components/Review/ReviewPreference/StudyBoardComents';
import { confirmSwal, successSwal, linkify } from 'utilities';
import { MESSAGES } from 'constants';

const StudyBoardDetail = () => {
  const { id } = useParams();
  let history = useHistory();

  const [isChanged, setIsChanged] = useState(false);

  const { studyBoardDetail, studyBoardFiles, studyBoardReplies, deletePost } =
    useStudyGroupBoard({ id, studyGroupId: id });
  const { data } = studyBoardDetail || {};
  const { data: files } = studyBoardFiles || {};
  const { data: replies } = studyBoardReplies || {};

  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/admin/downloadUrl`
  );

  const handleDownload = () => {
    if (isLoading) return;
    refetch();
  };

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deletePost.mutateAsync({ id });
      if (status === 200) {
        history.push('/study-board');
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Fragment>
      <Wrapper>
        <CardBody>
          <div className="right-box-padding">
            <div className="toolbar mb-2" role="toolbar">
              <div className="bootstrap-badge">
                <Badge variant="secondary badge-sm mr-2">
                  BoardType : 스터디그룹
                </Badge>
                <Badge variant="light badge-sm mr-2">
                  StudyGroup : {data?.studyGroupName}
                </Badge>
              </div>
            </div>
            <div className="read-content">
              <div className="media pt-3 d-sm-flex d-block justify-content-between">
                <UserSection
                  dataA={data?.userName}
                  dataB={data?.userId}
                  dataC={(data?.regDate || '').replace('T', ' ').slice(0, 16)}
                />
                <div className="clearfix mb-3">
                  <div
                    onClick={handleBack}
                    className="btn btn-primary px-3 light"
                  >
                    <i className="fa fa-reply"></i>
                  </div>
                  <div
                    onClick={handleDelete}
                    className="btn btn-primary px-3 light ml-2"
                  >
                    <i className="fa fa-trash"></i>
                  </div>
                </div>
              </div>
              <hr />
              <div className="media mb-3 mt-4">
                <div className="media-body">
                  <h3 className="my-1 text-primary">{data?.title}</h3>
                </div>
              </div>
              <div className="read-content-body">
                {data && <Viewer initialValue={linkify(data?.content)} />}
              </div>

              {/* 스터디 게시글 첨부파일 없을 때 안보이게 처리 */}
              {!!files?.length && (
                <>
                  <hr />
                  <div className="read-content-attachment">
                    <h6>
                      <i className="fa fa-download mb-2"></i>
                      Files
                      <Badge variant="primary badge-sm ml-2 mr-2">
                        {files?.length}
                      </Badge>
                    </h6>
                    <div className="row attachment">
                      {files?.length > 0 ? (
                        files?.map((file) => (
                          <div
                            onClick={handleDownload}
                            className="col-auto"
                            key={file.id}
                          >
                            {file.originalFileName}
                          </div>
                        ))
                      ) : (
                        <div className="col-auto">
                          등록된 자료 파일이 없습니다.
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </CardBody>
      </Wrapper>

      <StudyBoardComents
        postByReplyList={replies || []}
        setIsChanged={setIsChanged}
        isChanged={isChanged}
      />
    </Fragment>
  );
};

export default StudyBoardDetail;
