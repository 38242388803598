import React, { useEffect, useState } from 'react';
import Switch from 'react-switch';
import swal from 'sweetalert';

export const CustomSwitch = ({
  isTrue,
  data,
  handleToggle,
  disabled = false,
}) => {
  const [isCheck, setIsCheck] = useState(false);

  useEffect(() => {
    isTrue ? setIsCheck(true) : setIsCheck(false);
  }, [isTrue]);

  const handleChange = () => {
    swal({
      title: '설정을 변경하시겠습니까?',
      text: '변경전에 다시한번 확인을 부탁드립니다.',
      icon: 'warning',
      buttons: true,
      dangerMode: true,
      className: 'mySwal',
    }).then((willChange) => {
      isTrue ? setIsCheck(true) : setIsCheck(false);
      if (willChange) {
        handleToggle(isTrue, data);
      } else {
        swal('확인후 다시 진행을 부탁드립니다.');
      }
    });
  };

  return (
    <Switch
      disabled={disabled}
      onChange={handleChange}
      checked={isCheck}
      onColor="#ec7807"
      height={20}
      width={40}
      uncheckedIcon={false}
    />
  );
};

export default CustomSwitch;
