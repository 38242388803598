import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { ModalBadge, ValidationErrorMsg } from '../common';
import { useSetConnect } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const GatherLinkEditModal = ({ detailData, show, setShow }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { editRemoteClass } = useSetConnect();

  const submit = async (form) => {
    const body = {
      zoomUrl: form.zoomUrl,
    };

    try {
      const { status } = await editRemoteClass.mutateAsync({
        id: form.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.LINK_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.LINK_EDIT_FAIL });
    }
  };

  useEffect(() => {
    reset({
      ...detailData,
    });
  }, [detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">Gather링크 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputWrapper>
            <div>
              <ModalBadge text="링크" />
              <div>
                <input
                  type="text"
                  placeholder="Gather 링크를 입력하세요."
                  className="form-control"
                  name="zoomUrl"
                  {...register('zoomUrl', {
                    required: true,
                  })}
                />
                {errors.zoomUrl && (
                  <ValidationErrorMsg text="Gather 링크를 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-start">
            <button type="submit" className="btn btn-primary mr-3">
              업데이트
            </button>
            <button
              type="button"
              onClick={() => setShow(false)}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;

export default GatherLinkEditModal;
