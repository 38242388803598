import { useMutation, useQueryClient } from 'react-query';

import { changePBLUnitVisible } from '../../services/pbl';

const useChangePBLUnitVisible = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ unitId, pblVisible }) => {
      return await changePBLUnitVisible({ unitId, pblVisible });
    },
    onSettled: () =>
      queryClient.invalidateQueries({ queryKey: ['fetchPBLUnits'] }),
  });
  return { ...queryReturns };
};

export default useChangePBLUnitVisible;
