import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ModalBadge } from '../common';

const UnitDetailModal = ({ show, setShow, detailData }) => {
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">유닛 상세 정보</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="카테고리명" />
          </div>
          <div className="col-10">{detailData.classCategoryName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="유닛명" />
          </div>
          <div className="col-10">{detailData.title}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="내용" />
          </div>
          <div className="col-10">{detailData.content}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="유닛시간" />
          </div>
          <div className="col-10">{detailData.unitTime}H</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="수업유무" />
          </div>
          <div className="col-10">
            {detailData.lectureYn === 'Y' ? '있음' : '없음'}
          </div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="생성일" />
          </div>
          <div className="col-10">
            {(detailData.regDate || '').split('T')[0]}{' '}
            {(detailData.regDate || '').split('T')[1]}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UnitDetailModal;
