import React, { useEffect } from "react";
import { TimeSelect } from "./style";

const MinSelect = ({ register, time, setValue, name }) => {
  useEffect(() => {
    setValue(name, time ? new Date(time).getMinutes() + "" : "");
  }, [time]);

  const numberFormatter = (num, digits) => {
    const zeros = new Array(digits - 1).fill("0").join().replace(",");
    const minNum = Number("1" + zeros);

    if (Number(num) < minNum) {
      return zeros + num;
    }
    return String(num);
  };

  return (
    <>
      <TimeSelect defaultValue={new Date(time).getMinutes()} {...register}>
        <option value="" selected={!time}>
          분
        </option>
        {new Array(60).fill(0).map((v, i) => (
          <option
            key={i}
            value={i}
            selected={time ? new Date(time).getMinutes() === i : false}
          >
            {numberFormatter(i, 2) + "분"}
          </option>
        ))}
      </TimeSelect>
    </>
  );
};

export default MinSelect;
