import { useQuery } from 'react-query';

import { fetchAllUserType } from 'services';

/* 유저 롤(user type) 목록 조회 */
const useFetchAllUserType = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchAllUserType'],
    queryFn: async () => await fetchAllUserType(),
    select: (res) => res.data,
    retry: 1,
    retryDelay: 500,
    staleTime: Infinity,
    cacheTime: Infinity,
  });
  return { ...queryReturns };
};

export default useFetchAllUserType;
