/* 뱃지 배경색 */
export const BADGE_COLOR = [
  'primary',
  'secondary',
  'info',
  'danger',
  'success',
  'warning',
  'secondary',
  'muted',
  'dark',
];
