import { useQuery } from 'react-query';

import { fetchPBLResultsList } from 'services';

const useFetchPBLResultsList = ({ problemId, pageNum }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPBLResultsList', problemId, pageNum],
    queryFn: async () => await fetchPBLResultsList({ problemId, pageNum }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!problemId,
  });
  return { ...queryReturns };
};

export default useFetchPBLResultsList;
