import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Dropdown } from 'react-bootstrap';

import { useSetClass, useClassVideo } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CardBody, Wrapper } from 'components/layouts/common';
import { CardinalDropMenu, ValidationErrorMsg } from 'components/common';
import CustomSwitchNoConfirm from '../../components/ui/CustomSwitchNoConfirm';

const UnitUpload = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [classTitle, setClassTitle] = useState('과정 선택');
  const [classId, setClassId] = useState('');
  const [categoryName, setCategoryName] = useState('카테고리를 선택하세요.');
  const [categoryId, setCategoryId] = useState('');

  const [lectureUse, setLectureUse] = useState(false);

  const { addClassUnit } = useSetClass();
  const { classCategory } = useClassVideo({ classId });
  const { data: categoryList } = classCategory || {};

  const submit = async (form) => {
    if (!classId) {
      await errorSwal({ text: '과정을 선택해주세요.' });
      return;
    }
    if (!categoryId) {
      await errorSwal({ text: '카테고리를 선택해주세요.' });
      return;
    }

    const body = {
      classCategoryId: categoryId,
      content: form.content,
      title: form.title,
      unitTime: +form.unitTime,
      isLecture: lectureUse,
    };

    try {
      const { status } = await addClassUnit.mutateAsync({ body });
      if (status === 201) {
        history.push('/unit-list');
        await successSwal({ text: MESSAGES.UNIT_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.UNIT_CREATE_FAIL });
    }
  };

  const handleCategory = (data, id) => {
    setCategoryName(data);
    setCategoryId(id);
  };

  const handleLectureConfirm = () => {
    setLectureUse(!lectureUse);
  };

  return (
    <Wrapper>
      <CardBody>
        <div
          style={{ width: '100%' }}
          className="d-sm-flex justify-content-start mb-3"
        >
          <CardinalDropMenu
            classTitle={classTitle}
            setClassTitle={setClassTitle}
            setId={setClassId}
          />

          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
              size="md"
              className="mr-2 ml-2 height-38px"
              disabled={!classId}
            >
              {categoryName || '카테고리 선택'}
            </Dropdown.Toggle>
            {
              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => handleCategory('', '')}
                  className="fs-12"
                >
                  전체
                </Dropdown.Item>
                {categoryList?.map((list) => (
                  <Dropdown.Item
                    key={list.id}
                    eventKey={list.id + 1}
                    onClick={() =>
                      handleCategory(list.classCategoryName, list.id)
                    }
                    className="fs-12"
                  >
                    {list.classCategoryName}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            }
          </Dropdown>
        </div>

        <div className="basic-form">
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-row">
              <div className="form-group col-md-8">
                <input
                  type="text"
                  placeholder="유닛명을 입력하세요."
                  className="form-control"
                  name="title"
                  {...register('title', {
                    required: true,
                  })}
                />
                {errors.title && (
                  <ValidationErrorMsg text="유닛명을 입력해 주세요." />
                )}
              </div>

              <div className="form-group col-md-4">
                <input
                  type="number"
                  placeholder="유닛시간을 입력하세요."
                  min="0"
                  className="form-control"
                  name="unitTime"
                  {...register('unitTime', {
                    required: true,
                  })}
                />
                {errors.unitTime && (
                  <ValidationErrorMsg text="유닛 시간을 입력해 주세요." />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  rows="5"
                  id="comment"
                  placeholder="여기에 내용을 작성하세요."
                  name="content"
                  {...register('content', {
                    required: true,
                  })}
                ></textarea>
                {errors.content && (
                  <ValidationErrorMsg text="내용을 입력해 주세요." />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <span className="mr-3">온라인 수업 여부</span>
                  <CustomSwitchNoConfirm
                    isTrue={lectureUse}
                    handleToggle={handleLectureConfirm}
                  />
                  <span
                    style={{
                      fontSize: '0.7em',
                      marginLeft: '2px',
                      verticalAlign: 'text-top',
                    }}
                  >
                    {lectureUse ? '온라인 강의 있음' : '온라인 강의 없음'}
                  </span>
                </div>
              </div>
            </div>

            <hr />
            <div className="d-flex justify-content-start">
              <button type="submit" className="btn btn-primary mt-3 mr-3">
                등록
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default UnitUpload;
