import axios from 'axios';
import FileSaver from 'file-saver';
import { useQuery } from 'react-query';
import { errorSwal } from '../../utilities';
import { MESSAGES } from '../../constants';

const useDownload = (url) => {
  const queryReturns = useQuery(
    ['download', url],
    async () => {
      try {
        const response = await axios.get(url, {
          responseType: 'blob',
        });

        if (response) {
          FileSaver.saveAs(
            response.data,
            decodeURIComponent(
              response.headers['content-disposition']
                .split('filename=')[1]
                .replaceAll('"', '')
            )
          );
        }
      } catch (error) {
        await errorSwal({
          text: MESSAGES.FILE_DOWNLOAD_FAIL,
        });
      }
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
    }
  );
  return { ...queryReturns };
};

export default useDownload;
