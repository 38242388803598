import React, {
  useEffect,
  useContext,
  createContext,
  useState,
  useRef,
} from 'react';
import styled from 'styled-components';
import { useControllableState } from 'hooks';

const SelectContext = createContext(null);

const Select = ({
  children,
  value: valueProp,
  defaultValue,
  onChange: onValueChange,
  onBlur,
  style,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [value = '', setValue] = useControllableState({
    prop: valueProp,
    defaultProp: defaultValue,
    onChange: onValueChange,
  });

  const ref = useRef(null);

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOpen(false);
      }
    };
    if (open) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [ref, setOpen, open]);

  return (
    <SelectContext.Provider
      value={{
        open,
        onOpenChange: setOpen,
        value,
        onValueChange: setValue,
        selectedOption,
        setSelectedOption,
      }}
    >
      <div ref={ref} style={{ ...style, position: 'relative' }}>
        {children}
      </div>
    </SelectContext.Provider>
  );
};

const OptionList = ({ children }) => {
  const context = useContext(SelectContext);
  return context.open ? <OptionsWrapper>{children}</OptionsWrapper> : null;
};

const Option = ({ children, value }) => {
  const context = useContext(SelectContext);
  return (
    <OptionItem
      onClick={() => {
        context?.onValueChange(value);
        context?.onOpenChange(false);
        context?.setSelectedOption(children);
      }}
    >
      {children}
    </OptionItem>
  );
};

const SelectTrigger = ({ children }) => {
  const context = useContext(SelectContext);
  return (
    <SelectButton
      size={context?.size}
      open={context?.open}
      onClick={() => {
        context.onOpenChange(!context?.open);
      }}
    >
      {context?.selectedOption || children}
    </SelectButton>
  );
};

export default Select;
Select.OptionList = OptionList;
Select.Option = Option;
Select.Trigger = SelectTrigger;

const SelectButton = styled.button`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 38px;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  font-size: 14px;
  border: 1px solid #ec7807;
  color: ${(props) => (props.open ? 'white' : '#ec7807')};
  background-color: ${(props) => (props.open ? '#ec7807' : 'white')};

  &:after {
    ${(props) => (props.open ? `content: "\f106";` : `content: "\f107";`)}
    font-family: "fontAwesome";
    margin-left: 0.3rem;
  }

  &:hover {
    background-color: #ec7807;
    color: white;
  }
`;

const OptionsWrapper = styled.ul`
  position: absolute;
  top: 110%;
  left: 0;
  z-index: 1;
  min-width: 10rem;
  background-color: #fff;
  border-radius: 0.75rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  overflow: hidden;
`;

const OptionItem = styled.li`
  padding: 0.815rem 1.25rem;
  white-space: nowrap;
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;
