import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { useForm } from 'react-hook-form';

import { useClassBoard } from 'hooks';
import { CLASS_CATEGORY_URL, TITLE_MAX_LENGTH } from 'constants/classBoard';
import { isNoContent } from 'utilities/common';
import {
  BoardAppendixFile,
  CardinalDropMenu,
  ValidationErrorMsg,
} from 'components/common';
import ClassTextEditor from 'components/ui/ClassTextEditor';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import styled from 'styled-components';

export const ClassBoardWrite = () => {
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const {
    register,
    setError,
    handleSubmit,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [categoryName, setCategoryName] =
    useState('클래스를 먼저 선택해주세요.');

  const [classId, setClassId] = useState(0);
  const [categoryId, setCategoryId] = useState(0);
  const [textValue, setTextValue] = useState('');
  const [, setDeleteImgs] = useState([]);
  const [imageNames, setImagesNames] = useState([]);
  const [imgList, setImgList] = useState([]);

  const [isImageLoading, setIsImageLoading] = useState(false);

  const [files, setFiles] = useState([]);

  const { boardCategory, createBoard } = useClassBoard({});
  const { data: categoryList } = boardCategory || {};

  useEffect(() => {
    if (location.state) {
      setCategoryId(location.state.categoryId);
      setCategoryName(
        categoryList?.filter(
          (category) => category.id === location.state.categoryId
        )[0].categoryName
      );
    }
  }, [location, categoryList]);

  const handleCategorySelector = (categoryName, categoryId) => {
    setCategoryName(categoryName);
    setCategoryId(categoryId);
  };

  const submit = async (form) => {
    if (createBoard.isLoading) return;

    if (isNoContent(textValue)) {
      setError('content', {
        type: 'required',
      });
    } else {
      clearErrors();
    }
    if (!categoryId) {
      swal('카테고리를 선택해주세요.', {
        icon: 'error',
      });
    }

    const body = {
      lcPostCategoryId: categoryId,
      title: form.title,
      content: textValue,
      classId: classId,
      images: imageNames,
    };

    const formData = new FormData();
    formData.append(
      'data',
      new Blob([JSON.stringify(body)], {
        type: 'application/json',
      })
    );
    if (files.length > 0) {
      files.forEach((file) => {
        formData.append('file', file);
      });
    }

    try {
      const { status } = await createBoard.mutateAsync({ formData });
      if (status === 201) {
        history.push(CLASS_CATEGORY_URL[categoryId - 1]);
        await successSwal({ text: MESSAGES.POST_SUBMIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.POST_SUBMIT_FAIL });
    }
  };

  const handleChangeFile = (fileList) => {
    if ([...fileList].length > 5) {
      swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
      return;
    }

    setFiles((prev) => {
      if ([...prev, ...fileList].length > 5) {
        swal({ text: MESSAGES.FILE_MAXIMUM_COUNT_5, icon: 'error' });
        return [...prev];
      }

      return [...prev, ...fileList];
    });
  };

  const handleDeleteFile = (index, fileName) => {
    setFiles((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
  };

  return (
    <Wrapper>
      <CardHeader>
        <div className="d-sm-flex">
          <CardinalDropMenu
            classTitle={classTitle}
            setClassTitle={setClassTitle}
            setId={setClassId}
          />

          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
              size="md"
              className="mr-2 ml-2 height-38px"
              disabled
            >
              {categoryName}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {categoryList &&
                categoryList.map((list) => (
                  <Dropdown.Item
                    key={list.id}
                    eventKey={list.id + 1}
                    onClick={() =>
                      handleCategorySelector(list.categoryName, list.id)
                    }
                  >
                    {list.categoryName}
                  </Dropdown.Item>
                ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </CardHeader>

      <CardBody>
        <div>
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  placeholder="제목을 입력하세요."
                  className="form-control"
                  name="title"
                  {...register('title', {
                    required: true,
                    maxLength: TITLE_MAX_LENGTH,
                  })}
                />
                {errors.title?.type === 'required' && (
                  <ValidationErrorMsg text="제목을 입력해 주세요." />
                )}
                {errors.title?.type === 'maxLength' && (
                  <ValidationErrorMsg text="80자 이하로 입력해주세요." />
                )}
              </div>
              <div className="form-group col-md-12">
                <div className="default-tab">
                  <ClassTextEditor
                    htmlStr={textValue}
                    setHtmlStr={setTextValue}
                    isEdit={!!id}
                    setImageNames={setImagesNames}
                    setIsImageLoading={setIsImageLoading}
                    setDeleteImgs={setDeleteImgs}
                    imgList={imgList}
                  />
                  {errors.content?.type === 'required' && (
                    <ValidationErrorMsg text="내용을 입력해 주세요." />
                  )}
                </div>
              </div>
            </div>

            <div className="form-row mt-3">
              <div className="form-group col-md-12">
                <h5 className="mb-2">
                  <i className="fa fa-paperclip" />
                  첨부파일
                </h5>
                <BoardAppendixFile
                  onChange={handleChangeFile}
                  onDelete={handleDeleteFile}
                  originalFileNames={files}
                  multiple
                />
              </div>
            </div>

            <div className="d-flex justify-content-end">
              <Button
                type="submit"
                disabled={!classId}
                className="btn btn-primary"
              >
                게시글 등록
              </Button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Button = styled.button`
  &:disabled {
    cursor: not-allowed;
  }
`;

export default ClassBoardWrite;
