/* eslint-disable*/
import React, { useState } from 'react';
import { Switch, Route } from 'react-router-dom';
/* 스타일 */
import '../styles/index.css';
import '../assets/icons/fontawesome/css/all.css';

/* 레이아웃 */
import Nav from '../components/layouts/nav';

/* 대시보드, 홈 */
import Home from '../pages/Home';

/* 회원관리 */
import { UserList, UserDetail } from '../pages/user';

/* 과정관리 */
import {
  ClassInfoList,
  ClassInfoUpload,
  ClassList,
  ClassUpload,
  ChapterList,
  ChapterUpload,
  ClassCategoryUpload,
  ClassCategoryList,
  UnitList,
  UnitUpload,
} from '../pages/process';
import { ZoomLinkUpload, CloudAccountUpload } from '../pages/connect';

/* 내클래스 */
import {
  NoticeList,
  LibraryList,
  QuestionList,
  TaskList,
  TaskDetail,
  ClassBoardWrite,
  ClassBoardEdit,
  ClassBoardDetail,
} from '../pages/class';

/* 내클래스 PBL */
import {
  PblCategory,
  PblProblems,
  PblResults,
  PblProblemEdit,
  PblProblemCreate,
} from '../pages/pbl';

/* 역량평가 */
import {
  ExamProblemList,
  ExamList,
  ExamProblemDetail,
  ExamCreate,
  ExamGraph,
  GroupTestAnalysis,
  ExamResultsDetail,
  ExamDetail,
} from '../pages/exam';

/* 학습지원 게시판 */
import {
  Consulting,
  Contact,
  Faq,
  Story,
  SupportBoardWrite,
  SupportLibrary,
  SupportBoardDetail,
  SupportBoardEdit,
} from '../pages/support';

/* 스터디그룹관리 */
import {
  StudyGroupList,
  StudyRoomList,
  StudyGroupDetail,
  StudyGroupBoard,
  StudyBoardDetail,
} from '../pages/studyGroup';

/* 지식컨텐츠 */
import {
  KnowledgeContent,
  KnowledgeCreate,
  KnowledgeDetail,
  KnowledgeEdit,
} from '../pages/knowledgeContent';

/* 기본페이지 */
import Error400 from '../pages/Error400';
import Error403 from '../pages/Error403';
import Error404 from '../pages/Error404';
import Error500 from '../pages/Error500';
import Error503 from '../pages/Error503';

/* 유틸컴포넌트 */
import ScrollToTop from '../components/util/ScrollToTop';
import ExamProblemEdit from '../pages/exam/ExamProblemEdit';

const Markup = () => {
  let path = window.location.pathname;
  path = path.split('/');
  path = path[path.length - 1];
  let pagePath = path.split('-').includes('page');
  const [activeEvent, setActiveEvent] = useState(!path);

  const routes = [
    /* 대시보드, 홈 */
    { url: '', component: Home, exact: true },
    { url: 'dashboard', component: Home, exact: true },
    { url: 'login', component: Home, exact: true },

    /* 회원관리 */
    { url: 'user-list', component: UserList, exact: true },
    { url: 'user-view/:userId', component: UserDetail, exact: true },

    /* 과정관리 */
    { url: 'class-list', component: ClassList, exact: true },
    { url: 'class-upload', component: ClassUpload, exact: true },

    { url: 'category-list', component: ClassCategoryList, exact: true },
    { url: 'category-upload', component: ClassCategoryUpload, exact: true },

    { url: 'unit-list', component: UnitList, exact: true },
    { url: 'unit-upload', component: UnitUpload, exact: true },

    { url: 'chapter-list', component: ChapterList, exact: true },
    { url: 'chapter-upload', component: ChapterUpload, exact: true },

    { url: 'class-video', component: ClassInfoList, exact: true },
    { url: 'class-video-upload', component: ClassInfoUpload, exact: true },

    { url: 'upload-link', component: ZoomLinkUpload, exact: true },
    { url: 'upload-cloud', component: CloudAccountUpload, exact: true },

    /* 내 클래스 */
    { url: 'notice', component: NoticeList, exact: true },
    { url: 'library', component: LibraryList, exact: true },
    { url: 'question', component: QuestionList, exact: true },

    { url: 'class-board/:id', component: ClassBoardDetail, exact: true },
    { url: 'class-board-write', component: ClassBoardWrite, exact: true },
    { url: 'class-board-edit/:id', component: ClassBoardEdit, exact: true },

    { url: 'task', component: TaskList, exact: true },
    { url: 'task-view/:taskId', component: TaskDetail, exact: true },

    { url: 'pbl-category', component: PblCategory, exact: true },
    { url: 'pbl-result', component: PblResults, exact: true },
    {
      url: 'pbl-problem-edit/:unitId/:problemId',
      component: PblProblemEdit,
      exact: true,
    },
    {
      url: 'pbl-problem-create/:unitId/:level',
      component: PblProblemCreate,
      exact: true,
    },
    /* 역량평가 */
    { url: 'exam', component: ExamList, exact: true },
    { url: 'exam-detail/:examId', component: ExamDetail, exact: false },
    { url: 'exam-create', component: ExamCreate, exact: true },
    { url: 'exam-problem', component: ExamProblemList, exact: true },
    {
      url: 'exam-problem-detail/:problemId',
      component: ExamProblemDetail,
      exact: true,
    },
    {
      url: 'exam-problem-edit/:problemId',
      component: ExamProblemEdit,
      exact: true,
    },
    { url: 'exam-result-detail', component: ExamResultsDetail, exact: true },
    { url: 'analysis', component: GroupTestAnalysis, exact: true },
    { url: 'exam-graph', component: ExamGraph, exact: true },

    /* 학습지원 게시판 */
    { url: 'support-library', component: SupportLibrary, exact: true },
    { url: 'faq', component: Faq, exact: true },
    { url: 'story', component: Story, exact: true },
    { url: 'contact', component: Contact, exact: true },
    { url: 'consulting', component: Consulting, exact: true },

    { url: 'support-board/:id', component: SupportBoardDetail, exact: true },
    { url: 'support-board-write', component: SupportBoardWrite, exact: true },
    { url: 'support-board-edit/:id', component: SupportBoardEdit, exact: true },

    /* 스터디그룹 */
    { url: 'study-group', component: StudyGroupList, exact: true },
    {
      url: 'study-group-detail/:studyGroupId',
      component: StudyGroupDetail,
      exact: true,
    },
    { url: 'study-board', component: StudyGroupBoard, exact: true },
    { url: 'study-board-detail/:id', component: StudyBoardDetail, exact: true },
    { url: 'study-room', component: StudyRoomList, exact: true },

    /* 지식컨텐츠 */
    { url: 'knowledge-content', component: KnowledgeContent, exact: true },
    { url: 'knowledge-detail', component: KnowledgeDetail, exact: true },
    { url: 'knowledge-create', component: KnowledgeCreate, exact: true },
    { url: 'knowledge-edit', component: KnowledgeEdit, exact: true },

    /* 로그인, error 페이지 */
    { url: 'page-error-400', component: Error400, exact: true },
    { url: 'page-error-403', component: Error403, exact: true },
    { url: 'page-error-404', component: Error404, exact: true },
    { url: 'page-error-500', component: Error500, exact: true },
    { url: 'page-error-503', component: Error503, exact: true },
  ];

  return (
    <>
      <div
        id={`${!pagePath ? 'main-wrapper' : ''}`}
        className={`${!pagePath ? 'show' : 'mh100vh'}`}
      >
        {!pagePath && (
          <Nav
            onClick={() => setActiveEvent(!activeEvent)}
            activeEvent={activeEvent}
            onClick2={() => setActiveEvent(false)}
            onClick3={() => setActiveEvent(true)}
          />
        )}
        <div
          className={` ${!path && activeEvent ? 'rightside-event' : ''} ${
            !pagePath ? 'content-body' : ''
          }`}
        >
          <div className="container-fluid">
            <Switch>
              {routes.map((data, i) => (
                <Route
                  key={i}
                  exact={data.exact}
                  path={`/${data.url}`}
                  component={data.component}
                />
              ))}

              <Route path="/pbl-problem" component={PblProblems} />
            </Switch>
          </div>
        </div>
      </div>
      <ScrollToTop />
    </>
  );
};

export default Markup;
