import React from 'react';
import styled from "styled-components";

const ResultTable = ({ansArr, resArr, tableNum}) => {
  return (<>
    {[...Array(tableNum)].map((n, repeatIndex) => {
      return (
        <>
          <th className='no-borderline'>
            <TD>No.</TD>
            {ansArr.map((answer, i) => {
              return ((repeatIndex + 1) * 10 - 11 < i && i < (repeatIndex + 1) * 10 ? (<TD>{i + 1}</TD>) : null)
            })}
            <tr>
              <TD2>학생답</TD2>
              {ansArr.map((answer, i) => {
                return ((repeatIndex + 1) * 10 - 11 < i && i < (repeatIndex + 1) * 10 ? (<TD2>{answer}</TD2>) : null)
              })}
            </tr>
            <tr>
              <TD2>채점결과</TD2>
              {resArr.map((result, i) => {
                return ((repeatIndex + 1) * 10 - 11 < i && i < (repeatIndex + 1) * 10 ? (
                  <>{result === 'O' ? <TD2 color="green">O</TD2> : <TD2 color="red">X</TD2>}</>) : null)
              })}
            </tr>
          </th>
        </>
      )
    })}
  </>)
};

export default ResultTable
const TD = styled.td`
  min-width: 5vw;
  font-weight: inherit;
  background-color: rgba(0, 0, 0, 0.05)

`

const TD2 = styled(TD)`
  font-weight: 400;


  color: ${props => props.color};
  background-color: white;

  :nth-child(1) {
    font-weight: inherit;
  }




`