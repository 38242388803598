import { useMutation, useQueryClient } from 'react-query';

import { removeComment } from '../../services/pbl';

const useRemoveComment = () => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async ({ commentId }) => {
      return await removeComment({ commentId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchPBLComments'],
      }),
  });
  return { ...queryReturns };
};

export default useRemoveComment;
