import React, { useRef } from 'react';
import styled from 'styled-components';

import { useUsers } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import FullLoading from './FullLoading';

const FileUpload = () => {
  const { registerUsers } = useUsers({});

  const fileRef = useRef(null);

  const handleUploadClick = () => {
    fileRef.current.click();
  };
  const handleInputChange = async (e) => {
    if (fileRef.current.files[0]) {
      if (
        ![
          'application/vnd.ms-excel (official)',
          'application/msexcel',
          'application/x-msexcel',
          'application/x-ms-excel',
          'application/x-excel',
          'application/x-dos_ms_excel',
          'application/xls',
          'application/x-xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ].includes(fileRef.current.files[0].type)
      ) {
        fileRef.current.value = '';
        await errorSwal({ text: 'Excel 파일만 업로드가 가능합니다.' });
      } else {
        const frm = new FormData();
        let file = fileRef.current.files[fileRef.current.files.length - 1];
        frm.append('userFile', file);

        try {
          const response = await registerUsers.mutateAsync({ formData: frm });
          if (response.status === 201) {
            fileRef.current.value = '';
            await successSwal({
              text: `${response.data}명이 정상적으로 등록되었습니다.`,
            });
          }
        } catch (error) {
          fileRef.current.value = '';
          await errorSwal({
            text: `등록에 실패하였습니다. \n${error.response.data.message}`,
          });
        }
        fileRef.current.value = '';
      }
    }
  };

  return (
    <>
      {registerUsers.isLoading && <FullLoading />}

      <InputWrapper>
        <input
          style={{ display: 'none' }}
          ref={fileRef}
          type="file"
          name="list"
          id="list"
          accept=".xls, .xlsx"
          onChange={handleInputChange}
        />

        <FileBox>
          <a href="/assets/sslc_user_template.xlsx" download>
            <DownloadBtn>양식 다운로드</DownloadBtn>
          </a>
          <UploadBtn onClick={handleUploadClick}>엑셀파일로 유저등록</UploadBtn>
        </FileBox>
      </InputWrapper>
    </>
  );
};

const InputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-left: auto;
`;

const FileBox = styled.div`
  gap: 20px;
  display: flex;

  & span {
    margin-bottom: -4px;
  }
`;

const UploadBtn = styled.div`
  margin-bottom: 4px;
  width: max-content;
  height: 38px;
  line-height: 38px;
  text-align: center;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  font-size: 14px;
  border: 1px solid #ec7807;
  color: ${(props) => (props.open ? 'white' : '#ec7807')};
  background-color: ${(props) => (props.open ? '#ec7807' : 'white')};
  transition: 0.2s;

  &:hover {
    background-color: #ec7807;
    color: white;
    cursor: pointer;
  }
`;

const DownloadBtn = styled(UploadBtn)`
  margin-bottom: 0;
`;

export default FileUpload;
