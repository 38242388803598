import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';

const SearchInput = ({
  value,
  setValue,
  width = '250px',
  placeholder = '검색어를 입력해주세요.',
  ...rest
}) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const handleChangeValue = (e) => {
    setValue(e.target.value);
  };

  const handleReset = () => {
    history.replace({ pathname, search: '' });
  };
  return (
    <Wrapper>
      <StyledBox $width={width} {...rest}>
        <input
          placeholder={placeholder}
          name="content"
          value={value}
          onChange={handleChangeValue}
        />
      </StyledBox>

      <ResetButton onClick={handleReset}>
        검색 <br /> 초기화
      </ResetButton>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
`;

const StyledBox = styled.div`
  display: flex;

  > input {
    width: ${(p) => p.$width};
    height: 40px;
    border: 1px solid #ec7422;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 14px;
    transition: 0.3s;

    ::placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #ec7422;
    }

    &:focus {
      border-color: #ec7422;
      box-shadow: 1px 1px 3px 1px rgba(236, 116, 34, 0.3);
    }
  }
`;

const ResetButton = styled.div`
  display: grid;
  place-items: center;
  text-align: center;
  line-height: 1.1;
  height: 40px;
  border: 1px solid #ec7422;
  padding: 0 15px;
  border-radius: 10px;
  font-size: 14px;
  transition: 0.3s;
  color: #ec7422;
  cursor: pointer;

  &:hover {
    background-color: #ec7422;
    color: #fff;
  }
`;

export default SearchInput;
