import React from 'react';
import { CardBody, Wrapper } from '../layouts';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pageTitles } from 'constants';

const Sitemap = () => {
  return (
    <Wrapper>
      <CardBody>
        <StyledSiteWrapper>
          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-id-card-2" />
              <span>회원 관리</span>
            </h6>

            <ul>
              <li>
                <Link to="/user-list">{pageTitles['user-list']}</Link>
              </li>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-calendar-1" />
              <span>과정 관리</span>
            </h6>

            <ul>
              <li>커리큘럼</li>
              <ul className="children">
                <li>
                  <Link to="/class-list">- {pageTitles['class-list']}</Link>
                </li>
                <li>
                  <Link to="/category-list">
                    - {pageTitles['category-list']}
                  </Link>
                </li>
                <li>
                  <Link to="/unit-list">- {pageTitles['unit-list']}</Link>
                </li>
                <li>
                  <Link to="/chapter-list">- {pageTitles['chapter-list']}</Link>
                </li>
                <li>
                  <Link to="/class-video">- {pageTitles['class-video']}</Link>
                </li>
              </ul>

              <li>연결 관리</li>
              <ul className="children">
                <li>
                  <Link to="/upload-link">- {pageTitles['upload-link']}</Link>
                </li>
                <li>
                  <Link to="/upload-cloud">- {pageTitles['upload-cloud']}</Link>
                </li>
              </ul>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-reading" />
              <span>내 클래스 게시판</span>
            </h6>

            <ul>
              <li>
                <Link to="/notice">{pageTitles['notice']}</Link>
              </li>
              <li>
                <Link to="/library">{pageTitles['library']}</Link>
              </li>
              <li>
                <Link to="/task">{pageTitles['task']}</Link>
              </li>

              <li>PBL 관리</li>
              <ul className="children">
                <li>
                  <Link to="/pbl-category">- {pageTitles['pbl-category']}</Link>
                </li>
                <li>
                  <Link to="/pbl-problem">- {pageTitles['pbl-problem']}</Link>
                </li>
                <li>
                  <Link to="/pbl-result">- {pageTitles['pbl-result']}</Link>
                </li>
              </ul>

              <li>
                <Link to="/question">{pageTitles['question']}</Link>
              </li>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-television" />
              <span>역량평가</span>
            </h6>

            <ul>
              <li>
                <Link to="/exam">{pageTitles['exam']}</Link>
              </li>
              <li>
                <Link to="/exam-problem">{pageTitles['exam-problem']}</Link>
              </li>
              <li>
                <Link to="/exam-graph">{pageTitles['exam-graph']}</Link>
              </li>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-promotion" />
              <span>학습지원 게시판</span>
            </h6>

            <ul>
              <li>
                <Link to="/support-library">
                  {pageTitles['support-library']}
                </Link>
              </li>
              <li>
                <Link to="/faq">{pageTitles['faq']}</Link>
              </li>
              <li>
                <Link to="/story">{pageTitles['story']}</Link>
              </li>
              <li>
                <Link to="/contact">{pageTitles['contact']}</Link>
              </li>
              <li>
                <Link to="/consulting">{pageTitles['consulting']}</Link>
              </li>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-user-9" />
              <span>스터디그룹</span>
            </h6>

            <ul>
              <li>
                <Link to="/study-group">{pageTitles['study-group']}</Link>
              </li>
              <li>
                <Link to="/study-board">{pageTitles['study-board']}</Link>
              </li>
              <li>
                <Link to="/study-room">{pageTitles['study-room']}</Link>
              </li>
            </ul>
          </StyledSitemapItem>

          <StyledSitemapItem>
            <h6>
              <i className="flaticon-381-idea" />
              <span>지식컨텐츠</span>
            </h6>

            <ul>
              <li>
                <Link to="/knowledge-content">
                  {pageTitles['knowledge-content']}
                </Link>
              </li>
            </ul>
          </StyledSitemapItem>
        </StyledSiteWrapper>
      </CardBody>
    </Wrapper>
  );
};

const StyledSiteWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
`;

const StyledSitemapItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 3px solid #efefef;
  padding: 10px;
  border-radius: 15px;

  a {
    transition: 0.2s;
  }

  > h6 {
    display: flex;
    align-items: center;
    min-width: calc(100% + 30px) !important;
    gap: 30px;
    font-size: 22px;
    background-color: #ec7422;
    color: #fff;
    padding: 8px 10px 5px;
    border-radius: 15px;
    box-shadow: 1px 1px 3px #ec7422;
    position: relative;
    margin-bottom: 20px;
  }

  > ul {
    padding-left: 10px;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  > ul > li {
    margin-top: 5px;
    overflow: hidden;
    transition: transform 0.2s ease-in-out, text-shadow 0.7ms;

    > a {
      width: 100%;
      overflow: hidden;
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        border-bottom: 3px solid #e92b28;
        top: 96%;
        left: 100%;
        opacity: 0;
        transition: left 0.25s ease-in-out, opacity 0.4s ease-out;
      }
    }
    &:has(a):hover {
      transform: translateX(5px);

      > a:after {
        left: 0;
        opacity: 1;
      }
    }
  }

  ul.children {
    font-size: 0.9em;
    font-weight: normal;
    padding-left: 5px;
    margin-bottom: 10px;

    > li {
      transition: transform 0.2s ease-in-out, text-shadow 0.7ms;

      > a {
        width: 100%;
        overflow: hidden;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          border-bottom: 1px solid #e92b28;
          top: 95%;
          left: 100%;
          opacity: 0;
          transition: left 0.25s ease-in-out, opacity 0.4s ease-out;
        }
      }

      &:has(a):hover {
        transform: translateX(5px);

        > a:after {
          left: 0;
          opacity: 1;
        }
      }
    }
  }
`;

export default Sitemap;
