import React from 'react';
import styled from 'styled-components';
import '@toast-ui/editor/dist/toastui-editor.css';

import { TaskDetailView, TaskResultListByParent } from 'components/Task';

const TaskDetail = () => {
  return (
    <Wrapper>
      {/*과제 상세*/}
      <TaskDetailView />

      {/*과제 제출물*/}
      <TaskResultListByParent />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding-bottom: 15%;
`;

export default TaskDetail;
