import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useFetchAllUserType } from '../../hooks';

const UserTypeSelect = ({ roleName, setRoleName, setRole, setPageNum }) => {
  const { data: userTypes } = useFetchAllUserType();

  const handleSelect = (roleName, roleType) => {
    setRoleName(roleName);
    setRole(roleType);
    setPageNum(1);
  };

  return (
    <>
      <DropdownButton title={roleName} id="bg-vertical-dropdown-3">
        <Dropdown.Item
          eventKey="1"
          onClick={() => {
            handleSelect('All roleType', '');
          }}
        >
          All roleType
        </Dropdown.Item>
        {userTypes?.map((role) => (
          <Dropdown.Item
            key={role.id}
            eventKey={role.id + 1}
            onClick={() => {
              handleSelect(role.name, role.id);
            }}
          >
            {`${role.name}(${role.description})`}
          </Dropdown.Item>
        ))}
      </DropdownButton>
    </>
  );
};

export default UserTypeSelect;
