import { useQuery } from 'react-query';

import { fetchPBLProblems } from 'services';

const useFetchPBLProblems = ({ unitId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPBLProblems', unitId],
    queryFn: async () => await fetchPBLProblems({ unitId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!unitId,
  });
  return { ...queryReturns };
};

export default useFetchPBLProblems;
