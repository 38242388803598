export const linkify = (inputText) => {
  if (!inputText) return '<p>정보없음</p>';

  let replacedText;
  let urlPattern =
    /(\b(https?:\/\/|ftp:\/\/|www\.)[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;

  replacedText = inputText.replace(urlPattern, (url) => {
    if (url.startsWith('www.')) {
      return `<a href="https://${url}" target="_blank">${url}</a>`;
    } else {
      return `<a href="${url}" target="_blank">${url}</a>`;
    }
  });

  replacedText = replacedText.replaceAll(/\n/g, '<br>');

  return replacedText;
};

/*
 * 텍스트 에디터 내부에 내용 없는지 & 공백 체크
 */
export const isNoContent = (textValue) => {
  let parsedTextValue = textValue.replaceAll('<p>', '').replaceAll('</p>', '');
  const hasOnlyBr = /^<br>(<br>)*$/.test(parsedTextValue);
  return textValue.trim() === '' || hasOnlyBr || parsedTextValue.trim() === '';
};
