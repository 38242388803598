import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import {
  useDebounce,
  useExamProblem,
  useGetQueryString,
  useSetExamProblem,
  useSetParameterOnQuery,
} from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import { EXAM_PROBLEM_COLUMNS } from 'components/Board/Columns';
import { SearchInput, Pagination } from 'components/common';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';

const ExamProblemList = ({ history }) => {
  const queryValues = useGetQueryString();

  const examProblemTypes = ['객관식', '다중답안객관식', 'OX', '단답형'];

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [unitId, setUnitId] = useState('');
  const [unitName, setUnitName] = useState('유닛을 선택하세요.');
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setUnitId('');
      setUnitName('유닛을 선택하세요.');
    }
  }, [queryValues]);

  const { deleteProblem } = useSetExamProblem({});
  const { problemList, problemUnits } = useExamProblem({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    unitId,
    pageSize,
  });
  const { data } = problemList || {};
  const { data: unitList } = problemUnits || {};

  //Table Setting
  const columns = useMemo(() => EXAM_PROBLEM_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async ({ problemId }) => {
    const confirm = await confirmSwal({
      title: '해당 문제를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteProblem.mutateAsync({ problemId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.PROBLEM_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.PROBLEM_DELETE_FAIL });
      }
    }
  };

  const handleUnitName = (data, id) => {
    setUnitName(data);
    setUnitId(id);
  };

  const handleGoToProblem = (data, i) => {
    if (i !== 6) {
      history.push(`/exam-problem-detail/${data.examProblemId}`);
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{ width: '100%' }}
          className="d-sm-flex justify-content-between"
        >
          <Dropdown>
            <Dropdown.Toggle
              variant="outline-primary"
              size="md"
              className="mr-2 height-38px"
            >
              {unitName}
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ maxHeight: '500px', overflowY: 'scroll' }}>
              <Dropdown.Item
                onClick={() => handleUnitName('전체 조회', '')}
                className="fs-12"
              >
                전체 조회
              </Dropdown.Item>
              {unitList?.map((list) => (
                <Dropdown.Item
                  key={list.id}
                  eventKey={list.id + 1}
                  onClick={() => handleUnitName(list.unitName, list.id)}
                  className="fs-12"
                >
                  {list.unitName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          <div>
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <Thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </Thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            onClick={() =>
                              handleGoToProblem(cell.row.original, i)
                            }
                            className="cursor-pointer"
                            style={{
                              whiteSpace: i === 3 ? 'pre' : 'normal',
                            }}
                          >
                            {i === 2 ? (
                              <div data-tip={cell.row.original.unitName}>
                                {cell.row.original.unitName}
                              </div>
                            ) : i === 6 ? (
                              <>
                                <ReactTooltip />
                                <i
                                  className="far fa-trash-alt more-btn-icon-box mr-1"
                                  data-tip="Delete"
                                  onClick={() =>
                                    handleDelete({
                                      problemId:
                                        cell.row.original.examProblemId,
                                    })
                                  }
                                />
                              </>
                            ) : i === 0 ? (
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.examType]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {examProblemTypes[cell.row.original.examType]}
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          {page.length !== 0 && (
            <Pagination
              page={page}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            />
          )}
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Thead = styled.thead`
  & th {
    width: 70px;
  }

  & th:nth-child(3) {
    width: 210px !important;
  }

  & th:nth-child(4) {
    width: auto;
  }
`;

export default ExamProblemList;
