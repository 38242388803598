import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const BoardWriteButton = ({
  to,
  backGround = '#ec7422',
  text = '게시글 작성',
}) => {
  const history = useHistory();

  const handleGoToPage = () => history.push(to);

  return (
    <Wrapper $backGround={backGround}>
      <span onClick={handleGoToPage}>{text}</span>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 20px;

  span {
    justify-content: center;
    padding: 8px 20px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 1px 1px 1px #999;
    background-color: ${(p) => p.$backGround};
    cursor: pointer;
    transition: 0.3s;

    &:hover {
      background-color: #ff4040;
    }
  }
`;

export default BoardWriteButton;
