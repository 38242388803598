import { BASE_URL } from './config';

import axios from 'axios';

/* 스터디그룹 목록 조회 */
export const fetchStudyGroupList = async ({
  pageNum,
  pageSize,
  keyword,
  groupId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    groupId,
  };

  return await axios.get(`${BASE_URL}/admin/study-groups`, { params });
};

/* 스터디그룹 승인/해제 */
export const changeStudyGroupStatus = async ({ studyGroupId, hasConfirm }) => {
  return await axios.put(
    `${BASE_URL}/admin/study-groups/${studyGroupId}/confirm/${hasConfirm}`,
    {}
  );
};

/* 스터디그룹 삭제 */
export const removeStudyGroup = async ({ studyGroupId }) => {
  return await axios.delete(`${BASE_URL}/admin/study-groups/${studyGroupId}`);
};

/* 스터디그룹 상세조회 */
export const fetchStudyGroupDetail = async ({ studyGroupId }) => {
  return await axios.get(`${BASE_URL}/admin/study-groups/${studyGroupId}`);
};

/* 스터디그룹 상세 정보 수정 */
export const changeStudyGroupDetail = async ({ studyGroupId, body }) => {
  return await axios.put(`${BASE_URL}/admin/study-groups/${studyGroupId}`, {
    ...body,
  });
};

/* 스터디그룹 게시글 목록 조회 */
export const fetchStudyPosts = async ({
  pageNum,
  pageSize,
  keyword,
  studyGroupId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    studyGroupId,
  };

  return await axios.get(`${BASE_URL}/admin/study-groups/posts`, { params });
};

/* 스터디 그룹 게시글 삭제 */
export const removeStudyPost = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/study-groups/posts/${id}`);
};

/* 스터디그룹 게시글 상세 내용 조회 */
export const fetchStudyBoardDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/study-groups/posts/${id}`);
};

/* 스터디그룹 게시글 상세 첨부파일 조회 */
export const fetchStudyBoardFiles = async ({ studyGroupId }) => {
  return await axios.get(
    `${BASE_URL}/admin/study-groups/${studyGroupId}/attachments`
  );
};

/* 스터디그룹 게시글 상세 댓글 조회 */
export const fetchStudyBoardReply = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/study-groups/posts/${id}/comments`);
};

/* 스터디그룹 게시글 상세 댓글 삭제 */
export const removeStudyPostComment = async ({ studyGroupReplyId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/study-groups/posts/comments/${studyGroupReplyId}`
  );
};

/* 스터디그룹 게시글 상세 스터디룸 예약 내역 확인 */
export const fetchStudyGroupDetailRoom = async ({ studyGroupId }) => {
  return await axios.get(
    `${BASE_URL}/admin/study-groups/reservations/${studyGroupId}`
  );
};

/* 스터디그룹 멤버 리스트 조회 */
export const fetchStudyMembers = async ({
  studyGroupId,
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(
    `${BASE_URL}/admin/users/study-group/${studyGroupId}`,
    { params }
  );
};

/* 스터디룸 예약 목록 조회 */
export const fetchStudyRoomReserveList = async ({
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/study-groups/reservations`, {
    params,
  });
};

/* 스터디룸 예약 승인/해제 */
export const changeStudyRoomApprove = async ({ studyRoomId, body }) => {
  return await axios.put(
    `${BASE_URL}/admin/study-groups/reservations/confirm/${studyRoomId}`,
    { ...body }
  );
};

/* 스터디룸 예약 삭제 */
export const removeStudyRoomReserve = async ({ id }) => {
  return await axios.delete(
    `${BASE_URL}/admin/study-groups/reservations/${id}`
  );
};

/* ZOOM 링크 리스트 조회 */
export const fetchZoomLinkList = async () => {
  return await axios.get(`${BASE_URL}/admin/remote-class-link`);
};

/* 스터디룸에 ZOOM 링크 업데이트 */
export const changeStudyRoomZoomLink = async ({ studyRoomId, body }) => {
  return await axios.put(
    `${BASE_URL}/admin/study-groups/reservations/confirm/${studyRoomId}`,
    { ...body }
  );
};
