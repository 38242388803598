import { useRef, useState, useEffect } from 'react';
import swal from 'sweetalert';

const useInput = ({ originalFileNames, multiple }) => {
  const [preview, setPreview] = useState(
    multiple ? originalFileNames || [] : []
  );
  const inputRef = useRef(null);

  useEffect(() => {
    if (originalFileNames.length === 0) return;

    setPreview(originalFileNames || []);
  }, [originalFileNames]);

  const clickUpload = () => {
    inputRef.current.click();
  };

  const handleChange = (e) => {
    const target = e.target;

    if (!target.files?.length) {
      setPreview([]);
      return;
    }

    const files = [...target.files].filter(
      (file) => file.size < 10 * 1024 * 1024
    );

    if (target.files.length !== files.length) {
      swal({
        icon: 'error',
        title:
          '파일당 용량 제한을 넘어서는 파일은 업로드가 불가능합니다.(10MB)',
      });
    }

    if (multiple) {
      setPreview((prev) => [
        ...prev,
        ...files.slice(0, 5).map(({ name }) => name),
      ]);
    } else {
      setPreview(files.slice(0, 5).map(({ name }) => name));
    }

    return files;
  };

  const deleteFile = (index) => {
    setPreview((prev) => {
      const newList = [...prev];
      newList.splice(index, 1);
      return newList;
    });
  };

  const reset = () => {
    setPreview(multiple ? originalFileNames || [] : []);
  };

  return {
    inputRef,
    clickUpload,
    handleChange,
    preview,
    deleteFile,
    reset,
  };
};

export default useInput;
