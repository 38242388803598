import React from 'react';

import { useFetchCardinalList } from '../../hooks';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const CardinalDropMenu = ({
  classTitle,
  setClassTitle,
  setId,
  isGroupId = false,
  noAll = false,
}) => {
  const { data: groupList } = useFetchCardinalList();

  const handleSelect = (classTitle, classId, groupId) => {
    setClassTitle(classTitle);
    setId(isGroupId ? groupId : classId);
  };

  return (
    <DropdownButton title={classTitle} id="bg-vertical-dropdown-3">
      {!noAll && (
        <Dropdown.Item
          onClick={() => {
            handleSelect('과정을 선택해주세요.', '', '');
          }}
        >
          모든 과정 조회
        </Dropdown.Item>
      )}
      {groupList?.map((group, index) => (
        <Dropdown.Item
          key={index}
          eventKey={group.groupId + 1}
          onClick={() => {
            handleSelect(group.groupName, group.classId, group.groupId);
          }}
        >
          {`${group.groupName}(${group.classTitle})`}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
};

export default CardinalDropMenu;
