import React, { Fragment, useMemo, useEffect, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { USER_COLUMNS } from 'components/Board/Columns';
import CustomSwitch from 'components/ui/CustomSwitch';
import {
  CardinalDropMenu,
  FileUpload,
  Pagination,
  SearchInput,
} from 'components/common';
import {
  useDebounce,
  useUsers,
  useSetParameterOnQuery,
  useGetQueryString,
} from 'hooks';
import {
  SingleUserRegistryModal,
  SearchTypeSelect,
  UserTypeSelect,
} from 'components/Users';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { confirmSwal, errorSwal, successSwal } from '../../utilities';
import { MESSAGES, BADGE_COLOR, userRole } from '../../constants';

const UserList = ({ history }) => {
  const queryValues = useGetQueryString();

  const [roleName, setRoleName] = useState('유저 타입');
  const [searchName, setSearchName] = useState('전체 검색');
  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');

  const [classId, setClassId] = useState('');
  const [role, setRole] = useState('');
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [type, setType] = useState('all');
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setClassId('');
      setRole('');
      setType('all');
      setClassTitle('과정을 선택해주세요.');
      setSearchName('전체 검색');
      setRoleName('유저 타입');
    }
  }, [queryValues]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { usersList, editUserExpired, deleteUserAccount } = useUsers({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    type,
    role,
    classId,
  });
  const { data } = usersList || {};

  //Table Setting
  const columns = useMemo(() => USER_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleAccountDelete = async ({ userId }) => {
    const confirm = await confirmSwal({
      title: '계정을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteUserAccount.mutateAsync({ userId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.USER_ACCOUNT_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.USER_ACCOUNT_DELETE_FAIL });
      }
    }
  };

  const handleExpiredToggle = async (use, userId) => {
    let yn = 'Y';
    if (use) {
      yn = 'N';
    }
    const { status } = await editUserExpired.mutateAsync({ userId, yn });
    if (status === 200) {
      await successSwal({ text: '상태가 변경되었습니다.' });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen((prev) => !prev);
  };

  const handleGoDetail = (userId, i) => {
    if (![7, 8].includes(i)) {
      history.push(`/user-view/${userId}`);
    }
  };

  return (
    <>
      <Wrapper>
        <CardHeader>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
              gap: '10px',
            }}
          >
            <div style={{ display: 'flex', gap: '10px' }}>
              <CardinalDropMenu
                classTitle={classTitle}
                setClassTitle={setClassTitle}
                setId={setClassId}
              />

              <UserTypeSelect
                roleName={roleName}
                setRoleName={setRoleName}
                setRole={setRole}
                setPageNum={setPageNum}
              />
            </div>

            <div style={{ display: 'flex', gap: '10px' }}>
              <SearchTypeSelect
                searchName={searchName}
                setSearchName={setSearchName}
                setSearchType={setType}
              />

              <SearchInput value={keyword} setValue={setKeyword} />
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups?.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers?.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page?.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells?.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              {...cell.getCellProps()}
                              onClick={() =>
                                handleGoDetail(cell.row.original.userId, i)
                              }
                              className="cursor-pointer"
                            >
                              {i === 8 ? (
                                <>
                                  <ReactTooltip />
                                  <i
                                    className="far fa-trash-alt more-btn-icon-box mr-1"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleAccountDelete({
                                        userId: cell.row.original.userId,
                                      })
                                    }
                                  />
                                </>
                              ) : i === 2 ? (
                                <div
                                  className={`badge rounded-pill bg-${
                                    BADGE_COLOR[cell.row.original.type - 1]
                                  } text-white`}
                                  style={{ opacity: '0.7' }}
                                >
                                  {userRole[cell.row.original.type - 1]}
                                </div>
                              ) : i === 7 ? (
                                <CustomSwitch
                                  isTrue={
                                    cell.row.original.expireYn.toUpperCase() ===
                                    'Y'
                                  }
                                  data={cell.row.original.userId}
                                  handleToggle={handleExpiredToggle}
                                />
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            <RegisterButtonWrapper>
              {/* 유저 대량 등록 */}
              <FileUpload />

              <AddAccountBtn onClick={handleOpenModal}>
                단일 유저 등록
              </AddAccountBtn>
            </RegisterButtonWrapper>

            <Pagination
              page={page}
              pageNum={pageNum}
              setPageNum={setPageNum}
              data={data || {}}
              maxPostPage={data?.pages}
            />
          </div>
        </CardBody>
      </Wrapper>

      {/* 단일 유저 등록 모달 컴포넌트 */}
      <SingleUserRegistryModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </>
  );
};

const AddAccountBtn = styled.div`
  margin-bottom: 4px;
  margin-left: 20px;
  width: max-content;
  height: 38px;
  line-height: 38px;
  text-align: center;
  padding: 0 1.25rem;
  border-radius: 0.75rem;
  font-size: 14px;
  border: 1px solid #ec7807;
  color: ${(props) => (props.open ? 'white' : '#ec7807')};
  background-color: ${(props) => (props.open ? '#ec7807' : 'white')};
  transition: 0.2s;

  &:hover {
    background-color: #ec7807;
    color: white;
    cursor: pointer;
  }
`;

const RegisterButtonWrapper = styled.div`
  display: flex;
`;

export default UserList;
