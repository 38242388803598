import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import swal from 'sweetalert2';

import { useCreatePBLNewProblem } from 'hooks';
import TextEditor from 'components/ui/TextEditor';
import { CardHeader, Wrapper } from 'components/layouts/common';

const PblProblemCreate = () => {
  const MAXIMUM_FILES = 5;
  const MAXIMUM_FILE_SIZE = 1024 * 1024 * 10;

  const history = useHistory();
  const { unitId, level } = useParams();
  const { register, getValues } = useForm();

  const { mutateAsync } = useCreatePBLNewProblem({ unitId });

  const [isImageLoading, setIsImageLoading] = useState(false);

  const [textValue, setTextValue] = useState('');
  const [imageNames, setImageNames] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const [imageSrc, setImageSrc] = useState(null);

  const handleUploadThumnail = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      const file = input.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(file);

      return new Promise((resolve) => {
        reader.onload = () => {
          setImageSrc(reader.result || null);
          setThumbnail(file);
          resolve();
        };
      });
    });
  };
  const handleRemoveUploadedThumbnailImage = () => {
    setImageSrc(null);
    setThumbnail(null);
  };

  const handleUploadAttachmentFiles = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', '.zip');
    input.setAttribute('multiple', 'true');
    input.click();

    input.addEventListener('change', async () => {
      const files = input.files;

      if ([...files].some((file) => file.size > MAXIMUM_FILE_SIZE)) {
        swal.fire({
          title: `알림`,
          text: `참고 자료는 용량은 10MB를 넘길 수 없습니다.`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return;
      }

      if (files.length > MAXIMUM_FILES) {
        swal.fire({
          title: `알림`,
          text: `참고 자료는 ${MAXIMUM_FILES}개까지만 업로드 가능합니다.`,
          icon: 'error',
          confirmButtonText: '확인',
        });
        return;
      }

      setAttachments((prev) => [...prev, ...files]);
    });
  };
  const handleRemoveAttachmentFiles = () => {
    setAttachments([]);
  };
  const handleRemoveOneAttachmentFiles = (e) => {
    setAttachments((prev) => {
      return [...prev.filter((attach) => attach.name !== e.name)];
    });
  };

  const handleSubmitNewProblem = async () => {
    if (isImageLoading) return;
    const title = getValues('title');

    if (textValue.length > 15000) {
      swal.fire({
        title: `본문 컨텐츠가 너무 깁니다.`,
        icon: 'error',
      });
      return;
    }

    if (!title || !textValue) {
      swal.fire({
        title: `제목과 내용을 입력해주세요.`,
        icon: 'error',
      });
      return;
    }

    const form = {
      level,
      title,
      content: textValue,
      imgs: imageNames,
    };
    const formData = new FormData();

    formData.append(
      'form',
      new Blob([JSON.stringify(form)], {
        type: 'application/json',
      })
    );

    if (thumbnail) {
      formData.append('thumbnail', thumbnail);
    }
    if (attachments.length !== 0) {
      attachments.forEach((zipFile) => {
        formData.append('attachments', zipFile);
      });
    }

    try {
      const response = await mutateAsync({ formData });
      if (response.status === 201) {
        swal.fire({
          title: `성공적으로 등록되었습니다.`,
          icon: 'success',
        });
        history.goBack();
      }
    } catch (error) {
      swal.fire({
        title: `오류가 발생하였습니다.`,
        icon: 'error',
      });
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{ width: '100%' }}
          className="d-sm-flex justify-content-between"
        >
          <h2>PBL 문제 생성</h2>
        </div>
      </CardHeader>

      <Container>
        <InputContainer>
          <InputBox>
            <div className="title">레벨</div>
            <div className="input">
              <span className="level">{level}</span>
            </div>
          </InputBox>

          <InputBox>
            <div className="title">제목</div>
            <div className="input">
              <input type="text" {...register('title')} />
            </div>
          </InputBox>

          <InputBox>
            <div className="title">내용</div>
            <div className="input">
              <TextEditor
                htmlStr={textValue}
                setHtmlStr={setTextValue}
                setImageNames={setImageNames}
                imageNames={imageNames}
                setIsImageLoading={setIsImageLoading}
              />
            </div>
          </InputBox>

          <InputBox>
            <div className="title">썸네일 이미지</div>
            <div className="image-box">
              <img src={imageSrc} alt="" />
              <div>
                <span onClick={handleUploadThumnail}>업로드</span>
                {imageSrc && (
                  <span onClick={handleRemoveUploadedThumbnailImage}>
                    업로드 취소
                  </span>
                )}
              </div>
            </div>
          </InputBox>

          <InputBox>
            <div className="title">참고자료</div>
            <div className="input">
              {attachments?.map((e, index) => (
                <span className="file" key={index}>
                  {e.name}
                  <span onClick={() => handleRemoveOneAttachmentFiles(e)}>
                    &times;
                  </span>
                </span>
              ))}

              <div>
                <span
                  className="upload-button"
                  onClick={handleUploadAttachmentFiles}
                >
                  참고자료 업로드
                </span>
                {attachments.length !== 0 && (
                  <span
                    className="upload-button"
                    onClick={handleRemoveAttachmentFiles}
                  >
                    업로드 취소
                  </span>
                )}
              </div>
            </div>
          </InputBox>
        </InputContainer>

        <SubmitButton
          onClick={handleSubmitNewProblem}
          className={isImageLoading ? 'imageLoading' : ''}
        >
          등록
        </SubmitButton>
      </Container>
    </Wrapper>
  );
};

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: 30px;
  padding: 30px 20px 100px;
  flex-direction: column;
`;

const InputContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InputBox = styled.div`
    display: flex;
    overflow: hidden;
    column-gap: 20px;
    border-bottom: 2px solid #b7b7b7;

    div.title {
        min-width: 130px;
        max-width: 130px;
        padding: 20px;
        display: grid;
        place-items: center;
        font-size: 16px;
        font-weight: bold;
        background-color: #eee;
    }

    div.image-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: auto;
        padding: 10px 0;

        img {
            width: 100%;
            max-width: 250px;
            
        }

        span {
            max-width: 120px;
            display: inline-block;
            padding: 5px 20px;
            border: 1px solid #eee;
            margin-top: 10px;
            border-radius: 5px;
            color: #fff;
            font-weight: 500;
            background-color: #f59b4a;
            cursor: pointer;

            &:hover {
                filter: brightness(95%);
            }

            &:nth-child(2) {
                background-color: #c4c1c0;
                margin-left: 5px;
            }
        }
    }

    .content {
        white-space: pre-wrap
    }

    div.input {
        width: 100%;
        display: flex;
        flex-direction: column;
        height: auto;
        padding: 10px 0;
        row-gap: 5px;
        justify-content: center;

        input {
            width: 100%;
            height: 35px;
        }

        > span.level {
            display: grid;
            place-items: center;
            width: 35px;
            height: 35px;
            background-color: #fc9918;
            border-radius: 50%;
            font-size: 20px;
            font-weight: bold;
            color: #fff;
            z-index: 1;
        }

        .MuiInputBase-input {
            padding: 10px 30px 10px 20px !important;
        }

        .file {
            cursor: pointer;
            transition: 0.2s;

            &:hover {
                color: #969696;
            }
            
            >span {
                margin-left: 5px;
                font-size: 16px;
            }
        }
    }
}

.upload-button {
    max-width: 180px;
    text-align: center;
    display: inline-block;
    padding: 5px 20px;
    border: 1px solid #eee;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    background-color: #f59b4a;
    cursor: pointer;

    &:hover {
        filter: brightness(95%);
    }

    &:nth-child(2) {
        background-color: #c4c1c0;
        margin-left: 5px;
    }
`;

const SubmitButton = styled.span`
  width: 80px;
  padding: 10px 20px;
  border: #eee;
  background-color: #f59b4a;
  color: #fff;
  border-radius: 10px;
  margin-left: 20px;
  cursor: pointer;
  transition: 0.2s;
  text-align: center;

  &:hover {
    filter: brightness(95%);
  }

  &.imageLoading {
    background-color: #eee;
    cursor: not-allowed;
  }
`;

export default PblProblemCreate;
