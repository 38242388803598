import React from 'react';
import styled from 'styled-components';
import Loading from './Loading';

const FullLoading = () => {
  return (
    <Wrapper>
      <Loading height="100%" size={70} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.4);
`;

export default FullLoading;
