import React from 'react';
import { useForm } from 'react-hook-form';

import { useSetConnect } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CardBody, Wrapper } from '../layouts';
import { ModalBadge, ValidationErrorMsg } from '../common';
import styled from 'styled-components';

const RegisterCloudAccount = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { addCloudAccount } = useSetConnect();

  const submit = async (form) => {
    const body = {
      accessKeyId: form.accessKeyId,
      clientSecret: form.clientSecret,
      password: form.password,
      url: form.url,
      username: form.username,
    };

    try {
      const { status } = await addCloudAccount.mutateAsync({ body });
      if (status === 201) {
        reset();
        await successSwal({ text: MESSAGES.CLOUD_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CLOUD_CREATE_FAIL });
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="basic-form">
          <form onSubmit={handleSubmit(submit)}>
            <StyledInputWrapper>
              <div>
                <ModalBadge text="유저 네임" />
                <div>
                  <input
                    type="text"
                    placeholder="유저 네임을 입력하세요."
                    className="form-control"
                    name="username"
                    {...register('username', {
                      required: true,
                    })}
                  />
                  {errors.username && (
                    <ValidationErrorMsg text="유저 네임을 입력해 주세요." />
                  )}
                </div>
              </div>

              <div>
                <ModalBadge text="암호" />
                <div>
                  <input
                    type="text"
                    placeholder="패스워드를 입력하세요."
                    className="form-control"
                    name="password"
                    {...register('password', {
                      required: true,
                    })}
                  />
                  {errors.password && (
                    <ValidationErrorMsg text="패스워드를 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="링크" />
                <div>
                  <input
                    type="text"
                    placeholder="링크(url)를 입력하세요."
                    className="form-control"
                    name="url"
                    {...register('url', {
                      required: true,
                    })}
                  />
                  {errors.url && (
                    <ValidationErrorMsg text="링크(url)를 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <StyledInputWrapper>
              <div>
                <ModalBadge text="accessKey" />
                <div>
                  <input
                    type="text"
                    placeholder="accessKey를 입력하세요."
                    className="form-control"
                    name="accessKeyId"
                    {...register('accessKeyId', {
                      required: true,
                    })}
                  />
                  {errors.accessKeyId && (
                    <ValidationErrorMsg text="accessKey를 입력해 주세요." />
                  )}
                </div>
              </div>

              <div>
                <ModalBadge text="clientSecret" />
                <div>
                  <input
                    type="text"
                    placeholder="clientSecret을 입력하세요."
                    className="form-control"
                    name="clientSecret"
                    {...register('clientSecret', {
                      required: true,
                    })}
                  />
                  {errors.clientSecret && (
                    <ValidationErrorMsg text="clientSecret을 입력해 주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                클라우드 계정 등록
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
      display: flex;
      flex-direction: column;
    }
  }
`;

export default RegisterCloudAccount;
