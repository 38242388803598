import React from 'react';
import { useController, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  useCategoryList,
  useUnitList,
  useSetExam,
  useFetchCardinalList,
} from 'hooks';
import { Select } from 'components/common';
import { UnitList } from 'components/Exam';
import { CardBody, Wrapper } from 'components/layouts/common';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const ExamCreate = () => {
  const history = useHistory();
  const { register, handleSubmit, control, watch } = useForm();

  const { field: groupProps } = useController({
    name: 'group',
    control,
    rules: { required: '그룹을 선택해주세요.' },
    defaultValue: '',
  });

  const { field: categoryProps } = useController({
    name: 'category',
    control,
    rules: { required: '카테고리를 선택해주세요.' },
    defaultValue: '',
  });

  const { field: unitProps } = useController({
    name: 'units',
    control,
    defaultValue: [],
  });

  const { data: groupList } = useFetchCardinalList();
  const { data: categoryList } = useCategoryList(watch('group'));
  const { data: unitList } = useUnitList(watch('group'), watch('category'));
  const { createExam } = useSetExam({});

  const onValid = async (data) => {
    if (data.units.length === 0) return;

    const confirm = await confirmSwal({
      title: '시험을 생성하시겠습니까?',
      text: '변경전에 다시한번 확인을 부탁드립니다.',
    });

    if (confirm) {
      const body = {
        groupId: data.group,
        categoryId: data.category,
        unitIds: data.units.map((unit) => unit.id),
        problemCounts: data.units.map((unit) => unit.count),
        timeLimit: Math.max(0, data.timeLimit) * 60,
      };

      try {
        const { status } = await createExam.mutateAsync({ body });
        if (status === 201) {
          history.push('/exam');
          await successSwal({ text: MESSAGES.EXAM_CREATE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.EXAM_CREATE_FAIL });
      }
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="form-group">
          <form onSubmit={handleSubmit(onValid)}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Select defaultOpen={false} {...groupProps}>
                <Select.Trigger>과정을 선택해주세요.</Select.Trigger>
                <Select.OptionList>
                  {groupList?.map((group, index) => (
                    <Select.Option value={group.groupId} key={index}>
                      {group.classTitle}
                    </Select.Option>
                  ))}
                </Select.OptionList>
              </Select>
              <Select defaultOpen={false} {...categoryProps}>
                <Select.Trigger>카테고리 선택</Select.Trigger>
                <Select.OptionList>
                  {categoryList?.map((category, index) => (
                    <Select.Option value={category.id} key={index}>
                      {category.categoryName}
                    </Select.Option>
                  ))}
                </Select.OptionList>
              </Select>

              <input
                type="number"
                min={0}
                placeholder="시험시간(분)"
                style={{
                  width: '120px',
                  borderRadius: '10px',
                  border: '1px solid rgba(0,0,0,0.2)',
                  padding: '0 7px',
                }}
                {...register('timeLimit', {
                  required: '시험시간을 입력해주세요.',
                  valueAsNumber: true,
                  pattern: {
                    value: /^(0|[1-9]\d*)(\.\d+)?$/,
                  },
                })}
              />
            </div>

            {
              <UnitList
                groupTitle={
                  groupList?.find((group) => group.id === watch('group'))
                    ?.groupCourse
                }
                categoryTitle={
                  categoryList?.find(
                    (category) => category.id === watch('category')
                  )?.categoryName
                }
                unitList={unitList}
                {...unitProps}
              />
            }

            <StyledButton type="submit" className="btn btn-primary">
              시험 생성
            </StyledButton>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledButton = styled.button`
  float: right;
`;

export default ExamCreate;
