import React, { Fragment, useEffect, useMemo, useState } from 'react';
import swal from 'sweetalert';
import {
  useFilters,
  useGlobalFilter,
  usePagination,
  useTable,
} from 'react-table';
import FileSaver from 'file-saver';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import Pagination from '../common/Pagination';
import { downloadAllSubmits, downloadPersonalResult } from 'services/pbl';
import { PBL_RESULT_COLUMNS } from '../Board/Columns';
import { useRemoveStudentResult, useFetchPBLResultsList } from 'hooks';
import PblCommentModal from './PblCommentModal';

const PblResultView = () => {
  const { problemId } = useParams();
  const [show, setShow] = useState(false);
  const [studentId, setStudentId] = useState('');

  const [pageNum, setPageNum] = useState(1);
  const [data, setData] = useState([]);

  const { data: resultData } = useFetchPBLResultsList({ problemId, pageNum });
  const { mutateAsync: removeStudentResult } = useRemoveStudentResult({
    problemId,
  });

  const columns = useMemo(() => PBL_RESULT_COLUMNS, []);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const handleOpenCommentModal = (e) => {
    const current = data?.find((student) => student.modifiedBy === e);

    if (!current.commented) return;

    setShow((prev) => !prev);
    setStudentId(e);
  };

  const handlePersonalResult = async ({ problemId, userId }) => {
    try {
      const response = await downloadPersonalResult({ problemId, userId });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDownloadAll = async () => {
    try {
      const response = await downloadAllSubmits({ problemId });
      if (response) {
        const fileName = response.headers['content-disposition']
          .split('filename=')[1]
          .replaceAll('"', '');

        FileSaver.saveAs(response.data, decodeURIComponent(fileName));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRemoveStudentResult = async ({ submitId }) => {
    const result = await swal({
      title: '이 결과물을 정말로 반려하시겠습니까?',
      icon: 'warning',
      buttons: ['취소', '삭제'],
      dangerMode: true,
    });

    if (result) {
      try {
        const response = await removeStudentResult({ submitId });
        if (response.status === 204) {
          swal({
            title: `성공적으로 반려되었습니다.`,
            icon: 'success',
            buttons: '확인',
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!resultData) return;

    setData(resultData.list);
  }, [resultData]);

  return (
    <>
      <div className="card">
        <div className="card-header" style={{ width: '100%' }}>
          <h3>해당 문제 결과물 제출 리스트</h3>
        </div>

        <div className="card-body">
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row, index) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td {...cell.getCellProps()}>
                              {i === 0 ? (
                                <div data-tip={cell.row.original.className}>
                                  {(pageNum - 1) * 10 + index + 1}
                                </div>
                              ) : i === 4 ? (
                                <ResultBox>
                                  <div>
                                    <span
                                      onClick={() =>
                                        handleRemoveStudentResult({
                                          submitId: cell.row.original.submitId,
                                        })
                                      }
                                    >
                                      반려
                                    </span>
                                    <span
                                      onClick={() =>
                                        handlePersonalResult({
                                          problemId,
                                          userId: cell.row.original.modifiedBy,
                                        })
                                      }
                                    >
                                      다운로드
                                    </span>
                                    <span
                                      onClick={() =>
                                        handleOpenCommentModal(
                                          cell.row.original.modifiedBy
                                        )
                                      }
                                      className={
                                        cell.row.original.commented
                                          ? 'commented'
                                          : ''
                                      }
                                    >
                                      의견
                                    </span>
                                  </div>
                                </ResultBox>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {page.length === 0 ? (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                제출한 학생 목록이 없습니다.
              </div>
            ) : (
              ''
            )}
            <DownLoadAllButton onClick={handleDownloadAll}>
              전체 다운로드
            </DownLoadAllButton>

            <div className="text-center">
              {page.length !== 0 && (
                <Pagination
                  page={page}
                  pageNum={pageNum}
                  setPageNum={setPageNum}
                  maxPostPage={resultData?.pages}
                  data={resultData}
                ></Pagination>
              )}
            </div>
          </div>
        </div>
      </div>

      {show && (
        <PblCommentModal show={show} setShow={setShow} studentId={studentId} />
      )}
    </>
  );
};

const ResultBox = styled.div`
  display: flex;
  justify-content: flex-start;

  p {
    font-size: 14px;
  }

  div {
    display: flex;
    gap: 5px;
    justify-content: center;
    margin-top: 5px;

    span {
      padding: 5px 10px;
      border: 1px solid #eee;
      border-radius: 10px;
      cursor: pointer;
      color: #fff;
      font-weight: 500;
      background-color: #63c04c;

      &:first-child {
        background-color: #ff7247;
      }

      &:last-child.commented {
        background-color: #6fc1fb;
        cursor: pointer;
      }

      &:last-child {
        background-color: #eee;
        cursor: default;
      }

      &:hover {
        filter: brightness(95%);
      }
    }
  }
`;

const DownLoadAllButton = styled.div`
  color: #fff;
  max-width: 160px;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 15px;
  background-color: #b96ff8;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 1px 1px 5px #eee;
  cursor: pointer;
  margin-left: auto;

  &:hover {
    background-color: #ad5af1;
  }
`;

export default PblResultView;
