import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  changeKnowledge,
  createKnowledge,
  fetchKnowledgeList,
  removeKnowledge,
} from 'services';

const useKnowledge = ({ pageNum, pageSize }) => {
  const queryClient = useQueryClient();

  const knowledgeList = useQuery({
    queryKey: ['fetchKnowledgeList', { pageNum, pageSize }],
    queryFn: async () => await fetchKnowledgeList({ pageNum, pageSize }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
  });

  const addKnowledge = useMutation({
    mutationFn: async ({ formData }) => {
      return await createKnowledge({ formData });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchKnowledgeList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const editKnowledge = useMutation({
    mutationFn: async ({ id, formData }) => {
      return await changeKnowledge({ id, formData });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchKnowledgeList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const deleteKnowledge = useMutation({
    mutationFn: async ({ id }) => {
      return await removeKnowledge({ id });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchKnowledgeList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    knowledgeList,
    addKnowledge,
    editKnowledge,
    deleteKnowledge,
  };
};

export default useKnowledge;
