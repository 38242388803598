import React, {useEffect, useState} from 'react';
import Switch from "react-switch";

export const CustomSwitchNoConfirm = ({isTrue, data, handleToggle}) => {

  const [isCheck, setIsCheck] = useState(false)

  useEffect(() => {
    isTrue ? setIsCheck(true) : setIsCheck(false)
  }, [isTrue])

  const handleChange = () => {
    handleToggle(isTrue, data)
  }

  return (
    <Switch onChange={handleChange} checked={isCheck} onColor="#ec7807" height={20} width={40} uncheckedIcon={false}/>
  );
}

export default CustomSwitchNoConfirm;