import React from 'react';
import styled from 'styled-components';

const StatusBadge = ({ text, bgColor = '#999' }) => {
  return <Wrapper $bgColor={bgColor}>{text}</Wrapper>;
};

const Wrapper = styled.span`
  background-color: ${(p) => p.$bgColor};
  color: #fff;
  width: auto;
  border-radius: 5px;
  padding: 2px 30px;
`;

export default StatusBadge;
