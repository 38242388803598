import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { useKnowledge } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import {
  CardinalDropMenu,
  DropFile,
  ValidationErrorMsg,
} from 'components/common';
import { CardBody, Wrapper } from 'components/layouts';

const KnowledgeCreate = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });
  const history = useHistory();

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [classId, setClassId] = useState('');

  const [file, setFile] = useState();
  const [, setImageName] = useState();

  const { addKnowledge } = useKnowledge({});

  const submit = async (form) => {
    if (!classId) {
      errorSwal({ text: '과정을 선택해주세요.' });
      return;
    }

    form.classId = classId;
    const formData = new FormData();

    formData.append(
      'data',
      new Blob([JSON.stringify(form)], {
        type: 'application/json',
      })
    );

    if (file) {
      formData.append('file', file);
    } else {
      errorSwal({ text: '대표 이미지를 등록해주세요.' });
      return;
    }

    try {
      const { status } = await addKnowledge.mutateAsync({ formData });
      if (status === 201) {
        history.push('/knowledge-content');
        await successSwal({ text: MESSAGES.KNOWLEDGE_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.KNOWLEDGE_CREATE_FAIL });
    }
  };

  const handleGetDropZoneFile = (data) => {
    setFile(data);
    setImageName(data.name);
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="basic-form">
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-row">
              <div className="form-group">
                <CardinalDropMenu
                  classTitle={classTitle}
                  setClassTitle={setClassTitle}
                  setId={setClassId}
                />
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-10">
                <input
                  type="text"
                  placeholder="제목을 입력하세요."
                  className="form-control"
                  name="title"
                  {...register('title', {
                    required: true,
                  })}
                />
                {errors.title && (
                  <ValidationErrorMsg text="제목을 입력해 주세요." />
                )}
              </div>

              <div className="form-group col-md-2">
                <input
                  type="number"
                  placeholder="시간을 입력하세요."
                  min="0"
                  className="form-control"
                  name="totalTime"
                  {...register('totalTime', {
                    required: true,
                  })}
                />
                {errors.totalTime && (
                  <ValidationErrorMsg text="시간을 입력해 주세요." />
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  rows="15"
                  id="comment"
                  placeholder="여기에 내용을 작성하세요."
                  name="content"
                  {...register('content', {
                    required: true,
                  })}
                />
              </div>
              {errors.content && (
                <ValidationErrorMsg text="내용을 입력해 주세요." />
              )}
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <input
                  type="text"
                  placeholder="링크를 입력하세요. ex) https://sslc.kr"
                  className="form-control"
                  name="url"
                  {...register('url', {
                    required: true,
                    pattern: /(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/gi,
                  })}
                />
              </div>
              {errors.url?.type === 'required' && (
                <ValidationErrorMsg text="링크를 입력해 주세요." />
              )}
              {errors.url?.type === 'pattern' && (
                <ValidationErrorMsg text="링크의 형식을 확인해주세요. 웹페이지의 URL 형식이어야 합니다." />
              )}
            </div>

            <div className="form-row mt-3">
              <div className="form-group col-md-12">
                <h5 className="mb-2">
                  <i className="fa fa-paperclip"></i> 대표이미지를 등록하세요.
                </h5>
                <DropFile
                  accept="image/*"
                  handleGetDropZoneFile={handleGetDropZoneFile}
                />
              </div>
            </div>

            <hr />
            <div className="d-flex justify-content-start">
              <button type="submit" className="btn btn-primary mt-3 mr-3">
                등록
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default KnowledgeCreate;
