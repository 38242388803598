import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { useFetchPBLComments } from 'hooks';
import PBLComment from './PBLComment';

const PblCommentModal = ({
  show,
  setShow,
  studentId,
  problemId: propsProblemId,
}) => {
  const { problemId } = useParams();

  const { data } = useFetchPBLComments({
    problemId: problemId ?? propsProblemId,
    studentId,
  });
  const comments = data?.comments;

  useEffect(() => {
    const modal = document.querySelector('.modal-content');
    modal.style.top = '10vh';
    modal.style.overflowY = 'scroll';
    modal.style.overflowX = 'unset';
    modal.style.minHeight = '80vh';
    modal.style.maxHeight = '80vh';
    modal.style.overscrollBehavior = 'contain';
  }, []);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">
          {studentId}님의 강사 의견 조회
        </Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <CommentBox>
          {comments?.map((comment, index) => (
            <PBLComment
              key={index}
              author={comment?.createdBy}
              modifiedAt={comment?.modifiedAt}
              comment={comment?.comment}
              pblCommentId={comment?.id}
            />
          ))}
        </CommentBox>
      </Modal.Body>

      <Modal.Footer className="mb-1">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const CommentBox = styled.div`
  padding: 5px;
`;

export default PblCommentModal;
