import axios from 'axios';

import { BASE_URL } from './config';

/* PBL 유닛 조회 */
export const fetchPBLUnits = async ({ groupId }) => {
  return await axios.get(`${BASE_URL}/admin/pbl/${groupId}/units`);
};

/* PBL 문제 리스트 조회 */
export const fetchPBLProblems = async ({ unitId }) => {
  return await axios.get(`${BASE_URL}/admin/pbl/units/${unitId}/problems`);
};

/* PBL 문제 상세 조회 */
export const fetchPBLProblemDetail = async ({ unitId, problemId }) => {
  return await axios.get(
    `${BASE_URL}/admin/pbl/units/${unitId}/problems/${problemId}`
  );
};

/* PBL 문제 등록 */
export const createPBLNewProblem = async ({ unitId, formData }) => {
  return await axios.post(
    `${BASE_URL}/admin/pbl/units/${unitId}/problems`,
    formData
  );
};

/* PBL 문제 수정 */
export const editPBLProblem = async ({ problemId, formData }) => {
  return await axios.put(
    `${BASE_URL}/admin/pbl/problems/${problemId}`,
    formData
  );
};

/* PBL 문제 삭제 */
export const removePBLProblem = async ({ problemId }) => {
  return await axios.delete(`${BASE_URL}/admin/pbl/problems/${problemId}`);
};

/* PBL 학생 별 피드백 조회 */
export const fetchPBLComments = async ({ problemId, studentId }) => {
  return await axios.get(
    `${BASE_URL}/admin/pbl/comments/${problemId}/${studentId}`
  );
};

/* PBL 결과물 제출 학생 리스트 조회 */
export const fetchPBLResultsList = async ({ problemId, pageNum }) => {
  const params = { pageNum };

  return await axios.get(
    `${BASE_URL}/admin/pbl/problems/${problemId}/submitters`,
    {
      params,
    }
  );
};

/* PBL 유닛 노출 여부 변경 */
export const changePBLUnitVisible = async ({ unitId, pblVisible }) => {
  return await axios.put(`${BASE_URL}/admin/pbl/units/${unitId}/visible`, {
    pblVisible,
  });
};

/* PBL 출제 유닛 변경 */
export const changePBLUsedUnit = async ({ unitId, usePbl }) => {
  return await axios.put(`${BASE_URL}/admin/pbl/units/${unitId}`, {
    usePbl,
  });
};

/* PBL 초급 난이도 스킵 점수 조회 */
export const fetchPBLBasicScore = async ({ groupId }) => {
  const params = { groupId };

  return await axios.get(`${BASE_URL}/admin/pbl/skip-score`, {
    params,
  });
};

/* PBL 초급 난이도 스킵 점수 변경 */
export const changePBLBasicScore = async ({ groupId, skippableScore }) => {
  return await axios.put(`${BASE_URL}/admin/pbl/${groupId}/skip-score`, {
    skippableScore,
  });
};

/* PBL 문제에 제출된 모든 학생 제출물 일괄 다운로드 */
export const downloadAllSubmits = async ({ problemId }) => {
  return await axios.get(
    `${BASE_URL}/admin/pbl/problems/${problemId}/submissions`,
    {
      responseType: 'blob',
    }
  );
};

/* PBL 학생 제출물 단일 다운로드 */
export const downloadPersonalResult = async ({ problemId, userId }) => {
  const params = { userId };

  return await axios.get(
    `${BASE_URL}/admin/pbl/problems/${problemId}/submissions/single`,
    {
      params,
      responseType: 'blob',
    }
  );
};

/* PBL 문제 참고자료 다운로드 */
export const downloadAttachmentFiles = async ({ problemId, fileName }) => {
  const params = { fileName };

  return await axios.get(
    `${BASE_URL}/admin/pbl/problems/${problemId}/attachments`,
    {
      params,
      responseType: 'blob',
    }
  );
};

/* PBL 학생 제출물 반려 */
export const removeStudentResult = async ({ problemId, submitId }) => {
  return await axios.delete(
    `${BASE_URL}/admin/pbl/problems/${problemId}/${submitId}`
  );
};

/* PBL 해당 학생 강사 의견 삭제 */
export const removeComment = async ({ commentId }) => {
  return await axios.delete(`${BASE_URL}/admin/pbl/comments/${commentId}`);
};

/* PBL 모든 제출한 학생 리스트 통합 조회 */
export const fetchTotalSubmitter = async ({
  pageNum,
  groupId,
  searchValue,
}) => {
  const params = { pageNum, pageSize: 10, groupId, searchValue };

  return await axios.get(`${BASE_URL}/admin/pbl/submissions`, {
    params,
  });
};

/* 이미지 업로드 */
export const uploadContentImage = async (formData) => {
  return await axios.post(`${BASE_URL}/admin/pbl/problems/imgs`, formData);
};
