import { lazy, Suspense, useContext, useEffect } from 'react';
/// Components
import Index from './routes';
import { connect, useDispatch } from 'react-redux';
import { Route, Switch, useHistory, withRouter } from 'react-router-dom';

import { isAuthenticated } from './store/selectors/AuthSelectors';
import './styles/css/style.css';
import 'react-toastify/dist/ReactToastify.css';
import AuthProvider from './AuthProvider';
import Login from './pages/Login';
import AuthContext from './context/Auth.context';
import { logout } from './store/actions/AuthActions';
import { useVersionCheck } from './hooks';

function App(props) {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = localStorage.getItem('token');
  const auth = useContext(AuthContext);

  /* 해시 체크 후 새로운 버전이 확인되면 새로고침 */
  const { data: latestHash } = useVersionCheck();

  useEffect(() => {
    if (latestHash) {
      const currentHash = localStorage.getItem('app-hash');

      if (currentHash && currentHash !== latestHash.trim()) {
        window.location.reload();
        localStorage.setItem('app-hash', latestHash.trim());
      } else {
        localStorage.setItem('app-hash', latestHash.trim());
      }
    }
  }, [latestHash]);

  useEffect(() => {
    if (!token) {
      dispatch(logout(history));
    }
  }, [auth, token]);

  let routes = (
    <AuthProvider>
      <Switch>
        <Route path="/login" component={Login} />
      </Switch>
    </AuthProvider>
  );

  if (props.isAuthenticated) {
    return (
      <>
        <Suspense
          fallback={
            <div id="preloader">
              <div className="sk-three-bounce">
                <div className="sk-child sk-bounce1"></div>
                <div className="sk-child sk-bounce2"></div>
                <div className="sk-child sk-bounce3"></div>
              </div>
            </div>
          }
        >
          <AuthProvider>
            <Index />
          </AuthProvider>
        </Suspense>
      </>
    );
  } else {
    return (
      <div className="vh-100">
        <Suspense fallback={<FallBack />}>{routes}</Suspense>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: isAuthenticated(state),
  };
};

const FallBack = () => (
  <div id="preloader">
    <div className="sk-three-bounce">
      <div className="sk-child sk-bounce1"></div>
      <div className="sk-child sk-bounce2"></div>
      <div className="sk-child sk-bounce3"></div>
    </div>
  </div>
);

export default withRouter(connect(mapStateToProps)(App));
