import { BASE_URL } from './config';

import axios from 'axios';

/* 사용자 role 조회 */
export const fetchAllUserType = async () => {
  return await axios.get(`${BASE_URL}/admin/users/getAllUserType`);
};

/* 현재 모집 중인 기수 정보 조회 */
export const fetchRecruitClassList = async () => {
  return await axios.get(`${BASE_URL}/admin/class/getClassByRecruit`);
};

/* 기수 목록 조회(클래스 정보, 그룹 정보) */
export const fetchCardinalList = async () => {
  return await axios.get(`${BASE_URL}/admin/classes/groups`);
};
