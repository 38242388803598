import { useQuery } from 'react-query';

import { fetchUserAttendance } from 'services';

const useAttendances = ({ userId, classId, pageNum, pageSize, keyword }) => {
  const queryReturns = useQuery(
    ['useAttendances', { userId, pageNum, pageSize, keyword }],
    async () => {
      return await fetchUserAttendance({
        userId,
        classId,
        pageNum,
        pageSize,
        keyword,
      });
    },
    {
      select: (res) => res.data,
      enabled: !!userId,
      retry: false,
      keepPreviousData: true,
    }
  );

  return { ...queryReturns };
};

export default useAttendances;
