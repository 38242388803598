import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

import { useUsers } from 'hooks';
import { checkEmailValid } from 'services';
import { errorSwal, successSwal } from 'utilities';
import { ModalBadge, ValidationErrorMsg } from '../common';
import { EMAIL_REGEX, PHONE_REGEX, MESSAGES } from '../../constants';

const EditUserInfoModal = ({ editModal, setEditModal }) => {
  const { userId } = useParams();

  const { userInfo, editUserInfo } = useUsers({ userId });
  const { data: userInfoData } = userInfo || {};

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const emailValue = watch('email');

  const [isCheckedMail, setIsCheckedMail] = useState(true);
  const [isEmailCheckLoading, setIsEmailCheckLoading] = useState(false);

  const handleEmailCheck = async () => {
    setIsEmailCheckLoading(true);
    try {
      const { data } = await checkEmailValid({ email: emailValue });
      if (data.message === 'SUCCESS') {
        setIsCheckedMail(true);
        await successSwal({ text: MESSAGES.EMAIL_CONFIRM_SUCCESS });
      } else {
        setIsCheckedMail(false);
        await errorSwal({ text: MESSAGES.EMAIL_CONFIRM_FAIL });
      }
    } catch (error) {
      setIsCheckedMail(false);
      await errorSwal({ text: MESSAGES.ERROR_500 });
    }
    setIsEmailCheckLoading(false);
  };

  const editAccount = async (form) => {
    const body = {
      userId,
      email: form.email,
      phone: form.phone,
      name: form.name,
    };

    try {
      const response = await editUserInfo.mutateAsync({ body });
      if (response.status === 200) {
        setEditModal(false);
        setIsCheckedMail(false);
        await successSwal({ text: MESSAGES.USER_ACCOUNT_EDIT_SUCCESS });
      }
    } catch (error) {
      setIsCheckedMail(false);
      await errorSwal({ text: MESSAGES.USER_ACCOUNT_EDIT_FAIL });
    }
  };

  useEffect(() => {
    if (!editModal) return;

    const { name, email, phone } = userInfoData;

    reset({
      name,
      email,
      phone,
    });
  }, [editModal]);

  useEffect(() => {
    if (userInfoData?.email !== emailValue) {
      setIsCheckedMail(false);
    } else {
      setIsCheckedMail(true);
    }
  }, [emailValue]);

  return (
    <Modal className="fade bd-example-modal-lg" show={editModal} size="lg">
      <form onSubmit={handleSubmit(editAccount)}>
        <Modal.Header>
          <Modal.Title className="mt-5">회원정보 수정</Modal.Title>
          <Button
            variant=""
            className="close"
            onClick={() => setEditModal(false)}
          >
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="d-flex flex-row align-items-center mb-3">
            <ModalBadge required={false} text="사용자 ID" />
            <div className="col-10">{userId}</div>
          </div>

          <StyledInputBox>
            <ModalBadge text="이름" />
            <div>
              <input
                type="text"
                placeholder="사용자 이름을 입력하세요."
                className="form-control"
                name="name"
                {...register('name', { required: true })}
              />
              {errors.name && (
                <ValidationErrorMsg text="유저 이름을 입력해주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="이메일" />
            <div style={{ width: '100%' }} className="emailBox">
              <input
                type="text"
                placeholder="이메일을 입력하세요."
                className="form-control"
                name="email"
                {...register('email', {
                  required: true,
                  pattern: EMAIL_REGEX,
                })}
              />
              {errors.email?.type === 'required' && (
                <ValidationErrorMsg text="이메일을 입력해주세요." />
              )}
              {errors.email?.type === 'pattern' && (
                <ValidationErrorMsg text="이메일 형식이 올바르지 않습니다." />
              )}
            </div>
            <div className="button">
              {isCheckedMail ? (
                <button
                  disabled
                  type="button"
                  className="btn btn-primary"
                  style={{
                    backgroundColor: 'gray',
                    border: '1px solid gray',
                  }}
                >
                  확인완료
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleEmailCheck}
                  disabled={userInfoData?.email === emailValue}
                >
                  {isEmailCheckLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    '중복확인'
                  )}
                </button>
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="연락처" />
            <div>
              <input
                type="text"
                placeholder="전화번호를 입력하세요."
                className="form-control"
                name="phone"
                {...register('phone', {
                  required: true,
                  pattern: PHONE_REGEX,
                })}
              />
              {errors.phone?.type === 'required' && (
                <ValidationErrorMsg text="연락처를 입력해주세요." />
              )}
              {errors.phone?.type === 'pattern' && (
                <ValidationErrorMsg text="전화번호 형식이 올바르지 않습니다." />
              )}
            </div>
          </StyledInputBox>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-start">
            {isCheckedMail || userInfoData.name !== watch('name') ? (
              <button type="submit" className="btn btn-primary mr-3">
                업데이트
              </button>
            ) : (
              <button
                type="submit"
                disabled
                style={{ backgroundColor: 'gray', border: '1px solid gray' }}
                className="btn btn-primary mr-3"
              >
                업데이트
              </button>
            )}
            <button
              type="button"
              onClick={() => setEditModal(false)}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }

  > div:last-child,
  div.emailBox {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .button {
    flex: none !important;
  }
`;

export default EditUserInfoModal;
