import React from 'react';
import styled from 'styled-components';
import ReactTooltip from 'react-tooltip';

const ModalBadge = ({ text, required = true }) => {
  return (
    <StyledModalBadge>
      <p data-tip={required ? '필수값 입니다.' : ''}>
        {required && <span>*</span>}
        {text}
      </p>
      {required === true && <ReactTooltip place="bottom" />}
    </StyledModalBadge>
  );
};

const StyledModalBadge = styled.div`
  position: relative;
  display: grid;
  place-items: center;
  width: 100%;
  max-width: 100px;
  border-radius: 15px;
  color: #ec7422;
  background-color: #fdf4f8;
  font-size: 12px;
  font-weight: 600;

  p {
    position: relative;
    padding: 5px 0;
    line-height: 1;
    margin-bottom: 0;

    > span {
      position: absolute;
      font-size: 10px;
      left: -5px;
    }
  }
`;

export default ModalBadge;
