import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';

const Loading = ({ height = '500px', size = 50 }) => {
  return (
    <Wrapper $height={height}>
      <CircularProgress size={size} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  min-height: ${(p) => p.$height};

  svg {
    color: #ec7422;
  }
`;

export default Loading;
