import React, { useState, useEffect } from 'react';
import { Button, Dropdown, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useConnect, useSetConnect } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CardinalDropMenu, ModalBadge, ValidationErrorMsg } from '../common';

const CloudAccountEditModal = ({
  show,
  setShow,
  detailData,
  setDetailData,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [groupId, setGroupId] = useState('');

  const [userName, setUserName] = useState('');
  const [userId, setUserId] = useState('');

  const { editCloudAccount } = useSetConnect();
  const { userListByGroupId } = useConnect({ groupId });
  const { data: userList } = userListByGroupId || {};

  const handleUser = (data, id) => {
    setUserName(data);
    setUserId(id);
  };

  const submit = async (form) => {
    if (!groupId) {
      await errorSwal({ text: '과정을 선택해주세요.' });
      return;
    }

    if (userId === '') {
      await errorSwal({ text: '연결할 사용자를 선택해주세요.' });
      return;
    }

    const body = {
      username: form.username,
      password: form.password,
      url: form.url,
      accessKeyId: form.accessKeyId,
      clientSecret: form.clientSecret,
      userId,
    };

    try {
      const { status } = await editCloudAccount.mutateAsync({
        id: detailData.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        setDetailData({});
        await successSwal({ text: MESSAGES.CLOUD_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CLOUD_EDIT_FAIL });
    }
  };

  useEffect(() => {
    setUserName('');
    reset({ ...detailData });
  }, [detailData]);

  const closeModal = () => {
    setDetailData({});
    setShow(false);
  };

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">클라우드 정보 수정</Modal.Title>
          <Button variant="" className="close" onClick={closeModal}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <div className="form-row">
            <div
              style={{
                width: '100%',
                justifyContent: 'flex-start',
                gap: '10px',
                marginBottom: '10px',
              }}
              className="d-sm-flex"
            >
              {/* 과정 선택 */}
              <CardinalDropMenu
                classTitle={classTitle}
                setClassTitle={setClassTitle}
                setId={setGroupId}
                isGroupId
              />

              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  disabled={!groupId}
                >
                  {userName || '사용자 선택'}
                </Dropdown.Toggle>
                <Dropdown.Menu className="selectbox-scroll">
                  <Dropdown.Item
                    onClick={() => handleUser('없음', ' ')}
                    className="fs-12"
                  >
                    없음
                  </Dropdown.Item>
                  {userList?.map((list) => (
                    <Dropdown.Item
                      key={list.userId}
                      eventKey={list.userId}
                      onClick={() => handleUser(list.name, list.userId)}
                      className="fs-12"
                    >
                      {list.name} : {list.userId}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="유저 네임" />
              <div>
                <input
                  type="text"
                  placeholder="유저 네임을 입력하세요."
                  className="form-control"
                  name="username"
                  {...register('username', {
                    required: true,
                  })}
                />
                {errors.username && (
                  <ValidationErrorMsg text="유저 네임을 입력해 주세요." />
                )}
              </div>
            </div>

            <div>
              <ModalBadge text="암호" />
              <div>
                <input
                  type="text"
                  placeholder="암호를 입력하세요."
                  className="form-control"
                  name="password"
                  {...register('password', {
                    required: true,
                  })}
                />
                {errors.password && (
                  <ValidationErrorMsg text="암호를 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="링크" />
              <div>
                <input
                  type="text"
                  placeholder="링크를 입력하세요."
                  className="form-control"
                  name="url"
                  {...register('url', {
                    required: true,
                  })}
                />
                {errors.url && (
                  <ValidationErrorMsg text="링크를 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <StyledInputWrapper>
            <div>
              <ModalBadge text="accessKey" />
              <div>
                <input
                  type="text"
                  placeholder="accessKey를 입력하세요."
                  className="form-control"
                  name="accessKeyId"
                  {...register('accessKeyId', {
                    required: true,
                  })}
                />
                {errors.accessKeyId && (
                  <ValidationErrorMsg text="accessKey를 입력해 주세요." />
                )}
              </div>
            </div>

            <div>
              <ModalBadge text="clientSecret" />
              <div>
                <input
                  type="text"
                  placeholder="clientSecret을 입력하세요."
                  className="form-control"
                  name="clientSecret"
                  {...register('clientSecret', {
                    required: true,
                  })}
                />
                {errors.clientSecret && (
                  <ValidationErrorMsg text="clientSecret을 입력해 주세요." />
                )}
              </div>
            </div>
          </StyledInputWrapper>

          <div className="d-flex flex-row align-items-center mb-3">
            <ModalBadge required={false} text="연결된 사용자" />
            <div className="col-10">
              {detailData.userId ? (
                <>
                  {detailData.userId}
                  {' / '}
                  {detailData.name}
                  {' / '}
                  {
                    detailData.groupCourse?.split(' ')[
                      detailData.groupCourse?.split(' ').length - 1
                    ]
                  }
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <div className="d-flex justify-content-start">
            <button type="submit" className="btn btn-primary mr-3">
              업데이트
            </button>
            <button
              type="button"
              onClick={closeModal}
              className="btn btn-secondary"
            >
              취소
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }

      &.not-column {
        flex-direction: row;
        span {
          margin-right: 3px;
        }
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default CloudAccountEditModal;
