export const pageTitles = {
  '': '사이트맵',
  dashboard: '사이트맵',
  login: 'Dashboard',

  'user-list': '학습자﹒교강사관리',
  'user-view': '회원관리 상세',

  'class-list': '클래스 목록',
  'class-upload': '클래스 생성',
  'category-list': '카테고리 목록',
  'category-upload': '카테고리 생성',
  'unit-list': '유닛 목록',
  'unit-upload': '유닛 생성',
  'chapter-list': '챕터 목록',
  'chapter-upload': '챕터 생성',
  'class-video': '강의 목록',
  'class-video-upload': '강의 생성',
  'upload-link': 'Gather링크 등록/관리',
  'upload-cloud': '클라우드 등록/관리',

  notice: '공지사항',
  library: '학습자료',
  task: '과제 목록',
  'task-view': '과제 상세',
  question: '강의질문',
  'pbl-category': 'PBL 단원 관리',
  'pbl-problem': 'PBL 문제 관리',
  'pbl-result': 'PBL 결과물 관리',
  'pbl-problem-edit': 'PBL 문제 관리',
  'pbl-problem-create': 'PBL 문제 생성',
  'class-board': '클래스 게시글 상세',
  'class-board-write': '클래스 게시글 등록',
  'class-board-edit': '클래스 게시글 수정',

  exam: '시험 목록',
  'exam-detail': '시험 상세',
  'exam-problem': '시험 문제',
  'exam-problem-detail': '시험 문제 상세',
  'exam-problem-edit': '시험 문제 수정',
  'exam-result': '시험 결과',
  'exam-result-detail': '시험 결과 상세',
  'exam-create': '시험 생성',
  'exam-graph': '통계 그래프',

  'support-library': '공통 자료실',
  faq: 'FAQ',
  story: '우리들이야기',
  contact: '문의하기',
  consulting: '상담 관리',
  'support-board': '학습지원 게시글 상세',
  'support-board-write': '학습지원 게시글 등록',
  'support-board-edit': '학습지원 게시글 수정',

  'study-group': '스터디그룹 목록',
  'study-group-detail': '스터디그룹 상세',
  'study-board-detail': '스터디그룹 게시판 상세',
  'study-room': '스터디룸 예약 승인',

  'knowledge-content': '지식컨텐츠 목록',
  'knowledge-detail': '지식컨텐츠 상세',
  'knowledge-edit': '지식컨텐츠 수정',
  'knowledge-create': '사전강의 등록',

  analysis: '문항별 분석',
  'view-preLearning': '사전강의 상세',
  'preLearning-List': '사전강의 리스트',
  'classInfo-list': '모든 강의 목록',
  'classInfo-Upload': '강의 등록',

  'page-login': '로그인',
  'page-error-400': '400 Error',
  'page-error-403': '403 Error',
  'page-error-404': '404 Error',
  'page-error-500': '500 Error',
  'page-error-503': '503 Error',
};
