/* eslint-disable */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import { Badge, Dropdown } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';

import {
  useClass,
  useClassVideo,
  useDebounce,
  useGetQueryString,
  useSetClassVideo,
  useSetParameterOnQuery,
} from 'hooks';
import { confirmSwal, errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CLASS_INFO_COLUMNS } from 'components/Board/Columns';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import {
  SearchInput,
  Pagination,
  BoardWriteButton,
  CardinalDropMenu,
} from 'components/common';
import { VideoDetailModal, VideoEditModal } from 'components/ProcessManage';

const ClassInfoList = () => {
  const queryValues = useGetQueryString();

  const [editModal, setEditModal] = useState(false);
  const [detailModal, setDetailModal] = useState(false);
  const [detailData, setDetailData] = useState({});

  const [classTitle, setClassTitle] = useState('과정 선택');
  const [classCategoryName, setClassCategoryName] = useState('');
  const [unitName, setUnitName] = useState('');
  const [chapterName, setChapterName] = useState('');

  const [classId, setClassId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setClassTitle('과정 선택');
      setClassId('');
      setCategoryId('');
      setUnitId('');
      setChapterId('');
    }
  }, [queryValues]);

  // const [basicModal, setBasicModal] = useState(false);
  // const [classInfoZoomData, setClassInfoZoomData] = useState({
  //   id: '',
  //   zoomId: '',
  //   zoomYn: '',
  //   zoomUrl: '',
  // });

  const { deleteClassVideo } = useSetClassVideo();
  const { classUnitListByCategoryId } = useClass({ categoryId });
  const { classAllVideo, classCategory, classChapter } = useClassVideo({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    classId,
    categoryId,
    unitId,
    chapterId,
  });
  const { data } = classAllVideo || {};
  const { data: categoryList } = classCategory || {};
  const { data: unitList } = classUnitListByCategoryId || {};
  const { data: chapterList } = classChapter || {};

  //Table Setting
  const columns = useMemo(() => CLASS_INFO_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  const handleDelete = async ({ videoId }) => {
    const confirm = await confirmSwal({
      title: '해당 강의를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteClassVideo.mutateAsync({ videoId });
        if (status === 200) {
          await successSwal({ text: MESSAGES.VIDEO_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.VIDEO_DELETE_FAIL });
      }
    }
  };

  /* 카테고리 선택 핸들러 */
  const handleCategoryName = (categoryName, id) => {
    setClassCategoryName(categoryName);
    setCategoryId(id);
    setUnitName('');
  };

  /* 유닛 선택 핸들러 */
  const handleUnitName = (data, id) => {
    setUnitName(data);
    setUnitId(id);
    setChapterName('');
  };

  /* 챕터 선택 핸들러 */
  const handleChapterName = (data, id) => {
    setChapterName(data);
    setChapterId(id);
  };

  const handleGoDetail = (data, i) => {
    if (i !== 6) {
      setDetailModal(true);
      setDetailData({ ...data });
    }
  };

  const handleOpenEdit = (data) => {
    setEditModal(true);
    setDetailData({ ...data });
  };

  /* 전체 과정 선택 시 모든 옵션 초기화 */
  useEffect(() => {
    if (!classId) {
      setClassCategoryName('');
      setCategoryId('');
      setUnitName('');
      setUnitId('');
      setChapterName('');
      setChapterId('');
    }
  }, [classId]);

  useEffect(() => {
    classAllVideo.refetch();
  }, [editModal]);

  // const handleOpenZoomSet = (data) => {
  //   setBasicModal(true);
  //   setClassInfoZoomData({
  //     id: data.row.original.id,
  //     zoomId: data.row.original.zoomId,
  //     zoomYn: data.row.original.zoomYn,
  //     zoomUrl: data.row.original.zoomUrl,
  //   });
  // };

  return (
    <>
      <Wrapper>
        <CardHeader>
          <div
            style={{ width: '100%' }}
            className="d-flex justify-content-between"
          >
            <div style={{ display: 'flex' }}>
              {/* 과정 선택 */}
              <CardinalDropMenu
                classTitle={classTitle}
                setClassTitle={setClassTitle}
                setId={setClassId}
              />

              {/* 카테고리 선택 */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  className="mr-2 ml-2 height-38px"
                  disabled={!classId}
                >
                  {classCategoryName || '카테고리 선택'}
                </Dropdown.Toggle>
                {
                  <Dropdown.Menu>
                    <Dropdown.Item
                      onClick={() => handleCategoryName('', '')}
                      className="fs-12"
                    >
                      전체
                    </Dropdown.Item>
                    {categoryList?.map((list) => (
                      <Dropdown.Item
                        key={list.id}
                        eventKey={list.id + 1}
                        onClick={() =>
                          handleCategoryName(list.classCategoryName, list.id)
                        }
                        className="fs-12"
                      >
                        {list.classCategoryName}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                }
              </Dropdown>

              {/* 유닛 선택 */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  className="mr-2 ml-2 height-38px"
                  disabled={!categoryId}
                >
                  {unitName || '유닛 선택'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleUnitName('', '')}
                    className="fs-12"
                  >
                    전체
                  </Dropdown.Item>
                  {unitList?.map((list) => (
                    <Dropdown.Item
                      key={list.id}
                      eventKey={list.id + 1}
                      onClick={() => handleUnitName(list.unitName, list.id)}
                      className="fs-12"
                    >
                      {list.unitName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>

              {/* 챕터 선택 */}
              <Dropdown>
                <Dropdown.Toggle
                  variant="outline-primary"
                  size="md"
                  className="mr-2 ml-2 height-38px"
                  disabled={!unitName}
                >
                  {chapterName || '챕터 선택'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    onClick={() => handleChapterName('', '')}
                    className="fs-12"
                  >
                    전체
                  </Dropdown.Item>
                  {chapterList?.map((list) => (
                    <Dropdown.Item
                      key={list.chapterId}
                      eventKey={list.chapterId + 1}
                      onClick={() => {
                        handleChapterName(list.chapterName, list.chapterId);
                      }}
                      className="fs-12"
                    >
                      {list.chapterName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </div>

            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </CardHeader>

        <CardBody>
          <div className="table-responsive">
            <table
              {...getTableProps()}
              className="table filtering-table table-responsive-lg"
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th {...column.getHeaderProps()}>
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody className="" {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, i) => {
                        return (
                          <Fragment key={i}>
                            <td
                              {...cell.getCellProps()}
                              onClick={() =>
                                handleGoDetail(cell.row.original, i)
                              }
                              className="cursor-pointer"
                            >
                              {i === 0 ? (
                                <div data-tip={cell.row.original.className}>
                                  {cell.row.original.className}
                                </div>
                              ) : i === 5 ? (
                                <>
                                  <Badge
                                    variant={
                                      cell.row.original.videoName
                                        ? 'success badge-sm mr-2'
                                        : 'danger badge-sm mr-2'
                                    }
                                  >
                                    {cell.row.original.videoName ? 'On' : 'Off'}
                                  </Badge>
                                </>
                              ) : i === 6 ? (
                                <>
                                  <ReactTooltip place="bottom" />
                                  <i
                                    className="far fa-trash-alt more-btn-icon-box mr-1"
                                    data-tip="삭제하기"
                                    onClick={() =>
                                      handleDelete({
                                        videoId: cell.row.original.id,
                                      })
                                    }
                                  />

                                  <i
                                    className="fa-solid fa-pen more-btn-icon-box-info mr-1 cursor-pointer"
                                    onClick={() => handleOpenEdit(row.original)}
                                    data-tip="수정하기"
                                  />
                                </>
                              ) : (
                                cell.render('Cell')
                              )}
                            </td>
                          </Fragment>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>

            {page.length === 0 && (
              <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
                검색 결과가 없습니다.
              </div>
            )}

            <BoardWriteButton to="/class-video-upload" text="강의 등록" />

            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </CardBody>
      </Wrapper>

      {/*/!* 줌링크 모달 *!/*/}
      {/*<ClassZoomLinkModal*/}
      {/*  setBasicModal={setBasicModal}*/}
      {/*  basicModal={basicModal}*/}
      {/*  classInfoZoomData={classInfoZoomData}*/}
      {/*/>*/}

      {/* 강의 상세 보기 모달 */}
      <VideoDetailModal
        show={detailModal}
        setShow={setDetailModal}
        detailData={detailData}
      />

      {/* 강의 수정 모달 */}
      <VideoEditModal
        show={editModal}
        setShow={setEditModal}
        detailData={detailData}
      />
    </>
  );
};
export default ClassInfoList;
