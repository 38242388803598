import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';

import { useSetClass } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { ModalBadge, ValidationErrorMsg } from '../common';

const CategoryEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { editClassCategory } = useSetClass();

  const submit = async (form) => {
    const body = {
      categoryName: form.categoryName,
      level: form.level,
      groupId: detailData.groupId,
    };

    try {
      const { status } = await editClassCategory.mutateAsync({
        id: detailData?.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.CATEGORY_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CATEGORY_EDIT_FAIL });
    }
  };

  useEffect(() => {
    reset({
      ...detailData,
    });
  }, [detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">카테고리 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputBox>
            <ModalBadge text="카테고리명" />
            <div>
              <input
                type="text"
                placeholder="카테고리명을 입력하세요."
                className="form-control"
                name="categoryName"
                {...register('categoryName', {
                  required: true,
                })}
              />
              {errors.categoryName && (
                <ValidationErrorMsg text="카테고리명을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="레벨" />
            <div>
              <input
                type="number"
                min={0}
                placeholder="레벨을 입력하세요."
                className="form-control"
                name="level"
                {...register('level', {
                  required: true,
                })}
              />
              {errors.level && (
                <ValidationErrorMsg text="총 교육 시간을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export default CategoryEditModal;
