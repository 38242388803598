import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dropdown, Badge } from 'react-bootstrap';
import { DatePicker } from '@y0c/react-datepicker';
import { useForm } from 'react-hook-form';

import CustomSwitchNoConfirm from '../../components/ui/CustomSwitchNoConfirm';

import {
  useClassVideo,
  useConnect,
  useFetchCardinalList,
  useSetClassVideo,
} from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { CardBody, CardHeader, Wrapper } from 'components/layouts';
import { ValidationErrorMsg } from 'components/common';
import { format } from 'date-fns';

const ClassInfoUpload = () => {
  let history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const [className, setClassName] = useState('클래스를 선택하세요.');
  const [categoryName, setCategoryName] = useState('먼저 강의를 선택하세요.');
  const [unitName, setUnitName] = useState('먼저 카테고리를 선택하세요.');
  const [chapterName, setChapterName] = useState('먼저 유닛을 선택하세요.');
  const [zoomName, setZoomName] = useState('Gather 링크를 선택하세요.');
  const [zoomId, setZoomId] = useState('');
  const [zoomUse, setZoomUse] = useState(false);
  const [isChange, setIsChange] = useState(false);

  const [classId, setClassId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [unitId, setUnitId] = useState('');
  const [chapterId, setChapterId] = useState('');
  const [chapterDate, setChapterDate] = useState('');

  const { data: classList } = useFetchCardinalList();
  const { addVideo } = useSetClassVideo();

  const { classCategory, classUnit, classChapter } = useClassVideo({
    classId,
    categoryId,
    unitId,
  });
  const { data: categoryList } = classCategory || {};
  const { data: unitList } = classUnit || {};
  const { data: chapterList } = classChapter || {};

  const { remoteClassList } = useConnect({ type: 'link' });
  const { data: zoomUrlList } = remoteClassList || {};

  const submit = async (form) => {
    const body = {
      chapterContent: form.chapterContent,
      chapterDate,
      classId,
      classCategoryId: categoryId,
      unitId,
      chapterId,
      videoName: form.videoName,
      zoomId,
      zoomYn: zoomUse,
    };

    try {
      const response = await addVideo.mutateAsync({ body });
      if (response.status === 201) {
        history.push('/class-video');
        await successSwal({ text: MESSAGES.VIDEO_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.VIDEO_CREATE_FAIL });
    }
  };

  const onChange = (date) => {
    const chaDate = date.$d;
    const chaDateStr = format(new Date(chaDate), 'yyyy-MM-dd');

    setChapterDate(chaDateStr);
  };

  const handleBack = () => {
    history.goBack();
  };

  // 클래스 선택
  const handleClassName = async (data, id) => {
    setClassName(data);
    setCategoryName('카테고리를 선택하세요.');
    setClassId(id);
  };

  // 카테고리 선택
  const handleCategoryName = async (data, id) => {
    setCategoryName(data);
    setUnitName('유닛을 선택하세요.');
    setCategoryId(id);
  };

  // 유닛 선택 핸들러
  const handleUnitName = (data, id) => {
    setUnitName(data);
    setChapterName('챕터를 선택해주세요.');
    setUnitId(id);
  };

  const handleChapterName = (data, id) => {
    setChapterName(data);
    setChapterId(id);
  };

  const handleZoomConfirm = () => {
    setZoomUse(!zoomUse);
  };

  const handleZoomLink = (data, id) => {
    setZoomName(data);
    setZoomId(id);
  };

  return (
    <Wrapper>
      <CardHeader justifyContent="flex-end">
        <div className="d-sm-flex">
          <div onClick={handleBack} className="btn btn-primary px-3 light">
            <i className="fa fa-reply"></i>
          </div>
          <div
            onClick={() => {
              reset();
              setClassName('클래스를 선택하세요');
              setCategoryName('강의를 먼저 선택하세요');
              setUnitName('카테고리를 먼저 선택하세요');
              setChapterName('유닛을 먼저 선택하세요');
              setIsChange(!isChange);
            }}
            className="btn btn-primary px-3 light ml-2"
          >
            <i className="fas fa-sync-alt"></i>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="basic-form">
          <form onSubmit={handleSubmit(submit)}>
            <div className="form-row">
              <div className="form-group d-flex ">
                {/* 클래스 드롭다운 */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="md"
                    className="mr-2 ml-2"
                  >
                    {className}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {classList?.map((list) => (
                      <Dropdown.Item
                        key={list.classId}
                        eventKey={list.classId + 1}
                        onClick={() =>
                          handleClassName(list.classTitle, list.classId)
                        }
                        className="fs-12"
                      >
                        {list.classTitle}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {errors.classId && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.classId}
                  </div>
                )}

                {/* 카테고리 드롭다운 */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="md"
                    className="mr-2 ml-2"
                  >
                    {categoryName}
                  </Dropdown.Toggle>
                  {
                    <Dropdown.Menu>
                      {categoryList?.map((list) => (
                        <Dropdown.Item
                          key={list.id}
                          eventKey={list.id + 1}
                          onClick={() =>
                            handleCategoryName(list.classCategoryName, list.id)
                          }
                          className="fs-12"
                        >
                          {list.classCategoryName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  }
                </Dropdown>
                {errors.classCategoryId && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.classCategoryId}
                  </div>
                )}

                {/* 유닛 드롭다운 */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="md"
                    className="mr-2 ml-2"
                  >
                    {unitName}
                  </Dropdown.Toggle>
                  {
                    <Dropdown.Menu>
                      {unitList?.map((list) => (
                        <Dropdown.Item
                          key={list.id}
                          eventKey={list.id + 1}
                          onClick={() => handleUnitName(list.unitName, list.id)}
                          className="fs-12"
                        >
                          {list.unitName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  }
                </Dropdown>
                {errors.unitId && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.unitId}
                  </div>
                )}

                {/* 챕터 드롭다운 */}
                <Dropdown>
                  <Dropdown.Toggle
                    variant="outline-primary"
                    size="md"
                    className="mr-2 ml-2"
                  >
                    {chapterName}
                  </Dropdown.Toggle>
                  {
                    <Dropdown.Menu>
                      {chapterList?.map((list) => (
                        <Dropdown.Item
                          key={list.chapterId}
                          eventKey={list.chapterId + 1}
                          onClick={() =>
                            handleChapterName(list.chapterName, list.chapterId)
                          }
                          className="fs-12"
                        >
                          {list.chapterName}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  }
                </Dropdown>
                {errors.unitId && (
                  <div className="text-danger fs-12 ml-3 mt-3">
                    {errors.unitId}
                  </div>
                )}
              </div>
            </div>

            <div className="form-row">
              <div className="form-group col-md-12">
                <textarea
                  className="form-control"
                  rows="20"
                  id="comment"
                  placeholder="강의 내용을 입력하세요."
                  name="chapterContent"
                  {...register('chapterContent', {
                    required: true,
                  })}
                ></textarea>
                {errors.chapterContent && (
                  <ValidationErrorMsg text="강의 내용을 입력해 주세요." />
                )}
              </div>
            </div>

            <div className="form-row mt-2" style={{ display: 'block' }}>
              <div className="form-group d-flex align-items-center">
                <Badge variant="primary badge-sm light mr-2 ml-2">
                  Video Name
                </Badge>
                <div style={{ width: '100%' }}>
                  <input
                    type="text"
                    placeholder="강의 이름을 입력하세요. 쉼표(,)로 구분해주세요. ex) trx121g66a, sd467df"
                    className="form-control"
                    name="videoName"
                    {...register('videoName')}
                  />
                </div>
              </div>
            </div>

            <div className="form-row mt-2">
              <div className="form-group d-flex align-items-center mr-5">
                <Badge variant="primary badge-sm light mr-2 ml-2">
                  GATHER USE
                </Badge>
                <CustomSwitchNoConfirm
                  isTrue={zoomUse}
                  handleToggle={handleZoomConfirm}
                />
              </div>
            </div>

            {zoomUse && (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="outline-primary"
                      size="md"
                      className="zoom-drop-menu mr-3"
                    >
                      {zoomName}
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="zoom-drop-menu">
                      {zoomUrlList?.map((zoom) => (
                        <Dropdown.Item
                          key={zoom.id}
                          eventKey={zoom.id + 1}
                          onClick={() => handleZoomLink(zoom.zoomUrl, zoom.id)}
                          className="fs-12"
                        >
                          {zoom.zoomUrl}
                        </Dropdown.Item>
                      ))}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            )}

            <div className="form-row">
              <div className="form-group col-md-6">
                <DatePicker
                  initialDate={chapterDate}
                  placeholder="챕터일자"
                  onChange={onChange}
                />
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-start">
              <button type="submit" className="btn btn-primary mt-3 mr-3">
                등록
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default ClassInfoUpload;
