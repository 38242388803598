import React from 'react';
import styled from 'styled-components';

const Wrapper = ({ children }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  margin: 1.875rem 0;
  background-color: #fff;
  transition: all 0.5s ease-in-out;
  position: relative;
  border: 0 solid transparent;
  box-shadow: 0 0 10px 5px rgba(62, 73, 84, 0.08);
  height: calc(100% - 30px);
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-clip: border-box;
  border-radius: 15px;
`;

export default Wrapper;
