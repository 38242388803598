import { BASE_URL } from './config';

import axios from 'axios';

/* 학습자, 교강사 목록 조회 */
export const fetchUserList = async ({
  pageNum,
  pageSize,
  keyword,
  type,
  role,
  classId,
}) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
    type,
    role,
    classId,
  };

  return await axios.get(`${BASE_URL}/admin/users`, { params });
};

/* 사용자 계정 삭제 */
export const removeUserAccount = async ({ userId }) => {
  return await axios.delete(`${BASE_URL}/admin/users/delete/${userId}`);
};

/* 사용자 계정 Expired 변경 */
export const changeUserExpired = async ({ userId, yn }) => {
  return await axios.put(
    `${BASE_URL}/admin/users/confirmExpire/${userId}/${yn}`,
    {}
  );
};

/* 사용자 계정 Status 변경 */
export const changeUserStatus = async ({ userId, status }) => {
  return await axios.put(
    `${BASE_URL}/admin/users/confirmStatus/${userId}/${status}`,
    {}
  );
};

/* 엑셀 파일을 통해 유저 계정 대량 생성 */
export const registerUsersByExcel = async ({ formData }) => {
  return await axios.post(`${BASE_URL}/admin/users/batch`, formData);
};

/* 유저 단일 등록 시 이메일 중복 확인 */
export const checkEmailValid = async ({ email }) => {
  return await axios.get(`${BASE_URL}/admin/users/confirm/${email}`);
};

/* 클래스 아이디로 그룹아이디 목록 조회 */
export const fetchGroupIdByClassId = async ({ classId }) => {
  return await axios.get(
    `${BASE_URL}/admin/class/getGroupIdByClassId/${classId}`
  );
};

/* 단일 유저 계정 생성 */
export const createUserAccount = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/users`, { ...body });
};

/* userId로 유저 정보 조회 */
export const fetchUserInfoById = async ({ userId }) => {
  return await axios.get(`${BASE_URL}/admin/users/info/${userId}`);
};

/* 유저 정보 수정 */
export const changeUserInfo = async ({ body }) => {
  return await axios.put(`${BASE_URL}/admin/users/info`, { ...body });
};

/* 유저 출결 정보 조회 */
export const fetchUserAttendance = async ({
  classId,
  userId,
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = {
    classId,
    userId,
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/users/attendance`, { params });
};

/* 유저 출결 정보 수정 */
export const changeUserAttendance = async ({ body }) => {
  return await axios.put(`${BASE_URL}/admin/users/attendance`, { ...body });
};

/* 학생 패스워드 초기화 */
export const resetUserPassword = async ({ userId }) => {
  return await axios.put(
    `${BASE_URL}/admin/users/${userId}/password/reset`,
    {}
  );
};

/* 사용자 로그인 정보 조회 (대시보드) */
export const fetchAdminLoginInfo = async () => {
  return await axios.get(`${BASE_URL}/admin/users/api/v1/current/recent-login`);
};
