import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  createClassBoard,
  fetchClassDetail,
  fetchBoardCategories,
  fetchClassBoardList,
  fetchClassCategories,
  fetchClassAttachments,
  deleteClassBoard,
  fetchClassReply,
  createClassReply,
  deleteClassReply,
  editClassReply,
  editClassBoard,
} from '../../services';

const useClassBoard = ({
  pageNum,
  pageSize,
  keyword,
  classId,
  category,
  postId,
}) => {
  const queryClient = useQueryClient();

  const boardCategory = useQuery({
    queryKey: ['fetchBoardCategories'],
    queryFn: async () => {
      const response = await fetchBoardCategories();
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
  });

  const classCategory = useQuery({
    queryKey: ['fetchClassCategories'],
    queryFn: async () => {
      const response = await fetchClassCategories();
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
  });

  const boardList = useQuery({
    queryKey: [
      'fetchClassBoardList',
      { pageNum, pageSize, keyword, classId, category },
    ],
    queryFn: async () => {
      const response = await fetchClassBoardList({
        pageNum,
        pageSize,
        keyword,
        classId,
        category,
      });
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
    keepPreviousData: true,
  });

  const createBoard = useMutation({
    mutationFn: async ({ formData }) => {
      return await createClassBoard({ formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassBoardList', { pageNum, pageSize }],
      }),
  });

  const editPost = useMutation({
    mutationFn: async ({ formData }) => {
      return await editClassBoard({ postId, formData });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassDetail', { postId }],
      }),
  });

  const deleteBoard = useMutation({
    mutationFn: async ({ postId }) => {
      return await deleteClassBoard({ postId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassBoardList', { pageNum, pageSize }],
      }),
  });

  const boardDetail = useQuery({
    queryKey: ['fetchClassDetail', { postId }],
    queryFn: async () => {
      const response = await fetchClassDetail({ postId });
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
    enabled: !!postId,
  });

  const boardAttachments = useQuery({
    queryKey: ['fetchClassAttachments'],
    queryFn: async () => {
      const response = await fetchClassAttachments({ postId });
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
    enabled: !!postId,
  });

  const boardReply = useQuery({
    queryKey: ['fetchClassReply', { postId }],
    queryFn: async () => {
      const response = await fetchClassReply({ postId });
      return response.data.data;
    },
    retry: 2,
    retryDelay: 2000,
    enabled: !!postId,
  });

  const addReply = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassReply({ body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassReply', { postId }],
      }),
  });

  const deleteReply = useMutation({
    mutationFn: async ({ commentId }) => {
      return await deleteClassReply({ commentId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassReply', { postId }],
      }),
  });

  const editReply = useMutation({
    mutationFn: async ({ commentId, body }) => {
      return await editClassReply({ commentId, body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassReply', { postId }],
      }),
  });

  return {
    boardCategory,
    classCategory,

    boardList,
    createBoard,
    editPost,
    deleteBoard,
    boardDetail,
    boardAttachments,
    boardReply,

    addReply,
    deleteReply,
    editReply,
  };
};

export default useClassBoard;
