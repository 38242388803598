import { useMutation, useQueryClient } from 'react-query';

import {
  changeCloudAccount,
  changeRemoteClass,
  changeGatherLinkUse,
  createCloudAccount,
  createRemoteClass,
  removeCloudAccount,
  removeRemoteClass,
} from 'services';

const useSetConnect = () => {
  const queryClient = useQueryClient();

  const addRemoteClass = useMutation({
    mutationFn: async ({ body }) => {
      return await createRemoteClass({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchRemoteClassList'],
      }),
  });

  const deleteRemoteClass = useMutation({
    mutationFn: async ({ id }) => {
      return await removeRemoteClass({ id });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchRemoteClassList'],
      }),
  });

  const editRemoteClass = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeRemoteClass({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchRemoteClassList'],
      }),
  });

  const editRemoteClassUse = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeGatherLinkUse({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchRemoteClassList'],
      }),
  });

  const addCloudAccount = useMutation({
    mutationFn: async ({ body }) => {
      return await createCloudAccount({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchCloudAccountList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const editCloudAccount = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeCloudAccount({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchCloudAccountList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const deleteCloudAccount = useMutation({
    mutationFn: async ({ id }) => {
      return await removeCloudAccount({ id });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchCloudAccountList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    addRemoteClass,
    deleteRemoteClass,
    editRemoteClass,
    editRemoteClassUse,

    addCloudAccount,
    editCloudAccount,
    deleteCloudAccount,
  };
};

export default useSetConnect;
