import React from 'react';
import { useHistory } from 'react-router-dom';
import '@toast-ui/editor/dist/toastui-editor.css';

import { UserSection } from 'components/common';
import { ResultTable } from 'components/Exam';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';

const ExamResultsDetail = ({ location }) => {
  let history = useHistory();

  const ansArr = location.state.data.examSubmitAnswer
    .substr(1, location.state.data.examSubmitAnswer.length - 2)
    .split(', ');
  const resArr = location.state.data.examSubmitResult.split('');
  const tableNum = Math.ceil(ansArr.length / 10);

  const handleBack = () => {
    history.goBack();
  };

  return (
    <Wrapper>
      <CardHeader justifyContent="space-between">
        <UserSection
          dataA={location.state.data.userName}
          dataB={location.state.data.userId}
          dataC={location.state.data.groupName}
          dataD={location.state.data.examScoreResult}
          dataE={location.state.data.examHighLevelCount}
          dataF={location.state.data.examMiddleLevelCount}
          dataG={location.state.data.examLowLevelCount}
          dataH={location.state.data.examName}
        />
        <div className="d-sm-flex">
          <div onClick={handleBack} className="btn btn-primary px-3 light">
            <i className="fa fa-reply"></i>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="read-content">
              <div className="media mb-2 mt-3 mr-2">
                <div
                  className="media-body"
                  style={{
                    display: 'flex',
                    flexWrap: 'nowrap',
                    justifyContent: 'space-between',
                  }}
                >
                  <table className="table exam-result">
                    <tbody style={{ display: 'flex', flexWrap: 'wrap' }}>
                      <ResultTable
                        tableNum={tableNum}
                        resArr={resArr}
                        ansArr={ansArr}
                      />
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default ExamResultsDetail;
