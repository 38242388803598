import { createContext } from "react";

export const SetAuthContext = createContext(() => {});

const AuthContext = createContext({
  auth: false,
  userId: "",
  userPhone: "",
  name: "",
  type: "",
  email: "",
  phone: "",
  status: "",
  token: "",
  isOauth: "",
  authToken: "",
  freeYn: "",
  classId: "",
  subClassId: "",
  onLogin: () => {},
  onLogout: () => {},
});

export default AuthContext;

