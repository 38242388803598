import React, { useState } from 'react';

import { useSetConnect } from 'hooks';
import { CardBody, Wrapper } from '../layouts/common';
import { ModalBadge, ValidationErrorMsg } from '../common';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import styled from 'styled-components';

const UploadGatherLink = () => {
  const [zoomUrl, setZoomUrl] = useState('');
  const [error, setError] = useState(false);

  const { addRemoteClass } = useSetConnect();

  const handleOnChange = (e) => {
    const { value } = e.target;
    setZoomUrl(value);
  };

  const addZoom = async (e) => {
    e.preventDefault();

    if (!zoomUrl) {
      setError(true);
      return;
    }

    try {
      const body = {
        zoomUrl,
      };

      const { status } = await addRemoteClass.mutateAsync({ body });
      if (status === 201) {
        setZoomUrl('');
        await successSwal({ text: MESSAGES.LINK_CREATE_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.LINK_CREATE_FAIL });
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="basic-form">
          <form onSubmit={addZoom}>
            <StyledInputWrapper>
              <div>
                <ModalBadge text="링크" />
                <div>
                  <input
                    type="text"
                    placeholder="원격 수업 링크를 입력하세요. ex) https://sslc.kr"
                    value={zoomUrl}
                    className="form-control height-38px"
                    name="zoomUrl"
                    onChange={handleOnChange}
                  />
                  {error && (
                    <ValidationErrorMsg text="ZoomUrl을 입력해주세요." />
                  )}
                </div>
              </div>
            </StyledInputWrapper>

            <div className="form-row">
              <div className="form-group col-md-12"></div>
              {error && <ValidationErrorMsg text="ZoomUrl을 입력해주세요." />}
            </div>
            <div className="d-flex justify-content-end">
              <button type="submit" className="btn btn-primary">
                원격 수업 링크 등록
              </button>
            </div>
          </form>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;

      span {
        margin-left: 5px;
      }
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default UploadGatherLink;
