import { useMutation, useQueryClient } from 'react-query';

import { removeExamResult } from 'services';

const useSetExamResult = () => {
  const queryClient = useQueryClient();

  const deleteExamResult = useMutation({
    mutationFn: async ({ resultId }) => {
      return await removeExamResult({ resultId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExamDetailResult', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExamDetailResult', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return { deleteExamResult };
};

export default useSetExamResult;
