/* eslint-disable*/
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import LogoutPage from './Logout';
import profile from '../../../assets/avatar/1.png';
import LmsPage from './LmsPage';
import { pageTitles } from 'constants';
import AuthContext from '../../../context/Auth.context';

const Header = ({ toggle, onProfile }) => {
  const auth = useContext(AuthContext);

  const [adminUser] = useState({ name: auth.name, email: auth.email });
  const { pathname } = useLocation();
  const exactPath = pathname.split('/')[1];

  return (
    <div className="header">
      <div className="header-content">
        <nav className="navbar navbar-expand">
          <div className="collapse navbar-collapse justify-content-between">
            <div className="header-left">
              <div
                className="dashboard_bar"
                style={{ textTransform: 'capitalize' }}
              >
                {pageTitles[`${exactPath}`]}
              </div>
            </div>

            <ul className="navbar-nav header-right">
              {adminUser && (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <LmsPage />

                  <li
                    className={`nav-item dropdown header-profile ${
                      toggle === 'profile' ? 'show' : ''
                    }`}
                    onClick={() => onProfile()}
                  >
                    <MyPageBtn
                      to={'#'}
                      className="nav-link"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <div className="header-info">
                        <span>{adminUser?.name}</span>
                        <small>{adminUser?.email}</small>
                      </div>
                      <img src={profile} width="20" alt="" />
                    </MyPageBtn>
                    <div
                      className={`dropdown-menu dropdown-menu-right ${
                        toggle === 'profile' ? 'show' : ''
                      }`}
                    >
                      <LogoutPage />
                    </div>
                  </li>
                </div>
              )}
            </ul>
          </div>
        </nav>
      </div>
    </div>
  );
};

const MyPageBtn = styled.div`
  color: #464a53;
  font-size: 18px;
  padding: 0;
  display: flex;
  align-items: center;
`;

const UserInfoBox = styled.div``;

export default Header;
