import axios from 'axios';
import { logout } from '../store/actions/AuthActions';

/* 유저 회원가입 */
export const signUp = (userId, password) => {};

/* 유저 로그인 */
export const login = (userId, password) => {
  const postData = {
    userId,
    password,
  };

  return axios.post(`${process.env.REACT_APP_IP}/users/login`, postData);
};

export function formatError(errorResponse) {}

/* 자동 로그인 유무 확인 */
export function checkAutoLogin(dispatch, history, auth) {
  const token = localStorage.getItem('token');
  const expireDate = new Date(localStorage.getItem('expireDate'));

  if (!token) {
    dispatch(logout(history));
    auth.onLogout();
    history.replace('/login');
    return;
  }

  // console.log(expireDate); //Invalide Date
  if (String(expireDate) === 'Invalid Date') {
  }
  const today = new Date();
  // expireDate에 null이나 undefined가 들어있으면 값이 Invalid Date로 나옴.
  if (String(expireDate) !== 'Invalid Date') {
    if (today > expireDate) {
      dispatch(logout(history));
      auth.onLogout();
      history.replace('/login');
    }
  }
}
