import React, { useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';

import { useSetClass } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { ModalBadge, ValidationErrorMsg } from '../common';
import { MESSAGES } from 'constants';

const ChapterEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const { editClassChapter } = useSetClass();

  const submit = async (form) => {
    const body = {
      day: form.day,
      title: form.title,
      unitId: detailData.unitId,
    };

    try {
      const { status } = await editClassChapter.mutateAsync({
        id: detailData.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.CHAPTER_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.CHAPTER_EDIT_FAIL });
    }
  };

  useEffect(() => {
    reset({
      ...detailData,
    });
  }, [detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">챕터 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputBox>
            <ModalBadge text="유닛명" />
            <div>{detailData.unitName}</div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="챕터명" />
            <div>
              <input
                type="text"
                placeholder="챕터명을 입력하세요."
                className="form-control"
                name="title"
                {...register('title', {
                  required: true,
                })}
              />
              {errors.title && (
                <ValidationErrorMsg text="챕터명을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="일차" />
            <div>
              <input
                type="number"
                min={0}
                placeholder="일차를 입력하세요."
                className="form-control"
                name="day"
                {...register('day', {
                  required: true,
                })}
              />
              {errors.day && (
                <ValidationErrorMsg text="일차를 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  > div:nth-child(2) {
    display: flex;
  }

  > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export default ChapterEditModal;
