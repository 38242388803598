import React from 'react';
import styled from 'styled-components';

const CardHeader = ({
  justifyContent = 'space-between',
  children,
  ...rest
}) => {
  return (
    <Wrapper $justifyContent={justifyContent}>
      <div {...rest}>{children}</div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-color: #d7dae3;
  position: relative;
  background: transparent;
  padding: 1.5rem 1.875rem 1.25rem;
  border-bottom: 1px solid #ec7422;

  > div {
    display: flex;
    justify-content: ${(p) => p.$justifyContent};
  }
`;

export default CardHeader;
