import React from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useExam } from 'hooks';
import { CardBody, Wrapper } from '../layouts';
import { ModalBadge, StatusBadge } from '../common';

const ExamClassAverage = () => {
  const { examId } = useParams();

  const { examDetail } = useExam({ examId });
  const { data } = examDetail || {};
  const { exam } = data || {};
  const {
    startTime,
    endTime,
    highLevelCount,
    middleLevelCount,
    lowLevelCount,
  } = exam || {};
  const examStart = (startTime || '').split('T').join(' ') || '시작 전';
  const examEnd = (endTime || '').split('T').join(' ') || '-';
  const examProblemTotal = highLevelCount + middleLevelCount + lowLevelCount;

  const examCount = {
    1: '사전',
    2: '사후',
    3: '재평가',
  };

  const examStatus = {
    0: '출제중',
    1: '대기',
    2: '진행',
    3: '종료',
  };
  const examStatsColor = {
    1: '#2F4858',
    2: '#51D14F',
    3: '#f35757',
  };

  return (
    <Wrapper>
      <CardBody>
        <Title>시험 상세 정보</Title>

        <StyledInputWrapper>
          <div>
            <ModalBadge required={false} text="과정명" />
            <div>{exam?.groupCourse}</div>
          </div>

          <div>
            <ModalBadge required={false} text="카테고리명" />
            <div>{exam?.classCategoryName}</div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge required={false} text="차수" />
            <div>{examCount[exam?.count]} 평가</div>
          </div>

          <div>
            <ModalBadge required={false} text="상태" />
            <StatusBadge
              text={examStatus[exam?.status]}
              bgColor={examStatsColor[exam?.status]}
            />
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge required={false} text="시작일" />
            <div>{examStart}</div>
          </div>

          <div>
            <ModalBadge required={false} text="종료일" />
            <div>{examEnd}</div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge required={false} text="문제 개수" />
            <div>상: {exam?.highLevelCount}개</div>
            <div>중: {exam?.middleLevelCount}개</div>
            <div>하: {exam?.lowLevelCount}개</div>
            <div style={{ fontWeight: 'bold' }}>전체: {examProblemTotal}개</div>
          </div>
        </StyledInputWrapper>

        <StyledInputWrapper>
          <div>
            <ModalBadge required={false} text="응시자 수" />
            <div>{`${data?.candidateCount}명` || '-'}</div>
          </div>

          <div>
            <ModalBadge required={false} text="평균 점수" />
            <div>{`${data?.avgScore || '-'}`}</div>
          </div>
        </StyledInputWrapper>
      </CardBody>
    </Wrapper>
  );
};

const Title = styled.h4`
  margin-bottom: 20px;
`;

const StyledInputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 10px;

  > div {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;

    > div:nth-child(2) {
      display: flex;
      flex-direction: column;
    }

    > div:last-child {
      flex: 1;
    }
  }
`;

export default ExamClassAverage;
