import { useMutation, useQueryClient } from 'react-query';

import { createNewExam, removeExam, removeExamDetailProblem } from 'services';

const useSetExam = ({ examId }) => {
  const queryClient = useQueryClient();

  const createExam = useMutation({
    mutationFn: async ({ body }) => {
      return await createNewExam({ body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExams'],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExams'],
      }),
  });

  const deleteExam = useMutation({
    mutationFn: async ({ examId }) => {
      return await removeExam({ examId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExams'],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchExams'],
      }),
  });

  const deleteExamProblem = useMutation({
    mutationFn: async ({ problemId }) => {
      return await removeExamDetailProblem({ problemId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: [
          'fetchExamDetailProblems',
          { pageNum: 1, pageSize: 10, examId },
        ],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: [
          'fetchExamDetailProblems',
          { pageNum: 1, pageSize: 10, examId },
        ],
      }),
  });

  return {
    createExam,
    deleteExam,
    deleteExamProblem,
  };
};

export default useSetExam;
