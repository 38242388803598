import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import moment from 'moment-timezone';
import { RangeDatePicker } from '@y0c/react-datepicker';

import { useSetClass } from 'hooks';
import { ModalBadge, ValidationErrorMsg } from '../common';
import CustomSwitchNoConfirm from '../ui/CustomSwitchNoConfirm';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const GroupEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const groupStatusName = ['대기', '진행', '종료'];

  const [examYn, setExamYn] = useState(false);
  const [changedStartDate, setStartDate] = useState('');
  const [changedEndDate, setEndDate] = useState('');

  const { editGroup } = useSetClass();

  const submit = async (form) => {
    const body = {
      groupId: form.groupId,
      classId: form.classId,
      className: form.className,
      groupName: form.groupName,
      groupCourse: form.groupCourse,
      groupCourseCount: form.groupCourseCount,
      groupStartDate: changedStartDate || form.groupStartDate,
      groupDueDate: changedEndDate || form.groupId,
      groupStatus: form.groupStatus,
      groupOperatorId: form.groupOperatorId,
      groupSubOperatorId: form.groupSubOperatorId,
      groupCompletePercentage: form.groupCompletePercentage,
      groupTrainCourseId: form.groupTrainCourseId,
      groupExamYn: examYn ? 'Y' : 'N',
      allowLanguages: form.allowLanguages,
    };

    try {
      const { status } = await editGroup.mutateAsync({ body });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.GROUP_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.GROUP_EDIT_FAIL });
    }
  };

  const handleExamConfirm = () => {
    setExamYn((prev) => !prev);
  };

  const onChange = (start, end) => {
    start = moment(start).tz('Asia/Seoul').format().split('+')[0];
    end = moment(end).tz('Asia/Seoul').format().split('+')[0];

    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    reset({
      ...detailData,
    });
    setExamYn(detailData.groupExamYn === 'Y');
  }, [detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">그룹 상세 정보 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputBox>
            <ModalBadge text="과정명" />
            <div>
              <input
                type="text"
                placeholder="과정명을 입력하세요."
                className="form-control"
                name="groupCourse"
                {...register('groupCourse', {
                  required: true,
                })}
              />
              {errors.groupCourse && (
                <ValidationErrorMsg text="과정명을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="그룹 이름" />
            <div>
              <input
                type="text"
                placeholder="그룹 이름을 입력하세요."
                className="form-control"
                name="groupName"
                {...register('groupName', {
                  required: true,
                })}
              />
              {errors.groupName && (
                <ValidationErrorMsg text="그룹 이름을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="수료 목표 점수" />
            <div>
              <input
                type="number"
                min={0}
                placeholder="수료 목표 점수를 입력하세요."
                className="form-control"
                name="groupCompletePercentage"
                {...register('groupCompletePercentage', {
                  required: true,
                })}
              />
              {errors.groupCompletePercentage && (
                <ValidationErrorMsg text="수료 목표 점수를 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="기수" />
            <div>
              <input
                type="text"
                placeholder="과정명을 입력하세요."
                className="form-control"
                name="groupCourseCount"
                disabled
                {...register('groupCourseCount', {
                  required: true,
                })}
              />
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="시험 유무" />
            <div>
              <CustomSwitchNoConfirm
                isTrue={examYn}
                handleToggle={handleExamConfirm}
              />
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="그룹 상태" />
            <div>
              <span>{groupStatusName[0]}</span>
              <input
                type="radio"
                className="myform-radio mr-2"
                name="groupStatus"
                value={0}
                defaultChecked={detailData.groupStatus === 0}
                {...register('groupStatus')}
              />
              <span>{groupStatusName[1]}</span>
              <input
                type="radio"
                className="myform-radio mr-2"
                name="groupStatus"
                value={1}
                defaultChecked={detailData.groupStatus === 1}
                {...register('groupStatus')}
              />
              <span>{groupStatusName[2]}</span>
              <input
                type="radio"
                className="myform-radio mr-2"
                name="groupStatus"
                value={2}
                defaultChecked={detailData.groupStatus === 2}
                {...register('groupStatus')}
              />
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="교육 기간" />
            <RangeDatePicker
              initialStartDate={detailData.groupStartDate}
              initialEndDate={detailData.groupDueDate}
              startText="시작일"
              endText="종료일"
              startPlaceholder="강의시작일"
              endPlaceholder="강의종료일"
              onChange={onChange}
            />
          </StyledInputBox>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  > div:nth-child(2) {
    display: flex;
    align-items: center;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    flex: 1;
  }
`;

export default GroupEditModal;
