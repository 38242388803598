import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';

import {
  useDebounce,
  useGetQueryString,
  useSetParameterOnQuery,
  useStudyGroup,
} from 'hooks';
import { SearchInput, Pagination, CardinalDropMenu } from 'components/common';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { confirmSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { STUDY_COLUMNS } from 'components/Board/Columns';
import CustomSwitch from 'components/ui/CustomSwitch';

const StudyGroupList = () => {
  const queryValues = useGetQueryString();
  const history = useHistory();

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');
  const [groupId, setGroupId] = useState('');

  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setGroupId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { studyGroupList, editStudyGroupStatus, deleteStudyGroup } =
    useStudyGroup({
      pageNum: query.pageNum || 1,
      keyword: query.keyword || '',
      pageSize,
      groupId,
      type: 'list',
    });
  const { data } = studyGroupList || {};

  //Table Setting
  const columns = useMemo(() => STUDY_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  const handleConfirmToggle = async ({ studyGroupId, currentStatus }) => {
    let hasConfirm;
    let response;
    if (currentStatus === 'ON') {
      hasConfirm = false;
      response = await editStudyGroupStatus.mutateAsync({
        studyGroupId,
        hasConfirm,
      });
    } else {
      hasConfirm = true;
      response = await editStudyGroupStatus.mutateAsync({
        studyGroupId,
        hasConfirm,
      });
    }
    if (response.status === 200) {
      await successSwal({ text: MESSAGES.STUDYGROUP_STATUS_SUCCESS });
    }
  };

  const handleDelete = async ({ studyGroupId }) => {
    const confirm = await confirmSwal({
      title: '스터디그룹을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteStudyGroup.mutateAsync({ studyGroupId });
      if (status === 200) {
        await successSwal({ text: MESSAGES.STUDYGROUP_DELETE_SUCCESS });
      }
    }
  };

  const handleGoDetail = (studyGroupId, i) => {
    if (i === 6 || i === 7) return;

    if (i !== 7 || i !== 6) {
      history.push({
        pathname: `/study-group-detail/${studyGroupId}`,
      });
    }
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{ width: '100%', justifyContent: 'space-between' }}
          className="d-sm-flex"
        >
          <div style={{ display: 'flex' }}>
            <CardinalDropMenu
              classTitle={classTitle}
              setClassTitle={setClassTitle}
              setId={setGroupId}
              isGroupId
            />
          </div>

          <div>
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td
                            {...cell.getCellProps()}
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              handleGoDetail(cell.row.original.id, i)
                            }
                          >
                            {i === 6 ? (
                              <>
                                <ReactTooltip />
                                <i
                                  className="far fa-trash-alt more-btn-icon-box mr-1"
                                  data-tip="삭제하기"
                                  onClick={() =>
                                    handleDelete({
                                      studyGroupId: cell.row.original.id,
                                    })
                                  }
                                />
                              </>
                            ) : i === 5 ? (
                              <CustomSwitch
                                isTrue={cell.row.original.status === 'ON'}
                                data={cell}
                                handleToggle={() =>
                                  handleConfirmToggle({
                                    studyGroupId: cell.row.original.id,
                                    currentStatus: cell.row.original.status,
                                  })
                                }
                              />
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 ? (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          ) : (
            ''
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};
export default StudyGroupList;
