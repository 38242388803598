import axios from 'axios';
import { useQuery } from 'react-query';

const useUnitList = (groupId, categoryId) => {
  const { data, isLoading } = useQuery(
    ['useUnitList', groupId, categoryId],
    async () => {
      return await axios
        .get(
          `${process.env.REACT_APP_IP}/admin/exams/creation/${groupId}/${categoryId}`
        )
        .then((res) => res.data);
    },
    { enabled: !!groupId && !!categoryId, retry: false }
  );

  return { data, isLoading };
};

export default useUnitList;
