/// Menu
import MetisMenu from 'metismenujs';
import React, { Component, useCallback, useEffect } from 'react';
/// Scroll
import PerfectScrollbar from 'react-perfect-scrollbar';
/// Link
import { Link, useLocation } from 'react-router-dom';

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = ({ onClick, onClick3 }) => {
  const { pathname, key } = useLocation();
  const path = pathname.split('/')[1];

  useEffect(() => {
    let btn = document.querySelector('.nav-control');
    let aaa = document.querySelector('#main-wrapper');

    function toggleFunc() {
      return aaa.classList.toggle('menu-toggle');
    }

    btn.addEventListener('click', toggleFunc);
  }, []);

  const SClassManage = [
    'class-list',
    'class-upload',
    'category-list',
    'category-upload',
    'unit-list',
    'unit-upload',
    'chapter-list',
    'chapter-upload',
    'class-video',
    'class-video-upload',
  ];
  const SConnectManage = ['upload-link', 'upload-cloud'];
  const STaskManage = ['task', 'task-view'];
  const SPBLManage = [
    'pbl-category',
    'pbl-problem',
    'pbl-result',
    'pbl-problem-edit',
    'pbl-problem-create',
  ];
  const SExamList = ['exam', 'exam-create'];

  const userManage = ['user-list', 'user-view'];
  const processManage = [...SClassManage, ...SConnectManage];
  const classManage = ['notice', 'library', ...STaskManage, ...SPBLManage];
  const examManage = [
    'exam',
    'exam-problem',
    'exam-problem-detail',
    'exam-problem-edit',
    'exam-detail',
    'exam-create',
    'exam-graph',
  ];
  const supportBoard = [
    'support-library',
    'faq',
    'story',
    'contact',
    'consulting',
    'support-board',
  ];
  const studyGroupManage = [
    'study-group',
    'study-group-detail',
    'study-board',
    'study-board-detail',
    'zoom-reserve-confirm',
    'study-room',
  ];
  const knowledgeContentManage = [
    'knowledge-content',
    'knowledge-create',
    'knowledge-detail',
    'knowledge-edit',
  ];

  const checkActiveMenu = useCallback(
    (arr, ...rest) => {
      const copiedArray = [...arr, ...rest];

      return copiedArray.includes(path);
    },
    [key]
  );

  return (
    <div className="deznav gradient-sk">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu mb-5" id="menu">
          {/* 사이트맵 */}
          <li>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-381-home-2"></i>
              <span className="nav-text">메인</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${!path ? 'mm-active' : ''}`}
                  to="/"
                  onClick={() => {
                    onClick();
                  }}
                >
                  사이트맵
                </Link>
              </li>
            </ul>
          </li>

          {/* 회원관리 */}
          <li>
            <Link className="has-arrow ai-icon" to="#">
              <i className="flaticon-381-id-card-2"></i>
              <span className="nav-text forms">회원 관리</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === 'user-list' ? 'mm-active' : ''}`}
                  onClick={() => onClick()}
                  to="/user-list"
                >
                  학습자﹒교강사관리
                </Link>
              </li>
            </ul>
          </li>

          {/* 과정관리 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-calendar-1"></i>
              <span className="nav-text">과정 관리</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    checkActiveMenu(SClassManage)
                      ? 'has-arrow mm-active'
                      : 'has-arrow'
                  }`}
                  onClick={() => {
                    onClick();
                  }}
                  to={'#'}
                >
                  커리큘럼
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === 'class-list' ? 'mm-active' : ''}`}
                      to="/class-list"
                    >
                      - 클래스 목록
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === 'category-list' ? 'mm-active' : ''
                      }`}
                      to="/category-list"
                    >
                      - 카테고리 목록
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === 'unit-list' ? 'mm-active' : ''}`}
                      to="/unit-list"
                    >
                      - 유닛 목록
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === 'chapter-list' ? 'mm-active' : ''
                      }`}
                      to="/chapter-list"
                    >
                      - 챕터 목록
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${path === 'class-video' ? 'mm-active' : ''}`}
                      to="/class-video"
                    >
                      - 강의 목록
                    </Link>
                  </li>
                </ul>
              </li>

              <li>
                <Link
                  className={`${
                    checkActiveMenu(SConnectManage)
                      ? 'has-arrow mm-active'
                      : 'has-arrow'
                  }`}
                  onClick={() => {
                    onClick();
                  }}
                  to={'#'}
                >
                  연결 관리
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === 'upload-link' ? 'mm-active' : ''}`}
                      to="/upload-link"
                    >
                      - Gather링크 등록
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === 'upload-cloud' ? 'mm-active' : ''
                      }`}
                      to="/upload-cloud"
                    >
                      - 클라우드 등록/관리
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          {/* 내 클래스 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-reading"></i>
              <span className="nav-text">내 클래스 게시판</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === 'notice' ? 'mm-active' : ''}`}
                  to="/notice"
                >
                  공지사항
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'library' ? 'mm-active' : ''}`}
                  to="/library"
                >
                  학습자료
                </Link>
              </li>
              <li>
                <Link
                  className={`${
                    checkActiveMenu(STaskManage) ? 'mm-active' : ''
                  }`}
                  to="/task"
                >
                  과제 관리
                </Link>
              </li>

              <li>
                <Link
                  className={`${
                    checkActiveMenu(SPBLManage)
                      ? 'has-arrow mm-active'
                      : 'has-arrow'
                  }`}
                  onClick={() => {
                    onClick();
                  }}
                  to={'#'}
                >
                  PBL 관리
                </Link>
                <ul>
                  <li>
                    <Link
                      className={`${path === 'upload-link' ? 'mm-active' : ''}`}
                      to="/pbl-category"
                    >
                      - PBL 단원 관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === 'upload-cloud' ? 'mm-active' : ''
                      }`}
                      to="/pbl-problem"
                    >
                      - PBL 문제 관리
                    </Link>
                  </li>
                  <li>
                    <Link
                      className={`${
                        path === 'upload-cloud' ? 'mm-active' : ''
                      }`}
                      to="/pbl-result"
                    >
                      - PBL 결과물 관리
                    </Link>
                  </li>
                </ul>
              </li>
              <li>
                <Link
                  className={`${path === 'question' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/question"
                >
                  강의질문
                </Link>
              </li>
            </ul>
          </li>

          {/* 역량평가 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-television"></i>
              <span className="nav-text">역량평가</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${checkActiveMenu(SExamList) ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/exam"
                >
                  시험 목록
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'exam-problem' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/exam-problem"
                >
                  시험 문제
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'exam-graph' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/exam-graph"
                >
                  통계 그래프
                </Link>
              </li>
            </ul>
          </li>

          {/* 학습지원 게시판 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-promotion"></i>
              <span className="nav-text">학습지원 게시판</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === 'support-library' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/support-library"
                >
                  공통 자료실
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'faq' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/faq"
                >
                  FAQ
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'story' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/story"
                >
                  우리들이야기
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'contact' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/contact"
                >
                  문의하기
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'consulting' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/consulting"
                >
                  상담관리
                </Link>
              </li>
            </ul>
          </li>

          {/* 스터디그룹 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-user-9"></i>
              <span className="nav-text">스터디그룹</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${path === 'study-group' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/study-group"
                >
                  스터디그룹 목록
                </Link>
              </li>
              <li>
                <Link
                  className={`${path === 'study-room' ? 'mm-active' : ''}`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/study-room"
                >
                  스터디룸 예약 관리
                </Link>
              </li>
            </ul>
          </li>

          {/* 지식컨텐츠 */}
          <li>
            <Link className="has-arrow ai-icon" to={'#'}>
              <i className="flaticon-381-idea"></i>
              <span className="nav-text">지식컨텐츠</span>
            </Link>
            <ul>
              <li>
                <Link
                  className={`${
                    path === 'knowledge-content' ? 'mm-active' : ''
                  }`}
                  onClick={() => {
                    onClick();
                  }}
                  to="/knowledge-content"
                >
                  지식컨텐츠 목록
                </Link>
              </li>
            </ul>
          </li>
        </MM>
        <div className="copyright mt-5">
          <p>
            <strong>&copy; 2023 SK SHIELDUS ROOKIES.</strong> <br />
            ALL RIGHTS RESERVED.
          </p>
        </div>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;
