import { useQuery } from 'react-query';

import { fetchTotalSubmitter } from 'services';

const useFetchTotalSubmitter = ({ pageNum, groupId, searchValue }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchTotalSubmitter', { pageNum, groupId, searchValue }],
    queryFn: async () =>
      await fetchTotalSubmitter({ pageNum, groupId, searchValue }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
  });
  return { ...queryReturns };
};

export default useFetchTotalSubmitter;
