import React from 'react';

import { ExamDetailProblems, ExamClassAverage } from 'components/Exam';
import ExamDetailResult from '../../components/Exam/ExamDetailResult';

const ExamDetail = () => {
  return (
    <>
      {/* 클래스 평균 */}
      <ExamClassAverage />

      {/* 시험 결과 목록 */}
      <ExamDetailResult />

      {/* 시험 상세 문제 목록 */}
      <ExamDetailProblems />
    </>
  );
};
export default ExamDetail;
