import React from 'react';
import { format } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import { Viewer } from '@toast-ui/react-editor';
import '@toast-ui/editor/dist/toastui-editor.css';

import { useDownload, useKnowledge } from 'hooks';
import { CardBody, CardHeader, Wrapper } from '../../components/layouts';
import { confirmSwal, errorSwal, successSwal } from '../../utilities';
import { MESSAGES } from 'constants';
import styled from 'styled-components';

const KnowledgeDetail = ({ location }) => {
  const history = useHistory();

  const { deleteKnowledge } = useKnowledge({});

  const handleBack = () => {
    history.goBack();
  };

  const { refetch, isLoading } = useDownload(
    `${process.env.REACT_APP_IP}/admin/pre-learning/file/${location.state.data.id}`
  );

  //PreLearningDelete
  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '해당 지식 컨텐츠를 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      try {
        const { status } = await deleteKnowledge.mutateAsync({
          id: location.state.data.id,
        });
        if (status === 200) {
          history.push('/knowledge-content');
          await successSwal({ text: MESSAGES.KNOWLEDGE_DELETE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.KNOWLEDGE_DELETE_FAIL });
      }
    }
  };

  const handleDownload = () => {
    if (isLoading) return;
    refetch();
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <h4 className="card-title">{location.state.data.title}</h4>
          <span>
            작성일 :{' '}
            {format(new Date(location.state.data.regDate), 'yyyy.MM.dd hh:mm')}
          </span>
          <span>교육 시간 : {location.state.data.totalTime} 시간</span>
        </div>
        <div className="d-sm-flex">
          <div onClick={handleBack} className="btn btn-primary px-3 light">
            <i className="fa fa-reply"></i>
          </div>
          <Link
            to={{
              pathname: '/knowledge-edit',
              state: {
                data: location.state.data,
              },
            }}
            className="btn btn-primary px-3 light ml-2"
          >
            <i className="fa-solid fa-pen"></i>
          </Link>
          <div
            onClick={handleDelete}
            className="btn btn-primary px-3 light ml-2"
          >
            <i className="fa fa-trash"></i>
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="read-content">
          <div className="media ml-2 mr-2">
            <div className="media-body">
              <h4>{location.state.data.className}</h4>
            </div>
          </div>
        </div>
        <div className="read-content-body mx-2">
          <Viewer initialValue={location.state.data.content} />

          <hr />

          <h5 className="mt-2">
            <i className="fa-solid fa-link mr-3" />
            <a href={location.state.data.url} target="_blank">
              {location.state.data.url}
            </a>
          </h5>

          <hr />

          <h5 className="mt-2" onClick={handleDownload}>
            <i className="fa fa-paperclip mr-3" />
            {location.state.data.imageName}
          </h5>
          <ImageBox>
            <img
              src={`${process.env.REACT_APP_IP}/pre-learning/${location.state.data.savedImageName}`}
              alt=""
            />
          </ImageBox>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const ImageBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;

  > img {
    max-width: 100%;
    object-fit: contain;
  }
`;

export default KnowledgeDetail;
