import { useQuery } from 'react-query';

import { fetchPBLProblemDetail } from 'services';

const useFetchPBLProblemDetail = ({ unitId, problemId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPBLProblemDetail', unitId, problemId],
    queryFn: async () => await fetchPBLProblemDetail({ unitId, problemId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!unitId && !!problemId,
  });
  return { ...queryReturns };
};

export default useFetchPBLProblemDetail;
