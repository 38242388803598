import { useQuery } from 'react-query';

import {
  fetchCloudAccountList,
  fetchRemoteClassList,
  fetchUserListByGroupId,
} from 'services';

const useConnect = ({ pageNum, pageSize, keyword, type, groupId }) => {
  const remoteClassList = useQuery({
    queryKey: ['fetchRemoteClassList'],
    queryFn: async () => {
      const response = await fetchRemoteClassList();
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    staleTime: Infinity,
    cacheTime: Infinity,
    keepPreviousData: true,
    enabled: type === 'link',
  });

  const cloudAccountList = useQuery({
    queryKey: ['fetchCloudAccountList', { pageNum, pageSize, keyword }],
    queryFn: async () => {
      const response = await fetchCloudAccountList({
        pageNum,
        pageSize,
        keyword,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!pageNum,
  });

  const userListByGroupId = useQuery({
    queryKey: ['fetchUserListByGroupId', { groupId }],
    queryFn: async () => {
      const response = await fetchUserListByGroupId({
        groupId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!groupId,
  });

  return {
    remoteClassList,

    cloudAccountList,
    userListByGroupId,
  };
};

export default useConnect;
