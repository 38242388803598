import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { loadingToggleAction, loginAction } from '../store/actions/AuthActions';
import { useForm } from 'react-hook-form';

import logo from '../assets/logo/sk_shieldus_comm_rgb_kr.png';
import loginbg from '../assets/bg-login.jpg';
import astroWorker from '../assets/astro/astro.gif';
import astroThinking from '../assets/astro/astroThinking.gif';

import AuthContext from '../context/Auth.context';
import { parseJWT } from '../AuthProvider';
import { ValidationErrorMsg } from '../components/common';
import { login } from '../services';

const Login = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
  });

  const dispatch = useDispatch();
  const auth = useContext(AuthContext);
  const [astro, setAstro] = useState(astroWorker);

  const onLogin = async (form) => {
    setAstro(astroThinking);

    try {
      const response = await login(form.userId, form.password);

      auth.onLogin(
        response.headers.authorization,
        response.headers.refreshtoken
      );

      const parsedData = parseJWT(response.headers.authorization);
      const data = {
        ...parsedData,
        token: response.headers.authorization,
        authorization: response.headers.authorization,
        authToken: response.headers.authorization,
        refreshToken: response.headers.refreshtoken,
      };

      dispatch(loginAction(data, props.history));
    } catch (_error) {
      setAstro(astroWorker);
    }

    setAstro(astroWorker);
    dispatch(loadingToggleAction(true));
  };

  return (
    <div
      className="login-main-page"
      style={{ backgroundImage: 'url(' + loginbg + ')' }}
    >
      <div className="login-wrapper">
        <div className="login-aside-left">
          <Link to="/login" className="login-logo">
            <img src={logo} alt="SK실더스로고" className="mr-2" />
            <div className="fs-16 mt-2">Security와 Safety&Care</div>
            <img
              src={astro}
              className="astro"
              alt="astro"
              style={{ width: '50%' }}
            />
          </Link>
          <div className="login-description">
            <h2 className="text-primary mb-2">CMS</h2>
            <p className="fs-12 text-black">관리자 시스템 로그인 화면입니다.</p>
            <ul className="social-icons mt-4">
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/skshieldus"
                >
                  <i className="fa-brands fa-facebook"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/skshieldus/"
                >
                  <i className="fa-brands fa-instagram"></i>
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={'https://www.youtube.com/user/ADTKorea'}
                >
                  <i className="fa-brands fa-youtube"></i>
                </a>
              </li>
            </ul>
            <div className="mt-5">
              <div className="fs-12 text-gray">
                에스케이쉴더스 주식회사 | 사업자등록번호 120-86-07747
              </div>
              <div className="fs-12 text-gray">
                13486 경기도 성남시 분당구 판교로 227번길 23, 4&5층{' '}
              </div>
              <div className="fs-12 text-gray">
                COPYRIGHT © 2022 SK SHIELDUS. ALL RIGHTS RESERVED.
              </div>{' '}
            </div>
          </div>
        </div>

        <div className="login-aside-right">
          <div className="row m-0 justify-content-center h-100 align-items-center">
            <div className="col-xl-9 col-xxl-9">
              <div className="authincation-content">
                <div className="row no-gutters">
                  <div className="col-xl-12">
                    <div className="auth-form-1">
                      <div className="mb-4">
                        <h3 className="text-white mb-1">Welcome to CMS</h3>
                        <p className="text-white">
                          Sign in by entering information below
                        </p>
                      </div>
                      <form onSubmit={handleSubmit(onLogin)}>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong className="text-white">User ID</strong>
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            name="userId"
                            {...register('userId', {
                              required: true,
                            })}
                          />
                          {errors.userId && (
                            <ValidationErrorMsg text="사용자ID를 입력해주세요." />
                          )}
                        </div>
                        <div className="form-group">
                          <label className="mb-2 ">
                            <strong className="text-white">Password</strong>
                          </label>
                          <input
                            type="password"
                            className="form-control"
                            name="password"
                            {...register('password', {
                              required: true,
                            })}
                          />
                          {errors.password && (
                            <ValidationErrorMsg text="패스워드를 입력해주세요." />
                          )}
                        </div>

                        <div className="text-center">
                          <button
                            type="submit"
                            className="btn bg-white text-primary btn-block"
                          >
                            Sign In
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
