import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

import { LC_COLUMNS } from 'components/Board/Columns';
import {
  useClassBoard,
  useDebounce,
  useGetQueryString,
  useSetParameterOnQuery,
} from 'hooks';
import {
  BoardWriteButton,
  CardinalDropMenu,
  FileIcon,
  Pagination,
  SearchInput,
} from 'components/common';
import { Wrapper, CardHeader, CardBody } from 'components/layouts/common';
import { confirmSwal, successSwal } from 'utilities/swal';
import { MESSAGES, BADGE_COLOR } from '../../constants';
import '../../components/Board/filtering.css';

const NoticeList = () => {
  const queryValues = useGetQueryString();
  const history = useHistory();

  /* 공지사항 카테고리ID : 3 */
  const [categoryId] = useState(3);
  const [pageNum, setPageNum] = useState(queryValues.pageNum || 1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState(queryValues.keyword || '');
  const debouncedKeyword = useDebounce(keyword, 300);

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요.');
  const [classId, setClassId] = useState('');

  const query = useSetParameterOnQuery({
    pageNum,
    keyword: debouncedKeyword,
  });

  useEffect(() => {
    if (Object.keys(queryValues).length === 0) {
      setPageNum('');
      setKeyword('');
      setClassId('');
      setClassTitle('과정을 선택해주세요.');
    }
  }, [queryValues]);

  const { boardList, deleteBoard } = useClassBoard({
    pageNum: query.pageNum || 1,
    keyword: query.keyword || '',
    pageSize,
    classId,
    category: categoryId,
  });
  const { data } = boardList || {};

  const columns = useMemo(() => LC_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, page } =
    tableInstance;

  const handleDelete = async (id) => {
    const confirm = await confirmSwal({
      title: '게시글을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteBoard.mutateAsync({ postId: id });
      if (status === 204) {
        await successSwal({ text: MESSAGES.POST_DELETE_SUCCESS });
      }
    }
  };

  const handleDetail = (id) => {
    history.push(`/class-board/${id}`);
  };

  return (
    <Wrapper>
      <CardHeader>
        <div
          style={{ width: '100%', justifyContent: 'space-between' }}
          className="d-sm-flex"
        >
          <div style={{ display: 'flex' }}>
            <CardinalDropMenu
              classTitle={classTitle}
              setClassTitle={setClassTitle}
              setId={setClassId}
            />
          </div>

          <div>
            <SearchInput value={keyword} setValue={setKeyword} />
          </div>
        </div>
      </CardHeader>

      <CardBody>
        <div className="table-responsive">
          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead className="first-child-short">
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      let content;
                      switch (i) {
                        case 1:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              <div
                                className={`badge rounded-pill bg-${
                                  BADGE_COLOR[cell.row.original.categoryId - 1]
                                } text-white`}
                                style={{ opacity: '0.7' }}
                              >
                                {cell.row.original.categoryName}
                              </div>
                            </td>
                          );
                          break;
                        case 0:
                          content = (
                            <td
                              {...cell.getCellProps()}
                              key={i}
                              onClick={() => handleDetail(cell.row.original.id)}
                              className="cursor-pointer"
                            >
                              <span className="cursor-pointer">
                                {cell.row.original.className.slice(-3)}
                              </span>
                            </td>
                          );
                          break;
                        case 2:
                          content = (
                            <td
                              {...cell.getCellProps()}
                              key={i}
                              onClick={() => handleDetail(cell.row.original.id)}
                              className="cursor-pointer"
                            >
                              <Title>{cell.row.original.title}</Title>
                              {cell.row.original.isAttached && <FileIcon />}
                            </td>
                          );
                          break;
                        case 5:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              <>
                                <ReactTooltip />
                                <i
                                  className="far fa-trash-alt more-btn-icon-box mr-1"
                                  data-tip="Delete"
                                  onClick={() =>
                                    handleDelete(cell.row.original.id)
                                  }
                                />
                              </>
                            </td>
                          );
                          break;
                        default:
                          content = (
                            <td {...cell.getCellProps()} key={i}>
                              {cell.render('Cell')}
                            </td>
                          );
                          break;
                      }
                      return content;
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <BoardWriteButton
            to={{ pathname: '/class-board-write', state: { categoryId } }}
          />

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const Title = styled.span`
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: calc(100% - 30px);
  vertical-align: middle;
`;

export default NoticeList;
