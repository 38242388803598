import { useMutation, useQueryClient } from 'react-query';

import { changeUserAttendance } from 'services';

const useUpdateAttendance = ({ userId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation(
    async ({ body }) => {
      return await changeUserAttendance({ body });
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['useAttendances', { userId }]),
      onError: (err) => {
        console.error(err);
      },
    }
  );
  return { ...queryReturns };
};

export default useUpdateAttendance;
