import React, { useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';

import { useSetClass } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { DropClassCategoryUnit } from 'components/ProcessManage';
import { CardBody, Wrapper } from 'components/layouts/common';

const ChapterUpload = () => {
  const history = useHistory();

  const [classTitle, setClassTitle] = useState('과정을 선택해주세요');
  const [classList, setClassList] = useState([]);
  const [classId, setClassId] = useState('');
  const [categoryTitle, setCategoryTitle] = useState('카테고리를 선택하세요.');
  const [categoryList, setCategoryList] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [unitTitle, setUnitTitle] = useState('유닛을 선택해주세요.');
  const [unitList, setUnitList] = useState([]);
  const [unitId, setUnitId] = useState('');
  const handleUnit = (data, id) => {
    setUnitTitle(data);
    setUnitId(id);
  };

  const nextIndex = useRef(1);
  const [inputList, setInputList] = useState([
    {
      day: '',
      title: '',
      unitId: '',
    },
  ]);
  const [errors, setErrors] = useState([
    {
      unitId: '',
      title: '',
      day: '',
    },
  ]);

  const addInput = () => {
    const newInput = {
      unitId: '',
      title: '',
      day: '',
    };
    const newErr = {
      day: '',
      title: '',
      unitId: '',
    };
    setInputList([...inputList, newInput]); // input 객체 추가
    setErrors([...errors, newErr]);
    nextIndex.current += 1;
  };

  const { addClassChapter } = useSetClass();

  const deleteInput = (index) => {
    setInputList(inputList.filter((item, idx) => idx !== index)); // index 값과 같지 않은 애들만 남겨둔다
  };

  const handleOnChange = (e, index) => {
    const { name, value } = e.target;
    if (index > inputList.length) return;
    const copiedInputList = JSON.parse(JSON.stringify(inputList));
    if (name === 'title') {
      copiedInputList[index].title = value;
    } else if (name === 'day') {
      copiedInputList[index].day = Number(value);
    }
    setInputList(copiedInputList);
  };

  const addChapter = async (e) => {
    e.preventDefault();

    let error = false;
    const errorObj = [...errors];
    if (!classId) {
      errorObj[0].unitId = '과정을 선택해주세요.';
      error = true;
    } else if (!unitId) {
      errorObj[0].unitId = '유닛을 선택해주세요.';
      error = true;
    } else {
      errorObj[0].unitId = '';
    }

    for (let index in inputList) {
      if (!inputList[index].title) {
        errorObj[index].title = '챕터명을 입력해주세요.';
        error = true;
      } else {
        errorObj[index].title = '';
      }
      if (!inputList[index].day) {
        errorObj[index].day = '일수를 입력해주세요.';
        error = true;
      } else {
        errorObj[index].day = '';
      }
    }
    setErrors(errorObj);

    if (error) {
      return;
    } else {
      for (let index in inputList) {
        inputList[index].unitId = unitId;
      }

      const body = { chapters: [...inputList] };

      try {
        const { status } = await addClassChapter.mutateAsync({ body });
        if (status === 201) {
          history.push('/chapter-list');
          await successSwal({ text: MESSAGES.CHAPTER_CREATE_SUCCESS });
        }
      } catch (error) {
        await errorSwal({ text: MESSAGES.CHAPTER_CREATE_FAIL });
      }
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="d-flex">
          <DropClassCategoryUnit
            property={{
              classTitle,
              setClassTitle,
              classList,
              setClassList,
              classId,
              setClassId,
              categoryTitle,
              setCategoryTitle,
              categoryList,
              setCategoryList,
              categoryId,
              setCategoryId,
              unitTitle,
              setUnitTitle,
              unitList,
              setUnitList,
              unitId,
              setUnitId,
              handleUnit,
            }}
          />
          <Button
            variant="outline-info"
            size="md"
            className="mr-2 ml-2 mb-3 height-38px"
            onClick={() => addInput()}
          >
            +
          </Button>
          {errors[0].unitId && (
            <div className="text-danger fs-12 ml-3 mt-3">
              {errors[0].unitId}
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-xl-12 col-lg-12">
            <div className="basic-form">
              <form onSubmit={addChapter}>
                {inputList.map((item, index) => {
                  return (
                    <div key={index} className="form-row">
                      <div className="form-group col-md-7">
                        <input
                          type="text"
                          placeholder="챕터명을 입력하세요."
                          value={item.title}
                          className="form-control"
                          name="title"
                          onChange={(e) => handleOnChange(e, index)}
                        />
                        {errors[index].title && (
                          <div className="text-danger fs-12 ml-3 mt-3">
                            {errors[index].title}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-2">
                        <input
                          type="date"
                          placeholder="강의일을 입력하세요."
                          value={item.chapterDate}
                          className="form-control"
                          name="chapterDate"
                          onChange={(e) => handleOnChange(e, index)}
                        />
                        {errors[index].chapterDate && (
                          <div className="text-danger fs-12 ml-3 mt-3">
                            {errors[index].chapterDate}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-2">
                        <input
                          type="number"
                          placeholder="교육 일차를 입력하세요."
                          min="0"
                          value={item.day}
                          className="form-control"
                          name="day"
                          onChange={(e) => handleOnChange(e, index)}
                        />
                        {errors[index].day && (
                          <div className="text-danger fs-12 ml-3 mt-3">
                            {errors[index].day}
                          </div>
                        )}
                      </div>
                      <div className="form-group col-md-1">
                        <Button
                          variant="outline-danger"
                          size="md"
                          className="height-38px"
                          onClick={() => deleteInput(index)}
                        >
                          -
                        </Button>
                      </div>
                    </div>
                  );
                })}
                <hr />
                <div className="d-flex justify-content-start">
                  <button type="submit" className="btn btn-primary mt-3 mr-3">
                    등록
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

export default ChapterUpload;
