import React from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import moment from 'moment/moment';
import { useParams } from 'react-router-dom';

import {
  HourSelect,
  MinSelect,
  ResetBtn,
  SelectWrapper,
} from './UserPreference';
import { useUpdateAttendance } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';

const EditUserAttendanceModal = ({ modal, setModal }) => {
  const { userId } = useParams();

  const { register, handleSubmit, setValue } = useForm();

  const { mutateAsync: update, isLoading: updateLoading } = useUpdateAttendance(
    {
      userId,
    }
  );

  const validateTime = (base, hour, min) => {
    if ((hour && !min) || (!hour && min)) {
      throw Error('시간을 입력해주세요.');
    }
    if (!hour && !min) {
      return null;
    }

    const baseTime = moment(base);
    baseTime.hour(hour);
    baseTime.minute(min);
    return baseTime.zone('Asia/Seoul').format('yyyy-MM-DDTHH:mm:ss');
  };

  const onValid = async (data) => {
    if (updateLoading) return;
    try {
      const attendTime = validateTime(
        modal.chapterDate,
        data.attendHour,
        data.attendMin
      );
      const leavingTime = validateTime(
        modal.chapterDate,
        data.leavingHour,
        data.leavingMin
      );
      const outingStartTime = validateTime(
        modal.chapterDate,
        data.outingStartHour,
        data.outingStartMin
      );
      const outingEndTime = validateTime(
        modal.chapterDate,
        data.outingEndHour,
        data.outingEndMin
      );

      const body = {
        attendId: modal.attendId,
        attendTime,
        leavingTime,
        outingStartTime,
        outingEndTime,
      };

      const response = await update({ body });
      if (response.status === 200) {
        setModal(null);
        await successSwal({ text: MESSAGES.USER_ATTENDANCE_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.USER_ATTENDANCE_EDIT_FAIL });
    }
  };

  return (
    <Modal
      show={!!modal}
      onHide={() => setModal(null)}
      className="modal fade"
      id="reviewModal"
    >
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">
            {new Date(modal.chapterDate).toLocaleDateString()} 출결시간수정
          </h5>
          <button
            type="button"
            onClick={() => setModal(null)}
            className="close"
            data-dismiss="modal"
          >
            <span>×</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit(onValid)}>
            <div
              className="form-group"
              style={{ display: 'flex', flexDirection: 'column' }}
            >
              <label htmlFor="">출석시간</label>
              <SelectWrapper>
                <HourSelect
                  register={register('attendHour')}
                  time={modal.attendTime}
                  name="attendHour"
                  setValue={setValue}
                />
                <MinSelect
                  register={register('attendMin')}
                  time={modal.attendTime}
                  name="attendMin"
                  setValue={setValue}
                />
                <ResetBtn
                  type="button"
                  onClick={() =>
                    setModal((prev) => ({ ...prev, attendTime: null }))
                  }
                >
                  <i className="fas fa-trash"></i>
                </ResetBtn>
              </SelectWrapper>
              <label htmlFor="">퇴실시간</label>
              <SelectWrapper>
                <HourSelect
                  register={register('leavingHour')}
                  time={modal.leavingTime}
                  name="leavingHour"
                  setValue={setValue}
                />
                <MinSelect
                  register={register('leavingMin')}
                  time={modal.leavingTime}
                  name="leavingMin"
                  setValue={setValue}
                />
                <ResetBtn
                  type="button"
                  onClick={() =>
                    setModal((prev) => ({ ...prev, leavingTime: null }))
                  }
                >
                  <i className="fas fa-trash"></i>
                </ResetBtn>
              </SelectWrapper>
              <label htmlFor="">외출시간</label>
              <SelectWrapper>
                <HourSelect
                  register={register('outingStartHour')}
                  name="outingStartHour"
                  setValue={setValue}
                  time={modal.outingStartTime}
                />
                <MinSelect
                  register={register('outingStartMin')}
                  name="outingStartMin"
                  setValue={setValue}
                  time={modal.outingStartTime}
                />
                <ResetBtn
                  type="button"
                  onClick={() =>
                    setModal((prev) => ({
                      ...prev,
                      outingStartTime: null,
                    }))
                  }
                >
                  <i className="fas fa-trash"></i>
                </ResetBtn>
              </SelectWrapper>
              <label htmlFor="">외출복귀시간</label>
              <SelectWrapper>
                <HourSelect
                  register={register('outingEndHour')}
                  time={modal.outingEndTime}
                  name="outingEndHour"
                  setValue={setValue}
                />
                <MinSelect
                  register={register('outingEndMin')}
                  time={modal.outingEndTime}
                  name="outingEndMin"
                  setValue={setValue}
                />
                <ResetBtn
                  type="button"
                  onClick={() =>
                    setModal((prev) => ({ ...prev, outingEndTime: null }))
                  }
                >
                  <i className="fas fa-trash"></i>
                </ResetBtn>
              </SelectWrapper>
            </div>
            <button type="submit" className="btn btn-primary btn-block">
              수정적용
            </button>
          </form>
        </div>
      </div>
    </Modal>
  );
};

export default EditUserAttendanceModal;
