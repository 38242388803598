import { useQuery } from 'react-query';

import { fetchPBLBasicScore } from 'services';

const useFetchPBLBasicScore = ({ groupId }) => {
  const queryReturns = useQuery({
    queryKey: ['fetchPBLBasicScore', groupId],
    queryFn: async () => await fetchPBLBasicScore({ groupId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!groupId,
  });
  return { ...queryReturns };
};

export default useFetchPBLBasicScore;
