import { useQuery } from 'react-query';

import {
  fetchExamProblemDetail,
  fetchExamProblemList,
  fetchExamProblemUnit,
} from 'services';

const useExamProblem = ({ unitId, pageNum, pageSize, keyword, problemId }) => {
  const problemList = useQuery({
    queryKey: ['fetchExamProblemList', { unitId, pageNum, pageSize, keyword }],
    queryFn: async () =>
      await fetchExamProblemList({ unitId, pageNum, pageSize, keyword }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!pageNum,
    keepPreviousData: true,
  });

  const problemDetail = useQuery({
    queryKey: ['fetchExamProblemDetail', { problemId }],
    queryFn: async () => await fetchExamProblemDetail({ problemId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!problemId,
  });

  const problemUnits = useQuery({
    queryKey: ['fetchExamProblemUnit'],
    queryFn: async () => await fetchExamProblemUnit(),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    staleTime: 100000,
    cacheTime: 100000,
  });

  return {
    problemList,
    problemDetail,
    problemUnits,
  };
};

export default useExamProblem;
