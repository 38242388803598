import { useMutation, useQueryClient } from 'react-query';

import {
  changeClassCategory,
  changeClassChapter,
  changeClassGroup,
  changeClassUnit,
  createClassCategory,
  createClassChapter,
  createClassGroup,
  createClassUnit,
  removeClassCategory,
  removeClassChapter,
  removeClassGroup,
  removeClassUnit,
} from 'services';

const useSetClass = () => {
  const queryClient = useQueryClient();

  const addClassGroup = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassGroup({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassInfoList'],
      }),
  });

  const deleteClassGroup = useMutation({
    mutationFn: async ({ classId }) => {
      return await removeClassGroup({ classId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassInfoList'],
      }),
  });

  const editClassGroup = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeClassGroup({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassInfoList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const addClassCategory = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassCategory({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassCategoryInfo', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const editClassCategory = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeClassCategory({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassCategoryInfo', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const deleteClassCategory = useMutation({
    mutationFn: async ({ classCategoryId }) => {
      return await removeClassCategory({ classCategoryId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassCategoryInfo', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const addClassUnit = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassUnit({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassUnitList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const editClassUnit = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeClassUnit({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassUnitList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const deleteClassUnit = useMutation({
    mutationFn: async ({ unitId }) => {
      return await removeClassUnit({ unitId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassUnitList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const addClassChapter = useMutation({
    mutationFn: async ({ body }) => {
      return await createClassChapter({ body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassChapterList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const editClassChapter = useMutation({
    mutationFn: async ({ id, body }) => {
      return await changeClassChapter({ id, body });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassChapterList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  const deleteClassChapter = useMutation({
    mutationFn: async ({ chapterId }) => {
      return await removeClassChapter({ chapterId });
    },
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchClassChapterList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    addClassGroup,
    deleteClassGroup,
    editClassGroup,

    addClassCategory,
    editClassCategory,
    deleteClassCategory,

    addClassUnit,
    editClassUnit,
    deleteClassUnit,

    addClassChapter,
    editClassChapter,
    deleteClassChapter,
  };
};

export default useSetClass;
