import React from 'react';
import { Dropdown, DropdownButton } from 'react-bootstrap';

const SearchTypeSelect = ({ searchName, setSearchName, setSearchType }) => {
  const handleSearchType = (searchName, searchType) => {
    setSearchName(searchName);
    setSearchType(searchType);
  };

  return (
    <>
      <DropdownButton title={searchName} id="bg-vertical-dropdown-3">
        <Dropdown.Item onClick={() => handleSearchType('전체 검색', 'all')}>
          전체 검색
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleSearchType('아이디', 'userId')}>
          아이디
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleSearchType('이름', 'name')}>
          이름
        </Dropdown.Item>
        <Dropdown.Item onClick={() => handleSearchType('이메일', 'email')}>
          이메일
        </Dropdown.Item>
      </DropdownButton>
    </>
  );
};

export default SearchTypeSelect;
