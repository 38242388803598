import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import { ModalBadge } from '../common';

const CategoryDetailModal = ({ show, setShow, detailData }) => {
  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <Modal.Header>
        <Modal.Title className="mt-5">카테고리 상세 정보</Modal.Title>
        <Button variant="" className="close" onClick={() => setShow(false)}>
          <span>&times;</span>
        </Button>
      </Modal.Header>

      <Modal.Body>
        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="과정명" />
          </div>
          <div className="col-10">{detailData.classTitle}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="카테고리명" />
          </div>
          <div className="col-10">{detailData.categoryName}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="레벨" />
          </div>
          <div className="col-10">{detailData.level}</div>
        </div>

        <div className="d-flex flex-row align-items-center mb-3">
          <div className="col-2">
            <ModalBadge required={false} text="생성일" />
          </div>
          <div className="col-10">
            {(detailData.regDate || '').slice(0, 10)}
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer className="mb-3">
        <Button onClick={() => setShow(false)} variant="danger light">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CategoryDetailModal;
