import { format } from 'date-fns';
import { ColumnFilter } from './ColumnFilter';

export const COLUMNS = [
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'categoryName',
    Filter: ColumnFilter,
  },
  {
    Header: '제목',
    Footer: '제목',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'author',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '게시글 삭제',
    Footer: '게시글 삭제',
    accessor: 'id',
    Filter: '',
  },
];

export const CONTACT_COLUMNS = [
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'categoryName',
    Filter: ColumnFilter,
  },
  {
    Header: '제목',
    Footer: '제목',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'author',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '답변여부',
    Footer: '답변여부',
    accessor: 'isAdminAnswered',
    Filter: ColumnFilter,
  },
  {
    Header: '게시글 삭제',
    Footer: '게시글 삭제',
    accessor: 'id',
    Filter: '',
  },
];

export const LC_COLUMNS = [
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'className',
    Filter: ColumnFilter,
  },
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'categoryName',
    Filter: ColumnFilter,
  },
  {
    Header: '제목',
    Footer: '제목',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'author',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'createdAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '게시글 삭제',
    Footer: '게시글 삭제',
    accessor: 'lcPostId',
    Filter: '',
  },
];

export const STUDY_GROUP_POST_COLUMNS = [
  {
    Header: '스터디그룹',
    Footer: '스터디그룹',
    accessor: 'studyGroupName',
    Filter: ColumnFilter,
  },
  {
    Header: '제목',
    Footer: '제목',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'userName',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const USER_COLUMNS = [
  {
    Header: 'Id',
    Footer: 'Id',
    accessor: 'userId',
    Filter: '', //disableFilters: true,
  },
  {
    Header: '이름',
    Footer: '이름',
    accessor: 'name',
    Filter: ColumnFilter,
  },
  {
    Header: 'Type',
    Footer: 'Type',
    accessor: 'type',
    Filter: ColumnFilter,
  },
  {
    Header: 'email',
    Footer: 'email',
    accessor: 'email',
    Filter: ColumnFilter,
  },
  {
    Header: 'group',
    Footer: 'group',
    accessor: 'groupName',
    Filter: ColumnFilter,
  },
  {
    Header: 'Phone',
    Footer: 'Phone',
    accessor: 'phone',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'Expire',
    Footer: 'Expire',
    accessor: 'expireYn',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];
export const STUDY_GROUP_USER_COLUMNS = [
  {
    Header: 'Id',
    Footer: 'Id',
    accessor: 'userId',
    Filter: '', //disableFilters: true,
  },
  {
    Header: '이름',
    Footer: '이름',
    accessor: 'name',
    Filter: ColumnFilter,
  },
  {
    Header: 'Type',
    Footer: 'Type',
    accessor: 'type',
    Filter: ColumnFilter,
  },
  {
    Header: 'email',
    Footer: 'email',
    accessor: 'email',
    Filter: ColumnFilter,
  },
  {
    Header: 'group',
    Footer: 'group',
    accessor: 'groupName',
    Filter: ColumnFilter,
  },
  {
    Header: 'Phone',
    Footer: 'Phone',
    accessor: 'phone',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'sGroupRegDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'Status',
    Footer: 'Status',
    accessor: 'sGroupMemberStatus',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CLASS_REPLY_COLUMNS = [
  {
    Header: '댓글',
    Footer: '댓글',
    accessor: 'content',
    Filter: ColumnFilter,
  },
  {
    Header: 'userId',
    Footer: 'userId',
    accessor: 'userId',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'userName',
    Filter: ColumnFilter,
  },
  {
    Header: '게시글',
    Footer: '게시글',
    accessor: 'lcPostTitle',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const POST_REPLY_COLUMNS = [
  {
    Header: '게시글',
    Footer: '게시글',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '작성자',
    Footer: '작성자',
    accessor: 'replyAuthor',
    Filter: ColumnFilter,
  },
  {
    Header: '내용',
    Footer: '내용',
    accessor: 'content',
    Filter: ColumnFilter,
  },
  {
    Header: 'Updated',
    Footer: 'Updated',
    accessor: 'regDate',
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const TASK_RESULT_COLUMNS = [
  {
    Header: '제출자',
    Footer: '제출자',
    accessor: 'author',
    Filter: ColumnFilter,
  },
  {
    Header: '제출일시',
    Footer: '제출일시',
    accessor: 'createdDateTime',
    Cell: ({ value }) => {
      return value.slice(0, 10);
    },
    Filter: '',
  },
  {
    Header: '파일',
    Footer: '파일',
    accessor: 'originalFileName',
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const TASK_MANAGER_COLUMNS = [
  {
    Header: '클래스',
    Footer: '클래스',
    accessor: 'className',
    Filter: ColumnFilter,
  },
  {
    Header: '출제자',
    Footer: '출제자',
    accessor: 'author',
    Filter: ColumnFilter,
  },
  {
    Header: '과제타이틀',
    Footer: '과제타이틀',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: 'Start',
    Footer: 'Start',
    accessor: 'startDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'End',
    Footer: 'End',
    accessor: 'endDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const STUDY_COLUMNS = [
  {
    Header: '그룹명',
    Footer: '그룹명',
    accessor: 'studyGroupName',
    Filter: ColumnFilter,
  },
  {
    Header: '그룹장',
    Footer: '그룹장',
    accessor: 'userName',
    Filter: ColumnFilter,
  },
  {
    Header: '인원',
    Footer: '인원',
    accessor: 'memberCount',
    Cell: ({ row }) => {
      return `${row.original.memberCount} / ${row.original.limitCount}`;
    },
    Filter: ColumnFilter,
  },

  {
    Header: '개설일',
    Footer: '개설일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '승인일',
    Footer: '승인일',
    accessor: 'approvalDate',
    Cell: ({ value }) => {
      return value !== null ? format(new Date(value), 'yyyy.MM.dd') : '대기중';
    },
    Filter: '',
  },
  {
    Header: '승인',
    Footer: '승인',
    accessor: 'status',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const STG_ROOM_COLUMNS = [
  {
    Header: '그룹',
    Footer: '그룹',
    accessor: 'studyGroupName',
    Filter: ColumnFilter,
  },
  {
    Header: '내용',
    Footer: '내용',
    accessor: 'content',
    Filter: ColumnFilter,
  },
  {
    Header: '예약일시',
    Footer: '예약일시',
    accessor: 'reservationDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd-hh');
    },
    Filter: ColumnFilter,
  },
  {
    Header: '개설일',
    Footer: '개설일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '승인일',
    Footer: '승인일',
    accessor: 'approvalDate',
    Cell: ({ value }) => {
      return value !== null ? format(new Date(value), 'yyyy.MM.dd') : '대기중';
    },
    Filter: '',
  },
  {
    Header: '승인',
    Footer: '승인',
    accessor: 'approvalYn',
    Cell: ({ value }) => {
      return value === 'Y' ? '승인됨' : '승인 안됨';
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'ZOOM링크',
    Footer: 'ZOOM링크',
    accessor: 'zoomUrl',
    Cell: ({ value }) => {
      return value !== null ? value : '등록된 링크 없음';
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const ZOOM_LINK_COLUMNS = [
  {
    Header: 'Id',
    Footer: 'Id',
    accessor: 'id',
    Filter: '', //disableFilters: true,
  },
  {
    Header: 'Gather링크',
    Footer: 'Gather링크',
    accessor: 'zoomUrl',
    Filter: ColumnFilter,
  },
  {
    Header: '개설일',
    Footer: '개설일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '사용',
    Footer: '사용',
    accessor: 'useYn',
    Filter: ColumnFilter,
  },
  {
    Header: 'Gather',
    Footer: 'Gather',
    accessor: '',
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CLOUD_ACCOUNT_COLUMNS = [
  {
    Header: '클라우드 계정',
    Footer: '클라우드 계정',
    accessor: 'username',
    Filter: ColumnFilter,
  },
  {
    Header: '패스워드',
    Footer: '패스워드',
    accessor: 'password',
    Filter: '',
  },
  {
    Header: '주소',
    Footer: '주소',
    accessor: 'url',
    Filter: ColumnFilter,
  },
  {
    Header: 'accessKeyId',
    Footer: 'accessKeyId',
    accessor: 'accessKeyId',
    Filter: ColumnFilter,
  },
  {
    Header: '연결된 사용자',
    Footer: '연결된 사용자',
    accessor: 'userId',
    Filter: ColumnFilter,
  },
  {
    Header: '생성일',
    Footer: '생성일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return value !== null ? format(new Date(value), 'yyyy.MM.dd') : '대기중';
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const PRE_LEARNING_COLUMNS = [
  {
    Header: 'Id',
    Footer: 'Id',
    accessor: 'id',
    Filter: '', //disableFilters: true,
  },
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'className',
    Filter: ColumnFilter,
  },
  {
    Header: 'title',
    Footer: 'title',
    accessor: 'title',
    Filter: '',
  },
  {
    Header: 'totalTime',
    Footer: 'totalTime',
    accessor: 'totalTime',
    Filter: ColumnFilter,
  },
  {
    Header: '생성일',
    Footer: '생성일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return value !== null ? format(new Date(value), 'yyyy.MM.dd') : '대기중';
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CLASS_INFO_COLUMNS = [
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'className',
    Filter: ColumnFilter,
  },
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'classCategoryName',
    Filter: '',
  },
  {
    Header: '유닛',
    Footer: '유닛',
    accessor: 'unitName',
    Filter: ColumnFilter,
  },
  {
    Header: '챕터',
    Footer: '챕터',
    accessor: 'chapterName',
    Filter: ColumnFilter,
  },
  {
    Header: '목차시작일',
    Footer: '목차시작일',
    accessor: 'chapterDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'video',
    Footer: 'video',
    accessor: 'videoName',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CLASS_COLUMNS = [
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '총 교육시간',
    Footer: '총 교육시간',
    accessor: 'totalTime',
    Filter: ColumnFilter,
  },
  {
    Header: '기수',
    Footer: '기수',
    accessor: 'count',
    Filter: ColumnFilter,
  },
  {
    Header: '다시보기 가능일',
    Footer: '다시보기 가능일',
    accessor: 'replayAvailableDays',
    Filter: ColumnFilter,
  },
  {
    Header: '시작일',
    Footer: '시작일',
    accessor: 'startDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: ColumnFilter,
  },
  {
    Header: '종료일',
    Footer: '종료일',
    accessor: 'endDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'status',
    Footer: 'status',
    accessor: 'status',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CLASS_CATEGORY_COLUMNS = [
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'classTitle',
    Filter: '',
  },
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'categoryName',
    Filter: '',
  },
  {
    Header: '생성일',
    Footer: '생성일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const UNIT_COLUMNS = [
  {
    Header: 'title',
    Footer: 'title',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'classCategoryName',
    Filter: ColumnFilter,
  },
  {
    Header: '유닛 총 시간',
    Footer: '유닛 총 시간',
    accessor: 'unitTime',
    Cell: ({ value }) => {
      return `${value}H`;
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const CHAPTER_COLUMNS = [
  {
    Header: 'day',
    Footer: 'day',
    accessor: 'day',
    Filter: ColumnFilter,
  },
  {
    Header: 'title',
    Footer: 'title',
    accessor: 'title',
    Filter: ColumnFilter,
  },
  {
    Header: '유닛명',
    Footer: '유닛명',
    accessor: 'unitName',
    Filter: ColumnFilter,
  },
  {
    Header: '생성일',
    Footer: '생성일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const EXAM_PROBLEM_COLUMNS = [
  {
    Header: 'Type',
    Footer: 'Type',
    accessor: 'examType',
    Filter: ColumnFilter,
  },
  {
    Header: '난이도',
    Footer: '난이도',
    accessor: 'examLevel',
    Cell: ({ value }) => {
      return value === 0 ? '하' : value === 1 ? '중' : '상';
    },
    Filter: ColumnFilter,
  },
  {
    Header: '단원명',
    Footer: '단원명',
    accessor: 'unitName',
    Filter: ColumnFilter,
  },
  {
    Header: '문제',
    Footer: '문제',
    accessor: 'examQuestion',
    Filter: ColumnFilter,
  },
  {
    Header: '출제자',
    Footer: '출제자',
    accessor: 'examMakerName',
    Filter: ColumnFilter,
  },
  {
    Header: '출제일',
    Footer: '출제일',
    accessor: 'examMakeDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const EXAM_COLUMNS = [
  {
    Header: '과정명',
    Footer: '과정명',
    accessor: 'groupCourse',
    Filter: ColumnFilter,
  },
  {
    Header: '카테고리',
    Footer: '카테고리',
    accessor: 'classCategoryName',
    Filter: ColumnFilter,
  },
  {
    Header: 'High',
    Footer: 'High',
    accessor: 'highLevelCount',
    Cell: ({ value }) => {
      return (value / 20).toFixed(2);
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'Middle',
    Footer: 'Middle',
    accessor: 'middleLevelCount',
    Cell: ({ value }) => {
      return (value / 50).toFixed(2);
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'Low',
    Footer: 'Low',
    accessor: 'lowLevelCount',
    Cell: ({ value }) => {
      return (value / 30).toFixed(2);
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'timeLimit',
    Footer: 'timeLimit',
    accessor: 'timeLimit',
    Cell: ({ value }) => {
      return String(value / 60).slice(0, 5) + '분';
    },
    Filter: ColumnFilter,
  },
  {
    Header: 'Type',
    Footer: 'Type',
    accessor: 'count',
    Cell: ({ value }) => {
      let result;
      if (value === 1) {
        result = '사전';
      } else if (value === 2) {
        result = '사후';
      } else {
        result = '재시험';
      }
      return result;
    },
    Filter: ColumnFilter,
  },
  {
    Header: '상태',
    Footer: '상태',
    accessor: 'status',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const EXAM_RESULT_COLUMNS = [
  {
    Header: '시험명',
    Footer: 'examName',
    accessor: 'examName',
    Filter: ColumnFilter,
  },
  {
    Header: '그룹',
    Footer: '그룹',
    accessor: 'groupName',
    Filter: ColumnFilter,
  },
  {
    Header: '유저',
    Footer: '유저',
    accessor: 'userName',
    Filter: ColumnFilter,
  },
  {
    Header: '시험시작일',
    Footer: '시험시작일',
    accessor: 'examStartDateTime',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '시험제출일',
    Footer: '시험제출일',
    accessor: 'examSubmitDateTime',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd');
    },
    Filter: '',
  },
  {
    Header: '점수',
    Footer: '점수',
    accessor: 'examScoreResult',
    Filter: ColumnFilter,
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const EXAM_CONSULTING_COLUMNS = [
  {
    Header: '상담현황',
    Footer: '상담현황',
    accessor: 'resultComment',
    Filter: ColumnFilter,
  },
  {
    Header: '상담 신청자',
    Footer: '상담 신청자',
    accessor: 'userName',
    Filter: ColumnFilter,
  },
  {
    Header: '내용',
    Footer: '내용',
    accessor: 'content',
    Filter: ColumnFilter,
  },
  {
    Header: '상담 날짜',
    Footer: '상담 날짜',
    accessor: 'consultingDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd-HH:mm');
    },
    Filter: '',
  },
  {
    Header: '상담수락일',
    Footer: '상담수락일',
    accessor: 'approvalDate',
    Cell: ({ value }) => {
      return value ? format(new Date(value), 'yyyy.MM.dd-HH:mm') : '-';
    },
    Filter: '',
  },
  {
    Header: '상담 대응 관리자',
    Footer: '상담 대응 관리자',
    accessor: 'approvalUserId',
    Cell: ({ value }) => {
      return value ? value : '-';
    },
    Filter: '',
  },
  {
    Header: '신청일',
    Footer: '신청일',
    accessor: 'regDate',
    Cell: ({ value }) => {
      return format(new Date(value), 'yyyy.MM.dd-HH:mm');
    },
    Filter: '',
  },
  {
    Header: 'more',
    Footer: 'more',
    accessor: '',
    Filter: '',
  },
];

export const PBL_CATEGORY_COLUMNS = [
  {
    Header: 'TITLE',
    Footer: 'TITLE',
    accessor: 'unitTitle',
    Filter: ColumnFilter,
  },
  {
    Header: 'PBL 출제',
    Footer: 'PBL 출제',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header: 'LMS 메뉴 활성화',
    Footer: 'LMS 메뉴 활성화',
    accessor: '',
    Filter: '',
  },
];

export const PBL_RESULT_COLUMNS = [
  {
    Header: 'NO.',
    Footer: 'NO.',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header: '이름',
    Footer: '이름',
    accessor: 'name',
    Filter: ColumnFilter,
  },
  {
    Header: '제출일시',
    Footer: '제출일시',
    accessor: 'modifiedAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'yy.MM.dd HH:mm:ss');
    },
    Filter: ColumnFilter,
  },
  {
    Header: '결과물',
    Footer: '결과물',
    accessor: 'originalFileName',
    Filter: ColumnFilter,
  },
  {
    Header: 'MORE',
    Footer: 'MORE',
    accessor: '',
    Filter: '',
  },
];

export const PBL_RESULT_PAGE_COLUMNS = [
  {
    Header: '그룹',
    accessor: 'groupName',
    Filter: ColumnFilter,
  },
  {
    Header: '유닛',
    accessor: 'unitTitle',
    Filter: ColumnFilter,
  },
  {
    Header: '레벨',
    accessor: '',
    Filter: ColumnFilter,
  },
  {
    Header: '이름',
    accessor: 'name',
    Filter: ColumnFilter,
  },
  {
    Header: '제출날짜',
    accessor: 'modifiedAt',
    Cell: ({ value }) => {
      return format(new Date(value), 'yy.MM.dd HH:mm:ss');
    },
    Filter: '',
  },
  {
    Header: '파일',
    accessor: 'originalFileName',
    Filter: ColumnFilter,
  },
  {
    Header: 'MORE',
    accessor: '',
    Filter: ColumnFilter,
  },
];
