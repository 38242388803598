/* eslint-disable*/
import React from 'react';
import styled from 'styled-components';

const lms_url = process.env.REACT_APP_LMS_URL;

const LmsPage = () => {
  return (
    <Wrapper>
      <a href={lms_url}>
        <div className="button">Go to LMS</div>
      </a>
    </Wrapper>
  );
};

export default LmsPage;

const Wrapper = styled.div`
  margin-right: 10px;

  a {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  div.button {
    border: none;
    background: linear-gradient(
      89.85deg,
      rgb(238, 36, 36) 10.06%,
      rgb(239, 83, 16) 86.56%
    );
    color: white;
    width: 100px;
    height: 40px;
    border-radius: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    :hover {
      background: #fff;
      color: #ec7807;
      border: #ec7807 1px solid;
    }
    :active {
      scale: 0.98;
    }
  }
`;
