import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { format } from 'date-fns';
import styled from 'styled-components';

import { useStudyGroup } from 'hooks';
import { confirmSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { Badge } from 'components/common';
import { CardBody, CardHeader, Wrapper } from 'components/layouts/common';
import { UserSectionByUser, UserListByStGroup } from 'components/Group';
import StudyGroupStudyRoomReserve from '../../components/StudyGroup/StudyGroupStudyRoomReserve';
import { StudyGroupEditModal } from '../../components/StudyGroup';
import StudyGroupBoard from './StudyGroupBoard';

const StudyGroupDetail = () => {
  const { studyGroupId } = useParams();
  const history = useHistory();

  const [editModal, setEditModal] = useState(false);

  const { studyGroupDetail, deleteStudyGroup } = useStudyGroup({
    studyGroupId,
  });
  const { data } = studyGroupDetail || {};

  const handleOpenEdit = () => {
    setEditModal(true);
  };

  const handleDelete = async () => {
    const confirm = await confirmSwal({
      title: '스터디그룹을 삭제합니다.',
      text: '삭제를 진행하시겠습니까?',
    });

    if (confirm) {
      const { status } = await deleteStudyGroup.mutateAsync({ studyGroupId });
      if (status === 200) {
        history.push('/study-group');
        await successSwal({ text: MESSAGES.STUDYGROUP_DELETE_SUCCESS });
      }
    }
  };

  const handleBack = () => history.goBack();

  return (
    <>
      <Wrapper>
        <CardHeader>
          <div>
            <UserSectionByUser name={data?.userName} id={data?.userId} />
          </div>
          <div className="d-sm-flex">
            <div onClick={handleBack} className="btn btn-primary px-3 light">
              <i className="fa fa-reply"></i>
            </div>
            <div
              className="btn btn-primary px-3 light ml-2"
              onClick={handleOpenEdit}
            >
              <i className="fa fa-edit"></i>
            </div>
            <div
              onClick={handleDelete}
              className="btn btn-primary px-3 light ml-2"
            >
              <i className="fa fa-trash"></i>
            </div>
          </div>
        </CardHeader>

        <CardBody>
          <div className="read-content">
            <div className="media mb-5 mt-3 mr-2">
              <div className="media-body d-flex align-items-center justify-content-between">
                <div>
                  <h4 className="card-title mt-3">{data?.studyGroupName}</h4>
                  <div className="d-flex">
                    <Badge
                      style={{ marginRight: '5px' }}
                      text={data?.status}
                      color="#ec7422"
                    />
                    <Badge
                      text={`${data?.memberCount}명 / ${data?.limitCount}`}
                    />
                  </div>
                </div>
                <div>
                  {data?.regDate && (
                    <span>
                      개설신청일:
                      {format(new Date(data?.regDate), 'yyyy.MM.dd')}
                    </span>
                  )}
                  <br />
                  {data?.status === 'READY' ? (
                    <span className="mt-1">승인 대기중입니다</span>
                  ) : (
                    <span className="mt-1">
                      승인일{': '}
                      {data?.approvalDate &&
                        format(new Date(data?.approvalDate), 'yyyy.MM.dd')}
                    </span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="read-content">
            <div className="media mb-5 mt-3 mr-2 ml-2">
              <div className="media-body">
                <h3>설명</h3>
                <StyledContent>
                  {data?.content ? data?.content : '[정보없음]'}
                </StyledContent>
                <h3>조장</h3>
                <StyledContent>
                  {data?.userName ? data?.userName : '[정보없음]'}
                </StyledContent>
                <h3>초기멤버</h3>
                <StyledContent>
                  {data?.foundingMembers ? data?.foundingMembers : '[정보없음]'}
                </StyledContent>
                <h3>일정</h3>
                <StyledContent>
                  {data?.meetingTime ? data?.meetingTime : '[정보없음]'}
                </StyledContent>
              </div>
            </div>
          </div>
        </CardBody>
      </Wrapper>

      {/*스터디그룹 수정 모달*/}
      <StudyGroupEditModal editModal={editModal} setEditModal={setEditModal} />

      {/* 스터디그룹 게시글 리스트 */}
      <StudyGroupBoard />

      {/* 해당 그룹에 속한 멤버 목록 */}
      {data && <UserListByStGroup studyGroupId={studyGroupId} />}

      {/* 해당 그룹의 스터디룸 예약 내역, 목록 */}
      <StudyGroupStudyRoomReserve />
    </>
  );
};

const StyledContent = styled.p`
  margin-left: 20px;
  white-space: pre-line;
`;

const FileItem = styled.div`
  cursor: pointer;
  transition: 0.2s;

  &:hover {
    color: #0c85d0;
    text-decoration: underline;
  }
`;

export default StudyGroupDetail;
