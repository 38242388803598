import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button, Modal } from 'react-bootstrap';

import { useSetClass } from 'hooks';
import { errorSwal, successSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { ModalBadge, ValidationErrorMsg } from '../common';
import CustomSwitchNoConfirm from '../ui/CustomSwitchNoConfirm';

const UnitEditModal = ({ show, setShow, detailData }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onChange',
  });

  const [lectureUse, setLectureUse] = useState(false);

  const { editClassUnit } = useSetClass();

  const submit = async (form) => {
    const body = {
      classCategoryId: detailData.classCategoryId,
      content: form.content,
      isLecture: lectureUse,
      title: form.title,
      unitTime: form.unitTime,
    };

    try {
      const { status } = await editClassUnit.mutateAsync({
        id: detailData.id,
        body,
      });
      if (status === 200) {
        setShow(false);
        await successSwal({ text: MESSAGES.UNIT_EDIT_SUCCESS });
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.UNIT_EDIT_FAIL });
    }
  };

  const handleLectureConfirm = () => {
    setLectureUse(!lectureUse);
  };

  useEffect(() => {
    reset({
      ...detailData,
    });

    setLectureUse(detailData?.lectureYn === 'Y');
  }, [detailData]);

  return (
    <Modal className="fade bd-example-modal-lg" show={show} size="lg">
      <form onSubmit={handleSubmit(submit)}>
        <Modal.Header>
          <Modal.Title className="mt-5">유닛 수정</Modal.Title>
          <Button variant="" className="close" onClick={() => setShow(false)}>
            <span>&times;</span>
          </Button>
        </Modal.Header>

        <Modal.Body>
          <StyledInputBox>
            <ModalBadge text="카테고리명" required={false} />
            <div>
              <span>{detailData.classCategoryName}</span>
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="유닛명" />
            <div>
              <input
                type="text"
                placeholder="유닛명을 입력하세요."
                className="form-control"
                name="title"
                {...register('title', {
                  required: true,
                })}
              />
              {errors.title && (
                <ValidationErrorMsg text="유닛명을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="내용" />
            <div>
              <input
                type="text"
                placeholder="내용을 입력하세요."
                className="form-control"
                name="content"
                {...register('content', {
                  required: true,
                })}
              />
              {errors.content && (
                <ValidationErrorMsg text="내용을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="유닛 시간" />
            <div>
              <input
                type="number"
                min={0}
                placeholder="유닛 시간을 입력하세요."
                className="form-control"
                name="unitTime"
                {...register('unitTime', {
                  required: true,
                })}
              />
              {errors.unitTime && (
                <ValidationErrorMsg text="유닛 시간을 입력해 주세요." />
              )}
            </div>
          </StyledInputBox>

          <StyledInputBox>
            <ModalBadge text="수업유무" />
            <div>
              <CustomSwitchNoConfirm
                isTrue={lectureUse}
                handleToggle={handleLectureConfirm}
              />
              <span>{lectureUse ? '있음' : '없음'}</span>
            </div>
          </StyledInputBox>
        </Modal.Body>

        <Modal.Footer className="mb-3">
          <button type="submit" className="btn btn-primary mr-3">
            수정
          </button>
          <Button onClick={() => setShow(false)} variant="danger light">
            Close
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const StyledInputBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  > div:nth-child(2) {
    display: flex;

    span {
      margin-left: 5px;
    }
  }

  > div:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
`;

export default UnitEditModal;
