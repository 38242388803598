import React from 'react';
import styled from 'styled-components';
import swal from 'sweetalert';

import { useRemoveComment } from 'hooks';

const PBLComment = ({ author, modifiedAt, comment, pblCommentId }) => {
  const timeFormat = `${modifiedAt?.slice(0, 10)} ${modifiedAt?.slice(11, 16)}`;

  const { mutateAsync: removeComment } = useRemoveComment();

  const handleRemoveComment = async (commentId) => {
    const result = await swal({
      title: '해당 의견을 정말로 지우시겠습니까?',
      icon: 'warning',
      buttons: ['취소', '삭제'],
      dangerMode: true,
    });

    if (result) {
      try {
        const response = await removeComment({ commentId });
        if (response.status === 204) {
          swal({
            title: `해당 의견을 삭제하였습니다.`,
            icon: 'success',
            buttons: '확인',
          });
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <CommentBox>
      <div className="comment-title">
        <b>{author}</b>
        <span className="time">{timeFormat}</span>
        <span
          className="delete-button"
          onClick={() => handleRemoveComment(pblCommentId)}
        >
          삭제하기
        </span>
      </div>

      <div className="comment">
        <span>{comment}</span>
      </div>
    </CommentBox>
  );
};

const CommentBox = styled.div`
  width: 100%;
  padding: 15px 20px 20px;
  box-shadow: 1px 1px 3px #d3d3d3;
  border-radius: 10px;
  margin-bottom: 20px;

  .comment-title {
    margin-bottom: 5px;
    width: 100%;

    b {
      font-size: 18px;
      font-weight: bold;
      color: #333;
    }
  }

  .time {
    color: #969696;
    padding-left: 20px;
  }

  .comment {
    font-size: 14px;
    white-space: pre-wrap;
    span {
      white-space: pre-wrap;
    }
  }

  .delete-button {
    min-width: 80px;
    padding: 5px 10px;
    background-color: #da565f;
    border: 1px solid #eee;
    border-radius: 10px;
    cursor: pointer;
    text-align: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    float: right;

    &:hover {
      filter: brightness(95%);
    }

    &:first-child {
      background-color: #da565f;
    }
  }
`;

export default PBLComment;
