import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { QueryClientProvider, QueryClient } from 'react-query';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import ThemeContext from './context/ThemeContext';
import { store } from './store/store';
import reportWebVitals from './reportWebVitals';
import App from './App';
import AxiosConfig from './Axios';

const queryClient = new QueryClient();
let persistor = persistStore(store);

Sentry.init({
  dsn: process.env.REACT_APP_DSN,
  integrations: [new Integrations.BrowserTracing({ tracingOrigins: ['*'] })],
  tracesSampleRate: 1.0,
  enabled: process.env.REACT_APP_MODE === 'production',
});

ReactDOM.render(
  <>
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter basename="/">
            <ThemeContext>
              <React.StrictMode>
                <AxiosConfig />
                <App />
              </React.StrictMode>
            </ThemeContext>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </QueryClientProvider>
  </>,
  document.getElementById('root')
);
reportWebVitals();
