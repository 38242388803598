import React, { useEffect } from "react";
import { TimeSelect } from "./style";

const HourSelect = ({ register, time, setValue, name }) => {
  useEffect(() => {
    setValue(name, time ? new Date(time).getHours() + "" : "");
  }, [time]);

  const numberFormatter = (num, digits) => {
    const zeros = new Array(digits - 1).fill("0").join().replace(",");
    const minNum = Number("1" + zeros);

    if (Number(num) < minNum) {
      return zeros + num;
    }
    return String(num);
  };

  return (
    <>
      <TimeSelect {...register}>
        <option value="" selected={!time}>
          시간
        </option>
        {new Array(24).fill(0).map((v, i) => (
          <option
            key={i}
            value={i}
            selected={time ? new Date(time).getHours() === i : false}
          >
            {numberFormatter(i, 2) + "시"}
          </option>
        ))}
      </TimeSelect>
    </>
  );
};

export default HourSelect;
