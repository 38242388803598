import { BASE_URL } from './config';

import axios from 'axios';

/* 과제 출제 목록 조회 */
export const fetchTaskList = async ({
  pageNum,
  pageSize,
  classId,
  keyword,
}) => {
  const params = {
    pageNum,
    pageSize,
    classId,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/tasks/api/v1`, { params });
};

/* 과제 상세 조회 */
export const fetchTaskDetail = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/tasks/api/v1/${id}`);
};

/* 과제 상세 게시글 첨부 파일 조회 */
export const fetchTaskDetailFile = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/tasks/api/v1/${id}/attachments`);
};

/* 과제 상세 게시글 첨부 파일 다운로드 */
export const downloadTaskAttachment = async ({ taskId }) => {
  return await axios.get(
    `${BASE_URL}/admin/tasks/api/v1/${taskId}/attachments/download`,
    {
      responseType: 'blob',
    }
  );
};

/* taskId를 통해 해당 과제 제출물 리스트 조회 */
export const fetchTaskResultListByTaskId = async ({
  taskId,
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = { pageNum, pageSize, keyword };

  return await axios.get(
    `${BASE_URL}/admin/tasks/api/v1/${taskId}/submissions`,
    { params }
  );
};

/* 과제 제출물 전체 다운로드 */
export const downloadAllResult = async ({ taskId }) => {
  return await axios.get(
    `${BASE_URL}/admin/tasks/api/v1/${taskId}/submissions/zip`,
    {
      responseType: 'blob',
    }
  );
};

/* 과제 개인별 제출물 다운로드 */
export const downloadPersonalTaskResult = async ({ id }) => {
  return await axios.get(`${BASE_URL}/admin/tasks/api/v1/submission/${id}`, {
    responseType: 'blob',
  });
};

/* 과제 제출물 파일 정보 조회 */
export const fetchTaskResultFileDetail = async ({ taskResultId }) => {
  return await axios.get(`${BASE_URL}/admin/tasks/fileByTask/${taskResultId}`);
};

/* 과제 제출물 상세 점수 및 댓글 조회 */
export const fetchTaskResultScore = async ({ taskResultId }) => {
  return await axios.get(`${BASE_URL}/admin/tasks/scoreByTask/${taskResultId}`);
};
