import { BASE_URL } from './config';

import axios from 'axios';

/* 상담 신청 목록 조회 */
export const fetchConsultingList = async ({
  groupId,
  pageNum,
  pageSize,
  keyword,
}) => {
  const params = {
    groupId,
    pageNum,
    pageSize,
    keyword,
  };

  return await axios.get(`${BASE_URL}/admin/consultations`, { params });
};

/* 상담 신청 삭제 (완료된 상담 신청 삭제 불가능) */
export const removeConsult = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/consultations/${id}`);
};

/* 상담 신청 상태 변경 (상담대기, 상담승인, 상담완료) */
export const changeConsultStatus = async ({ id }) => {
  return await axios.put(`${BASE_URL}/admin/consultations/${id}`);
};

/* 해당 유저의 상담 신청 목록 조회 */
export const fetchUserConsultingList = async ({ userId }) => {
  return await axios.get(`${BASE_URL}/admin/consultations/${userId}`);
};
