import { useQuery } from 'react-query';

import { fetchConsultingList, fetchUserConsultingList } from 'services/consult';

const useConsultingHook = ({ groupId, pageNum, pageSize, keyword, userId }) => {
  const consultingList = useQuery({
    queryKey: ['fetchConsultingList', { groupId, pageNum, pageSize, keyword }],
    queryFn: async () =>
      await fetchConsultingList({ groupId, pageNum, pageSize, keyword }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!pageNum,
  });

  const userConsultingList = useQuery({
    queryKey: ['fetchUserConsultingList', { userId }],
    queryFn: async () => await fetchUserConsultingList({ userId }),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    enabled: !!userId,
  });

  return {
    consultingList,
    userConsultingList,
  };
};

export default useConsultingHook;
