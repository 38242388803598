import { useMutation, useQueryClient } from 'react-query';

import { removePBLProblem } from 'services/pbl';

const useRemovePBLProblem = ({ problemId }) => {
  const queryClient = useQueryClient();

  const queryReturns = useMutation({
    mutationFn: async () => {
      return await removePBLProblem({ problemId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchPBLProblems'],
      }),
  });
  return { ...queryReturns };
};

export default useRemovePBLProblem;
