import { useQuery } from 'react-query';

import {
  fetchClassCategoryInfo,
  fetchClassChapterList,
  fetchClassGroupDetail,
  fetchClassInfoList,
  fetchClassUnitList,
  fetchClassUnitListByCategoryId,
} from 'services';

const useClass = ({
  pageNum,
  pageSize,
  keyword,
  categoryId,
  type,
  id,
  classId,
  classCategoryId,
}) => {
  const classInfoList = useQuery({
    queryKey: ['fetchClassInfoList', { pageNum, pageSize, keyword }],
    queryFn: async () => {
      const response = await fetchClassInfoList({ pageNum, pageSize, keyword });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: type === 'class',
    staleTime: 30000,
    cacheTime: 30000,
  });

  const classGroupDetail = useQuery({
    queryKey: ['fetchClassGroupDetail', { id }],
    queryFn: async () => {
      const response = await fetchClassGroupDetail({ id });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!id,
  });

  const classCategoryInfo = useQuery({
    queryKey: [
      'fetchClassCategoryInfo',
      { pageNum, pageSize, keyword, classId },
    ],
    queryFn: async () => {
      const response = await fetchClassCategoryInfo({
        pageNum,
        pageSize,
        keyword,
        classId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: type === 'category',
    staleTime: 30000,
    cacheTime: 30000,
  });

  const classUnitList = useQuery({
    queryKey: [
      'fetchClassUnitList',
      { pageNum, pageSize, keyword, classId, classCategoryId },
    ],
    queryFn: async () => {
      const response = await fetchClassUnitList({
        pageNum,
        pageSize,
        keyword,
        classId,
        classCategoryId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: type === 'unit',
  });

  const classChapterList = useQuery({
    queryKey: [
      'fetchClassChapterList',
      { pageNum, pageSize, keyword, classId },
    ],
    queryFn: async () => {
      const response = await fetchClassChapterList({
        pageNum,
        pageSize,
        keyword,
        classId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: type === 'chapter',
  });

  const classUnitListByCategoryId = useQuery({
    queryKey: ['fetchClassUnitListByCategoryId', { categoryId }],
    queryFn: async () => {
      const response = await fetchClassUnitListByCategoryId({
        categoryId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!categoryId,
  });

  return {
    classInfoList,
    classGroupDetail,

    classCategoryInfo,
    classUnitList,
    classChapterList,
    classUnitListByCategoryId,
  };
};

export default useClass;
