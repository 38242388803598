import { BASE_URL } from './config';

import axios from 'axios';

/* 원격 수업 정보 조회 */
export const fetchRemoteClassList = async () => {
  return await axios.get(`${BASE_URL}/admin/remote-class-link`);
};

/* 원격수업 정보 추가 */
export const createRemoteClass = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/remote-class-link`, { ...body });
};

/* 원격수업 정보 수정 */
export const changeRemoteClass = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/remote-class-link/${id}`, {
    ...body,
  });
};

/* 원격수업 정보 삭제 */
export const removeRemoteClass = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/remote-class-link/${id}`);
};

/* 원격수업 정보 사용 여부 상태 수정 */
export const changeGatherLinkUse = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/remote-class-link/${id}/is-use`, {
    ...body,
  });
};

/* 클라우드 계정 목록 조회 */
export const fetchCloudAccountList = async ({ pageNum, pageSize, keyword }) => {
  const params = {
    pageNum,
    pageSize,
    keyword,
  };

  return axios.get(`${BASE_URL}/admin/cloud-accounts`, { params });
};

/* 클라우드 계정 등록 */
export const createCloudAccount = async ({ body }) => {
  return await axios.post(`${BASE_URL}/admin/cloud-accounts`, { ...body });
};

/* 클라우드 계정 수정 */
export const changeCloudAccount = async ({ id, body }) => {
  return await axios.put(`${BASE_URL}/admin/cloud-accounts/${id}`, { ...body });
};

/* 클라우드 계정 삭제 */
export const removeCloudAccount = async ({ id }) => {
  return await axios.delete(`${BASE_URL}/admin/cloud-accounts/${id}`);
};

/* groupId를 통해 해당 클래스에 속한 유저 목록 조회 */
export const fetchUserListByGroupId = async ({ groupId }) => {
  return await axios.get(`${BASE_URL}/admin/users/group/${groupId}`);
};
