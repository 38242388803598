/* eslint-disable*/
import React, { Fragment } from 'react';
import styled from 'styled-components';

import { CardBody, Wrapper } from '../../layouts';
import { StudyGroupCommentItem } from '../../StudyGroup';

export const StudyBoardComments = ({ postByReplyList }) => {
  return (
    <>
      <Wrapper>
        <CardBody>
          <CommentList>
            <div className="count">
              댓글<span>{postByReplyList.length || 0}</span>개
            </div>

            <div className="comment-list">
              {!!postByReplyList.length ? (
                postByReplyList.map((e, index) => (
                  <StudyGroupCommentItem key={index} replyInfo={e} />
                ))
              ) : (
                <div className="no-comment">댓글이 존재하지 않습니다.</div>
              )}
            </div>
          </CommentList>
        </CardBody>
      </Wrapper>
    </>
  );
};

const CommentList = styled.div`
  div.count {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;

    span {
      margin-left: 5px;
      color: #ec7422;
    }
  }

  div.comment-list {
    display: flex;
    flex-direction: column;
    border-top: 1px solid #eee;

    div.no-comment {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-bottom: 1px solid #eee;
      font-size: 16px;
      color: rgb(34, 34, 34);
    }

    div.comment {
      padding: 26px 22px;
      border-bottom: 1px solid #eee;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 10px;
    }
  }
`;

export default StudyBoardComments;
