/* eslint-disable*/
import React, { Fragment, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
} from 'react-table';
import ReactTooltip from 'react-tooltip';
import { LinearProgress } from '@material-ui/core';
import styled from 'styled-components';

import { useDebounce, useFetchTaskData } from 'hooks';
import { downloadAllResult, downloadPersonalTaskResult } from 'services';
import { MESSAGES } from '../../constants';
import FileSaver from 'file-saver';
import { errorSwal } from 'utilities';
import { TASK_RESULT_COLUMNS } from '../Board/Columns';
import { Pagination, SearchInput } from '../common';
import { CardBody, Wrapper } from '../layouts/common';

const TaskResultListByParent = () => {
  const { taskId } = useParams();

  const [pageNum, setPageNum] = useState(1);
  const [pageSize] = useState(10);
  const [keyword, setKeyword] = useState('');
  const debouncedKeyword = useDebounce(keyword, 300);

  const { taskResultByTaskId } = useFetchTaskData({
    taskId,
    pageSize,
    pageNum,
    keyword: debouncedKeyword,
  });
  const { data } = taskResultByTaskId || {};

  const [isFileLoading, setIsFileLoading] = useState(false);

  //Table Setting
  const columns = useMemo(() => TASK_RESULT_COLUMNS, []);
  const tableData = useMemo(() => (data && data.list) || [], [data]);
  const tableInstance = useTable(
    {
      columns,
      data: tableData,
      initialState: { pageIndex: 0 },
    },
    useFilters,
    useGlobalFilter,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page,
  } = tableInstance;

  // const { globalFilter, pageIndex } = state;

  const handleDownload = async (taskId) => {
    setIsFileLoading(true);
    try {
      const response = await downloadAllResult({ taskId });

      if (response.status === 200) {
        const contentDisposition = response.headers['content-disposition'];

        let fileName = 'default_filename.zip';
        if (contentDisposition && contentDisposition.includes('filename=')) {
          const fileNameMatch = contentDisposition.match(
            /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
          );
          if (fileNameMatch && fileNameMatch[1]) {
            fileName = decodeURIComponent(
              fileNameMatch[1].replace(/['"]/g, '')
            );
          }
        }

        FileSaver.saveAs(response.data, fileName);
      }
      setIsFileLoading(false);
    } catch (err) {
      setIsFileLoading(false);
      await errorSwal({ text: MESSAGES.FILE_DOWNLOAD_FAIL });
    }
  };

  const handleDownloadPersonal = async (id) => {
    try {
      const response = await downloadPersonalTaskResult({ id });
      if (response) {
        FileSaver.saveAs(
          response.data,
          decodeURIComponent(
            response.headers['content-disposition'].split('filename=')[1]
          )
        );
      }
    } catch (error) {
      await errorSwal({ text: MESSAGES.FILE_DOWNLOAD_FAIL });
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="table-responsive">
          <div
            style={{ width: '100%' }}
            className="d-sm-flex justify-content-between"
          >
            <div
              className="read-content-attachment"
              onClick={() => handleDownload(taskId)}
            >
              <StyledH6 style={{ cursor: 'pointer' }}>
                {isFileLoading ? (
                  <LoadingBox>
                    <LinearProgress style={{ backgroundColor: '#eee' }} />
                    <span>로딩중</span>
                  </LoadingBox>
                ) : (
                  <p>
                    <i className="fa fa-download mb-2" />
                    <span>제출된 과제 일괄 다운로드</span>
                  </p>
                )}
              </StyledH6>
            </div>

            <SearchInput value={keyword} setValue={setKeyword} />
          </div>

          <table
            {...getTableProps()}
            className="table filtering-table table-responsive-lg"
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="" {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell, i) => {
                      return (
                        <Fragment key={i}>
                          <td {...cell.getCellProps()}>
                            {i === 3 ? (
                              <div style={{ paddingLeft: '10px' }}>
                                <ReactTooltip place="bottom" />

                                <StyledIcon
                                  className="fa-solid fa-download mr-1"
                                  data-tip="파일 다운로드"
                                  onClick={() =>
                                    handleDownloadPersonal(cell.row.original.id)
                                  }
                                ></StyledIcon>
                              </div>
                            ) : (
                              cell.render('Cell')
                            )}
                          </td>
                        </Fragment>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>

          {page.length === 0 && (
            <div className="d-flex justify-content-center mt-5 mb-5 text-primary">
              검색 결과가 없습니다.
            </div>
          )}

          <div className="text-center">
            {page.length !== 0 && (
              <Pagination
                page={page}
                pageNum={pageNum}
                setPageNum={setPageNum}
                data={data || {}}
                maxPostPage={data?.pages}
              />
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledH6 = styled.h6`
  max-width: 250px;

  p {
    color: #ec7422;
    transition: 0.2s;

    &:hover {
      filter: contrast(120%);
    }
  }

  div.MuiLinearProgress-bar.MuiLinearProgress-barColorPrimary {
    background-color: #777 !important;
  }
`;

const LoadingBox = styled.div`
  text-align: center;

  span {
    display: inline-block;
    padding-top: 4px;
  }
`;

const StyledIcon = styled.i`
  cursor: pointer;
  transition: 0.2s;

  :hover {
    color: #ec7422;
  }
`;

export default TaskResultListByParent;
