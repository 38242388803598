import React from 'react';
import { CardBody, Wrapper } from '../layouts/common';
import { Badge } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router-dom';
import FileSaver from 'file-saver';
import styled from 'styled-components';

import { useFetchTaskData } from 'hooks';
import { downloadTaskAttachment } from 'services';
import { errorSwal } from 'utilities';
import { MESSAGES } from 'constants';
import { UserSection } from '../common';

const TaskDetailView = () => {
  const history = useHistory();
  const { taskId } = useParams();

  const { taskDetailFile, taskDetail } = useFetchTaskData({
    taskId,
    id: taskId,
  });
  const { data: taskFile } = taskDetailFile || {};
  const { data: detail } = taskDetail || {};

  const handleBack = () => {
    history.goBack();
  };

  const handleDownloadAttachment = async (taskId) => {
    try {
      const response = await downloadTaskAttachment({ taskId });
      if (response) {
        FileSaver.saveAs(
          response.data,
          decodeURIComponent(
            response.headers['content-disposition'].split('filename=')[1]
          )
        );
      }
    } catch (err) {
      await errorSwal({ text: MESSAGES.FILE_INVALID });
    }
  };

  return (
    <Wrapper>
      <CardBody>
        <div className="right-box-padding">
          <div className="toolbar mb-2" role="toolbar">
            <div className="bootstrap-badge">
              <Badge variant="info badge-sm mr-2">
                과정 : {detail?.className}
              </Badge>
              <Badge variant="light badge-sm mr-2">
                조회수 : {detail?.hitCount}
              </Badge>
            </div>
          </div>
          <div className="read-content">
            <div className="media pt-3 d-sm-flex d-block justify-content-between">
              <UserSection
                dataA={detail?.userName}
                dataB={detail?.userId}
                dataC={(detail?.createdDateTime || '')
                  .replace('T', ' ')
                  .slice(0, 16)}
              />
              <div className="clearfix mb-3">
                <div
                  onClick={handleBack}
                  className="btn btn-primary px-3 light"
                >
                  <i className="fa fa-reply"></i>
                </div>
              </div>
            </div>

            <hr />

            <div className="media mb-3 mt-4">
              <div className="media-body">
                <span className="pull-right"></span>
                <h3 className="my-1 text-primary">{detail?.title}</h3>
              </div>
            </div>

            <div className="read-content-body">
              <p
                dangerouslySetInnerHTML={{
                  __html: detail?.content,
                }}
              />
            </div>

            <StyledRangeBox>
              <h6>과제 제출 기간</h6>
              <span>
                {detail?.startDate} ~ {detail?.endDate}
              </span>
            </StyledRangeBox>

            {taskFile?.originalFileName && (
              <>
                <hr />
                <StyledAttachmentsBox>
                  <h6>
                    <img src="/icons/appendix.png" alt="" />
                    <span>첨부파일</span>
                  </h6>

                  {taskFile && (
                    <p onClick={() => handleDownloadAttachment(taskId)}>
                      <span>{taskFile.originalFileName || '-'}</span>
                    </p>
                  )}
                </StyledAttachmentsBox>
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Wrapper>
  );
};

const StyledRangeBox = styled.div`
  width: 100%;
  max-width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 3px solid #ec7422;
  border-radius: 15px;
  padding: 20px;
  margin: 10px auto;

  h6 {
    font-size: 1.2rem;
    display: grid;
    color: #ec7422;
  }
`;

const StyledAttachmentsBox = styled.div`
  h6 {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: bold;

    span {
      padding-top: 2px;
      line-height: 0.9;
    }
  }

  p {
    margin-bottom: 5px;
    width: 100%;
    display: flex;
    gap: 5px;
    align-items: center;
    cursor: pointer;
    transition: 0.2s;

    :hover {
      text-decoration: underline;
    }
  }
`;

export default TaskDetailView;
