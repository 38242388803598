import { useQuery } from 'react-query';

import { fetchAdminLoginInfo } from 'services';

const useAdminLoginInfo = () => {
  const queryReturns = useQuery({
    queryKey: ['fetchAdminLoginInfo'],
    queryFn: async () => await fetchAdminLoginInfo(),
    select: (res) => res.data.data,
    retry: 1,
    retryDelay: 500,
    refetchOnMount: true,
    refetchOnReconnect: true,
  });
  return { ...queryReturns };
};

export default useAdminLoginInfo;
