import React from 'react';

const GlobalFilter = ({ setFilter }) => {
  return (
    <div style={{ width: '100%' }}>
      <input
        className="ml-2 input-search form-control height-38px"
        placeholder="검색어를 입력하세요."
        onChange={(e) => setFilter(e.target.value)}
        style={{ float: 'right', width: '200px' }}
      />
    </div>
  );
};

export default GlobalFilter;
