import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  changeStudyGroupDetail,
  changeStudyGroupStatus,
  changeStudyRoomApprove,
  changeStudyRoomZoomLink,
  fetchStudyGroupDetail,
  fetchStudyGroupDetailRoom,
  fetchStudyGroupList,
  fetchStudyMembers,
  fetchStudyPosts,
  fetchStudyRoomReserveList,
  fetchZoomLinkList,
  removeStudyGroup,
  removeStudyPost,
  removeStudyRoomReserve,
} from 'services/studyGroup';

const useStudyGroup = ({
  pageNum,
  pageSize,
  keyword,
  type,
  studyGroupId,
  groupId,
}) => {
  const queryClient = useQueryClient();

  /* 스터디그룹 목록 리스트 */
  const studyGroupList = useQuery({
    queryKey: ['fetchStudyGroupList', { pageNum, pageSize, keyword, groupId }],
    queryFn: async () => {
      const response = await fetchStudyGroupList({
        pageNum,
        pageSize,
        keyword,
        groupId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!pageNum && type === 'list',
  });

  /* 스터디그룹 상태 변경 */
  const editStudyGroupStatus = useMutation({
    mutationFn: async ({ studyGroupId, hasConfirm }) => {
      return await changeStudyGroupStatus({ studyGroupId, hasConfirm });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupList', { pageNum, pageSize, keyword }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupList', { pageNum, pageSize, keyword }],
      }),
  });

  /* 스터디그룹 삭제 */
  const deleteStudyGroup = useMutation({
    mutationFn: async ({ studyGroupId }) => {
      return await removeStudyGroup({ studyGroupId });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupList', { pageNum, pageSize, keyword }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupList', { pageNum, pageSize, keyword }],
      }),
  });

  /* 스터디그룹 상세 조회 */
  const studyGroupDetail = useQuery({
    queryKey: ['fetchStudyGroupDetail', { studyGroupId }],
    queryFn: async () => {
      const response = await fetchStudyGroupDetail({ studyGroupId });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: type === 'studyGroupDetail',
  });

  /* 스터디그룹 상세 수정 */
  const editStudyGroupDetail = useMutation({
    mutationFn: async ({ studyGroupId, body }) => {
      return await changeStudyGroupDetail({ studyGroupId, body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupDetail', { studyGroupId }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyGroupDetail', { studyGroupId }],
      }),
  });

  /* 스터디룸 예약 상세 내용 */
  const studyGroupDetailRoom = useQuery({
    queryKey: ['fetchStudyGroupDetailRoom', { studyGroupId }],
    queryFn: async () => {
      const response = await fetchStudyGroupDetailRoom({ studyGroupId });
      return response.data;
    },
    retry: 1,
    retryDelay: 500,
    enabled: !!studyGroupId && type === 'studyGroupDetail',
  });

  /* 스터디그룹 게시글 목록 */
  const studyBoardList = useQuery({
    queryKey: ['fetchStudyPosts', { pageNum, pageSize, keyword, studyGroupId }],
    queryFn: async () => {
      const response = await fetchStudyPosts({
        pageNum,
        pageSize,
        keyword,
        studyGroupId,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!studyGroupId,
  });

  /* 스터디그룹 게시글 삭제 */
  const deletePost = useMutation({
    mutationFn: async ({ id }) => {
      return await removeStudyPost({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyPosts', { pageNum, pageSize, keyword }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyPosts', { pageNum, pageSize, keyword }],
      }),
  });

  /* 스터디그룹 멤버 리스트 조회 */
  const studyGroupMembers = useQuery({
    queryKey: [
      'fetchStudyMembers',
      { studyGroupId, pageNum, pageSize, keyword },
    ],
    queryFn: async () => {
      const response = await fetchStudyMembers({
        studyGroupId,
        pageNum,
        pageSize,
        keyword,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!pageNum && type === 'studyGroupDetail',
  });

  /* 스터디룸 예약 신청 리스트 */
  const studyRoomReserveList = useQuery({
    queryKey: ['fetchStudyRoomReserveList', { pageNum, pageSize, keyword }],
    queryFn: async () => {
      const response = await fetchStudyRoomReserveList({
        pageNum,
        pageSize,
        keyword,
      });
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: !!pageNum && type === 'studyRoom',
  });

  /* 스터디룸 예약 신청 승인/해제 */
  const updateStudyRoomApprove = useMutation({
    mutationFn: async ({ studyRoomId, body }) => {
      return await changeStudyRoomApprove({ studyRoomId, body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum, pageSize: 10 }],
      }),
  });

  /* 스터디룸 예약 신청 삭제 */
  const deleteStudyRoomReserve = useMutation({
    mutationFn: async ({ id }) => {
      return await removeStudyRoomReserve({ id });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum, pageSize, keyword }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum, pageSize, keyword }],
      }),
  });

  /* 스터디룸 줌 링크 조회 */
  const zoomLinkList = useQuery({
    queryKey: ['fetchZoomLinkList'],
    queryFn: async () => {
      const response = await fetchZoomLinkList();
      return response.data.data;
    },
    retry: 1,
    retryDelay: 500,
    keepPreviousData: true,
    enabled: type === 'studyRoom',
  });

  /* 스터디룸 예약 승인 시 줌 링크 등록 */
  const updateStudyRoomZoomLink = useMutation({
    mutationFn: async ({ studyRoomId, body }) => {
      return await changeStudyRoomZoomLink({ studyRoomId, body });
    },
    onSettled: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum: 1, pageSize: 10 }],
      }),
    onSuccess: () =>
      queryClient.invalidateQueries({
        queryKey: ['fetchStudyRoomReserveList', { pageNum: 1, pageSize: 10 }],
      }),
  });

  return {
    studyGroupList,
    editStudyGroupStatus,
    studyGroupDetail,
    studyGroupDetailRoom,
    editStudyGroupDetail,
    deleteStudyGroup,

    studyGroupMembers,

    studyBoardList,
    deletePost,

    studyRoomReserveList,
    updateStudyRoomApprove,
    deleteStudyRoomReserve,
    zoomLinkList,
    updateStudyRoomZoomLink,
  };
};

export default useStudyGroup;
